import { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useMethods, useData, requiredRule, dayjs } from 'utils';
import { Modal, Preloader, TimePickerField } from 'components';
import { checkInAction } from 'actions';
import { dateServerFormat, timeClientFormat, timeServerFormat } from 'const';

export const AddCourierAttendance = (props) => {
	const { children, courier = {} } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { couriers = [] } = useData();
	const { __ } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				employee_id: courier.id || null,
				started_at: null
			});
		}
	}, [visible, courier]);

	const couriersList = couriers.map((item) => ({ value: item.id, label: item.name }));

	const submitHandler = async (values) => {
		setPending(true);
		const success = await checkInAction({
			employee_id: values.employee_id,
			started_at: `${dayjs().format(dateServerFormat)} ${dayjs(values.started_at).format(timeServerFormat)}`
		});
		setPending(false);
		if (success) {
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Добавление посещения курьера')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="employee_id" label={__('Курьер')} required rules={[requiredRule(__)]}>
								<Select dropdownClassName="form__dropdown" options={couriersList} disabled={courier.id} />
							</Form.Item>
							<Form.Item className="form__item" label={__('Время начала смены')} name="started_at" required rules={[requiredRule(__)]}>
								<TimePickerField format={timeClientFormat} allowClear={true} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Добавить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
