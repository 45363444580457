import axios from 'axios';
import { getCookie, getLocale, json2data, json2formData, notify } from 'utils';
import { refreshTokenAction } from 'actions';

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API}/api`,
	headers: {
		'Content-Type': 'application/json'
	}
});

let refreshProcess = null;
const refresh = () => {
	if (!refreshProcess) {
		refreshProcess = refreshTokenAction();
	}
	return refreshProcess.then((result) => {
		refreshProcess = null;
		return result;
	});
};

const reRequest = (config) =>
	new Promise((resolve) => {
		instance
			.request(config)
			.then((response) => {
				resolve(getResponse(response));
			})
			.catch((error) => {
				const { response } = error;
				resolve(getResponse(response));
			});
	});

const waitRequest = (config, headers = {}) =>
	new Promise((resolve) => {
		const timeout = headers['retry-after'] ? +headers['retry-after'] * 1000 : 5000;
		setTimeout(() => {
			resolve(reRequest(config));
		}, timeout);
	});

const getConfig = (config) => {
	const { headers = {} } = config;

	const nextConfig = {
		...config,
		headers: {
			'Accept-Language': getLocale(),
			Authorization: getCookie('accessToken', ''),
			...headers
		}
	};

	if (config.params) {
		nextConfig.params = headers['Content-Type'] === 'multipart/form-data' ? json2formData(config.params) : json2data(config.params);
	}

	return nextConfig;
};

const getResponse = async (response = {}) => {
	if (response.data instanceof Blob) {
		return response;
	}

	let responseData = {};

	if (response.data && response.data.notyMessage) {
		const type = response.data.success ? 'success' : 'error';
		notify(type, response.data.notyMessage);
	}

	if ([200, 201, 204].includes(response.status)) {
		responseData = response.data || {};
	} else {
		if (response.status === 401) {
			const { success } = await refresh();
			if (success) {
				const config = {
					...response.config,
					headers: {
						...response.config.headers,
						Authorization: getCookie('accessToken', '')
					}
				};
				responseData = await reRequest(config);
			}
		} else if (response.status === 429) {
			responseData = await waitRequest(response.config, response.headers);
		}
	}

	return responseData;
};

export const api = {
	get: (url, config = {}) => {
		return new Promise((resolve) => {
			instance
				.get(url, getConfig(config))
				.then((response) => {
					resolve(getResponse(response));
				})
				.catch((error) => {
					const { response } = error;
					resolve(getResponse(response));
				});
		});
	},
	post: (url, data, config = {}) => {
		const { headers = {} } = config;
		return new Promise((resolve) => {
			const nextData = headers['Content-Type'] === 'multipart/form-data' ? json2formData(data) : json2data(data);
			instance
				.post(url, nextData, getConfig(config))
				.then((response) => {
					resolve(getResponse(response));
				})
				.catch((error) => {
					const { response } = error;
					resolve(getResponse(response));
				});
		});
	},
	put: (url, data, config = {}) => {
		const { headers = {} } = config;
		return new Promise((resolve) => {
			const nextData = headers['Content-Type'] === 'multipart/form-data' ? json2formData(data) : json2data(data);
			instance
				.put(url, nextData, getConfig(config))
				.then((response) => {
					resolve(getResponse(response));
				})
				.catch((error) => {
					const { response } = error;
					resolve(getResponse(response));
				});
		});
	},
	delete: (url, config = {}) => {
		return new Promise((resolve) => {
			instance
				.delete(url, getConfig(config))
				.then((response) => {
					resolve(getResponse(response));
				})
				.catch((error) => {
					const { response } = error;
					resolve(getResponse(response));
				});
		});
	}
};
