import { useEffect, useState } from 'react';
import { useMethods, makeYears, makeMonths, dayjs } from 'utils';
import { DocsTransactionsFilter, DocsTransactions } from 'components';
import { getRestaurantsAction, getReportsTransactionsAction } from 'actions';

export const DocsTransactionsTab = () => {
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const docsTransFiltersDefault = {
				restaurant_id: null,
				month: +dayjs().format('M'),
				year: +dayjs().format('YYYY')
			};
			const docsTransFilters = { ...docsTransFiltersDefault };
			const [restaurants, docsTrans] = await Promise.all([getRestaurantsAction(), getReportsTransactionsAction(docsTransFilters)]);
			setPending(false);
			update({
				docsTransFiltersDefault,
				docsTransFilters,
				restaurants,
				docsTrans,
				years: makeYears(),
				months: makeMonths(__)
			});
		})();

		return () => {
			update({
				docsTransFiltersDefault: {},
				docsTransFilters: {},
				docsTrans: {}
			});
		};
	}, []);

	return (
		<>
			<DocsTransactionsFilter setPending={setPending} />
			<DocsTransactions pending={pending} />
		</>
	);
};
