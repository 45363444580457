import { useMethods, useData, formatDigits, access } from 'utils';
import { Table, Row, Col, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as OutflowIcon } from 'assets/svg/outflow.svg';
import { ReactComponent as InflowIcon } from 'assets/svg/inflow.svg';
import { CourierOutflow, CourierInflow } from 'components';

export const CouriersAccountTable = (props) => {
	const { pending } = props;
	const { couriersAccount = [] } = useData();
	const { __ } = useMethods();

	const sortOrders = {
		busy: 0,
		free: 1,
		off: 2
	};
	const filterCouriers = couriersAccount.sort(function (a, b) {
		if (sortOrders[a.status] > sortOrders[b.status]) {
			return 1;
		} else if (sortOrders[a.status] < sortOrders[b.status]) {
			return -1;
		}
		return 0;
	});

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1550
			}}
			columns={[
				{
					title: __('Курьер'),
					dataIndex: 'name',
					render: (name, record) => {
						return (
							<span>
								<span className={`courier-status courier-status--${record.status}`} />
								{name}
							</span>
						);
					}
				},
				{
					title: __('Кол-во доставленных заказов'),
					dataIndex: 'orders_quantity'
				},
				{
					title: __('Тариф за заказ, грн'),
					dataIndex: 'per_order_rate',
					render: (sum) => {
						return formatDigits({ number: sum, toFixed: 2, defaultValue: 0 });
					}
				},
				{
					title: __('Текущий километраж, км'),
					dataIndex: 'total_distance'
				},
				{
					title: __('Наличные для изъятия, грн'),
					dataIndex: 'cash_orders_total',
					render: (sum) => {
						return formatDigits({ number: sum, toFixed: 2, defaultValue: 0 });
					}
				},
				{
					title: __('Выплаты курьеру, грн'),
					dataIndex: 'total_payout',
					render: (sum) => {
						return formatDigits({ number: sum, toFixed: 2, defaultValue: 0 });
					}
				},
				{
					title: __('Действия'),
					align: 'right',
					render: (_, record) => (
						<Row gutter={10} justify="end">
							{access('couriers-orders-cash-hand-over') ? (
								<Col>
									<CourierOutflow courier={record}>
										<Tooltip placement="topRight" title={__('Изъять наличные у курьера')}>
											<button className="button button--trans-main button--icon-xs">
												<OutflowIcon />
											</button>
										</Tooltip>
									</CourierOutflow>
								</Col>
							) : null}
							{access('couriers-daily-income-payout') ? (
								<Col>
									<CourierInflow courier={record}>
										<Tooltip placement="topRight" title={__('Расчет за смену с курьером')}>
											<button className="button button--trans-error button--icon-xs">
												<InflowIcon />
											</button>
										</Tooltip>
									</CourierInflow>
								</Col>
							) : null}
						</Row>
					)
				}
			]}
			dataSource={filterCouriers}
			pagination={false}
		/>
	);
};
