let store = {};

export const setStore = (key, value) => {
	if (typeof key === 'object' && key !== null) {
		store = {
			...store,
			...key
		};
	} else if (key) {
		store[key] = value;
	}
};

export const getStore = (key, defaultValue = null) => {
	return store[key] || defaultValue;
};

export const removeStore = (key) => {
	const newStore = {};
	Object.keys(store).forEach((id) => {
		if (id !== key) {
			newStore[id] = store[id];
		}
	});
	store = newStore;
};

export const clearStore = () => {
	store = {};
};
