import { api, dayjs } from 'utils';
import { dateServerFormat } from 'const';

export const getCurrentCashboxAction = async () => {
	const response = await api.get(`/cashboxes/shift/opened`);
	return response.data && !Array.isArray(response.data) ? response.data : {};
};

export const closeCashboxAction = async () => {
	const response = await api.put(`/cashboxes/shift/close`);
	return response.success;
};

export const openCashboxAction = async () => {
	const response = await api.post(`/cashboxes/shift/open`);
	return response.data && !Array.isArray(response.data) ? response.data : {};
};

export const createCashboxInflowAction = async (values = {}) => {
	const response = await api.post(`/cashboxes/shift/transactions/add-inflow`, values, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response ? response.data : null;
};

export const createCashboxOutflowAction = async (values = {}) => {
	const response = await api.post(`/cashboxes/shift/transactions/add-outflow`, values, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response ? response.data : null;
};

export const updateCashboxTransactionAction = async (id, values = {}) => {
	const response = await api.post(`/cashboxes/shift/transactions/${id}`, values, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response ? response.data : null;
};

export const deleteCashboxTransactionAction = async (id) => {
	const response = await api.delete(`/cashboxes/shift/transactions/${id}`);
	return response.success;
};

export const printXReportAction = async () => {
	const response = await api.get(`/cashboxes/shift/print-x-report`);
	return response.success;
};

export const deleteCashboxFileAction = async (id) => {
	const response = await api.delete(`/cashboxes/shift/transactions/file/${id}`);
	return response.success;
};

export const addCashboxFilesAction = async (id, values = {}) => {
	const response = await api.post(`/cashboxes/shift/transactions/${id}/add-files`, values, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.success;
};

export const getCashboxShiftsAction = async (params = {}) => {
	const response = await api.get(`/cashboxes/shift/list-for-select`, { params });
	return response.data || [];
};

export const getCashboxAction = async (id) => {
	const response = await api.get(`/cashboxes/shift/${id}`);
	return response.data && !Array.isArray(response.data) ? response.data : {};
};

export const getFiscalTransactionsAction = async (values = {}) => {
	const { date, ...rest } = values;
	const params = rest;
	params.date = date ? dayjs(date).format(dateServerFormat) : null;
	const response = await api.get(`/fiscal-transactions`, { params });
	return response.data && Array.isArray(response.data) ? response.data : [];
};

export const printFiscalXReportAction = async (id) => {
	const response = await api.get(`/fiscal-transactions/print-x-report/${id}`);
	return response.success;
};

export const printFiscalZReportAction = async (id) => {
	const response = await api.get(`/fiscal-transactions/print-z-report/${id}`);
	return response.success;
};

export const addFiscalTransactionsAction = async (values = {}) => {
	const response = await api.post(`/fiscal-transactions`, values);
	return response.data;
};

export const getFiscalTransactionTypesAction = async () => {
	const response = await api.get(`/fiscal-transaction-types`);
	return response.data && Array.isArray(response.data) ? response.data : [];
};

export const getFiscalTransactionPendingAction = async (id) => {
	const response = await api.get(`/fiscal-transactions/${id}/pending`);
	return response.success;
};
