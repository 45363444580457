import { CashboxTable, FinishedCashboxInfo, Preloader } from 'components';
import { useData } from 'utils';
import { useState } from 'react';

export const FinishedCashboxTab = () => {
	const { cashboxArchive = {} } = useData();
	const [pending, setPending] = useState(false);

	return (
		<Preloader spinning={pending}>
			<FinishedCashboxInfo setPending={setPending} />
			<CashboxTable cashbox={cashboxArchive} setPending={setPending} isArchive={true} />
		</Preloader>
	);
};
