import { useState, useEffect } from 'react';
import { Form, Row, Col, Switch } from 'antd';
import { useMethods, useData, dayjs, requiredRule, notify } from 'utils';
import { Modal, Preloader, TimePickerField } from 'components';
import { timeClientFormat, dateTimeServerFormat, timeServerFormat } from 'const';
import { editPlannedShiftAction, getShiftsMonthlyScheduleAction, getShiftsMonthlyScheduleStatisticAction } from 'actions';

export const EditPlannedShift = (props) => {
	const { children, shift = {}, onOpen } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { scheduleFilters = {} } = useData();
	const { __, update } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				planned_started_at: shift.planned_started_at ? dayjs(shift.planned_started_at, dateTimeServerFormat) : null,
				planned_finished_at: shift.planned_finished ? dayjs(shift.planned_finished, dateTimeServerFormat) : null,
				planned_is_day_off: shift.planned_is_day_off || false
			});
		}
	}, [visible, shift]);

	const submitHandler = async (values) => {
		setPending(true);
		const updateData = {};
		const success = await editPlannedShiftAction(shift.id, {
			planned_started_at: values.planned_started_at ? `${shift.date} ${dayjs(values.planned_started_at).format(timeServerFormat)}` : null,
			planned_finished_at: values.planned_finished_at ? `${shift.date} ${dayjs(values.planned_finished_at).format(timeServerFormat)}` : null,
			planned_is_day_off: !!values.planned_is_day_off
		});
		if (success) {
			const [schedule, scheduleStatistic] = await Promise.all([
				getShiftsMonthlyScheduleAction(scheduleFilters),
				getShiftsMonthlyScheduleStatisticAction(scheduleFilters)
			]);
			updateData.schedule = schedule;
			updateData.scheduleStatistic = scheduleStatistic;
			notify('success', __('Смена изменена'));
			setPending(false);
			update(updateData);
			setVisible(false);
		} else {
			setPending(false);
		}
	};

	const openHandler = () => {
		if (onOpen) {
			onOpen();
		}
		setVisible(true);
	};

	return (
		<>
			<div onClick={openHandler}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Редактирование смены')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Row gutter={20}>
								<Col span={24} sm={12}>
									<Form.Item
										className="form__item"
										label={__('Время начала смены')}
										name="planned_started_at"
										required
										rules={[requiredRule(__)]}
									>
										<TimePickerField format={timeClientFormat} allowClear={true} />
									</Form.Item>
								</Col>
								<Col span={24} sm={12}>
									<Form.Item
										className="form__item"
										label={__('Время конца смены')}
										name="planned_finished_at"
										required
										rules={[requiredRule(__)]}
									>
										<TimePickerField format={timeClientFormat} allowClear={true} />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item className="form__item" name="planned_is_day_off" label={__('Выходной')} valuePropName="checked">
								<Switch checkedChildren={__('Да')} unCheckedChildren={__('Нет')} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Сохранить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
