import { useEffect } from 'react';
import { Form, Select, Tooltip, Input, DatePicker } from 'antd';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMethods, useData, downloadFile, access, dayjs } from 'utils';
import { getReportsSalesAction } from 'actions';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';
import { dateClientFormat, dateServerFormat } from 'const';

let timeoutId = null;

export const DocsSalesFilter = (props) => {
	const { setPending } = props;
	const { restaurants = [], dishCategories = [], docsSalesFiltersDefault = {}, docsSalesFilters = {}, user = {} } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const fileName = `sales-report-${docsSalesFilters.restaurant_id}${
		docsSalesFilters.category_ids && docsSalesFilters.category_ids.length ? `-${docsSalesFilters.category_ids.join('-')}` : ''
	}-${docsSalesFilters.date_from}-${docsSalesFilters.date_to}${docsSalesFilters.product_name ? `-${docsSalesFilters.product_name}` : ''}.xlsx`;

	useEffect(() => {
		form.setFieldsValue({
			...docsSalesFiltersDefault,
			restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id
		});
	}, [docsSalesFiltersDefault]);

	const filterHandler = (changedValues, values) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(async () => {
			setPending(true);
			const filter = {
				...docsSalesFilters,
				...values
			};
			const docsSales = await getReportsSalesAction(filter);
			setPending(false);
			update({
				docsSalesFilters: filter,
				docsSales
			});
		}, 500);
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(docsSalesFiltersDefault);
		const docsSales = await getReportsSalesAction(docsSalesFiltersDefault);
		setPending(false);
		update({
			docsSalesFilters: docsSalesFiltersDefault,
			docsSales
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const docsSales = await getReportsSalesAction(docsSalesFilters);
		setPending(false);
		update({
			docsSales
		});
	};

	const downloadXlsFile = (event) => {
		event.preventDefault();
		downloadFile(`/reports/generate-product-sales-file`, fileName, {
			restaurant_id: docsSalesFilters.restaurant_id,
			category_ids: docsSalesFilters.category_ids,
			date_from: docsSalesFilters.date_from ? dayjs(docsSalesFilters.date_from).format(dateServerFormat) : null,
			date_to: docsSalesFilters.date_to ? dayjs(docsSalesFilters.date_to).format(dateServerFormat) : null,
			product_name: docsSalesFilters.product_name
		});
	};

	return (
		<div className="filter filter--5">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						{access('docs-reports-product-sales-restaurant-filter') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="restaurant_id" label={__('Ресторан')}>
									<Select
										dropdownClassName="form__dropdown"
										options={restaurants.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
						<div className="filter__item">
							<Form.Item className="form__item" name="category_ids" label={__('Категория')}>
								<Select
									dropdownClassName="form__dropdown"
									options={dishCategories.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									mode="multiple"
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="product_name" label={__('Название блюда')}>
								<Input placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" label={__('Дата от')} name="date_from">
								<DatePicker
									format={dateClientFormat}
									allowClear={true}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" label={__('Дата до')} name="date_to">
								<DatePicker
									format={dateClientFormat}
									allowClear={true}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
								/>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
				{access('docs-reports-dishes-file') ? (
					<div className="filter__button">
						<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
							<a href={`/public/${fileName}`} className="button button--default button--icon-def" onClick={downloadXlsFile}>
								<XlsIcon />
							</a>
						</Tooltip>
					</div>
				) : null}
			</div>
		</div>
	);
};
