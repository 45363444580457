import { MainLayout } from 'layouts';
import { useData, useMethods, access } from 'utils';
import { Tabs } from 'antd';
import { ActiveOrdersTab, FinishedOrdersTab } from 'components';

export const OrdersPage = () => {
	const { orders = [] } = useData();
	const { __ } = useMethods();

	return (
		<MainLayout seo={{ title: `${__('Заказы')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				{access('orders-tab-in-progress') ? (
					<Tabs.TabPane tab={`${__('Текущие заказы')} (${orders.length})`} key="active">
						<ActiveOrdersTab />
					</Tabs.TabPane>
				) : null}
				{access('orders-tab-finished') ? (
					<Tabs.TabPane tab={__('Архив заказов')} key="finished">
						<FinishedOrdersTab />
					</Tabs.TabPane>
				) : null}
			</Tabs>
		</MainLayout>
	);
};
