import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';

const MapWrapper = withScriptjs(
	withGoogleMap((props) => {
		const { mapProps = {}, children } = props;

		return (
			<GoogleMap
				ref={(map) => {
					if (map && mapProps.bounds && Array.isArray(mapProps.bounds)) {
						const bounds = new window.google.maps.LatLngBounds();
						mapProps.bounds.forEach(({ lat, lng }) => {
							bounds.extend(new window.google.maps.LatLng(lat, lng));
						});
						map.fitBounds(bounds);
					}
					return map;
				}}
				defaultZoom={16}
				options={{
					streetViewControl: false,
					rotateControl: false,
					mapTypeControl: false
				}}
				{...mapProps}
			>
				{children}
			</GoogleMap>
		);
	})
);

export const Map = (prop) => {
	const { containerProps = {}, props = {}, mapProps = {}, children } = prop;

	return (
		<div {...containerProps}>
			<MapWrapper
				mapProps={mapProps}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
				loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
				containerElement={<div style={{ height: `100%`, width: `100%` }} />}
				mapElement={<div style={{ height: `100%`, width: `100%` }} />}
				{...props}
			>
				{children}
			</MapWrapper>
		</div>
	);
};
