import { useEffect } from 'react';
import { Form, Input, Select, Tooltip, Switch } from 'antd';
import { print, useData, useMethods, dayjs, access } from 'utils';
import { dateClientFormat } from 'const';
import { ClearOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import { getFinishedOrdersAction, getFinishedOrdersStatisticAction } from 'actions';
import { RangePickerField } from 'components';

export const FinishedOrdersFilter = (props) => {
	const { setPending } = props;
	const { finishedOrdersFiltersDefault = {}, finishedOrdersFilters = {}, paymentTypes = [], orderTypes = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(finishedOrdersFiltersDefault);
	}, [finishedOrdersFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...finishedOrdersFilters,
			...values,
			page: 1
		};
		const [finishedOrdersStatistic, finishedOrders] = await Promise.all([
			getFinishedOrdersStatisticAction(filter),
			getFinishedOrdersAction(filter)
		]);
		setPending(false);
		update({
			finishedOrdersFilters: {
				...finishedOrdersFilters,
				...filter
			},
			finishedOrdersStatistic,
			finishedOrders
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		const filters = {
			...finishedOrdersFiltersDefault,
			status: finishedOrdersFilters.status
		};
		form.setFieldsValue(filters);
		const [finishedOrdersStatistic, finishedOrders] = await Promise.all([
			getFinishedOrdersStatisticAction(filters),
			getFinishedOrdersAction(filters)
		]);
		setPending(false);
		update({
			finishedOrdersFilters: filters,
			finishedOrdersStatistic,
			finishedOrders
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const finishedOrders = await getFinishedOrdersStatisticAction(finishedOrdersFilters);
		setPending(false);
		update({
			finishedOrders
		});
	};

	const printTable = () => {
		print('#finished-orders .ant-table-content', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};

	return (
		<div className="filter filter--7">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						{access('orders-tab-finished-date-filter') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="delivery_date" label={__('Дата доставки')}>
									<RangePickerField
										format={dateClientFormat}
										showTime={false}
										disabledDate={(current) => current && current.endOf('day') > dayjs().endOf('day')}
										placeholder={__('Не выбрано')}
										separator="—"
										allowClear={false}
									/>
								</Form.Item>
							</div>
						) : null}
						<div className="filter__item">
							<Form.Item className="form__item" name="phone" label={__('Телефон')}>
								<Input type="tel" placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="address_name" label={__('Адрес')}>
								<Input placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="order_type_id" label={__('Тип заказа')}>
								<Select
									dropdownClassName="form__dropdown"
									options={orderTypes.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="payment_type_id" label={__('Тип оплаты')}>
								<Select
									dropdownClassName="form__dropdown"
									options={paymentTypes.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="day_id" label={__('Номер заказа')}>
								<Input placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<div className="filter__row">
								<div className="filter__col">
									<Form.Item className="form__item" name="with_discount" valuePropName="checked" label={__('Со скидкой')}>
										<Switch />
									</Form.Item>
								</div>
								<div className="filter__col">
									<Form.Item className="form__item" name="is_fiscal_printed" valuePropName="checked" label={__('С чеками')}>
										<Switch />
									</Form.Item>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Распечатать')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={printTable}>
							<PrinterOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
