import { useEffect, useState } from 'react';
import { CouriersAccountFilter, CouriersAccountTable } from 'components';
import { useMethods, dayjs } from 'utils';
import { getCouriersAccountAction } from 'actions';
import { dateServerFormat } from 'const';

export const CouriersAccountTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const couriersAccountFiltersDefault = {
				courier: null,
				date: dayjs()
			};
			const couriersAccountFilters = { ...couriersAccountFiltersDefault };
			const couriersAccount = await getCouriersAccountAction({
				courier: couriersAccountFilters.courier ? +couriersAccountFilters.courier : null,
				date: couriersAccountFilters.date ? dayjs(couriersAccountFilters.date).format(dateServerFormat) : null
			});
			setPending(false);
			update({
				couriersAccountFiltersDefault,
				couriersAccountFilters,
				couriersAccount
			});
		})();

		return () => {
			update({
				couriersAccountFiltersDefault: {},
				couriersAccountFilters: {},
				couriersAccount: []
			});
		};
	}, []);

	return (
		<>
			<CouriersAccountFilter setPending={setPending} />
			<CouriersAccountTable pending={pending} />
		</>
	);
};
