import { useState } from 'react';
import { AutoComplete, Form } from 'antd';
import { useMethods, requiredRule, nameFormatter } from 'utils';
import { clientSearchNameAction } from 'actions';

let timeoutId = null;

export const ClientNameField = (props) => {
	const { disabled } = props;
	const { __ } = useMethods();
	const [clients, setClients] = useState([]);

	const changeHandler = (value) => {
		if (value === undefined) {
			setClients([]);
		} else {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(async () => {
				if (value.length >= 2) {
					const result = await clientSearchNameAction(value);
					setClients(result.map(({ name }) => ({ value: name, label: name })));
				} else {
					setClients([]);
				}
			}, 500);
		}
	};

	return (
		<Form.Item className="form__item" name="name" label={__('ФИО')} normalize={nameFormatter} required rules={[requiredRule(__)]}>
			<AutoComplete allowClear={true} disabled={disabled} options={clients} autoClearSearchValue={false} onChange={changeHandler} />
		</Form.Item>
	);
};
