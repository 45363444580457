import { useEffect, useState } from 'react';
import { useMethods, useData, dayjs } from 'utils';
import { DocsCouriers } from 'components';
import { getRestaurantsAction, getReportsCouriersAction } from 'actions';

export const DocsCouriersTab = () => {
	const { user = {} } = useData();
	const { update } = useMethods();
	const [pending1, setPending1] = useState(false);
	const [pending2, setPending2] = useState(false);

	useEffect(() => {
		(async () => {
			setPending1(true);
			setPending2(true);
			const restaurants = await getRestaurantsAction();
			const docsCouriersFiltersDefault = {
				restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id,
				date1: dayjs(),
				date2: dayjs().subtract(1, 'day'),
				date3: dayjs(),
				date4: dayjs().subtract(1, 'year')
			};
			const docsCouriersFilters = {
				...docsCouriersFiltersDefault
			};
			const [docsCouriers1, docsCouriers2, docsCouriers3, docsCouriers4] = await Promise.all([
				getReportsCouriersAction({
					restaurant_id: docsCouriersFilters.restaurant_id,
					date: docsCouriersFilters.date1
				}),
				getReportsCouriersAction({
					restaurant_id: docsCouriersFilters.restaurant_id,
					date: docsCouriersFilters.date2
				}),
				getReportsCouriersAction({
					restaurant_id: docsCouriersFilters.restaurant_id,
					date: docsCouriersFilters.date3
				}),
				getReportsCouriersAction({
					restaurant_id: docsCouriersFilters.restaurant_id,
					date: docsCouriersFilters.date4
				})
			]);
			setPending1(false);
			setPending2(false);
			update({
				docsCouriersFiltersDefault,
				docsCouriersFilters,
				restaurants,
				docsCouriers1,
				docsCouriers2,
				docsCouriers3,
				docsCouriers4
			});
		})();

		return () => {
			update({
				docsCouriersFiltersDefault: {},
				docsCouriersFilters: {},
				docsCouriers1: [],
				docsCouriers2: [],
				docsCouriers3: [],
				docsCouriers4: []
			});
		};
	}, []);

	return (
		<>
			<DocsCouriers pending1={pending1} pending2={pending2} setPending1={setPending1} setPending2={setPending2} />
		</>
	);
};
