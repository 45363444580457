import { useEffect, useState } from 'react';
import { FinishedOrdersStatistic, FinishedOrdersFilter, FinishedOrders } from 'components';
import { getFinishedOrdersAction, getFinishedOrdersStatisticAction } from 'actions';
import { useMethods, dayjs } from 'utils';

export const FinishedOrdersTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const finishedOrdersFiltersDefault = {
				status: null,
				custom: null,
				day_id: null,
				phone: null,
				address_name: null,
				payment_type_id: null,
				order_type_id: null,
				with_discount: false,
				page: 1,
				per_page: 50,
				delivery_date: [dayjs(), dayjs()],
				is_fiscal_printed: false
			};
			const finishedOrdersStatistic = await getFinishedOrdersStatisticAction(finishedOrdersFiltersDefault);
			const { statuses = [] } = finishedOrdersStatistic;
			finishedOrdersFiltersDefault.status = statuses[0] ? statuses[0].id : null;
			const finishedOrders = await getFinishedOrdersAction(finishedOrdersFiltersDefault);
			const finishedOrdersFilters = { ...finishedOrdersFiltersDefault };
			setPending(false);
			update({
				finishedOrdersFiltersDefault,
				finishedOrdersFilters,
				finishedOrdersStatistic,
				finishedOrders
			});
		})();

		return () => {
			update({
				finishedOrdersFiltersDefault: {},
				finishedOrdersFilters: {},
				finishedOrdersStatistic: {},
				finishedOrders: {}
			});
		};
	}, []);

	return (
		<>
			<FinishedOrdersStatistic setPending={setPending} />
			<FinishedOrdersFilter setPending={setPending} />
			<FinishedOrders pending={pending} setPending={setPending} />
		</>
	);
};
