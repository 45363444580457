import { useEffect } from 'react';
import { DatePicker, Form, Input, Select, Tooltip } from 'antd';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { useData, useMethods, dayjs } from 'utils';
import { getWarehouseRestsAction } from 'actions';
import { dateClientFormat } from 'const';

export const WarehouseRestsFilter = (props) => {
	const { setPending } = props;
	const { warehouseRestsFiltersDefault = {}, warehouseRestsFilters = {}, warehouses = [], nomenclatureCategories = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(warehouseRestsFiltersDefault);
	}, [warehouseRestsFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...warehouseRestsFilters,
			...values,
			page: 1
		};
		const warehouseRests = await getWarehouseRestsAction(filter);
		setPending(false);
		update({
			warehouseRestsFilters: filter,
			warehouseRests
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(warehouseRestsFiltersDefault);
		const warehouseRests = await getWarehouseRestsAction(warehouseRestsFiltersDefault);
		setPending(false);
		update({
			warehouseRestsFilters: warehouseRestsFiltersDefault,
			warehouseRests
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const warehouseRests = await getWarehouseRestsAction(warehouseRestsFilters);
		setPending(false);
		update({
			warehouseRests
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="date" label={__('Дата')}>
								<DatePicker
									format={dateClientFormat}
									allowClear={true}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="name" label={__('Название номенклатуры')}>
								<Input allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="warehouse_id" label={__('Склад')}>
								<Select
									dropdownClassName="form__dropdown"
									options={warehouses.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="category_id" label={__('Категория')}>
								<Select
									dropdownClassName="form__dropdown"
									options={nomenclatureCategories.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
