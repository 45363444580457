import { useState, useEffect } from 'react';
import { Form, Select, InputNumber } from 'antd';
import { useMethods, useData, requiredRule, floatFormatter, print, money2str } from 'utils';
import { CashWarrant, Modal, Preloader } from 'components';
import { courierCashDebtAction, ordersCashHandOverAction } from 'actions';

export const CourierOutflow = (props) => {
	const { children, courier = {} } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { couriers = [] } = useData();
	const { __ } = useMethods();

	useEffect(() => {
		if (visible) {
			form.setFieldsValue({
				employee_id: courier.id || null,
				payed_sum: 0
			});
			(async () => {
				setPending(true);
				const cashData = await courierCashDebtAction(courier.id);
				form.setFieldsValue({
					payed_sum: cashData.cash_debt || 0
				});
				setPending(false);
			})();
		}
	}, [visible, courier]);

	const couriersList = couriers.map((item) => ({ value: item.id, label: item.name }));

	const submitHandler = async (values) => {
		setPending(true);
		const response = await ordersCashHandOverAction(values.employee_id, {
			payed_sum: values.payed_sum
		});
		setPending(false);
		if (response) {
			response.forEach((item) => {
				print(
					`#cash-warrant-outflow-${courier.id}`,
					{
						'[NUMBER]': item.cash_uid || 0,
						'[NAME]': item.counterparty_name || '',
						'[DESCRIPTION]': item.description || '',
						'[SUM]': money2str(Math.abs(item.sum) || 0)
					},
					`@page {margin: 1.1cm 1cm 1.1cm 1cm; size: portrait;}`
				);
			});
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Изъятие средств у курьера')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="employee_id" label={__('От кого')} required rules={[requiredRule(__)]}>
								<Select dropdownClassName="form__dropdown" options={couriersList} disabled={true} />
							</Form.Item>
							<Form.Item className="form__item" label={__('Сумма изъятия, грн')} name="payed_sum" required rules={[requiredRule(__)]}>
								<InputNumber min={0} formatter={floatFormatter} disabled={true} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Подтвердить изъятие')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
					<div className="is-print">
						<div id={`cash-warrant-outflow-${courier.id}`}>
							<CashWarrant isAdd={true} />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};
