import { useState, useEffect } from 'react';
import { Form, Select, DatePicker } from 'antd';
import { useMethods, useData, dayjs, requiredRule, notify } from 'utils';
import { Modal, Preloader } from 'components';
import { dateClientFormat, dateServerFormat } from 'const';
import { editScheduleAction, getShiftsMonthlyScheduleAction, getShiftsMonthlyScheduleStatisticAction } from 'actions';

export const EditSchedule = (props) => {
	const { children, employee = {} } = props;
	const { employee_id, schedule_template_id } = employee;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { scheduleFilters = {}, employees = [], scheduleTemplates = [] } = useData();
	const { __, update } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				employee_id: `${employee_id}`,
				schedule_template_id: schedule_template_id,
				schedule_start: dayjs().add(1, 'day').startOf('day')
			});
		}
	}, [visible, employee]);

	const submitHandler = async (values) => {
		setPending(true);
		const updateData = {};
		const success = await editScheduleAction({
			employee_id: values.employee_id ? +values.employee_id : null,
			schedule_template_id: values.schedule_template_id,
			schedule_start: values.schedule_start ? dayjs(values.schedule_start).format(dateServerFormat) : null
		});
		const [schedule, scheduleStatistic] = await Promise.all([
			getShiftsMonthlyScheduleAction(scheduleFilters),
			getShiftsMonthlyScheduleStatisticAction(scheduleFilters)
		]);
		updateData.schedule = schedule;
		updateData.scheduleStatistic = scheduleStatistic;
		setPending(false);
		update(updateData);
		if (success) {
			notify('success', __('Смена изменена'));
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Редактирование смены')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" label={__('ФИО сотрудника')} name="employee_id" required rules={[requiredRule(__)]}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									options={employees.map((item) => ({ label: item.name, value: String(item.id) }))}
									disabled={employee_id}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item className="form__item" name="schedule_template_id" label={__('Шаблон графика')}>
								<Select
									dropdownClassName="form__dropdown"
									options={scheduleTemplates.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item
								className="form__item"
								label={__('Применить с даты')}
								name="schedule_start"
								required
								rules={[requiredRule(__)]}
							>
								<DatePicker
									format={dateClientFormat}
									allowClear={true}
									disabledDate={(current) => current && current < dayjs().endOf('day')}
								/>
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Сохранить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
