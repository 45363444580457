import { MainLayout } from 'layouts';
import { useMethods, access } from 'utils';
import { Tabs } from 'antd';
import { EmployeesShiftsTab, EmployeesTransactionsTab, EmployeesPayrollsTab, EmployeesScheduleTab } from 'components';

export const EmployeesPage = () => {
	const { __ } = useMethods();

	return (
		<MainLayout seo={{ title: `${__('Сотрудники и зарплаты')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				{access('employees-tab-shifts') ? (
					<Tabs.TabPane tab={__('График работы / Ставка')} key="shifts">
						<EmployeesShiftsTab />
					</Tabs.TabPane>
				) : null}
				{access('employees-tab-transactions') ? (
					<Tabs.TabPane tab={__('Финансовые операции')} key="transactions">
						<EmployeesTransactionsTab />
					</Tabs.TabPane>
				) : null}
				{access('employees-tab-payroll') ? (
					<Tabs.TabPane tab={__('Табель выплат')} key="payroll">
						<EmployeesPayrollsTab />
					</Tabs.TabPane>
				) : null}
				{access('employees-tab-schedule') ? (
					<Tabs.TabPane tab={__('Планирование графика')} key="schedule">
						<EmployeesScheduleTab />
					</Tabs.TabPane>
				) : null}
			</Tabs>
		</MainLayout>
	);
};
