import { useEffect } from 'react';
import { useMethods, useData, print, access } from 'utils';
import { Form, Input, Tooltip } from 'antd';
import { getClientsAction, clientExportAction } from 'actions';
import { ClearOutlined, PlusOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Link, RangePickerField } from 'components';
import { dateClientFormat, fileIcons } from 'const';

let timeoutId = null;

export const ClientsFilter = (props) => {
	const { setPending } = props;
	const { clientsFiltersDefault = {}, clientsFilters = {} } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(clientsFiltersDefault);
	}, [clientsFiltersDefault]);

	const filterHandler = (changedValues, values) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(async () => {
			setPending(true);
			const filters = {
				...clientsFilters,
				...values,
				page: 1
			};
			const clients = await getClientsAction(filters);
			setPending(false);
			update({
				clientsFilters: {
					...clientsFilters,
					...filters
				},
				clients
			});
		}, 500);
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(clientsFiltersDefault);
		const clients = await getClientsAction(clientsFiltersDefault);
		setPending(false);
		update({
			clientsFilters: clientsFiltersDefault,
			clients
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const clients = await getClientsAction(clientsFilters);
		setPending(false);
		update({
			clients
		});
	};

	const printTable = () => {
		print('#clients-table .ant-table-content', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};

	const loadXlsFileClients = async () => {
		await clientExportAction(clientsFilters);
	};

	return (
		<div className="filter filter--5">
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Новый клиент')} placement="topLeft">
						<Link href="/clients/create" className="button button--main button--icon-small">
							<PlusOutlined />
						</Link>
					</Tooltip>
				</div>
			</div>
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="name" label={__('ФИО')}>
								<Input placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="phone" label={__('Телефон')}>
								<Input type="tel" placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="card" label={__('Номер карты')}>
								<Input type="tel" placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="address" label={__('Адрес')}>
								<Input placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="last_order_date" label={__('Период времени')}>
								<RangePickerField format={dateClientFormat} showTime={false} allowClear={true} separator="—" />
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Распечатать')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={printTable}>
							<PrinterOutlined />
						</button>
					</Tooltip>
				</div>
				{access('clients-export') ? (
					<div className="filter__button">
						<Tooltip title={__('Загрузить на почту')} placement="topRight">
							<button className="button button--main-border button--icon-small" onClick={loadXlsFileClients}>
								{fileIcons.xls}
							</button>
						</Tooltip>
					</div>
				) : null}
			</div>
		</div>
	);
};
