import { Avatar, Radio, Popover } from 'antd';
import foodPlaceholder from 'assets/images/placeholder.png';
import { pizzaSizes } from 'const';
import { CountInput } from 'components';
import { createNomenclatureName, useData, useMethods, formatDigits } from 'utils';

export const ProductCard = (props) => {
	const {
		isRelated,
		isNomenclature,
		product,
		quantity,
		price = 0,
		pizzaSize = 0,
		disabledCount,
		minCount = 1,
		maxCount = 9999,
		onChangeCount,
		onChangeSize,
		onAdd,
		type
	} = props;
	const { order = {}, locale } = useData();
	const { __ } = useMethods();
	const orderNomenclatures = order.nomenclatures || [];

	const isAlreadyAdded = orderNomenclatures.some((item) => item.id === product.id);
	const name = isNomenclature
		? createNomenclatureName(product.name, product.unit_title, product.unit_weight, __)
		: !!product.name[locale]
		? product.name[locale]
		: product.name;
	const sum = !!price ? price : product.is_pizza ? +product.price_30 : product.price;

	// const caloriesBlock = [];
	// if (product.proteins) {
	// 	caloriesBlock.push(`Б-${product.proteins.replace('.0', '')}`);
	// }
	// if (product.fats) {
	// 	caloriesBlock.push(`Ж-${product.fats.replace('.0', '')}`);
	// }
	// if (product.carbohydrates) {
	// 	caloriesBlock.push(`У-${product.carbohydrates.replace('.0', '')}`);
	// }
	// if (product.calories) {
	// 	caloriesBlock.push(`К-${product.calories.replace('.0', '')}`);
	// }

	const isAddProduct = (order.products || []).some((item) => item.id === product.id);

	const classes = ['product-search-card'];
	if (product.ingredientable) {
		classes.push('product-search-card--ingredientable');
	}
	if (type === 'reverse') {
		classes.push('product-search-card--reverse');
	}

	return (
		<div className={classes.join(' ')}>
			<div className="product-search-card__title">{name}</div>
			<div className="product-search-card__text">{product.components}</div>
			{/* {caloriesBlock.length ? <div className="product-search-card__text">{caloriesBlock.join(', ')}</div> : null} */}
			<div className="product-search-card__row">
				<div className="product-search-card__image">
					{!isNomenclature && product.consist ? (
						<Popover
							overlayClassName="product-components"
							title={__('Состав')}
							content={
								<div>
									{product.consist.split(',').map((name, indx, array) => (
										<div key={indx}>
											{name}
											{array.length - 1 > indx ? ',' : ''}
										</div>
									))}
								</div>
							}
							placement="rightTop"
						>
							<Avatar src={product.image || foodPlaceholder} shape="square" />
						</Popover>
					) : (
						<Avatar src={product.image || foodPlaceholder} shape="square" />
					)}
				</div>
				<div className="product-search-card__info">
					<div className="product-search-card__title">
						{formatDigits({ number: sum, toFixed: 2 })} {__('грн')}
					</div>
					{!isNomenclature && product.is_pizza ? (
						<div className="product-search-card__size">
							<Radio.Group value={pizzaSize} onChange={(event) => onChangeSize(product, event)}>
								{pizzaSizes.map((item) => (
									<Radio value={item.value} key={item.value}>
										{item.text}
									</Radio>
								))}
							</Radio.Group>
						</div>
					) : null}
					<div className="product-search-card__block">
						<CountInput
							min={minCount}
							max={maxCount}
							value={quantity}
							onChange={(value) => onChangeCount(product, value)}
							disabled={disabledCount}
						/>
					</div>
					{isRelated ? null : isNomenclature && isAlreadyAdded ? (
						<button type="button" className={`button button--small button--main-label product-search-card__button`}>
							{__('Добавлен')}
						</button>
					) : (
						<button
							type="button"
							className={`button button--small${isAddProduct ? ' button--main' : ' button--main-border'} product-search-card__button`}
							onClick={() => onAdd(product)}
						>
							{__('Добавить')}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
