import { HtmlHead } from 'components';

export const BaseLayout = (props) => {
	const { seo, children } = props;

	return (
		<>
			<HtmlHead seo={seo} />
			<div className="layout layout--base layout--bg" style={{ backgroundImage: 'url(/images/bg.jpg)' }}>
				<div className="layout__content">{children}</div>
			</div>
		</>
	);
};
