import { useState, useEffect } from 'react';
import { Col, Descriptions, Input, Row, Switch } from 'antd';
import { useMethods, useData, phoneFormat, phoneClearFull, phoneClear } from 'utils';
import { LeftOutlined, PhoneFilled, StopOutlined } from '@ant-design/icons';
import { Link } from 'components';
import {
	getClientFromPhoneAction,
	sipAnswerAction,
	sipCallAction,
	sipCancelAction,
	sipEndAction,
	sipChangeStatusAction,
	createClientAction
} from 'actions';
import { useHistory } from 'react-router-dom';

export const PhoneTab = () => {
	const { __, update } = useMethods();
	const { sip = {} } = useData();
	const { incoming = {}, outgoing = {}, online = true } = sip;
	const { client: incomingClient = {}, number: incomingNumber = '' } = incoming;
	const { order: outgoingOrder = {}, client: outgoingClient = {}, number: outgoingNumber = '' } = outgoing;
	const [phone, setPhone] = useState('');
	const isDisabledCall = phone.length !== 10;
	const history = useHistory();

	const addDigitHandler = (digit) => {
		setPhone(phoneClearFull(`${phone}${digit}`));
	};

	const changeInputHandler = (event) => {
		setPhone(phoneClearFull(event.currentTarget.value));
	};

	const deleteDigitHandler = () => {
		setPhone(phoneClearFull(`${phone.substring(0, phone.length - 1)}`));
	};

	const clearDigitHandler = () => {
		setPhone('');
	};

	const createOrderHandler = async () => {
		const number = phoneClear(incoming.number ? incoming.number : outgoing.number ? outgoing.number : phone);
		let client = await getClientFromPhoneAction(number);

		if(!client.id) {
			client = await createClientAction({
				phones: [
					{
						id: null,
						main: true,
						value: number
					}
				],
				addresses: [],
				name: __('Новый клиент')
			});
		}
		if (client.id) {
			update({
				client
			});
			history.push('/orders/create');
		}
	};

	useEffect(() => {
		if (phone && outgoing.status === 'done') {
			clearDigitHandler();
		}
	}, [outgoing]);

	return (
		<>
			<div className='call-modal__content'>
				<Row gutter={24} justify='space-between' align='middle'>
					<Col span={8}>
						<div className='button button--main button--small'
							 onClick={createOrderHandler}>
							<span>{__('Новый заказ')}</span>
						</div>
					</Col>
					<Col span={8} style={{ textAlign: 'center' }}>
						<Switch
							className='call-modal__status'
							checked={!!online}
							onChange={sipChangeStatusAction}
							checkedChildren={__('Онлайн')}
							unCheckedChildren={__('Офлайн')}
						/>
					</Col>
					<Col span={8} style={{ textAlign: 'right' }}>
						{!!outgoingOrder.id ? (
							<Link className='button button--main button--small' href={`/orders/${outgoingOrder.id}`}>
								<span>{__('Открыть заказ')}</span>
							</Link>
						) : null}
					</Col>
				</Row>
			</div>
			{incoming.status !== 'answer' ? (
				<div className='call-modal__card'>
					<div className='call-modal__title'>{__('Исходящий звонок')}</div>
					{outgoing.status === 'done' ? (
						<div className='call-modal__block'>
							<Row justify={'center'}>
								<Col className={`call-modal__panel`}>
									<Input
										type='tel'
										name='phone'
										style={{ marginBottom: '16px' }}
										value={phoneFormat(phone)}
										className={`call-modal__input`}
										onChange={changeInputHandler}
									/>
									<Row type={'flex'} justify={'center'} gutter={16} style={{ marginBottom: '16px' }}>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(1)}
											>
												<span>1</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(2)}
											>
												<span>2</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(3)}
											>
												<span>3</span>
											</button>
										</Col>
									</Row>
									<Row type={'flex'} justify={'center'} gutter={16} style={{ marginBottom: '16px' }}>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(4)}
											>
												<span>4</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(5)}
											>
												<span>5</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(6)}
											>
												<span>6</span>
											</button>
										</Col>
									</Row>
									<Row type={'flex'} justify={'center'} gutter={16} style={{ marginBottom: '16px' }}>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(7)}
											>
												<span>7</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(8)}
											>
												<span>8</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(9)}
											>
												<span>9</span>
											</button>
										</Col>
									</Row>
									<Row type={'flex'} justify={'center'} gutter={16}>
										<Col style={{ width: '56px' }}>
											<button className='button button--error button--icon-sm'
													onClick={clearDigitHandler}>
												<span>C</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button
												className='button button--main button--icon-sm'
												disabled={!isDisabledCall}
												onClick={() => addDigitHandler(0)}
											>
												<span>0</span>
											</button>
										</Col>
										<Col style={{ width: '56px' }}>
											<button className='button button--error button--icon-sm'
													onClick={deleteDigitHandler}>
												<LeftOutlined />
											</button>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					) : null}
					{outgoing.status === 'call' || outgoing.status === 'calling' ? (
						<div className='call-modal__block'>
							<Descriptions column={1} size={'small'} bordered>
								<Descriptions.Item
									label={`${__('Тел. номер')}:`}>{phoneFormat(outgoingNumber)}</Descriptions.Item>
								{outgoingClient.id ? (
									<>
										<Descriptions.Item
											label={`${__('Имя клиента')}:`}>{outgoingClient.name || '-'}</Descriptions.Item>
										<Descriptions.Item
											label={`${__('Примечание')}:`}>{outgoingClient.notes || '-'}</Descriptions.Item>
										{outgoingClient.blacklisted ? (
											<Descriptions.Item
												label={`${__('В черном списке')}:`}>{__('Да')}</Descriptions.Item>
										) : null}
									</>
								) : null}
								{outgoingOrder.id ? (
									<Descriptions.Item
										label={`${__('Номер заказа')}:`}>{outgoingOrder.day_id || '-'}</Descriptions.Item>
								) : null}
							</Descriptions>
						</div>
					) : null}
					<div className='call-modal__block'>
						<Row justify={'center'} gutter={24}>
							{outgoing.status === 'done' ? (
								<Col>
									<button
										className='button button--main button--small'
										disabled={isDisabledCall}
										onClick={() => sipCallAction(phoneClear(phone))}
									>
										<PhoneFilled />
										<span>{__('Позвонить')}</span>
									</button>
								</Col>
							) : null}
							{outgoing.status === 'call' || outgoing.status === 'calling' ? (
								<Col>
									<button className='button button--error button--small' onClick={sipEndAction}>
										<StopOutlined />
										<span>{__('Завершить')}</span>
									</button>
								</Col>
							) : null}
						</Row>
					</div>
				</div>
			) : null}
			{online && (incoming.status === 'incoming' || incoming.status === 'answer') ? (
				<div className='call-modal__card'>
					<div className='call-modal__title'>{__('Входящий звонок')}</div>
					<div className='call-modal__block'>
						<Descriptions column={1} size={'small'} bordered>
							<Descriptions.Item
								label={`${__('Тел. номер')}:`}>{phoneFormat(incomingNumber)}</Descriptions.Item>
							{incomingClient.id ? (
								<>
									<Descriptions.Item
										label={`${__('Имя клиента')}:`}>{incomingClient.name || '-'}</Descriptions.Item>
									<Descriptions.Item
										label={`${__('Примечание')}:`}>{incomingClient.notes || '-'}</Descriptions.Item>
									{incomingClient.blacklisted ? (
										<Descriptions.Item
											label={`${__('В черном списке')}:`}>{__('Да')}</Descriptions.Item>
									) : null}
								</>
							) : null}
						</Descriptions>
					</div>
					<div className='call-modal__block'>
						<Row justify={'center'} gutter={24}>
							{incoming.status === 'incoming' ? (
								<>
									<Col>
										<button className='button button--main button--small' onClick={sipAnswerAction}>
											<PhoneFilled />
											<span>{__('Ответить')}</span>
										</button>
									</Col>
									<Col>
										<button className='button button--error button--small'
												onClick={sipCancelAction}>
											<StopOutlined />
											<span>{__('Отклонить')}</span>
										</button>
									</Col>
								</>
							) : null}
							{incoming.status === 'answer' ? (
								<Col>
									<button className='button button--error button--small' onClick={sipCancelAction}>
										<StopOutlined />
										<span>{__('Завершить')}</span>
									</button>
								</Col>
							) : null}
						</Row>
					</div>
				</div>
			) : null}
		</>
	);
};
