import { useState, useEffect } from 'react';
import { Modal, Preloader } from 'components';
import { Form, Select, Input, InputNumber } from 'antd';
import { useMethods, requiredRule, useData, floatFormatter, minLengthRule, notify } from 'utils';
import { LoadingOutlined } from '@ant-design/icons';
import { addFiscalTransactionsAction } from 'actions';

export const FiscalCashboxModal = (props) => {
	const { children } = props;
	const { __ } = useMethods();
	const [form] = Form.useForm();
	const [pending, setPending] = useState(false);
	const [visible, setVisible] = useState(false);
	const { fiscalTransactionTypes = [], entrepreneurs = [], paymentTypes = [] } = useData();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				entrepreneur_id: null,
				type: null,
				sum: null,
				payment_type_id: null,
				description: null
			});
		}
	}, [visible]);

	const submitHandler = async (values) => {
		setPending(true);
		const data = {
			entrepreneur_id: values.entrepreneur_id || null,
			type: values.type || null,
			sum: parseFloat(values.sum),
			payment_type_id: values.payment_type_id || null,
			description: values.description
		};

		const transaction = await addFiscalTransactionsAction(data);
		setPending(false);
		if (transaction) {
			notify('success', __('Транзакция добавлена'));
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Добавление транзакции')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending} indicator={null}>
							<Form.Item className="form__item" name="entrepreneur_id" label={__('Юр. лицо')} required rules={[requiredRule(__)]}>
								<Select
									dropdownClassName="form__dropdown"
									placeholder={__('Не выбрано')}
									options={entrepreneurs.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item className="form__item" name="sum" label={__('Сумма транзакции, грн')} required rules={[requiredRule(__)]}>
								<InputNumber min={0} step={0.01} formatter={floatFormatter} />
							</Form.Item>
							<Form.Item className="form__item " name="type" label={__('Назначение средств')} required rules={[requiredRule(__)]}>
								<Select
									dropdownClassName="form__dropdown"
									placeholder={__('Не выбрано')}
									options={fiscalTransactionTypes.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item className="form__item" name="payment_type_id" label={__('Тип оплаты')} required rules={[requiredRule(__)]}>
								<Select
									dropdownClassName="form__dropdown"
									placeholder={__('Не выбрано')}
									options={paymentTypes.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item
								className="form__item  form__item--autosize"
								name="description"
								label={__('Примечание')}
								required
								rules={[requiredRule(__), minLengthRule(__, 10)]}
							>
								<Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									{pending ? <LoadingOutlined /> : null}
									<span>{__('Добавить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
