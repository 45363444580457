import { MainLayout } from 'layouts';
import { useMethods, access } from 'utils';
import { Tabs } from 'antd';
import { WaybillsTab, WarehouseRestsTab, ProcurementTab, ReportPurchasesTab, DocsNomTab } from 'components';

export const WarehousePage = () => {
	const { __ } = useMethods();

	return (
		<MainLayout seo={{ title: `${__('Складской учет')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				{access('waybill') ? (
					<Tabs.TabPane tab={__('Архив накладных')} key="waybills">
						<WaybillsTab />
					</Tabs.TabPane>
				) : null}
				{access('show-warehouse-rests') ? (
					<Tabs.TabPane tab={__('Остаток по складам')} key="rests">
						<WarehouseRestsTab />
					</Tabs.TabPane>
				) : null}
				<Tabs.TabPane tab={__('Закупки')} key="procurement">
					<ProcurementTab />
				</Tabs.TabPane>
				{access('docs-reports-purchases') ? (
					<Tabs.TabPane tab={__('Отчёт по закупкам')} key="purchases">
						<ReportPurchasesTab />
					</Tabs.TabPane>
				) : null}
				{access('docs-nomenclature-expenses') ? (
					<Tabs.TabPane tab={__('Отчёт по продуктовым затратам')} key="nomenclature">
						<DocsNomTab />
					</Tabs.TabPane>
				) : null}
			</Tabs>
		</MainLayout>
	);
};
