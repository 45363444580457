import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { paginationDefault, dateClientFormat, dateServerFormat } from 'const';
import { Table, Tooltip } from 'antd';
import { useMethods, useData, dayjs, getNegativeOrPositiveCurrencyFormat, access } from 'utils';
import { getTransactionsAction } from 'actions';
import { FinancialTransaction } from 'components';

export const EmployeesTransactions = (props) => {
	const { pending, setPending } = props;
	const { transactions = {}, transactionsFilters = {}, positions = [], operationsTypes = [], rates = [] } = useData();
	const { data = [], meta = {} } = transactions;
	const { current_page = 1, total = 0, per_page = 10 } = meta;
	const { __, update } = useMethods();

	const tableChangeHandler = async (pagination) => {
		setPending(true);
		const filter = {
			...transactionsFilters,
			page: pagination.current,
			per_page: pagination.pageSize
		};
		const transactions = await getTransactionsAction(filter);
		setPending(false);
		update({
			transactionsFilters: filter,
			transactions
		});
	};

	return (
		<Table
			id="employees-transactions"
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 960
			}}
			columns={[
				{
					title: __('Номер'),
					dataIndex: 'id'
				},
				{
					title: __('Сотрудник'),
					dataIndex: 'employee'
				},
				{
					title: __('Должность'),
					dataIndex: 'position_id',
					render: (id) => positions.find((item) => item.id === id)?.name || '-'
				},
				{
					title: __('Сумма'),
					dataIndex: 'sum',
					render: (sum) => getNegativeOrPositiveCurrencyFormat({ number: sum, defaultValue: '-', currency: __('грн') })
				},
				{
					title: __('Дата'),
					dataIndex: 'date',
					width: 150,
					render: (date) => (date ? dayjs(date, dateServerFormat).format(dateClientFormat) : '-')
				},
				{
					title: __('Кто создал'),
					dataIndex: 'creator'
				},
				{
					title: __('Тип операции'),
					dataIndex: 'type_id',
					render: (id) => operationsTypes.find((item) => item.id === id)?.name || '-'
				},
				{
					title: __('Тариф'),
					dataIndex: 'rate_id',
					render: (id) => rates.find((item) => item.id === id)?.name || '-'
				},
				{
					title: __('Примечание'),
					dataIndex: 'description'
				},
				{
					title: __('Действия'),
					align: 'right',
					render: (_, record) =>
						record.is_editable && access('employees-transactions-update') ? (
							<Tooltip title={__('Редактировать операцию')} placement="topRight">
								<FinancialTransaction transaction={record}>
									<button type="button" className="button button--icon-xs button--trans-main">
										<EditOutlined />
									</button>
								</FinancialTransaction>
							</Tooltip>
						) : (
							'-'
						)
				}
			]}
			dataSource={data}
			pagination={{
				...paginationDefault,
				current: current_page,
				pageSize: +per_page,
				total
			}}
			onChange={tableChangeHandler}
		/>
	);
};
