import { Table } from 'antd';
import { useData, dayjs, useMethods } from 'utils';
import { dateTimeServerFormat, dateTimeClientFormat } from 'const';

export const OrderStatusHistory = () => {
	const { statusHistory = [] } = useData();
	const { __ } = useMethods();

	return (
		<div>
			<div className="order-form__title">{__('Информация о заказе')}</div>
			{statusHistory.length ? (
				<Table
					rowKey="id"
					className="table order-form__history"
					bordered
					columns={[
						{
							title: __('Статус'),
							dataIndex: 'status'
						},
						{
							title: __('Изменен'),
							dataIndex: 'employee'
						},
						{
							title: __('Дата'),
							dataIndex: 'date'
						}
					]}
					dataSource={statusHistory.map((item, index) => ({
						id: index,
						status: item.status,
						employee: item.employee,
						date: item.datetime ? dayjs(item.datetime, dateTimeServerFormat).format(dateTimeClientFormat) : '-'
					}))}
					pagination={false}
				/>
			) : null}
		</div>
	);
};
