import { Modal } from 'antd';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';

const CustomModal = (props) => {
	const { type = '', visible, setVisible, noClose, children, ...rest } = props;

	return (
		<Modal
			visible={visible}
			onOk={() => setVisible(false)}
			onCancel={() => setVisible(false)}
			footer={null}
			closable={false}
			maskClosable={false}
			className={`modal${type ? ` modal--${type}` : ''}`}
			{...rest}
		>
			{children}
			{!noClose ? (
				<div className="modal__close" onClick={() => setVisible(false)}>
					<IconClose />
				</div>
			) : null}
		</Modal>
	);
};

export { CustomModal as Modal };
