import { useEffect } from 'react';
import { useMethods, useData, dayjs, requiredRule, access, nomenclaturesChange, notify } from 'utils';
import { Form, Select, Row, Col, DatePicker, Tooltip, Input } from 'antd';
import { Link, Preloader, WaybillTable, AddWaybillSupplier, AddNomenclatures } from 'components';
import { dateClientFormat, dateServerFormat } from 'const';
import { getSuppliersAction, getEmployeesListAction, getWarehouseRestListAction, getNomenclatureListAction, createWaybillAction } from 'actions';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export const WaybillForm = (props) => {
	const { pending, setPending } = props;
	const {
		waybillType,
		waybillId,
		waybillFilter = {},
		waybillOld = {},
		waybillsTypes = [],
		warehouses = [],
		supplierEmployees = [],
		suppliers = [],
		recipientEmployees = []
	} = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const history = useHistory();

	const isWaybill = waybillType && waybillId;
	const waybillsTypesList = waybillsTypes.map((item) => {
		const [, editWaybill] = item.permissions || [];
		return {
			label: item.title,
			value: item.alias,
			disabled: !editWaybill || !access(editWaybill)
		};
	});
	const typeItem = waybillsTypes.find((item) => item.alias === waybillFilter.type) || {};
	const { properties = [] } = typeItem;
	const propertiesList = [
		'date',
		'supplier_warehouse_id',
		'supplier_employee_id',
		'recipient_warehouse_id',
		'supplier_id',
		'recipient_employee_id'
	];
	let isInfo = !!properties.length;
	for (let key of propertiesList) {
		if (properties.includes(key) && !waybillFilter[key]) {
			isInfo = false;
		}
	}

	useEffect(() => {
		form.setFieldsValue(waybillOld);
	}, [waybillOld]);

	const changeHandler = async (changedValues, values) => {
		const data = {
			waybillFilter: {
				...waybillFilter,
				...values
			}
		};
		if (changedValues.type) {
			const filter = {
				...waybillOld,
				type: changedValues.type
			};
			data.waybillFilter = filter;
			form.setFieldsValue(filter);
		}
		if (changedValues.supplier_warehouse_id) {
			setPending(true);
			data.supplierEmployees = await getEmployeesListAction({ warehouse_id: changedValues.supplier_warehouse_id });
			setPending(false);
		}
		if (changedValues.recipient_warehouse_id) {
			setPending(true);
			data.suppliers = await getSuppliersAction({ warehouse_id: changedValues.recipient_warehouse_id });
			data.recipientEmployees = await getEmployeesListAction({ warehouse_id: changedValues.recipient_warehouse_id });
			setPending(false);
		}
		update(data);
	};

	const submitHandler = async (values) => {
		setPending(true);
		const { type, date, ...rest } = values;
		const success = await createWaybillAction(type, {
			date: date ? dayjs(date).format(dateServerFormat) : null,
			nomenclatures: waybillFilter.nomenclatures || [],
			...rest
		});
		if (success) {
			notify('success', __('Накладная успешно создана'));
			setPending(false);
			history.push('/warehouse');
		} else {
			setPending(false);
		}
	};

	const restsHandler = async () => {
		setPending(true);
		const nomenclatures = await getWarehouseRestListAction({
			warehouse_id: properties.includes('supplier_warehouse_id') ? waybillFilter.supplier_warehouse_id : waybillFilter.recipient_warehouse_id
		});
		setPending(false);
		update({
			waybillFilter: {
				...waybillFilter,
				nomenclatures: nomenclaturesChange(nomenclatures, true)
			}
		});
	};

	const nomenclaturesHandler = async () => {
		setPending(true);
		const nomenclatures = await getNomenclatureListAction({
			warehouse_id: properties.includes('supplier_warehouse_id') ? waybillFilter.supplier_warehouse_id : waybillFilter.recipient_warehouse_id,
			supplier_id: properties.includes('supplier_id') ? waybillFilter.supplier_id : null,
			simplest: waybillFilter.type !== 'inventory-act'
		});
		setPending(false);
		update({
			waybillFilter: {
				...waybillFilter,
				nomenclatures: nomenclaturesChange(nomenclatures)
			}
		});
	};

	return (
		<Preloader spinning={pending}>
			<Form className="form" form={form} layout="vertical" noValidate={true} onFinish={submitHandler} onValuesChange={changeHandler}>
				<Row gutter={10}>
					<Col style={{ marginTop: '25px' }}>
						<Link href="/warehouse" className="button button--main">
							<ArrowLeftOutlined />
							<span>{__('Назад')}</span>
						</Link>
					</Col>
					<Col span={24} md={8} xl={4}>
						<Form.Item className="form__item" name="type" label={__('Тип накладной')} required rules={[requiredRule(__)]}>
							<Select
								dropdownClassName="form__dropdown"
								options={waybillsTypesList}
								placeholder={__('Не выбрано')}
								allowClear={false}
								required
								rules={[requiredRule(__)]}
								disabled={isWaybill}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={10}>
					{properties.includes('date') ? (
						<Col span={24} md={8} xl={4}>
							<Form.Item className="form__item" name="date" label={__('Дата')} required rules={[requiredRule(__)]}>
								<DatePicker
									format={dateClientFormat}
									allowClear={true}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
									disabled={isWaybill}
								/>
							</Form.Item>
						</Col>
					) : null}
					{properties.includes('supplier_warehouse_id') ? (
						<Col span={24} md={8} xl={4}>
							<Form.Item
								className="form__item"
								name="supplier_warehouse_id"
								label={__('Со склада')}
								required
								rules={[requiredRule(__)]}
							>
								<Select
									dropdownClassName="form__dropdown"
									options={warehouses.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={false}
									disabled={isWaybill}
								/>
							</Form.Item>
						</Col>
					) : null}
					{properties.includes('supplier_employee_id') ? (
						<Col span={24} md={8} xl={4}>
							<Form.Item className="form__item" name="supplier_employee_id" label={__('Выдал')} required rules={[requiredRule(__)]}>
								<Select
									dropdownClassName="form__dropdown"
									options={supplierEmployees.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={false}
									disabled={isWaybill}
								/>
							</Form.Item>
						</Col>
					) : null}
					{properties.includes('recipient_warehouse_id') ? (
						<Col span={24} md={8} xl={4}>
							<Form.Item
								className="form__item"
								name="recipient_warehouse_id"
								label={__('На склад')}
								required
								rules={[requiredRule(__)]}
							>
								<Select
									dropdownClassName="form__dropdown"
									options={warehouses.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={false}
									disabled={isWaybill}
								/>
							</Form.Item>
						</Col>
					) : null}
					{properties.includes('supplier_id') ? (
						<Col span={24} md={8} xl={4}>
							<Row gutter={10} style={{ flexWrap: 'nowrap' }}>
								<Col style={{ width: 'calc(100% - 50px)' }}>
									<Form.Item className="form__item" name="supplier_id" label={__('Поставщик')} required rules={[requiredRule(__)]}>
										<Select
											dropdownClassName="form__dropdown"
											options={suppliers.map((item) => ({
												label: `${item.name} (${item.with_nds ? __('с ПДВ') : __('без ПДВ')})`,
												value: item.id
											}))}
											placeholder={__('Не выбрано')}
											allowClear={false}
											disabled={isWaybill}
										/>
									</Form.Item>
								</Col>
								{!isWaybill && waybillFilter.recipient_warehouse_id ? (
									<Col style={{ flexShrink: 0, marginTop: '25px' }}>
										<AddWaybillSupplier>
											<Tooltip placement="topRight" title={__('Создать поставщика')}>
												<button type="button" className="button button--icon-small button--main">
													<PlusOutlined />
												</button>
											</Tooltip>
										</AddWaybillSupplier>
									</Col>
								) : null}
							</Row>
						</Col>
					) : null}
					{properties.includes('recipient_employee_id') ? (
						<Col span={24} md={8} xl={4}>
							<Form.Item className="form__item" name="recipient_employee_id" label={__('Принял')} required rules={[requiredRule(__)]}>
								<Select
									dropdownClassName="form__dropdown"
									options={recipientEmployees.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={false}
									disabled={isWaybill}
								/>
							</Form.Item>
						</Col>
					) : null}
					{properties.includes('description') ? (
						<Col span={24} md={8} xl={4}>
							<Form.Item className="form__item form__item--autosize" name="description" label={__('Описание')}>
								<Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} allowClear={true} disabled={isWaybill} />
							</Form.Item>
						</Col>
					) : null}
				</Row>
				{isInfo ? (
					<>
						{!isWaybill ? (
							<Row gutter={10}>
								<Col style={{ marginBottom: '20px' }}>
									<AddNomenclatures>
										<button type="button" className="button button--main">
											<span>{__('Добавить номенклатуру')}</span>
										</button>
									</AddNomenclatures>
								</Col>
								<Col style={{ marginBottom: '20px' }}>
									{waybillFilter.type === 'inventory-act' ? (
										<button type="button" className="button button--default" onClick={restsHandler}>
											<span>{__('Сформировать из остатков')}</span>
										</button>
									) : (
										<button type="button" className="button button--default" onClick={nomenclaturesHandler}>
											<span>{__('Сформировать из доступных номенклатур')}</span>
										</button>
									)}
								</Col>
							</Row>
						) : null}
						<WaybillTable />
					</>
				) : null}
				<Row gutter={12} justify="end" style={{ marginTop: '50px' }}>
					{!pending && !isWaybill ? (
						<Col>
							<button className="button button--main">{__('Создать')}</button>
						</Col>
					) : null}
				</Row>
			</Form>
		</Preloader>
	);
};
