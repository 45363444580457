import { useState } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useMethods, useData, dayjs, dateParse, randomColor } from 'utils';
import { Modal } from 'components';
import { dateServerFormat, dateClientFormat } from 'const';

const colors = [
	'#2560bc',
	'#009345',
	'#81007f',
	'#f4c708',
	'#ed88a6',
	'#1a1a1a',
	'#666666',
	'#c12e27',
	'#d9531e',
	'#34cc99',
	'#6d573c',
	'#c6c3af',
	'#826ac6',
	'#0ad507'
];

export const RevenueGraph = (props) => {
	const { children } = props;
	const [visible, setVisible] = useState(false);
	const { __ } = useMethods();
	const { restaurants: restaurantsList = [], docsRevenue = {}, docsRevenueFilters = {} } = useData();
	const { dates = [], restaurants = [] } = docsRevenue;

	const isOneDate =
		docsRevenueFilters.dates &&
		docsRevenueFilters.dates.length &&
		dayjs(docsRevenueFilters.dates[0], dateServerFormat).format(dateClientFormat) ===
			dayjs(docsRevenueFilters.dates[1], dateServerFormat).format(dateClientFormat);

	const lines = restaurants
		.sort((a, b) => {
			const aCount = +(a.count || 0);
			const bCount = +(b.count || 0);
			return bCount - aCount;
		})
		.map((restaurant, i) => {
			const { restaurant_slug } = restaurant;
			return (
				<Line
					key={i}
					type="monotone"
					name={restaurantsList.find((e) => e.slug === restaurant_slug)?.name || restaurant_slug}
					dataKey={restaurant_slug}
					stroke={colors[i] || randomColor()}
				/>
			);
		});

	const data = dates.map((dateKey) => {
		let obj = {};
		restaurants.forEach((el) => {
			obj[el.restaurant_slug] = el.dates.find((d) => d.date === dateKey)?.amount || 0;
		});
		const name = isOneDate
			? `${dateKey}:00`
			: dayjs(dateKey, dateServerFormat).isSame(dayjs(), 'year')
			? dayjs(dateKey, dateServerFormat).format('DD.MM')
			: dayjs(dateKey, dateServerFormat).format(dateClientFormat);
		return {
			name,
			date: dateKey,
			...obj
		};
	});

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="big">
				<div className="modal__container">
					<div className="modal__title">{__('График выручки')}</div>
					<ResponsiveContainer width="100%" height={600}>
						<LineChart data={data}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip
								formatter={(label) => `${label} ${__('грн')}`}
								labelFormatter={(v) => (v ? dateParse(data.find((e) => e.name === v)?.date || '') : '')}
							/>
							<Legend />
							{lines}
						</LineChart>
					</ResponsiveContainer>
					<div>
						{__('с')} {isOneDate ? `${dates[0]}:00` : dayjs(dates[0]).format(dateClientFormat)} {__('по')}{' '}
						{isOneDate ? `${dates[dates.length - 1]}:00` : dayjs(dates[dates.length - 1]).format(dateClientFormat)}
					</div>
				</div>
			</Modal>
		</>
	);
};
