import { useEffect, useState } from 'react';
import { EmployeesPayrolls, EmployeesPayrollsFilter } from 'components';
import { useMethods, dayjs } from 'utils';
import { getEmployeesTotalsAction } from 'actions';

export const EmployeesPayrollsTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const payrolls = await getEmployeesTotalsAction();
			const payrollsFiltersDefault = {
				employee_id: null,
				department_id: null,
				position_id: null,
				date: dayjs().subtract(1, 'day')
			};
			const payrollsFilters = { ...payrollsFiltersDefault };
			setPending(false);
			update({
				payrolls,
				payrollsFiltersDefault,
				payrollsFilters,
				payrollsSelect: {
					selectedRowKeys: [],
					selectedRows: []
				},
				employeesTab: 'payroll'
			});
		})();

		return () => {
			update({
				payrolls: {},
				payrollsFiltersDefault: {},
				payrollsFilters: {},
				employeesTab: 'payroll'
			});
		};
	}, []);

	return (
		<>
			<EmployeesPayrollsFilter setPending={setPending} />
			<EmployeesPayrolls pending={pending} />
		</>
	);
};
