import { useEffect } from 'react';
import { Form, Tooltip } from 'antd';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMethods, useData, formatDigits } from 'utils';
import { getNomenclatureExpensesAction } from 'actions';
import { RangePickerField } from 'components';
import { dateClientFormat } from 'const';

let timeoutId = null;

export const DocsNomFilter = (props) => {
	const { setPending } = props;
	const { docsNomFiltersDefault = {}, docsNomFilters = {}, docsNom = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	const { cost } = docsNom.reduce(
		(a, e) => {
			return {
				cost: e.cost + a.cost
			};
		},
		{ cost: 0 }
	);

	useEffect(() => {
		form.setFieldsValue({
			...docsNomFiltersDefault
		});
	}, [docsNomFiltersDefault]);

	const filterHandler = (changedValues, values) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(async () => {
			setPending(true);
			const filter = {
				...docsNomFilters,
				...values
			};
			const docsNom = await getNomenclatureExpensesAction(filter);
			setPending(false);
			update({
				docsNomFilters: filter,
				docsNom
			});
		}, 500);
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(docsNomFiltersDefault);
		const docsNom = await getNomenclatureExpensesAction(docsNomFiltersDefault);
		setPending(false);
		update({
			docsNomFilters: docsNomFiltersDefault,
			docsNom
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const docsNom = await getNomenclatureExpensesAction(docsNomFilters);
		setPending(false);
		update({
			docsNom
		});
	};

	return (
		<div className="filter filter--6">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="date" label={__('Период времени')}>
								<RangePickerField allowClear={false} format={dateClientFormat} showTime={false} separator="—" />
							</Form.Item>
						</div>
						<div className="filter__item">
							<div className="filter__text-block">
								<div style={{marginTop: '17px'}}>
									{__('Сумма')}:{' '}
									<span style={{ color: '#ff0101' }}>
										{formatDigits({ number: cost, toFixed: 2, defaultValue: 0 })} {__('грн')}
									</span>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
