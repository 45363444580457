import { LoadingOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { paginationDefault, dateClientFormat, dateServerFormat, dateTimeServerFormat, timeClientFormat, timeServerFormat } from 'const';
import { Table, Popconfirm, Row, Col, Tooltip } from 'antd';
import { useMethods, useData, dayjs, access } from 'utils';
import { getShiftsAction, deleteAttendanceAction } from 'actions';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { AddAttendance, EditAttendance } from 'components';

export const EmployeesShifts = (props) => {
	const { pending, setPending } = props;
	const { shifts = {}, shiftsFilters = {}, positions = [] } = useData();
	const { data = [], meta = {} } = shifts;
	const { current_page = 1, total = 0, per_page = 10 } = meta;
	const { __, update } = useMethods();

	const tableChangeHandler = async (pagination) => {
		setPending(true);
		const filter = {
			...shiftsFilters,
			page: pagination.current,
			per_page: pagination.pageSize
		};
		const shifts = await getShiftsAction(filter);
		setPending(false);
		update({
			shiftsFilters: filter,
			shifts
		});
	};

	const deleteAttendanceHandler = async (id) => {
		setPending(true);
		const response = await deleteAttendanceAction(id);
		const shifts = await getShiftsAction(shiftsFilters);
		setPending(false);
		if (response) {
			update({
				shifts
			});
		}
	};

	return (
		<Table
			id="employees-shifts"
			rowKey="id"
			className="table"
			bordered
			rowClassName={(record) => (record.unclosed_attendance || parseInt(record.duration) >= 13 ? 'employee-highlight' : '')}
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 960
			}}
			columns={[
				{
					title: __('Сотрудник'),
					dataIndex: 'employee_name'
				},
				{
					title: __('Должность'),
					dataIndex: 'position_id',
					render: (id) => positions.find((item) => item.id === id)?.name || '-'
				},
				{
					title: __('Дата смены'),
					dataIndex: 'date',
					render: (date) => (date ? dayjs(date, dateServerFormat).format(dateClientFormat) : '-')
				},
				{
					title: __('Начало смены'),
					dataIndex: 'started_at',
					render: (date) => (date ? dayjs(date, dateTimeServerFormat).format(timeClientFormat) : '-')
				},
				{
					title: __('Окончание смены'),
					dataIndex: 'finished_at',
					render: (date) => (date ? dayjs(date, dateTimeServerFormat).format(timeClientFormat) : '-')
				},
				{
					title: __('Длительность'),
					dataIndex: 'duration',
					width: 80,
					render: (duration) => (duration ? dayjs(duration, timeClientFormat).format(timeClientFormat) : '-')
				},
				{
					title: __('Стоимость часа'),
					dataIndex: 'hour_rate'
				}
			]}
			expandedRowRender={(record) => (
				<Table
					rowKey={(attendance) => attendance.id}
					className="table"
					bordered
					dataSource={record.attendances}
					pagination={false}
					title={() => (
						<Row align="middle" justify="space-between">
							<Col>
								<b>{__('Список посещений')}</b>
							</Col>
							<Col>
								<AddAttendance employeeId={record.employee_id} date={record.date}>
									<Tooltip title={__('Добавить посещение')} placement="topRight">
										<button type="button" className="button button--icon-xs button--trans-main">
											<PlusOutlined />
										</button>
									</Tooltip>
								</AddAttendance>
							</Col>
						</Row>
					)}
					columns={[
						{
							title: __('Начало'),
							dataIndex: 'started_at',
							render: (date) => (date ? dayjs(date, dateTimeServerFormat).format(timeClientFormat) : '-')
						},
						{
							title: __('Конец'),
							dataIndex: 'finished_at',
							render: (date) => (date ? dayjs(date, dateTimeServerFormat).format(timeClientFormat) : '-')
						},
						{
							title: __('Длительность'),
							dataIndex: 'duration',
							render: (duration) => (duration ? dayjs(duration, timeClientFormat).format(timeClientFormat) : '-')
						},
						{
							title: __('Создал'),
							dataIndex: 'creator'
						},
						{
							title: __('Изменил'),
							dataIndex: 'changer'
						},
						{
							title: __('Действия'),
							dataIndex: 'actions',
							align: 'right',
							render: (_, attendance) => {
								return (
									<Row gutter={10} justify="end">
										{access('employees-attendance-update') ? (
											<Col>
												<EditAttendance
													employeeId={record.employee_id}
													id={attendance.id}
													date={record.date}
													startedTime={
														attendance.started_at
															? dayjs(attendance.started_at, dateTimeServerFormat).format(timeServerFormat)
															: null
													}
													finishedTime={
														attendance.finished_at
															? dayjs(attendance.finished_at, dateTimeServerFormat).format(timeServerFormat)
															: null
													}
												>
													<Tooltip title={__('Редактировать посещение')} placement="topRight">
														<button type="button" className="button button--icon-xs button--trans-main">
															<EditOutlined />
														</button>
													</Tooltip>
												</EditAttendance>
											</Col>
										) : null}
										{access('employees-attendance-delete') ? (
											<Col>
												<Popconfirm
													title={__('Вы точно хотите удалить запись посещения?')}
													okText={__('Да')}
													cancelText={__('Отмена')}
													placement="topRight"
													onConfirm={() => deleteAttendanceHandler(attendance.id)}
												>
													<Tooltip title={__('Удалить посещение')} placement="bottomRight">
														<button type="button" className="button button--icon-xs button--trans-error">
															<IconClose />
														</button>
													</Tooltip>
												</Popconfirm>
											</Col>
										) : null}
									</Row>
								);
							}
						}
					]}
				/>
			)}
			dataSource={data}
			pagination={{
				...paginationDefault,
				current: current_page,
				pageSize: +per_page,
				total
			}}
			onChange={tableChangeHandler}
		/>
	);
};
