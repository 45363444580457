import { useState } from 'react';
import { Modal } from 'components';
import { useMethods, dayjs, dateParse, randomColor } from 'utils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { dateClientFormat, dateServerFormat, revenueDateInfo } from 'const';

const colors = ['#378ef0', '#fb8c13', '#2de3b5', '#f3a7ff', '#9730f9'];

export const RevenueGraphStatistic = (props) => {
	const { children, data = {}, onOpen } = props;
	const { __ } = useMethods();
	const [visible, setVisible] = useState(false);
	const { name, dates = [] } = data;

	const lines = Object.entries(revenueDateInfo).map(([key, value], index) => {
		return <Line key={index} type="monotone" name={__(value)} dataKey={key} stroke={colors[index] || randomColor()} />;
	});

	const list = dates
		.map((values) => {
			const parsedDate = dayjs(values.date, dateServerFormat);
			const name = parsedDate.isSame(dayjs(), 'year') ? parsedDate.format('DD.MM') : parsedDate.format(dateClientFormat);
			return {
				name,
				...values
			};
		})
		.sort((a, b) => a.date.localeCompare(b.date));

	const openHandler = () => {
		setVisible(true);
		onOpen();
	};

	return (
		<>
			<div onClick={openHandler}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="big">
				<div className="modal__container">
					<div className="modal__title">{`${__('Общая статистика')} (${__('г.')} ${name})`}</div>
					<ResponsiveContainer width="100%" height={600}>
						<LineChart data={list}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip labelFormatter={(v) => (v ? dateParse(list.find((e) => e.name === v)?.date || '') : '')} />
							<Legend />
							{lines}
						</LineChart>
					</ResponsiveContainer>
					<div>
						c {dayjs(list[0].date).format(dateClientFormat)} по {dayjs(list[list.length - 1].date).format(dateClientFormat)}
					</div>
				</div>
			</Modal>
		</>
	);
};
