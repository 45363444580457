import { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useMethods, useData, requiredRule } from 'utils';
import { Modal } from 'components';

export const ChangeProductNote = (props) => {
	const { children, product = {} } = props;
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const { locale, order } = useData();
	const { __, update } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				note: product.note
			});
		}
	}, [visible]);

	const submitHandler = async (values) => {
		update({
			order: {
				...order,
				products: order.products.map((item) => {
					if (item.id === product.id) {
						return {
							...item,
							note: values.note
						};
					} else {
						return item;
					}
				})
			}
		});
		setVisible(false);
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">
						{__('Примечание к товарной позиции')}
						<br />
						<b>{product.name[locale]}</b>
					</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Form.Item
							className="form__item form__item--textarea"
							name="note"
							label={__('Примечание')}
							required
							rules={[requiredRule(__)]}
						>
							<Input.TextArea />
						</Form.Item>
						<div className="form__button">
							<button className="button button--main button--full">
								<span>{__('Сохранить')}</span>
							</button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	);
};
