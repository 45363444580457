import { MainLayout } from 'layouts';
import { useMethods, access } from 'utils';
import { Tabs } from 'antd';
import { ActiveCashboxTab, FinishedCashboxTab } from 'components';

export const OperationsPage = () => {
	const { __ } = useMethods();

	return (
		<MainLayout seo={{ title: `${__('Касса')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				{access('cashbox-tab-opened') ? (
					<Tabs.TabPane tab={__('Текущая касса')} key="active">
						<ActiveCashboxTab />
					</Tabs.TabPane>
				) : null}
				{access('cashbox-tab-archived') ? (
					<Tabs.TabPane tab={__('Архив кассы')} key="finished">
						<FinishedCashboxTab />
					</Tabs.TabPane>
				) : null}
			</Tabs>
		</MainLayout>
	);
};
