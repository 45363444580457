import { useState } from 'react';
import { Row, Col, Popover } from 'antd';
import { formatDigits, useMethods } from 'utils';
import { RevenueGraphStatistic } from 'components';

export const RevenueContent = (props) => {
	const { record = {}, data = {}, needGraph } = props;
	const { count = 0, amount = 0, self_delivery_count = 0, site_count = 0, client_count = 0, rejected_count = 0 } = record;
	const { __ } = useMethods();
	const [visible, setVisible] = useState(false);

	return (
		<Popover
			visible={visible}
			onVisibleChange={(flag) => setVisible(flag)}
			content={
				<>
					<Row gutter={5} justify="space-between">
						<Col>{__('Кол-во заказов')}</Col>
						<Col>
							<b>{count}</b>
						</Col>
					</Row>
					<Row gutter={5} justify="space-between">
						<Col>{__('Самовывоз')}</Col>
						<Col>
							<b>{self_delivery_count}</b>
						</Col>
					</Row>
					<Row gutter={5} justify="space-between">
						<Col>{__('Заказы с сайта')}</Col>
						<Col>
							<b>{site_count}</b>
						</Col>
					</Row>
					<Row gutter={5} justify="space-between">
						<Col>{__('Новые клиенты')}</Col>
						<Col>
							<b>{client_count}</b>
						</Col>
					</Row>
					<Row gutter={5} justify="space-between">
						<Col>{__('Отказы')}</Col>
						<Col>
							<b>{rejected_count}</b>
						</Col>
					</Row>
					{needGraph ? (
						<RevenueGraphStatistic data={data} onOpen={() => setVisible(false)}>
							<button className="button button--small button--full button--main" style={{ marginTop: '10px' }}>
								<span>{__('Подробнее')}</span>
							</button>
						</RevenueGraphStatistic>
					) : null}
				</>
			}
			title={__('Общая статистика')}
			overlayStyle={{ width: '200px' }}
		>
			{count} / {formatDigits({ number: amount || 0, toFixed: 2, defaultValue: 0 })} {__('грн')}
		</Popover>
	);
};
