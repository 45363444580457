import { useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMethods, useData, access } from 'utils';
import { getReportsSalaryAction } from 'actions';

let timeoutId = null;

export const DocsSalaryFilter = (props) => {
	const { setPending } = props;
	const {
		restaurants = [],
		years = [],
		months = [],
		docsSalaryFiltersDefault = {},
		docsSalaryFilters = {},
		user = {}
	} = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue({
			...docsSalaryFiltersDefault,
			restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id
		});
	}, [docsSalaryFiltersDefault]);

	const filterHandler = (changedValues, values) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(async () => {
			setPending(true);
			const filter = {
				...docsSalaryFilters,
				...values
			};
			const docsSalary = await getReportsSalaryAction(filter);
			setPending(false);
			update({
				docsSalaryFilters: filter,
				docsSalary
			});
		}, 500);
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(docsSalaryFiltersDefault);
		const docsSalary = await getReportsSalaryAction(docsSalaryFiltersDefault);
		setPending(false);
		update({
			docsSalaryFilters: docsSalaryFiltersDefault,
			docsSalary
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const docsSalary = await getReportsSalaryAction(docsSalaryFilters);
		setPending(false);
		update({
			docsSalary
		});
	};

	return (
		<div className="filter filter--6">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						{access('docs-salary-expenses-percentage-restaurant-filter') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="restaurant_id" label={__('Ресторан')}>
									<Select
										dropdownClassName="form__dropdown"
										options={restaurants.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
						<div className="filter__item">
							<Form.Item className="form__item" name="month" label={__('Месяц')}>
								<Select dropdownClassName="form__dropdown" options={months} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="year" label={__('Год')}>
								<Select dropdownClassName="form__dropdown" options={years} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
