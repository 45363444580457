import { useEffect } from 'react';
import { DatePicker, Form, Select, Tooltip, Typography } from 'antd';
import { dateClientFormat, dateServerFormat } from 'const';
import { ClearOutlined, PrinterOutlined, ReloadOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { print, useMethods, useData, downloadFile, dayjs, access, formatDigits } from 'utils';
import { getEmployeesTotalsAction, checksPrintingAction } from 'actions';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';
import { PaySalary } from 'components';

export const EmployeesPayrollsFilter = (props) => {
	const { setPending } = props;
	const {
		employees = [],
		departments = [],
		positions = [],
		payrollsFiltersDefault = {},
		payrollsFilters = {},
		payrolls = {},
		payrollsSelect = { selectedRowKeys: [], selectedRows: [] }
	} = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(payrollsFiltersDefault);
	}, [payrollsFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...payrollsFilters,
			...values
		};
		const payrolls = await getEmployeesTotalsAction(filter);
		setPending(false);
		update({
			payrollsFilters: filter,
			payrolls
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(payrollsFiltersDefault);
		const payrolls = await getEmployeesTotalsAction();
		setPending(false);
		update({
			payrollsFilters: payrollsFiltersDefault,
			payrolls
		});
	};

	const printTable = () => {
		print('#employees-payrolls .ant-table-content', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};

	const updateHandler = async () => {
		setPending(true);
		const payrolls = await getEmployeesTotalsAction(payrollsFilters);
		setPending(false);
		update({
			payrolls
		});
	};

	const checksPrintingHandler = async () => {
		await checksPrintingAction({
			employees: payrollsSelect.selectedRows.map((item) => item.employee_id),
			date: payrollsFilters.date ? dayjs(payrollsFilters.date).format(dateServerFormat) : null
		});
	};

	const downloadXlsFile = (event) => {
		event.preventDefault();
		downloadFile(`transactions/employees-totals-for-period-export`, `transactions-totals-for-period.xls`, {
			...payrollsFilters,
			date: payrollsFilters.date ? dayjs(payrollsFilters.date).format(dateServerFormat) : null
		});
	};

	return (
		<>
			<div className="filter filter--4">
				<div className="filter__buttons">
					{access('employees-payroll-pay') ? (
						<div className="filter__button">
							{payrollsSelect.selectedRows.length ? (
								<PaySalary>
									<Tooltip title={__('Выплатить зарплату')} placement="topLeft">
										<button className="button button--main button--icon-small">
											<MoneyCollectOutlined />
										</button>
									</Tooltip>
								</PaySalary>
							) : (
								<button className="button button--main button--icon-small" disabled={true}>
									<MoneyCollectOutlined />
								</button>
							)}
						</div>
					) : null}
					{access('employees-payroll-print-checks') ? (
						<div className="filter__button">
							{payrollsSelect.selectedRows.length ? (
								<Tooltip title={__('Распечатать чеки')} placement="topLeft">
									<button className="button button--main button--icon-small" onClick={checksPrintingHandler}>
										<PrinterOutlined />
									</button>
								</Tooltip>
							) : (
								<button className="button button--main button--icon-small" disabled={true}>
									<PrinterOutlined />
								</button>
							)}
						</div>
					) : null}
				</div>
				<div className="filter__form">
					<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
						<div className="filter__list">
							<div className="filter__item">
								<Form.Item className="form__item" label={__('ФИО сотрудника')} name="employee_id">
									<Select
										autoClearSearchValue={false}
										showArrow={false}
										notFoundContent={null}
										showSearch={true}
										optionFilterProp="label"
										dropdownClassName="form__dropdown"
										options={employees.map((item) => ({ label: item.name, value: item.id }))}
										allowClear={true}
										placeholder={__('Не выбрано')}
									/>
								</Form.Item>
							</div>
							<div className="filter__item">
								<Form.Item className="form__item" name="department_id" label={__('Отдел')}>
									<Select
										autoClearSearchValue={false}
										showArrow={false}
										notFoundContent={null}
										showSearch={true}
										optionFilterProp="label"
										dropdownClassName="form__dropdown"
										options={departments.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
							<div className="filter__item">
								<Form.Item className="form__item" name="position_id" label={__('Должность')}>
									<Select
										autoClearSearchValue={false}
										showArrow={false}
										notFoundContent={null}
										showSearch={true}
										optionFilterProp="label"
										dropdownClassName="form__dropdown"
										options={positions.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
							<div className="filter__item">
								<Form.Item className="form__item" name="date" label={__('Дата')}>
									<DatePicker
										allowClear={false}
										format={dateClientFormat}
										showTime={false}
										disabledDate={(current) => current && current.endOf('day') >= dayjs().endOf('day')}
									/>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
				<div className="filter__buttons">
					<div className="filter__button">
						<Tooltip title={__('Очистить фильтр')} placement="topRight">
							<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
								<ClearOutlined />
							</button>
						</Tooltip>
					</div>
					<div className="filter__button">
						<Tooltip title={__('Обновить')} placement="topRight">
							<button className="button button--main-border button--icon-small" onClick={updateHandler}>
								<ReloadOutlined />
							</button>
						</Tooltip>
					</div>
					<div className="filter__button filter__button--lg-show">
						<Tooltip title={__('Распечатать')} placement="topRight">
							<button className="button button--main-border button--icon-small" onClick={printTable}>
								<PrinterOutlined />
							</button>
						</Tooltip>
					</div>
					<div className="filter__button filter__button--lg-show">
						<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
							<a
								href="/public/transactions-totals-for-period.xls"
								className="button button--default button--icon-def"
								onClick={downloadXlsFile}
							>
								<XlsIcon />
							</a>
						</Tooltip>
					</div>
				</div>
			</div>
			<Typography.Title level={4} style={{ paddingTop: '10px' }}>
				{__('Всего к выплате')}
				{': '}
				<span style={{ color: '#e31e24' }}>
					{formatDigits({ number: payrolls.total || 0, toFixed: 2 })} {__('грн')}
				</span>
			</Typography.Title>
		</>
	);
};
