import { getCookie, getStore, getUrl, isUrl, setUrl } from 'utils';
import { logoutClientAction } from 'actions';
import { sidebarMenu } from 'const';

export const access = (alias) => {
	const permissions = getStore('permissions', []);
	return Array.isArray(permissions) && permissions.includes(alias);
};

export const getHomeUrl = () => {
	let result = '/employees';
	const permissions = getStore('permissions', []);
	for (let i = 0; i < sidebarMenu.length; i++) {
		const item = sidebarMenu[i];
		if (permissions.includes(item.permission)) {
			result = item.href;
			break;
		}
	}
	return result;
};

export const checkUser = (isUser, redirect, homeUrl) => {
	let result = '';
	const url = getUrl();
	const isAuthUrl = isUrl('/login', true);
	const isReloginUrl = isUrl('/relogin', true);
	const isToken = !!getCookie('refreshToken');

	if (!isReloginUrl) {
		if ((!isUser || !isToken) && !isAuthUrl) {
			result = setUrl(`/login?redirect=${url}`);
			logoutClientAction();
		}

		if (isUser && isToken && isAuthUrl) {
			result = redirect || homeUrl;
		}
	}

	return result;
};
