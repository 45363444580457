import { useEffect, useState } from 'react';
import { useMethods, dayjs } from 'utils';
import { DocsNomFilter, DocsNom } from 'components';
import { getNomenclatureExpensesAction } from 'actions';

export const DocsNomTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const docsNomFiltersDefault = {
				date: [dayjs().subtract(7, 'day'), dayjs()]
			};
			const docsNomFilters = {
				...docsNomFiltersDefault
			};
			const docsNom = await getNomenclatureExpensesAction(docsNomFilters);
			setPending(false);
			update({
				docsNomFiltersDefault,
				docsNomFilters,
				docsNom
			});
		})();

		return () => {
			update({
				docsNomFiltersDefault: {},
				docsNomFilters: {},
				docsNom: []
			});
		};
	}, []);

	return (
		<>
			<DocsNomFilter setPending={setPending} />
			<DocsNom pending={pending} />
		</>
	);
};
