export const dictionaries = {
	'dictionary-order-statuses': { prop: 'orderStatuses', actions: ['created', 'updated', 'deleted'] },
	'dictionary-payment-types': { prop: 'paymentTypes', actions: ['created', 'updated', 'deleted'] },
	'dictionary-staff-departments': { prop: 'departments', actions: ['created', 'updated', 'deleted'] },
	'dictionary-staff-positions': { prop: 'positions', actions: ['created', 'updated', 'deleted'] },
	'dictionary-staff-rates': { prop: 'rates', actions: ['created', 'updated', 'deleted'] },
	'dictionary-couriers': { prop: 'couriers', actions: ['created', 'updated', 'deleted'] },
	'dictionary-settings': { prop: 'settings', actions: ['changed'] },
	'dictionary-cities': { prop: 'cities', actions: ['created', 'updated', 'deleted'] },
	'dictionary-warehouses': { prop: 'warehouses', actions: ['created', 'updated', 'deleted'] },
	'dictionary-delivery-types': { prop: 'deliveryTypes', actions: ['created', 'updated', 'deleted'] }
};

export const restaurantDictionaries = {
	'dictionary-order-sales': { prop: 'orderSales', actions: ['created', 'updated', 'deleted'] },
	'dictionary-order-types': { prop: 'orderTypes', actions: ['created', 'updated', 'deleted'] },
	'dictionary-transactions-types': { prop: 'transactionsTypes', actions: ['created', 'updated', 'deleted'] },
	'dictionary-employees-transactions-types': { prop: 'operationsTypes', actions: ['created', 'updated', 'deleted'] }
};

export const restaurantSettings = {
	'current-restaurant-settings': { prop: 'restaurantSettings', actions: ['changed'] }
};

export const employeeDictionaries = {
	notifications: { prop: 'notifications', actions: ['created', 'updated', 'deleted'] }
};
