export const paginationDefault = {
	size: 'small',
	showSizeChanger: true,
	pageSizeOptions: ['10', '25', '50', '100'],
	defaultCurrent: 1,
	defaultPageSize: 10
};

export const paginationLarge = {
	size: 'small',
	showSizeChanger: true,
	pageSizeOptions: ['50', '100', '150', '200'],
	defaultCurrent: 1,
	defaultPageSize: 100
};
