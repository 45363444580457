import { LoadingOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { useMethods, useData, formatDigits, dayjs, getSummaryCellData, getStringLengthInPx } from 'utils';

export const DocsTransactions = (props) => {
	const { pending } = props;
	const { docsTrans = {} } = useData();
	const { columns = [], rows = [], values = [] } = docsTrans;
	const { __ } = useMethods();
	const tableHeight = window.innerHeight - 357;

	let width = 40;
	const columnsList = [
		{
			title: __('Дата'),
			dataIndex: 'date',
			fixed: 'left',
			width: 40,
			render: (data, record) =>
				record.isFoot ? (
					data
				) : (
					<>
						{data || '-'}
						<div style={{ lineHeight: '6px' }}>{dayjs(data, 'DD.MM').format('dd')}</div>
					</>
				)
		}
	];
	const dataSource = [];
	const summary = [];

	columns.forEach((column, index) => {
		const total = formatDigits({ number: column.total || 0, toFixed: 0, defaultValue: 0 });
		let currentWidth = getStringLengthInPx(total);
		if (currentWidth < 40) {
			currentWidth = 40;
		} else if (currentWidth > 90) {
			currentWidth = 90;
		}
		width += currentWidth;
		columnsList.push({
			title: (
				<Tooltip placement={'topLeft'} title={column.name}>
					{column.name}
				</Tooltip>
			),
			dataIndex: `column${index}`,
			width: currentWidth,
			render: (col) => {
				const descriptions =
					col.description && col.description.length
						? col.description.reduce((a, e, i) => {
								return a ? (
									<>
										{a}
										<div>
											{i + 1}. {e};
										</div>
									</>
								) : (
									<div>1. {e};</div>
								);
						  }, '')
						: null;
				return {
					props: {
						style: {
							backgroundColor: column.color
						}
					},
					children: descriptions ? (
						<Tooltip placement={'bottomRight'} title={descriptions}>
							{formatDigits({ number: col.amount || 0, toFixed: 0, defaultValue: 0 })}
						</Tooltip>
					) : (
						formatDigits({ number: col.amount || 0, toFixed: 0, defaultValue: 0 })
					)
				};
			}
		});
	});

	rows.forEach((row, i) => {
		const value = values[i];
		if (value) {
			const res = {
				isFoot: false,
				date: row
			};
			value.forEach((val, j) => {
				res[`column${j}`] = val;
			});
			dataSource.push(res);
		}
	});

	const total = {
		isFoot: true,
		date: __('Итого')
	};
	const percent = {
		isFoot: true,
		date: '%'
	};
	const cash = {
		isFoot: true,
		date: __('Наличные')
	};
	const cashless = {
		isFoot: true,
		date: __('Безнал')
	};
	columns.forEach((column, i) => {
		total[`column${i}`] = { amount: column.total };
		percent[`column${i}`] = { amount: column.percent };
		cash[`column${i}`] = { amount: column.cash_total };
		cashless[`column${i}`] = { amount: column.cashless_total };
	});
	summary.push(cash);
	summary.push(cashless);
	summary.push(total);
	summary.push(percent);

	return (
		<Table
			rowKey="date"
			className="table table--docs-transactions"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				y: tableHeight >= 580 ? tableHeight : 580,
				x: width
			}}
			rowClassName={(record) => (record.isFoot ? 'is-foot' : '')}
			columns={columnsList}
			dataSource={dataSource}
			pagination={false}
			summary={
				dataSource.length
					? () => (
							<Table.Summary fixed>
								{summary.map((row, rowIndex) => (
									<Table.Summary.Row key={rowIndex}>
										{columnsList.map((column, colIndex) => {
											const { cellProps, cellChild } = getSummaryCellData(column, row);
											return (
												<Table.Summary.Cell key={`${rowIndex}${colIndex}`} index={colIndex} {...cellProps}>
													{cellChild}
												</Table.Summary.Cell>
											);
										})}
									</Table.Summary.Row>
								))}
							</Table.Summary>
					  )
					: null
			}
		/>
	);
};
