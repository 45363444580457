import { LoadingOutlined } from '@ant-design/icons';
import { Table, Row, Col, Tooltip } from 'antd';
import { useMethods, useData, dayjs, formatDigits, getSummaryCellData } from 'utils';
import { ReactComponent as GraphRevenue } from 'assets/svg/graph-revenue.svg';
import { dateClientFormat, dateServerFormat } from 'const';
import { RevenueContent, RevenueGraphDay, RevenueGraphCity } from 'components';

export const DocsRevenue = (props) => {
	const { pending } = props;
	const { restaurants: restaurantsList = [], docsRevenue = {}, docsRevenueFilters = {} } = useData();
	const { dates = [], restaurants = [] } = docsRevenue;
	const { __ } = useMethods();

	const isOneDate =
		docsRevenueFilters.dates &&
		docsRevenueFilters.dates.length &&
		dayjs(docsRevenueFilters.dates[0], dateServerFormat).format(dateClientFormat) ===
			dayjs(docsRevenueFilters.dates[1], dateServerFormat).format(dateClientFormat);

	const rows = [];
	restaurants.forEach((record) => {
		let clientCount = 0;
		record.dates.forEach((item) => {
			clientCount += item.client_count;
		});

		const item = {
			...record,
			name: restaurantsList.find((e) => e.slug === record.restaurant_slug)?.name || record.restaurant_slug,
			check: record.count ? record.amount / record.count : 0,
			percent: (clientCount * 100) / record.count
		};

		dates.forEach((date) => {
			item[`${date}`] = record.dates.find((el = {}) => `${el.date}` === `${date}`);
		});

		rows.push(item);
	});

	let width = 150 * 3 + 200;
	const columns = [
		{
			title: __('Город'),
			width: 150,
			sorter: (a, b) => {
				return a.name.localeCompare(b.name);
			},
			className: 'is-arriving',
			dataIndex: 'name',
			fixed: 'left',
			render: (name, record) => {
				if (!record.isFoot) {
					return (
						<Row gutter={5} justify="space-between" align="middle" style={{ flexWrap: 'nowrap' }}>
							<Col>{name}</Col>
							{isOneDate && record.dates.filter((e) => !!e.amount).length > 1 ? (
								<Col>
									<RevenueGraphCity data={record}>
										<Tooltip title={__('График по городу за день')} placement="topLeft">
											<button className="button button--icon-xs button--trans">
												<GraphRevenue />
											</button>
										</Tooltip>
									</RevenueGraphCity>
								</Col>
							) : null}
						</Row>
					);
				} else {
					return __('Всего');
				}
			}
		},
		{
			title: __('Заказы'),
			width: 200,
			sorter: (a, b) => {
				return a.count - b.count;
			},
			className: 'is-arriving',
			defaultSortOrder: 'descend',
			dataIndex: 'count',
			render: (count, record) => {
				if (!record.amount) {
					return '-';
				}
				if (!record.isFoot) {
					return <RevenueContent record={record} data={record} needGraph={!isOneDate} />;
				} else {
					return `${count} / ${formatDigits({ number: record.amount || 0, toFixed: 2, defaultValue: 0 })} ${__('грн')}`;
				}
			}
		},
		{
			title: __('Средний чек'),
			width: 150,
			className: 'is-arriving',
			dataIndex: 'check',
			sorter: (a, b) => {
				return a.check - b.check;
			},
			render: (check) => {
				return check > 0 ? formatDigits({ number: check, toFixed: 2, defaultValue: 0 }) : '-';
			}
		},
		{
			title: __('Процент новых клиентов'),
			width: 150,
			className: 'is-arriving',
			dataIndex: 'percent',
			sorter: (a, b) => {
				return a.percent - b.percent;
			},
			render: (percent) => (percent ? `${percent.toFixed(0)}%` : '-')
		}
	];
	dates.forEach((date, index) => {
		columns.push({
			title: (
				<div>
					{isOneDate ? (
						<div>{date}:00</div>
					) : (
						<>
							<div>{dayjs(date, dateServerFormat).format(dateClientFormat)}</div>
							<div>{dayjs(date, dateServerFormat).format('dddd')}</div>
						</>
					)}
				</div>
			),
			dataIndex: `${date}`,
			className: !isOneDate && dayjs(date, dateServerFormat).isoWeekday() === 7 && index !== dates.length - 1 ? 'is-finish-week' : '',
			width: 170,
			render: (obj, record) => {
				if (!obj || !obj.amount) {
					return '-';
				}
				if (!record.isFoot) {
					return <RevenueContent record={obj} data={record} needGraph={!isOneDate} />;
				} else {
					return (
						<Row gutter={5} justify="space-between" align="middle" style={{ flexWrap: 'nowrap' }}>
							<Col>
								{obj.count} / {formatDigits({ number: obj.amount || 0, toFixed: 2, defaultValue: 0 })} {__('грн')}
							</Col>
							{!isOneDate && rows.filter((item) => item[`${obj.date}`]?.amount).length > 1 ? (
								<Col>
									<RevenueGraphDay date={obj.date} data={rows}>
										<Tooltip title={__('График за день')} placement="topLeft">
											<button className="button button--icon-xs button--trans">
												<GraphRevenue />
											</button>
										</Tooltip>
									</RevenueGraphDay>
								</Col>
							) : null}
						</Row>
					);
				}
			}
		});
		width += 170;
	});

	const summaryRow = {
		restaurant_slug: 'all',
		count: 0,
		amount: 0,
		clientCount: 0,
		isFoot: true
	};
	dates.forEach((date) => {
		if (rows.filter((el = {}) => !!el[`${date}`]).length) {
			summaryRow[`${date}`] = {
				count: 0,
				amount: 0,
				date
			};
		}
	});
	rows.forEach((record) => {
		record.dates.forEach((item) => {
			summaryRow.clientCount += item.client_count;
		});

		summaryRow.count += record.count;
		summaryRow.amount += record.amount;

		dates.forEach((date) => {
			if (summaryRow[`${date}`] && record[`${date}`]) {
				summaryRow[`${date}`].count += record[`${date}`].count;
				summaryRow[`${date}`].amount += record[`${date}`].amount;
			}
		});
	});
	summaryRow.check = summaryRow.count ? summaryRow.amount / summaryRow.count : 0;
	summaryRow.percent = (summaryRow.clientCount * 100) / summaryRow.count;
	const summary = [summaryRow];

	return (
		<Table
			rowKey="restaurant_slug"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: width,
				y: 1000
			}}
			rowClassName={(record) => (record.isFoot ? 'is-foot' : '')}
			columns={columns}
			dataSource={rows}
			pagination={false}
			summary={() => (
				<Table.Summary fixed>
					{summary.map((row, rowIndex) => (
						<Table.Summary.Row key={rowIndex}>
							{columns.map((column, colIndex) => {
								const { cellProps, cellChild } = getSummaryCellData(column, row);
								return (
									<Table.Summary.Cell key={`${rowIndex}${colIndex}`} index={colIndex} {...cellProps}>
										{cellChild}
									</Table.Summary.Cell>
								);
							})}
						</Table.Summary.Row>
					))}
				</Table.Summary>
			)}
		/>
	);
};
