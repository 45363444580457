import { defaultLocale } from 'const';
import { getLocale } from 'utils';

export const setUrl = (url, isFull, locale = getLocale()) => {
	let urlResult = url.replace(/^\/?\/?(.*)$/, '$1');
	urlResult = urlResult.replace(/^([^?#]*)\/(\?[^#]*)?(#.*)?$/, '$1$2$3');

	if (locale !== defaultLocale) {
		urlResult = `${locale}${urlResult ? `/${urlResult}` : ''}`;
	}

	const urlFull = urlResult[0] === '?' || urlResult[0] === '#' ? urlResult : `/${urlResult}`;
	urlResult = isFull ? `${process.env.REACT_APP_URL}${urlFull}` : `/${urlResult}`;

	return urlResult;
};

export const isUrl = (pathname, isInclude) => {
	return isInclude ? window.location.pathname.includes(pathname) : pathname === window.location.pathname;
};

export const getUrl = (isAbs) => {
	return `${isAbs ? process.env.REACT_APP_URL : ''}${window.location.pathname}`;
};

export const getSearch = () => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	return Object.fromEntries(urlSearchParams.entries());
};
