import {
	HomePage,
	LoginPage,
	ReloginPage,
	ErrorPage,
	EmployeesPage,
	OrdersPage,
	OrderPage,
	DocumentsPage,
	CashboxPage,
	OperationsPage,
	ClientsPage,
	ClientPage,
	CouriersPage,
	WarehousePage,
	WaybillPage
} from 'pages';
import { ReactComponent as EmployeesIcon } from 'assets/svg/employees.svg';
import { ReactComponent as ClientsIcon } from 'assets/svg/clients.svg';
import { ReactComponent as OrdersIcon } from 'assets/svg/orders.svg';
import { ReactComponent as DocsIcon } from 'assets/svg/docs.svg';
import { ReactComponent as Cashbox } from 'assets/svg/cashbox.svg';
import { ReactComponent as Operation } from 'assets/svg/operation.svg';
import { ReactComponent as CouriersIcon } from 'assets/svg/couriers.svg';
import { ReactComponent as WarehouseIcon } from 'assets/svg/warehouse.svg';

export const routesList = [
	{
		path: '/',
		component: HomePage,
		exact: true
	},
	{
		path: '/login',
		component: LoginPage,
		exact: false
	},
	{
		path: '/relogin',
		component: ReloginPage,
		exact: false
	},
	{
		path: '/employees',
		component: EmployeesPage,
		exact: true
	},
	{
		path: '/orders',
		component: OrdersPage,
		exact: true
	},
	{
		path: '/orders/create',
		component: OrderPage,
		exact: true
	},
	{
		path: '/orders/:id([0-9]+)',
		component: OrderPage,
		exact: true
	},
	{
		path: '/documents',
		component: DocumentsPage,
		exact: true
	},
	{
		path: '/cashbox',
		component: CashboxPage,
		exact: true
	},
	{
		path: '/operations',
		component: OperationsPage,
		exact: true
	},
	{
		path: '/clients',
		component: ClientsPage,
		exact: true
	},
	{
		path: '/clients/create',
		component: ClientPage,
		exact: true
	},
	{
		path: '/clients/:id([0-9]+)',
		component: ClientPage,
		exact: true
	},
	{
		path: '/couriers',
		component: CouriersPage,
		exact: true
	},
	{
		path: '/warehouse',
		component: WarehousePage,
		exact: true
	},
	{
		path: '/warehouse/create',
		component: WaybillPage,
		exact: true
	},
	{
		path: '/warehouse/:type/:id([0-9]+)',
		component: WaybillPage,
		exact: true
	},
	{
		path: '*',
		component: ErrorPage,
		exact: false
	}
];

export const sidebarMenu = [
	{ href: '/employees', name: 'Сотрудники и зарплаты', Icon: EmployeesIcon, permission: 'employees-page' },
	{ href: '/clients', name: 'Клиенты', Icon: ClientsIcon, permission: 'clients-page' },
	{ href: '/couriers', name: 'Курьеры', Icon: CouriersIcon, permission: 'couriers-page' },
	{ href: '/orders', name: 'Заказы', Icon: OrdersIcon, permission: 'orders-page' },
	{ href: '/operations', name: 'Финансовые операции', Icon: Operation, permission: 'cashbox-page' },
	{ href: '/cashbox', name: 'Касса', Icon: Cashbox, permission: 'order-fiscal-invoice-printing' },
	{ href: '/documents', name: 'Документы', Icon: DocsIcon, permission: 'docs-page' },
	{ href: '/warehouse', name: 'Складской учет', Icon: WarehouseIcon, permission: 'warehouse' }
];
