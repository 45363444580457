import { Table } from 'antd';
import { useData, useMethods } from 'utils';
import { LoadingOutlined } from '@ant-design/icons';

export const ProcurementTable = (props) => {
	const { pending } = props;
	const { procurements = [] } = useData();
	const { __ } = useMethods();

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 750,
				y: 650
			}}
			columns={[
				{
					title: __('Склад'),
					dataIndex: 'warehouse',
					width: 150,
					render: (warehouse) => (warehouse && warehouse.name ? warehouse.name : '-')
				},
				{
					title: __('Категория'),
					dataIndex: 'ingredient',
					width: 150,
					render: ({ category_name }) => category_name || '-',
					sorter: (a, b) => a.ingredient?.category_name.localeCompare(b.ingredient?.category_name)
				},
				{
					title: __('Номенклатура'),
					dataIndex: 'ingredient',
					width: 150,
					render: ({ name }) => name || '-',
					sorter: (a, b) => a.ingredient?.name.localeCompare(b.ingredient?.name)
				},
				{
					title: __('Количество'),
					dataIndex: 'value',
					width: 150,
					sorter: (a, b) => a.value - b.value,
					render: (value, record) => `${value || 0} ${record.ingredient.unit_title}`
				},
				{
					title: __('Минимум'),
					dataIndex: 'min_value',
					width: 150,
					render: (value, record) => `${value || 0} ${record.ingredient.unit_title}`
				}
			]}
			dataSource={procurements}
			pagination={false}
		/>
	);
};
