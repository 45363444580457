import { Row, Col, Table } from 'antd';
import { useMethods, useData, dayjs, phoneFormat } from 'utils';
import { dateClientFormat, timeClientFormat } from 'const';
import { Link } from 'components';
import { LoadingOutlined } from '@ant-design/icons';
import { getHistoryMissedAction, sipCallAction } from 'actions';

export const MissedTab = (props) => {
	const { pending, setPending } = props;
	const { __, update } = useMethods();
	const { historyMissed = {}, callsTypes = [] } = useData();
	const { items = [], offset = 0, next } = historyMissed;

	const reloadHandler = async () => {
		setPending(true);
		const list = await getHistoryMissedAction();
		update({
			historyMissed: list
		});
		setPending(false);
	};

	const loadMoreHandler = async () => {
		setPending(true);
		const list = await getHistoryMissedAction({ offset });
		update({
			historyMissed: {
				items: [...(historyMissed.items || []), ...(list.items || [])],
				offset: list.offset || 0,
				next: list.next
			}
		});
		setPending(false);
	};

	return (
		<div className="call-modal__content">
			<Table
				className="table"
				rowKey={(record) => record.id}
				dataSource={items}
				pagination={false}
				showHeader={false}
				loading={{
					spinning: pending,
					size: 'large',
					indicator: <LoadingOutlined />
				}}
				scroll={{
					y: 320
				}}
				columns={[
					{
						dataIndex: 'type_id',
						render: (id) => callsTypes.find((item) => item.id === id)?.name || '-'
					},
					{
						dataIndex: 'client_num',
						render: (phone, record) => {
							return (
								<>
									{record.client_id ? (
										<div style={{ marginBottom: '5px' }}>
											<Link href={`/clients/${record.client_id}`} target={'_blank'}>
												{record.client_name}
											</Link>
										</div>
									) : null}
									<div>
										<div className="link" onClick={() => sipCallAction(phone)}>
											{phoneFormat(phone)}
										</div>
									</div>
								</>
							);
						}
					},
					{
						dataIndex: 'time',
						render: (time) => {
							return time ? (
								<>
									<div>{dayjs(time * 1000).format(dateClientFormat)}</div>
									<div>{dayjs(time * 1000).format(timeClientFormat)}</div>
								</>
							) : (
								'-'
							);
						}
					}
				]}
			/>
			<Row justify="space-between" gutter={24} style={{ marginTop: '20px' }}>
				<Col>
					<button className="button button--main button--small" onClick={reloadHandler}>
						<span>{__('Обновить')}</span>
					</button>
				</Col>
				{next ? (
					<Col>
						<button className="button button--main button--small" onClick={loadMoreHandler}>
							<span>{__('Загрузить еще')}</span>
						</button>
					</Col>
				) : null}
			</Row>
		</div>
	);
};
