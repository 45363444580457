import { createContext, useContext } from 'react';

export const Store = createContext({});

export const useData = () => {
	const context = useContext(Store);
	return context.data;
};

export const useMethods = () => {
	const context = useContext(Store);
	return context.methods;
};
