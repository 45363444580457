import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useMethods, useData, requiredRule } from 'utils';
import { Modal, Preloader } from 'components';
import { changeActiveTripAction } from 'actions';

export const ChangeCourier = (props) => {
	const { children, courier = {} } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { couriersDate = [], packedOrders = [] } = useData();
	const { __ } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				courier_id: null,
				order_ids: []
			});
		}
	}, [visible]);

	const order = courier.orders.find((item) => !item.is_trip_finished) || {};
	const couriersList = couriersDate.filter((item) => item.status === 'free').map((item) => ({ value: item.id, label: item.name }));
	const ordersList = packedOrders.map((item) => ({ value: item.id, label: `№${item.day_id}` }));

	const submitHandler = async (values) => {
		setPending(true);
		await changeActiveTripAction(order.trip_id, values);
		setPending(false);
		setVisible(false);
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{`${__('Замена курьера')} ${courier.name}`}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="courier_id" label={__('Курьер')} required rules={[requiredRule(__)]}>
								<Select dropdownClassName="form__dropdown" options={couriersList} allowClear={true} />
							</Form.Item>
							<Form.Item className="form__item" name="order_ids" label={__('Готовые заказы')}>
								<Select dropdownClassName="form__dropdown" options={ordersList} allowClear={true} mode="multiple" />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Изменить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
