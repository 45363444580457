import { useEffect, useState } from 'react';
import { EmployeesSchedule, EmployeesScheduleFilter } from 'components';
import { useMethods, dayjs } from 'utils';
import { getScheduleTemplatesAction, getShiftsMonthlyScheduleAction, getShiftsMonthlyScheduleStatisticAction } from 'actions';

export const EmployeesScheduleTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const [scheduleTemplates, schedule, scheduleStatistic] = await Promise.all([
				getScheduleTemplatesAction(),
				getShiftsMonthlyScheduleAction(),
				getShiftsMonthlyScheduleStatisticAction()
			]);
			const scheduleFiltersDefault = {
				employee_ids: [],
				department_id: null,
				position_id: null,
				date: [dayjs().startOf('month'), dayjs().endOf('month')]
			};
			const scheduleFilters = { ...scheduleFiltersDefault };
			setPending(false);
			update({
				scheduleTemplates,
				schedule,
				scheduleStatistic,
				scheduleFiltersDefault,
				scheduleFilters,
				employeesTab: 'schedule'
			});
		})();

		return () => {
			update({
				schedule: [],
				scheduleFiltersDefault: {},
				scheduleFilters: {},
				employeesTab: 'schedule'
			});
		};
	}, []);

	return (
		<>
			<EmployeesScheduleFilter setPending={setPending} />
			<EmployeesSchedule pending={pending} />
		</>
	);
};
