import { api, dayjs } from 'utils';
import { dateServerFormat } from 'const';

export const getNomenclatureCategoriesAction = async () => {
	const response = await api.get(`/nomenclature-category/list`);
	return response.data ? response.data : [];
};

export const getWaybillsTypesAction = async () => {
	const response = await api.get(`/waybills-types`);
	return response.data ? response.data : [];
};

export const getWaybillsAction = async (values = {}) => {
	const { date = [], ...rest } = values;
	const params = {
		...rest,
		date: date[0] && date[1] ? `${dayjs(date[0]).format(dateServerFormat)} - ${dayjs(date[1]).format(dateServerFormat)}` : null
	};
	const response = await api.get(`/waybills`, { params });
	return response || {};
};

export const createWaybillAction = async (type, params) => {
	const values = {
		...params
	};
	values.nomenclatures = values.nomenclatures.map((item) => ({
		...item,
		cost: item.cost || item.calc_cost
	}));
	const { data } = await api.post(`/waybills/${type}`, values);
	return !!data;
};

export const deleteWaybillAction = async (type, id) => {
	const { success } = await api.delete(`/waybills/${type}/${id}`);
	return !!success;
};

export const getWarehouseRestsAction = async (values = {}) => {
	const { date, ...rest } = values;
	const params = {
		...rest,
		date: date ? dayjs(date).format(dateServerFormat) : null
	};
	const response = await api.get(`/warehouse-rests`, { params });
	return response || {};
};

export const getProcurementsAction = async () => {
	const response = await api.get(`/warehouse-deficit`);
	return response.data ? response.data : [];
};

export const getSuppliersAction = async (params = {}) => {
	const response = await api.get(`/suppliers/list`, { params });
	return response.data ? response.data : [];
};

export const getWaybillAction = async (type, id) => {
	const response = await api.get(`/waybills/${type}/${id}`);
	return response.data ? response.data : {};
};

export const addSupplierAction = async (values = {}) => {
	const response = await api.post(`/suppliers`, values);
	return !!response.data;
};

export const getWarehouseRestListAction = async (params = {}) => {
	const response = await api.get(`/warehouse-rests/list`, { params });
	return (response.data || [])
		.map((item) => ({
			...(item.nomenclature || {}),
			value: item.value
		}))
		.filter((item) => item.value > 0);
};

export const getNomenclatureListAction = async (params = {}) => {
	const response = await api.get(`/nomenclature/list`, { params });
	return response.data ? response.data : [];
};
