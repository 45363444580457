import { useEffect, useState } from 'react';
import { useMethods, useData, dayjs } from 'utils';
import { DocsCheck } from 'components';
import { getRestaurantsAction, getReportsDayCheckAction, getReportsHourCheckAction } from 'actions';

export const DocsCheckTab = () => {
	const { user = {} } = useData();
	const { update } = useMethods();
	const [pending1, setPending1] = useState(false);
	const [pending2, setPending2] = useState(false);

	useEffect(() => {
		(async () => {
			setPending1(true);
			setPending2(true);
			const restaurants = await getRestaurantsAction();
			const docsCheckFiltersDefault = {
				restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id,
				date_from: dayjs().subtract(1, 'month'),
				date_to: dayjs(),
				date: dayjs()
			};
			const docsCheckFilters = {
				...docsCheckFiltersDefault
			};
			const [docsDayCheck, docsHourCheck] = await Promise.all([
				getReportsDayCheckAction({
					restaurant_id: docsCheckFilters.restaurant_id,
					date_from: docsCheckFilters.date_from,
					date_to: docsCheckFilters.date_to
				}),
				getReportsHourCheckAction({
					restaurant_id: docsCheckFilters.restaurant_id,
					date: docsCheckFilters.date
				})
			]);
			setPending1(false);
			setPending2(false);
			update({
				docsCheckFiltersDefault,
				docsCheckFilters,
				restaurants,
				docsDayCheck,
				docsHourCheck
			});
		})();

		return () => {
			update({
				docsCheckFiltersDefault: {},
				docsCheckFilters: {},
				docsDayCheck: [],
				docsHourCheck: []
			});
		};
	}, []);

	return (
		<>
			<DocsCheck pending1={pending1} pending2={pending2} setPending1={setPending1} setPending2={setPending2} />
		</>
	);
};
