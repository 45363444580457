import { useEffect, useState } from 'react';
import { Form, Select, Switch } from 'antd';
import { useMethods, useData, dayjs, requiredRule } from 'utils';
import { Modal, Preloader } from 'components';
import { dateServerFormat } from 'const';
import { paySalaryAction, getEmployeesTotalsAction } from 'actions';

export const PaySalary = (props) => {
	const { children } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { operationsTypes = [], payrollsFilters = {}, payrollsSelect = { selectedRowKeys: [], selectedRows: [] } } = useData();
	const { __, update } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				type: null,
				print_checks: false
			});
		}
	}, [visible]);

	const submitHandler = async (values) => {
		setPending(true);
		const updateData = {};
		const success = await paySalaryAction({
			employees: payrollsSelect.selectedRows.map((item) => item.employee_id),
			date: payrollsFilters.date ? dayjs(payrollsFilters.date).format(dateServerFormat) : null,
			type: values.type,
			print_checks: values.print_checks ? 1 : 0
		});
		updateData.payrolls = await getEmployeesTotalsAction(payrollsFilters);
		setPending(false);
		update(updateData);
		if (success) {
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Выплата зарплаты')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="type" label={__('Тип операции')} required rules={[requiredRule(__)]}>
								<Select
									dropdownClassName="form__dropdown"
									options={operationsTypes
										.filter((item) => [9, 10].includes(item.id))
										.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item className="form__item" name="print_checks" label={__('Распечатать чеки')} valuePropName="checked">
								<Switch checkedChildren={__('Да')} unCheckedChildren={__('Нет')} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Выплатить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
