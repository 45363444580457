import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { useMethods, useData } from 'utils';

export const DocsNom = (props) => {
	const { pending } = props;
	const { docsNom = [] } = useData();
	const { __ } = useMethods();

	const columns = [
		{
			title: __('Блюдо'),
			dataIndex: 'name',
			sorter: (a, b) => a.name.localeCompare(b.name)
		},
		{
			title: __('Кол-во'),
			dataIndex: 'value',
			sorter: (a, b) => +a.value - +b.value
		},
		{
			title: __('Ед. измерения'),
			dataIndex: 'unit_name',
			sorter: (a, b) => a.unit_name.localeCompare(b.unit_name)
		}
	];

	return (
		<Table
			rowKey="index"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			columns={columns}
			dataSource={docsNom}
			pagination={false}
		/>
	);
};
