import { MainLayout } from 'layouts';
import { useMethods, access } from 'utils';
import { Tabs } from 'antd';
import { CouriersTab, CouriersAccountTab } from 'components';

export const CouriersPage = () => {
	const { __ } = useMethods();

	return (
		<MainLayout seo={{ title: `${__('Курьеры')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				{access('couriers-tab-couriers') ? (
					<Tabs.TabPane tab={__('Курьеры')} key="couriers">
						<CouriersTab />
					</Tabs.TabPane>
				) : null}
				{access('couriers-tab-accounts') ? (
					<Tabs.TabPane tab={__('Личный счет курьера')} key="account">
						<CouriersAccountTab />
					</Tabs.TabPane>
				) : null}
			</Tabs>
		</MainLayout>
	);
};
