import { useState } from 'react';
import { dateClientFormat, dateServerFormat, dateTimeServerFormat, timeClientFormat, timeServerFormat } from 'const';
import { Col, Popconfirm, Popover, Row, Tooltip } from 'antd';
import { access, dayjs, useMethods, useData } from 'utils';
import { EditAttendance, AddAttendance, EditPlannedShift } from 'components';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { deleteAttendanceAction, getShiftsMonthlyScheduleAction, getShiftsMonthlyScheduleStatisticAction } from 'actions';

export const ShiftCell = (props) => {
	const { day = {}, record = {} } = props;
	const { show = {}, holidays = {}, attendances = [] } = day;
	const [visible, setVisible] = useState();
	const { __, update } = useMethods();
	const { scheduleFilters = {} } = useData();
	const currentDate = dayjs().format(dateServerFormat);
	const isDisabled = currentDate === day.date;
	const isDayOff = day.planned_is_day_off;
	const startTime = show.started_at ? dayjs(show.started_at, dateTimeServerFormat).format(timeClientFormat) : '';
	const endTime = show.finished_at ? dayjs(show.finished_at, dateTimeServerFormat).format(timeClientFormat) : '';

	if (isDisabled) {
		return (
			<div className="shift-cell shift-cell--day is-current-day">
				<div className="shift-cell__day">{dayjs(day.date).format('DD.MM')}</div>
				{!isDayOff && startTime && endTime ? (
					<div className="shift-cell__time">
						{startTime}
						<br />
						{endTime}
					</div>
				) : null}
			</div>
		);
	} else {
		const shiftDate = day.date ? dayjs(day.date, dateServerFormat).format(dateClientFormat) : '';
		const planedStartTime = day.planned_started_at ? dayjs(day.planned_started_at, dateTimeServerFormat).format(timeClientFormat) : '';
		const planedEndTime = day.planned_finished_at ? dayjs(day.planned_finished_at, dateTimeServerFormat).format(timeClientFormat) : '';
		const factStartTime = day.started_at ? dayjs(day.started_at, dateTimeServerFormat).format(timeClientFormat) : '';
		const factEndTime = day.finished_at ? dayjs(day.finished_at, dateTimeServerFormat).format(timeClientFormat) : '';
		const plannedSummary = day.planned_duration ? dayjs(day.planned_duration, timeServerFormat).format(timeClientFormat) : '';
		const summary = day.duration ? dayjs(day.duration, timeServerFormat).format(timeClientFormat) : '';
		const isPast = day.is_past;
		const isFuture = !isPast;
		const classes = [];
		if (isPast) {
			if (isDayOff) {
				classes.push('is-past-day-off');
			} else {
				classes.push('is-past-work-day');
			}
		} else {
			if (isDayOff) {
				classes.push('is-future-day-off');
			} else {
				classes.push('is-future-work-day');
			}
		}
		let style = {};
		let triangleStyle = {};
		if (!isDayOff || !!attendances.length) {
			if (show.come_in_time_color) {
				triangleStyle.borderRightColor = show.come_in_time_color;
				classes.push('is-label');
			}
			if (show.full_hours_worked_color) {
				style.color = show.full_hours_worked_color;
			}
		}
		if (holidays) {
			if (holidays.national && !['rgb(255, 255, 255)', '#ffffff'].includes(holidays.national)) {
				style.backgroundColor = holidays.national;
			} else if (holidays.non_national && !['rgb(255, 255, 255)', '#ffffff'].includes(holidays.non_national)) {
				style.backgroundColor = holidays.non_national;
			}
		}

		const deleteAttendanceHandler = async (id) => {
			const response = await deleteAttendanceAction(id);
			const [schedule, scheduleStatistic] = await Promise.all([
				getShiftsMonthlyScheduleAction(scheduleFilters),
				getShiftsMonthlyScheduleStatisticAction(scheduleFilters)
			]);
			if (response) {
				update({
					schedule,
					scheduleStatistic
				});
			}
		};

		return (
			<Popover
				trigger="click"
				title={<b>{__('Подробная информация по смене')}</b>}
				placement="topRight"
				overlayClassName="shift-cell__info"
				visible={visible}
				onVisibleChange={(flag) => setVisible(flag)}
				content={
					<>
						{holidays && holidays.name ? <div className="shift-cell__holiday">{holidays.name}</div> : null}
						<Row gutter={36} justify={'space-between'} align={'bottom'}>
							<Col>{__('Дата смены')}</Col>
							<Col>{shiftDate}</Col>
						</Row>
						<Row gutter={36} justify={'space-between'} align={'bottom'}>
							<Col>{__('Планируемое рабочее время')}</Col>
							<Col>{planedStartTime && planedEndTime ? planedStartTime + ' - ' + planedEndTime : isDayOff ? __('Выходной') : ''}</Col>
						</Row>
						{isPast ? (
							<>
								{!!factStartTime && !!factEndTime ? (
									<Row type={'flex'} gutter={36} justify={'space-between'} align={'bottom'}>
										<Col>{__('Фактическое рабочее время')}</Col>
										<Col>{factStartTime && factEndTime ? factStartTime + ' - ' + factEndTime : 'Нет'}</Col>
									</Row>
								) : null}
								<Row gutter={36} justify={'space-between'} align={'bottom'}>
									<Col>{__('Суммарно планируемое время')}</Col>
									<Col>{plannedSummary || __('Нет')}</Col>
								</Row>
								{!!summary ? (
									<Row gutter={36} justify={'space-between'} align={'bottom'}>
										<Col>{__('Суммарно отработанное время')}</Col>
										<Col>{summary || __('Нет')}</Col>
									</Row>
								) : null}
								{attendances.map((attendance) => {
									const startFormatted = attendance.started_at
										? dayjs(attendance.started_at, dateTimeServerFormat).format(timeClientFormat)
										: '';
									const finishFormatted = attendance.finished_at
										? dayjs(attendance.finished_at, dateTimeServerFormat).format(timeClientFormat)
										: '';
									return (
										<div style={{ marginTop: '10px' }} key={attendance.id}>
											<Row gutter={12} justify={'space-between'} align={'middle'}>
												<Col>
													{startFormatted} - {finishFormatted}
												</Col>
												{access('employees-attendance-update') || access('employees-attendance-delete') ? (
													<Col>
														<Row gutter={5}>
															{access('employees-attendance-update') ? (
																<Col>
																	<EditAttendance
																		employeeId={record.employee_id}
																		id={attendance.id}
																		date={day.date}
																		startedTime={
																			attendance.started_at
																				? dayjs(attendance.started_at, dateTimeServerFormat).format(
																						timeServerFormat
																				  )
																				: null
																		}
																		finishedTime={
																			attendance.finished_at
																				? dayjs(attendance.finished_at, dateTimeServerFormat).format(
																						timeServerFormat
																				  )
																				: null
																		}
																		onOpen={() => setVisible(false)}
																	>
																		<Tooltip title={__('Редактировать посещение')} placement="topRight">
																			<button
																				type="button"
																				className="button button--icon-xs button--trans-main"
																			>
																				<EditOutlined />
																			</button>
																		</Tooltip>
																	</EditAttendance>
																</Col>
															) : null}
															{access('employees-attendance-delete') ? (
																<Col>
																	<Popconfirm
																		title={__('Вы точно хотите удалить запись посещения?')}
																		okText={__('Да')}
																		cancelText={__('Отмена')}
																		placement="topRight"
																		onConfirm={() => deleteAttendanceHandler(attendance.id)}
																	>
																		<Tooltip title={__('Удалить посещение')} placement="bottomRight">
																			<button
																				type="button"
																				className="button button--icon-xs button--trans-error"
																			>
																				<CloseIcon />
																			</button>
																		</Tooltip>
																	</Popconfirm>
																</Col>
															) : null}
														</Row>
													</Col>
												) : null}
											</Row>
										</div>
									);
								})}
							</>
						) : null}
						{isFuture && access('employees-schedule-planned-update') ? (
							<div style={{ marginTop: '10px' }}>
								<EditPlannedShift shift={day} onOpen={() => setVisible(false)}>
									<button type="button" className="button button--small button--full button--main">
										<EditOutlined />
										<span>{__('Редактировать смену')}</span>
									</button>
								</EditPlannedShift>
							</div>
						) : null}
						{!isFuture && access('employees-attendance-create') ? (
							<div style={{ marginTop: '10px' }}>
								<AddAttendance employeeId={record.employee_id} date={day.date} onOpen={() => setVisible(false)}>
									<button type="button" className="button button--small button--full button--main">
										<PlusOutlined />
										<span>{__('Добавить посещение')}</span>
									</button>
								</AddAttendance>
							</div>
						) : null}
					</>
				}
			>
				<div className={`shift-cell shift-cell--day ${classes.join(' ')}`} style={style}>
					<div className="shift-cell__triangle" style={triangleStyle} />
					<div className="shift-cell__inner">
						<div className="shift-cell__day">{dayjs(day.date).format('DD.MM')}</div>
						{startTime && endTime ? (
							<div className="shift-cell__time">
								{startTime}
								<br />
								{endTime}
							</div>
						) : null}
					</div>
				</div>
			</Popover>
		);
	}
};
