import { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import { useMethods } from 'utils';
import { Modal } from 'components';

export const RevenueGraphCity = (props) => {
	const { children, data = [] } = props;
	const { name, dates = [] } = data;
	const [visible, setVisible] = useState(false);
	const { __ } = useMethods();

	const list = dates.map((item) => {
		return {
			name: `${item.date}:00`,
			amount: item.amount
		};
	});

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="big">
				<div className="modal__container">
					<div className="modal__title">{`${__('Статистика по')} ${__('г.')} ${name}`}</div>
					<ResponsiveContainer width="100%" height={600}>
						<LineChart data={list}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip
								labelFormatter={(label) => `${__('Время')}: ${label}`}
								formatter={(value) => `${value.toFixed(2)} ${__('грн')}`}
							/>
							<Line type="monotone" dataKey="amount" name={__('Сумма')} stroke="#000000" />
						</LineChart>
					</ResponsiveContainer>
				</div>
			</Modal>
		</>
	);
};
