import { api, getCookie, setCookie, removeCookie, setStore, removeStore } from 'utils';

export const getUserAction = async () => {
	const response = await api.get(`/current-user-info`);
	const data = response.data || {};
	const { permissions = [] } = data;
	setStore('permissions', permissions);
	return data;
};

export const loginClientAction = (values = {}) => {
	const { token_type, access_token, refresh_token, permissions = [] } = values;

	setCookie('accessToken', `${token_type} ${access_token}`);
	setCookie('refreshToken', refresh_token);
	setStore('permissions', permissions);

	return true;
};

export const loginServerAction = async (values = {}) => {
	const response = await api.post(`/login`, values);
	const { success } = response;
	return success ? response : {};
};

export const loginAction = async (values = {}) => {
	const response = await loginServerAction(values);
	const { success } = response;

	if (success) {
		loginClientAction(response);
	} else {
		logoutClientAction();
	}

	return success ? response : {};
};

export const refreshTokenAction = async () => {
	const refreshToken = getCookie('refreshToken', '');

	const response = await api.post(`/refresh-token`, { refresh_token: refreshToken });
	const { success } = response;
	if (success) {
		loginClientAction(response);
	} else {
		logoutClientAction();
	}

	return success ? response : {};
};

export const logoutClientAction = () => {
	removeCookie('accessToken');
	removeCookie('refreshToken');
	removeStore('permissions');

	return true;
};

export const logoutServerAction = async () => {
	const response = await api.post(`/logout`);
	return response.success;
};

export const logoutAction = async () => {
	const response = await logoutServerAction();
	logoutClientAction();

	return response;
};
