import { InfoWindow, Marker } from 'react-google-maps';
import { Map } from 'components';
import { useState } from 'react';
import { useMethods } from 'utils';

export const AddressMap = (props) => {
	const { lat, lng } = props;
	const { __ } = useMethods();
	const [open, setOpen] = useState(null);

	return (
		<Map
			mapProps={{
				defaultCenter: { lat: +lat, lng: +lng }
			}}
			containerProps={{
				style: {
					width: '100%',
					height: 205
				}
			}}
		>
			<Marker position={{ lat: +lat, lng: +lng }} onClick={() => setOpen(true)}>
				{open ? (
					<InfoWindow onCloseClick={() => setOpen(false)}>
						<div>{__('Место адреса')}</div>
					</InfoWindow>
				) : null}
			</Marker>
		</Map>
	);
};
