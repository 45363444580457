import cookie from 'cookie';
import { decode, encode } from 'utils';

export const setCookie = (key, value, options = {}, res = {}) => {
	value = encode(value);
	options = {
		path: '/',
		sameSite: 'lax',
		maxAge: process.env.COOKIE_LIFETIME,
		...options
	};
	window.document.cookie = cookie.serialize(key, value, options);
};

export const getCookie = (key, defaultValue = null, req = {}) => {
	const result = cookie.parse(window.document.cookie);
	const value = result[key] ? result[key] : null;
	try {
		return decode(value);
	} catch (error) {
		return defaultValue;
	}
};

export const removeCookie = (key, options = {}, res = {}) => {
	setCookie(
		key,
		null,
		{
			...options,
			maxAge: -1
		},
		res
	);
};
