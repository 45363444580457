import { useMethods } from 'utils';
import { ReactComponent as WezomIcon } from 'assets/svg/wezom.svg';

export const Footer = () => {
	const { __ } = useMethods();

	return (
		<div className="footer">
			<div className="footer__item">
				<div className="footer__text">
					<span>{__('Официальный сайт')}:</span>
					<a href="https://ikura.ua" target="_blank" rel="noreferrer">
						ikura.ua
					</a>
				</div>
			</div>
			<div className="footer__item">
				<div className="footer__wezom">
					<span>by</span>
					<a href="https://wezom.com.ua" target="_blank" rel="noreferrer">
						<WezomIcon />
					</a>
				</div>
			</div>
		</div>
	);
};
