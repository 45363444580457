import { decode, encode } from 'utils';

export const setLocalStorage = (key, value) => {
	window.localStorage.setItem(key, encode(value));
};

export const getLocalStorage = (key, defaultValue = null) => {
	let result = defaultValue;
	try {
		result = decode(window.localStorage.getItem(key));
	} catch (error) {}
	return result || defaultValue;
};

export const removeLocalStorage = (key) => {
	window.localStorage.removeItem(key);
};
