import { useEffect, useState } from 'react';
import { MainLayout } from 'layouts';
import { useMethods, dayjs, nomenclaturesChange } from 'utils';
import { Tabs } from 'antd';
import { WaybillForm } from 'components';
import { getEmployeesListAction, getNomenclatureCategoriesAction, getSuppliersAction, getWaybillAction, getWaybillsTypesAction } from 'actions';
import { dateTimeServerFormat } from 'const';

export const WaybillPage = (props) => {
	const { match = {} } = props;
	const { params = {} } = match;
	const { type, id } = params;
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);
	const [title, setTitle] = useState(type && id ? `№${id}` : __('Создание накладной'));

	useEffect(() => {
		(async () => {
			setPending(true);
			const waybillsTypes = await getWaybillsTypesAction();
			const nomenclatureCategories = await getNomenclatureCategoriesAction();
			const data = {
				waybillsTypes,
				nomenclatureCategories,
				waybill: {},
				waybillOld: {},
				waybillFilter: {
					date: dayjs()
				},
				supplierEmployees: [],
				suppliers: [],
				recipientEmployees: []
			};
			if (type && id) {
				data.waybillType = type;
				data.waybillId = id;

				const typeName = waybillsTypes.find((item) => item.alias === type)?.title;
				setTitle(`${typeName || __('Накладная')} №${id}`);

				data.waybill = await getWaybillAction(type, id);
				data.waybillFilter.type = type;
				data.waybillFilter.date = data.waybill.date ? dayjs(data.waybill.date, dateTimeServerFormat) : null;
				data.waybillFilter.supplier_warehouse_id = data.waybill.supplier_warehouse?.id || null;
				data.waybillFilter.supplier_employee_id = data.waybill.supplier_employee?.id || null;
				data.waybillFilter.recipient_warehouse_id = data.waybill.recipient_warehouse?.id || null;
				data.waybillFilter.supplier_id = data.waybill.supplier?.id || null;
				data.waybillFilter.recipient_employee_id = data.waybill.recipient_employee?.id || null;
				data.waybillFilter.description = data.waybill.description || null;
				data.waybillFilter.nomenclatures = nomenclaturesChange(data.waybill.nomenclature || [], true);

				const supplier_warehouse_id = data.waybill.supplier_warehouse?.id;
				if (supplier_warehouse_id) {
					data.supplierEmployees = await getEmployeesListAction({ warehouse_id: supplier_warehouse_id });
				}

				const recipient_warehouse_id = data.waybill.recipient_warehouse?.id;
				if (recipient_warehouse_id) {
					data.suppliers = await getSuppliersAction({ warehouse_id: recipient_warehouse_id });
					data.recipientEmployees = await getEmployeesListAction({ warehouse_id: recipient_warehouse_id });
				}
			} else {
				data.waybillFilter.supplier_warehouse_id = null;
				data.waybillFilter.supplier_employee_id = null;
				data.waybillFilter.recipient_warehouse_id = null;
				data.waybillFilter.supplier_id = null;
				data.waybillFilter.recipient_employee_id = null;
				data.waybillFilter.description = data.waybill.description || null;
				data.waybillFilter.nomenclatures = [];
			}
			data.waybillOld = data.waybillFilter;
			setPending(false);
			update(data);
		})();

		return () => {
			update({
				waybill: {},
				waybillOld: {},
				waybillFilter: {},
				waybillType: null,
				waybillId: null,
				supplierEmployees: [],
				suppliers: [],
				recipientEmployees: []
			});
		};
	}, []);

	return (
		<MainLayout seo={{ title: `${title} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				<Tabs.TabPane tab={title} key="waybill">
					<WaybillForm pending={pending} setPending={setPending} />
				</Tabs.TabPane>
			</Tabs>
		</MainLayout>
	);
};
