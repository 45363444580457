import { Table, Tooltip, Row, Col } from 'antd';
import { useData, useMethods, getNegativeOrPositiveCurrencyFormat, dayjs } from 'utils';
import { dateTimeClientFormat } from 'const';
import { ReloadOutlined } from '@ant-design/icons';
import { getFiscalTransactionPendingAction } from 'actions';

export const FiscalCashboxTable = () => {
	const { __ } = useMethods();
	const { fiscalTransactions = [], entrepreneurs = [] } = useData();

	const pendingHandler = async (id) => {
		await getFiscalTransactionPendingAction(id);
	};

	const columns = [
		{
			title: __('Дата/время'),
			dataIndex: 'created_at',
			sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
			render: (created_at) => (created_at ? dayjs(created_at * 1000).format(dateTimeClientFormat) : '-')
		},
		{
			title: __('Назначение средств'),
			dataIndex: 'type_name',
			sorter: (a, b) => a.type_name.localeCompare(b.type_name)
		},
		{
			title: __('Сумма'),
			dataIndex: 'sum',
			sorter: (a, b) => a.sum - b.sum,
			render: (sum, record) => {
				const sumFormat = record.type_id === 'outflow' ? -Math.abs(+sum) : sum;
				return getNegativeOrPositiveCurrencyFormat({ number: sumFormat, currency: 'грн' });
			}
		},
		{
			title: __('Юр. лицо'),
			dataIndex: 'entrepreneur_id',
			sorter: (a, b) => a.entrepreneur_id - b.entrepreneur_id,
			render: (entrepreneur_id) => {
				const entrepreneur = entrepreneurs.find((item) => item.id === entrepreneur_id);
				return entrepreneur ? entrepreneur.name : '-';
			}
		},
		{
			title: __('Статус чека'),
			dataIndex: 'status_name',
			sorter: (a, b) => a.status_name.localeCompare(b.status_name),
			render: (status_name, record) =>
				record.status_id === 'error' ? (
					<Row gutter={12} align="middle">
						<Col>
							<Tooltip title={record.error_description} placement="topRight">
								<span style={{ color: '#e31e24' }}>{status_name}</span>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip placement="topRight" title={__('Повторить печать')}>
								<button type="button" className="button button--trans-main button--icon-xs" onClick={() => pendingHandler(record.id)}>
									<ReloadOutlined />
								</button>
							</Tooltip>
						</Col>
					</Row>
				) : (
					status_name
				)
		},
		{
			title: __('Примечание'),
			dataIndex: 'description'
		}
	];

	return <Table rowKey="id" className="table" bordered columns={columns} dataSource={fiscalTransactions} pagination={false} />;
};
