import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import {useMethods, useData, formatDigits, getSummaryCellData} from 'utils';

export const DocsSalary = (props) => {
	const { pending } = props;
	const { docsSalary = {} } = useData();
	const { columns = [], rows = [], values = [] } = docsSalary;
	const { __ } = useMethods();

	const columnsList = [
		{
			title: __('Дата'),
			dataIndex: 'date'
		}
	];
	columns.forEach((item, index) => {
		columnsList.push({
			title: item.name,
			dataIndex: `column${index}`,
			render: (data) => ({
				props: {
					style: {
						backgroundColor: item.color
					}
				},
				children: data
			})
		});
	});

	const list = [];
	rows.forEach((item, index) => {
		list.push({
			date: item,
			index
		});
	});
	values.forEach((value, row) => {
		value.forEach((item, index) => {
			if (list[row]) {
				list[row][`column${index}`] = `${formatDigits({ number: item.amount || 0, toFixed: 2 })} / ${item.percent}%`;
			}
		});
	});

	const summaryRow = {
		date: __('Итого'),
		isFoot: true,
		index: 'summary'
	};
	columns.forEach((item, index) => {
		summaryRow[`column${index}`] = `${formatDigits({ number: item.total || 0, toFixed: 2 })} / ${item.percent}%`;
	});
	const summary = [summaryRow];

	return (
		<Table
			rowKey="index"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			rowClassName={(record) => (record.isFoot ? 'is-foot' : '')}
			columns={columnsList}
			dataSource={list}
			pagination={false}
			summary={() => (
				<Table.Summary fixed>
					{summary.map((row, rowIndex) => (
						<Table.Summary.Row key={rowIndex}>
							{columnsList.map((column, colIndex) => {
								const { cellProps, cellChild } = getSummaryCellData(column, row);
								return (
									<Table.Summary.Cell key={`${rowIndex}${colIndex}`} index={colIndex} {...cellProps}>
										{cellChild}
									</Table.Summary.Cell>
								);
							})}
						</Table.Summary.Row>
					))}
				</Table.Summary>
			)}
		/>
	);
};
