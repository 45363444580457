import { BaseLayout } from 'layouts';
import { Error } from 'components';
import { useData } from 'utils';

export const ErrorPage = () => {
	const { seo = {} } = useData();

	return (
		<BaseLayout seo={seo} error={true}>
			<Error statusCode={404} />
		</BaseLayout>
	);
};
