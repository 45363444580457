import { Table, Popconfirm, Row, Col, Tooltip, Popover, Upload } from 'antd';
import { WithdrawalCashboxModal, DepositCashboxModal, CashWarrant } from 'components';
import { deleteCashboxTransactionAction, deleteCashboxFileAction, addCashboxFilesAction } from 'actions';
import {
	useData,
	useMethods,
	access,
	getNegativeOrPositiveCurrencyFormat,
	downloadFile,
	checkValue,
	filetypeRule,
	filesizeEachRule,
	notify,
	print,
	money2str
} from 'utils';
import { PrinterOutlined, EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';

export const CashboxTable = (props) => {
	const { cashbox = {}, setPending, isArchive } = props;
	const { __ } = useMethods();
	const { transactionsTypes = [], paymentTypes = [] } = useData();
	const isCashboxData = !!cashbox.id;
	const transactions = (cashbox.transactions || []).map((item) => ({
		...item,
		balance_id: item.transactions_type_id
	}));

	const deleteCashboxTransactionHandler = async (id) => {
		setPending(true);
		await deleteCashboxTransactionAction(id);
		setPending(false);
	};

	const openFileHandler = (event, file) => {
		event.preventDefault();
		downloadFile(file.url, file.name);
	};

	const uploadFileHandler = async (id, files) => {
		const error = await checkValue('files', files, [filetypeRule(__, 'png, jpg, jpeg, pdf, txt, doc, docx, xls, xlsx'), filesizeEachRule(__, 8)]);
		if (!error) {
			setPending(true);
			await addCashboxFilesAction(id, {
				files
			});
			setPending(false);
		} else {
			notify('error', error);
		}
		return false;
	};

	const deleteFileHandler = async (id) => {
		setPending(true);
		await deleteCashboxFileAction(id);
		setPending(false);
	};

	const printTransactionHandler = async (transaction) => {
		print(
			`#transaction-${transaction.id}`,
			{
				'[NUMBER]': transaction.cash_uid || 0,
				'[NAME]': transaction.counterparty_name || '',
				'[DESCRIPTION]': transaction.description || '',
				'[SUM]': money2str(Math.abs(transaction.sum) || 0)
			},
			`@page {margin: 1.5cm 1cm 1.4cm 1cm; size: portrait;}`
		);
	};

	const columns = [
		{
			title: __('Дата/время'),
			dataIndex: 'created_at',
			sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at)
		},
		{
			title: __('Приход/Расход'),
			dataIndex: 'balance_id',
			sorter: (a, b) => a.balance_id - b.balance_id,
			render: (transactions_type_id) => {
				if (cashbox.transactions) {
					const transactionsType = transactionsTypes.filter((item) => item.id === transactions_type_id);
					return transactionsType[0].impact === 1 ? __('Приход') : transactionsType[0].impact === 2 ? __('Расход') : '-';
				}
			}
		},
		{
			title: __('Тип оплаты'),
			dataIndex: 'payment_type_id',
			sorter: (a, b) => a.payment_type_id - b.payment_type_id,
			render: (payment_type_id) => {
				const payment = paymentTypes.find((item) => item.id === payment_type_id);
				if (cashbox.transactions && payment) {
					return payment.name;
				}
			}
		},
		{
			title: __('Назначение'),
			dataIndex: 'transactions_type_id',
			sorter: (a, b) => a.transactions_type_id - b.transactions_type_id,
			render: (transactions_type_id) => {
				if (cashbox.transactions) {
					const transactionsType = transactionsTypes.filter((item) => item.id === transactions_type_id);
					return transactionsType[0]?.name || '-';
				}
			}
		},
		{
			title: __('Сумма'),
			dataIndex: 'sum',
			sorter: (a, b) => a.sum - b.sum,
			render: (sum) => getNegativeOrPositiveCurrencyFormat({ number: sum, currency: 'грн' })
		},
		{
			title: __('Пользователь'),
			dataIndex: 'creator_name',
			sorter: (a, b) => a.creator_name.localeCompare(b.creator_name)
		},
		{
			title: __('Кому/От кого'),
			dataIndex: 'counterparty_name',
			sorter: (a, b) => a.counterparty_name.localeCompare(b.counterparty_name)
		},
		{
			title: __('Примечание'),
			dataIndex: 'description'
		},
		{
			title: __('Файлы'),
			render: (record) => (
				<Row items="middle" gutter={10}>
					<Col>
						{record.files.length ? (
							<Popover
								content={
									<div>
										{record.files.map((file, index) => (
											<Row key={index} gutter={10} justify="space-between" style={{ flexWrap: 'nowrap', marginBottom: '5px' }}>
												<Col>
													<a
														href={`/public/${file.name}`}
														onClick={(event) => openFileHandler(event, file)}
														rel="noopener noreferrer"
														className="link"
													>
														{file.name}
													</a>
												</Col>
												{!isArchive && access('cashbox-transaction-update') && !record.protected ? (
													<Col>
														<button className="button button--icon-close" onClick={() => deleteFileHandler(file.id)}>
															<CloseIcon />
														</button>
													</Col>
												) : null}
											</Row>
										))}
									</div>
								}
								placement="topRight"
							>
								<div className="button button--xs button--white">
									{__('Загружено файлов')}: {record.files.length}
								</div>
							</Popover>
						) : (
							<div className="button button--xs button--white">
								{__('Загружено файлов')}: {record.files.length}
							</div>
						)}
					</Col>
					{access('cashbox-transaction-update') && !record.protected ? (
						<Col>
							<Upload beforeUpload={(file, fileList) => uploadFileHandler(record.id, fileList)} showUploadList={false} multiple={true}>
								<Tooltip placement="topRight" title={__('Догрузить файлы')}>
									<button className="button button--icon-xs button--trans">
										<UploadOutlined />
									</button>
								</Tooltip>
							</Upload>
						</Col>
					) : null}
				</Row>
			)
		},
		{
			title: __('Действия'),
			align: 'right',
			render: (_, record) => {
				const isProtected = record.protected;
				const transactionsType = transactionsTypes.filter((item) => item.id === record.transactions_type_id);
				const type = transactionsType[0].impact;

				return (
					<Row justify="end" align={'middle'} gutter={10}>
						{!isArchive && access('cashbox-transaction-update') && !record.protected ? (
							<Col>
								{type === 1 ? (
									<DepositCashboxModal values={record}>
										<Tooltip title={__('Редактировать операцию')} placement="topRight">
											<button type="button" className="button button--trans button--icon-xs">
												<EditOutlined />
											</button>
										</Tooltip>
									</DepositCashboxModal>
								) : null}
								{type === 2 ? (
									<WithdrawalCashboxModal values={record}>
										<Tooltip title={__('Редактировать операцию')} placement="topRight">
											<button type="button" className="button button--trans button--icon-xs">
												<EditOutlined />
											</button>
										</Tooltip>
									</WithdrawalCashboxModal>
								) : null}
							</Col>
						) : null}
						{!isArchive && access('cashbox-transaction-delete') && !record.protected ? (
							<Col>
								<Popconfirm
									title={__('Вы точно хотите удалить транзакцию?')}
									okText={__('Да')}
									cancelText={__('Отмена')}
									placement={'topRight'}
									onConfirm={() => deleteCashboxTransactionHandler(record.id)}
									disabled={isProtected}
								>
									<Tooltip title={__('Удалить операцию')} placement="topRight">
										<button type="button" className="button button--trans-error button--icon-xs">
											<DeleteOutlined />
										</button>
									</Tooltip>
								</Popconfirm>
							</Col>
						) : null}
						<Col>
							<Tooltip title={__('Распечатать операцию')} placement="topRight" onClick={() => printTransactionHandler(record)}>
								<button className="button button--trans button--icon-xs">
									<PrinterOutlined />
								</button>
							</Tooltip>
							<div className="is-print">
								<div id={`transaction-${record.id}`}>
									<CashWarrant isAdd={record.impact === 1} />
								</div>
							</div>
						</Col>
					</Row>
				);
			}
		}
	];

	return isCashboxData ? <Table rowKey="id" className="table" bordered columns={columns} dataSource={transactions} pagination={false} /> : null;
};
