import { useMethods, useData } from 'utils';
import { statusIcon } from 'const';
import { Tag } from 'antd';

export const CouriersStatistic = () => {
	const { couriersDate = [], couriersFilters = {} } = useData();
	const { __, update } = useMethods();

	const statistic = {
		all: { count: 0, sum: 0 },
		delivering: { count: 0, sum: 0 }
	};

	couriersDate.forEach((item) => {
		if (item.status === 'free') {
			statistic.all.count++;
			statistic.all.sum += item.orders_all_total;
		} else if (item.status === 'busy') {
			statistic.delivering.count += !!item.orders.length && !!item.orders.find((order) => !order.is_trip_finished) ? 1 : 0;
			statistic.delivering.sum += item.orders.reduce((a, order) => (order.status_id === 7 ? a + order.total : a), 0);
			statistic.all.count++;
			statistic.all.sum += item.orders_all_total;
		}
	});

	const result = [
		{
			alias: 'all',
			title: __('Всего активных'),
			value: `${statistic.all.count} / ${statistic.all.sum} ${__('грн')}`,
			icon: statusIcon[0]
		},
		{
			alias: 'busy',
			title: __('Доставляют'),
			value: `${statistic.delivering.count} / ${statistic.delivering.sum} ${__('грн')}`,
			icon: statusIcon[7]
		}
	];

	const changeHandler = (status) => {
		update({
			couriersFilters: {
				...couriersFilters,
				status
			}
		});
	};

	return (
		<div className="orders-statistic">
			<div className="orders-statistic__list">
				{result.map(({ alias, title, value, icon }, index) => (
					<div className="orders-statistic__item" key={index}>
						<Tag
							className={`orders-statistic__tag${couriersFilters.status === alias ? ' is-active' : ''}`}
							onClick={() => changeHandler(alias)}
						>
							{icon}
							<span>{title}:</span>
							<strong>{value}</strong>
						</Tag>
					</div>
				))}
			</div>
		</div>
	);
};
