import { useEffect } from 'react';
import { Form, Select, Tooltip, Row, Col, DatePicker } from 'antd';
import { useMethods, useData, downloadFile, access, dayjs, formatDigits } from 'utils';
import { getReportsDayCheckAction, getReportsHourCheckAction } from 'actions';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';
import { dateClientFormat, dateServerFormat } from 'const';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Preloader } from 'components';

export const DocsCheck = (props) => {
	const { pending1, pending2, setPending1, setPending2 } = props;
	const { restaurants = [], docsCheckFiltersDefault = {}, docsCheckFilters = {}, docsDayCheck = [], docsHourCheck = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const fileName1 = `average-day-check-report-${docsCheckFilters.restaurant_id}-${
		docsCheckFilters.category_ids && docsCheckFilters.category_ids.length ? `-${docsCheckFilters.category_ids.join('-')}` : ''
	}-${docsCheckFilters.date_from}-${docsCheckFilters.date_to}.xlsx`;
	const fileName2 = `average-hour-check-report-${docsCheckFilters.restaurant_id}-${
		docsCheckFilters.category_ids && docsCheckFilters.category_ids.length ? `-${docsCheckFilters.category_ids.join('-')}` : ''
	}-${docsCheckFilters.date}.xlsx`;

	useEffect(() => {
		form.setFieldsValue(docsCheckFiltersDefault);
	}, [docsCheckFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		if (changedValues.restaurant_id || changedValues.category_ids || changedValues.date_from || changedValues.date_to) {
			setPending1(true);
		}
		if (changedValues.restaurant_id || changedValues.category_ids || changedValues.date) {
			setPending2(true);
		}
		const filter = {
			...docsCheckFilters,
			...values
		};
		const data = {
			docsCheckFilters: filter
		};
		if (changedValues.restaurant_id || changedValues.category_ids || changedValues.date_from || changedValues.date_to) {
			data.docsDayCheck = await getReportsDayCheckAction({
				restaurant_id: filter.restaurant_id,
				date_from: filter.date_from,
				date_to: filter.date_to
			});
		}
		if (changedValues.restaurant_id || changedValues.category_ids || changedValues.date) {
			data.docsHourCheck = await getReportsHourCheckAction({
				restaurant_id: filter.restaurant_id,
				date: filter.date
			});
		}
		if (changedValues.restaurant_id || changedValues.category_ids || changedValues.date_from || changedValues.date_to) {
			setPending1(false);
		}
		if (changedValues.restaurant_id || changedValues.category_ids || changedValues.date) {
			setPending2(false);
		}
		update(data);
	};

	const downloadXlsFile1 = (event) => {
		event.preventDefault();
		downloadFile(`/reports/generate-average-day-check-file`, fileName1, {
			restaurant_id: docsCheckFilters.restaurant_id,
			category_ids: docsCheckFilters.category_ids,
			date_from: docsCheckFilters.date_from ? dayjs(docsCheckFilters.date_from).format(dateServerFormat) : null,
			date_to: docsCheckFilters.date_to ? dayjs(docsCheckFilters.date_to).format(dateServerFormat) : null
		});
	};

	const downloadXlsFile2 = (event) => {
		event.preventDefault();
		downloadFile(`/reports/generate-average-hour-check-file`, fileName2, {
			restaurant_id: docsCheckFilters.restaurant_id,
			category_ids: docsCheckFilters.category_ids,
			date: docsCheckFilters.date ? dayjs(docsCheckFilters.date).format(dateServerFormat) : null
		});
	};

	const labels1 = [];
	const data1 = [];
	docsDayCheck.forEach((item) => {
		labels1.push(item.date);
		data1.push(+item.amount);
	});

	const labels2 = [];
	const data2 = [];
	docsHourCheck.forEach((item) => {
		labels2.push(item.hour);
		data2.push(+item.amount);
	});

	const option1 = {
		data: {
			labels: labels1,
			datasets: [
				{
					backgroundColor: '#142cd1',
					borderWidth: 0,
					data: data1
				}
			]
		},
		options: {
			title: {
				display: false
			},
			legend: {
				display: false
			},
			tooltips: {
				enabled: false
			},
			layout: {
				padding: {
					top: 30
				}
			},
			scales: {
				xAxes: [
					{
						display: true,
						ticks: {
							stepSize: 1
						},
						scaleLabel: {
							display: true,
							fontFamily: 'Arial',
							fontColor: '#000000',
							fontSize: 14,
							labelString: __('Дни')
						}
					}
				],
				yAxes: [
					{
						display: true,
						ticks: {
							callback: function (value) {
								return parseInt(value);
							}
						},
						scaleLabel: {
							display: true,
							fontFamily: 'Arial',
							fontColor: '#000000',
							fontSize: 14,
							labelString: __('Средний чек, грн')
						}
					}
				]
			},
			plugins: {
				datalabels: {
					anchor: 'end',
					align: 'end',
					color: '#000000',
					rotation: -90,
					formatter: function (value) {
						return formatDigits({ number: value, toFixed: 2, defaultValue: 0 });
					}
				}
			}
		},
		plugins: [ChartDataLabels]
	};

	const option2 = {
		data: {
			labels: labels2,
			datasets: [
				{
					backgroundColor: '#ff200d',
					borderWidth: 0,
					data: data2
				}
			]
		},
		options: {
			title: {
				display: false
			},
			legend: {
				display: false
			},
			tooltips: {
				enabled: false
			},
			layout: {
				padding: {
					top: 30
				}
			},
			scales: {
				xAxes: [
					{
						display: true,
						ticks: {
							stepSize: 1
						},
						scaleLabel: {
							display: true,
							fontFamily: 'Arial',
							fontColor: '#000000',
							fontSize: 14,
							labelString: __('Часы')
						}
					}
				],
				yAxes: [
					{
						display: true,
						ticks: {
							callback: function (value) {
								return parseInt(value);
							}
						},
						scaleLabel: {
							display: true,
							fontFamily: 'Arial',
							fontColor: '#000000',
							fontSize: 14,
							labelString: __('Средний чек, грн')
						}
					}
				]
			},
			plugins: {
				datalabels: {
					anchor: 'end',
					align: 'end',
					color: '#000000',
					formatter: function (value) {
						return formatDigits({ number: value, toFixed: 2, defaultValue: 0 });
					}
				}
			}
		},
		plugins: [ChartDataLabels]
	};

	return (
		<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
			<div className="filter filter--4">
				<div className="filter__form">
					<div className="filter__list">
						{access('docs-reports-average-check-restaurant-filter') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="restaurant_id" label={__('Ресторан')}>
									<Select
										dropdownClassName="form__dropdown"
										options={restaurants.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
					</div>
				</div>
			</div>
			<Row gutter={30} align="bottom">
				<Col span={24} lg={12}>
					<Row gutter={12} align="bottom">
						<Col span={24} style={{ marginBottom: '20px' }}>
							<Row gutter={12} justify="space-between">
								<Col>
									<h2>{__('Отчет по дням')}</h2>
								</Col>
								{access('docs-reports-average-check-file') ? (
									<Col>
										<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
											<a
												href={`/public/${fileName1}`}
												className="button button--default button--icon-def"
												onClick={downloadXlsFile1}
											>
												<XlsIcon />
											</a>
										</Tooltip>
									</Col>
								) : null}
							</Row>
						</Col>
						<Col span={24} sm={12}>
							<Form.Item className="form__item" label={__('Дата от')} name="date_from">
								<DatePicker format={dateClientFormat} disabledDate={(current) => current && current > dayjs().endOf('day')} />
							</Form.Item>
						</Col>
						<Col span={24} sm={12}>
							<Form.Item className="form__item" label={__('Дата до')} name="date_to">
								<DatePicker format={dateClientFormat} disabledDate={(current) => current && current > dayjs().endOf('day')} />
							</Form.Item>
						</Col>
						<Col span={24} style={{ marginTop: '20px' }}>
							<Preloader spinning={pending1}>
								<Bar {...option1} />
							</Preloader>
						</Col>
					</Row>
				</Col>
				<Col span={24} lg={12}>
					<Row gutter={12} align="bottom">
						<Col span={24} style={{ marginBottom: '20px' }}>
							<Row gutter={12} justify="space-between">
								<Col>
									<h2>{__('Отчет по часам')}</h2>
								</Col>
								{access('docs-reports-average-check-file') ? (
									<Col>
										<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
											<a
												href={`/public/${fileName2}`}
												className="button button--default button--icon-def"
												onClick={downloadXlsFile2}
											>
												<XlsIcon />
											</a>
										</Tooltip>
									</Col>
								) : null}
							</Row>
						</Col>
						<Col span={24} sm={12}>
							<Form.Item className="form__item" label={__('Дата')} name="date">
								<DatePicker format={dateClientFormat} disabledDate={(current) => current && current > dayjs().endOf('day')} />
							</Form.Item>
						</Col>
						<Col span={24} style={{ marginTop: '20px' }}>
							<Preloader spinning={pending2}>
								<Bar {...option2} />
							</Preloader>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};
