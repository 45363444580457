import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { antdLocales, defaultLocale } from 'const';
import { getRouteProps, useMethods, getLocaleProps, useData, checkUser } from 'utils';
import { Redirect } from 'react-router-dom';

export const RouteProvider = (props) => {
	const { children, locale, location } = props;
	const { locale: currentLocale, user = {}, search = {}, homeUrl } = useData();
	const { update } = useMethods();

	const redirect = checkUser(!!user.id, search.redirect, homeUrl);
	const redirectPath = redirect.replace(/^([^?#]*)(\/)?(\?[^#]*)?(#.*)?$/, '$1');
	const redirectSearch = redirect.replace(/^([^?#]*)(\/)?(\?[^#]*)?(#.*)?$/, '$3');
	const localeRedirectPath = redirectPath ? `${locale !== defaultLocale ? `/${locale}` : ''}${redirectPath}` : '';

	useEffect(() => {
		(async () => {
			const routeProps = await getRouteProps();
			const localeProps = locale !== currentLocale ? await getLocaleProps() : {};
			update({
				...routeProps,
				...localeProps
			});
		})();
	}, []);

	return redirect && location.pathname !== localeRedirectPath ? (
		<Redirect to={{ pathname: redirectPath, search: redirectSearch, state: { from: location } }} />
	) : (
		<ConfigProvider locale={antdLocales[locale]}>{children}</ConfigProvider>
	);
};
