import { useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import { useMethods, useData } from 'utils';
import { Modal, Preloader, ProductCard } from 'components';
import { refreshOrderAction } from 'actions';

export const ChangeRelatedProducts = (props) => {
	const { children, product } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const { dishCategories = [], order = {}, ingredients = [], products = [] } = useData();
	const { __, update } = useMethods();
	const { relatedProducts = [], relatedIngredients = [], category_id } = product;
	const maxCount = 5;
	const maxValue = 3;
	const [selectedProducts, setSelectedProducts] = useState(product.products || []);
	const [selectedIngredients, setSelectedIngredients] = useState(product.ingredients || []);

	const isAccessProducts = relatedProducts && relatedProducts.length && ![95, 143].includes(category_id);
	const isAccessIngredients = relatedIngredients && relatedIngredients.length;

	let productsCount = 0;
	selectedProducts.forEach((item) => (productsCount += item.quantity));

	let ingredientsCount = 0;
	selectedIngredients.forEach((item) => (ingredientsCount += item.quantity));

	const changeCountHandler = (productItem, count, type) => {
		const list = type === 'product' ? selectedProducts : selectedIngredients;
		let productsList;
		const isSet = !!list.find((item) => item.id === productItem.id);
		if (+count === 0) {
			productsList = list.filter((item) => item.id !== productItem.id);
		} else if (isSet) {
			productsList = list.map((item) =>
				item.id === productItem.id
					? {
							...item,
							quantity: +count
					  }
					: item
			);
		} else {
			productsList = [
				...list,
				{
					...productItem,
					quantity: 1
				}
			];
		}
		if (type === 'product') {
			setSelectedProducts(productsList);
		} else {
			setSelectedIngredients(productsList);
		}
	};

	const saveHandler = async () => {
		setPending(true);
		const productsUpdate = order.products.map((prod) => {
			if (prod._uid === product._uid) {
				return {
					...prod,
					products: selectedProducts,
					ingredients: selectedIngredients
				};
			} else {
				return prod;
			}
		});
		const orderData = {
			...order,
			products: productsUpdate
		};
		const orderResponse = await refreshOrderAction(orderData);
		setPending(false);
		setVisible(false);
		update({
			order: orderResponse
		});
	};

	const visibleHandler = (isVisible) => {
		if (!isVisible) {
			setSelectedProducts(product.products);
			setSelectedIngredients(product.ingredients);
		}
		setVisible(isVisible);
	};

	return (
		<>
			<div onClick={() => visibleHandler(true)}>{children}</div>
			<Modal visible={visible} setVisible={visibleHandler} type="right">
				<div className="modal__container">
					<div className="modal__title modal__title--small-margin">
						{__('Редактирование состава продукта')} <strong>{product.name}</strong>
					</div>
					<div className="modal__text">{__('Можно добавить не более 5 штук всего и не более 3 штук одного наименования')}</div>
					<Tabs className="tabs" animated={false}>
						{isAccessIngredients ? (
							<Tabs.TabPane tab={__('Ингредиенты')} key="ingredients">
								<Preloader spinning={pending}>
									<Tabs animated={false}>
										{relatedIngredients.map((category) => {
											const cat = ingredients.find((item) => item.id === category.category_id);
											return !!cat ? (
												<Tabs.TabPane tab={cat.name} key={category.category_id}>
													<div className="related-products">
														<div className="related-products__row">
															{category.items.map((id) => {
																const item = (cat.ingredients || []).find((item) => item.id === id);
																const productItem = selectedIngredients.find((item) => item.id === id);
																const productItemCount =
																	productItem && productItem.quantity ? productItem.quantity : 0;

																return !!item ? (
																	<div key={item.id} className="related-products__col">
																		<ProductCard
																			key={productItemCount}
																			type="reverse"
																			isRelated={true}
																			product={item}
																			quantity={productItemCount}
																			minCount={0}
																			maxCount={ingredientsCount >= maxCount ? productItemCount : maxValue}
																			disabledCount={ingredientsCount >= maxCount && !productItem}
																			onChangeCount={(product, value) => changeCountHandler(product, value)}
																		/>
																	</div>
																) : null;
															})}
														</div>
													</div>
												</Tabs.TabPane>
											) : null;
										})}
									</Tabs>
								</Preloader>
							</Tabs.TabPane>
						) : null}
						{isAccessProducts ? (
							<Tabs.TabPane tab={__('Продукты')} key="products">
								<Preloader spinning={pending}>
									<Tabs animated={false}>
										{relatedProducts.map((category) => {
											const cat = dishCategories.find((item) => item.id === category.category_id);
											return !!cat ? (
												<Tabs.TabPane tab={cat.name} key={category.category_id}>
													<div className="related-products">
														<div className="related-products__row">
															{category.items.map((id) => {
																const item = products.find((item) => item.id === id);
																const productItem = selectedProducts.find((item) => item.id === id);
																const productItemCount =
																	productItem && productItem.quantity ? productItem.quantity : 0;

																return !!item ? (
																	<Col key={item.id} className="related-products__col">
																		<ProductCard
																			key={productItemCount}
																			type="reverse"
																			isRelated={true}
																			product={item}
																			quantity={productItemCount}
																			minCount={0}
																			maxCount={productsCount >= maxCount ? productItemCount : maxValue}
																			disabledCount={productsCount >= maxCount && !productItem}
																			onChangeCount={(product, value) =>
																				changeCountHandler(product, value, 'product')
																			}
																		/>
																	</Col>
																) : null;
															})}
														</div>
													</div>
												</Tabs.TabPane>
											) : null;
										})}
									</Tabs>
								</Preloader>
							</Tabs.TabPane>
						) : null}
					</Tabs>
					<Row justify="end" gutter={16} style={{ marginTop: '20px' }}>
						<Col>
							<button className="button button--main-border" onClick={() => visibleHandler(false)}>
								<span>{__('Отмена')}</span>
							</button>
						</Col>
						<Col>
							<button className="button button--main" onClick={saveHandler}>
								<span>{__('Применить')}</span>
							</button>
						</Col>
					</Row>
				</div>
			</Modal>
		</>
	);
};
