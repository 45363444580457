import { useState, useEffect } from 'react';
import { Form, DatePicker, Row, Col, Select } from 'antd';
import { useMethods, useData, dayjs, requiredRule, notify } from 'utils';
import { Modal, Preloader, TimePickerField } from 'components';
import { dateServerFormat, dateClientFormat, timeClientFormat, timeServerFormat } from 'const';
import {
	addAttendanceAction,
	deleteNotificationAction,
	getNotificationsAction,
	getShiftsAction,
	getShiftsMonthlyScheduleAction,
	getShiftsMonthlyScheduleStatisticAction
} from 'actions';

export const AddAttendance = (props) => {
	const { children, employeeId, date, startedTime, finishedTime, notificationId, onOpen } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { employees = [], shiftsFilters = {}, scheduleFilters = {}, employeesTab } = useData();
	const { __, update } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				employeeId: employeeId ? `${employeeId}` : null,
				date: date ? dayjs(date, dateServerFormat) : null,
				startedTime: date && startedTime ? dayjs(`${date} ${startedTime}`) : null,
				finishedTime: date && finishedTime ? dayjs(`${date} ${finishedTime}`) : null
			});
		}
	}, [visible, employeeId, date, startedTime, finishedTime]);

	const submitHandler = async (values) => {
		setPending(true);
		const updateData = {};
		const success = await addAttendanceAction({
			employee_id: +values.employeeId,
			started_at: `${dayjs(values.date).format(dateServerFormat)} ${dayjs(values.startedTime).format(timeServerFormat)}`,
			finished_at: `${dayjs(values.date).format(dateServerFormat)} ${dayjs(values.finishedTime).format(timeServerFormat)}`
		});
		if (employeesTab === 'shifts') {
			updateData.shifts = await getShiftsAction(shiftsFilters);
		} else if (employeesTab === 'schedule') {
			const [schedule, scheduleStatistic] = await Promise.all([
				getShiftsMonthlyScheduleAction(scheduleFilters),
				getShiftsMonthlyScheduleStatisticAction(scheduleFilters)
			]);
			updateData.schedule = schedule;
			updateData.scheduleStatistic = scheduleStatistic;
		}
		if (notificationId) {
			await deleteNotificationAction(notificationId);
			updateData.notifications = await getNotificationsAction();
		}
		setPending(false);
		update(updateData);
		if (success) {
			notify('success', __('Посещение добавлено'));
			setVisible(false);
		}
	};

	const openHandler = () => {
		if (onOpen) {
			onOpen();
		}
		setVisible(true);
	};

	return (
		<>
			<div onClick={openHandler}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Добавление посещения')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" label={__('ФИО сотрудника')} name="employeeId" required rules={[requiredRule(__)]}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									options={employees.map((item) => ({ label: item.name, value: String(item.id) }))}
									disabled={employeeId}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item className="form__item" label={__('Дата смены')} name="date" required rules={[requiredRule(__)]}>
								<DatePicker
									format={dateClientFormat}
									disabled={date}
									allowClear={true}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
								/>
							</Form.Item>
							<Row gutter={20}>
								<Col span={24} sm={12}>
									<Form.Item
										className="form__item"
										label={__('Время начала смены')}
										name="startedTime"
										required
										rules={[requiredRule(__)]}
									>
										<TimePickerField
											format={timeClientFormat}
											popupClassName={notificationId ? 'ant-picker-digit-reverse' : ''}
											allowClear={true}
										/>
									</Form.Item>
								</Col>
								<Col span={24} sm={12}>
									<Form.Item
										className="form__item"
										label={__('Время конца смены')}
										name="finishedTime"
										required
										rules={[requiredRule(__)]}
									>
										<TimePickerField
											format={timeClientFormat}
											popupClassName={notificationId ? 'ant-picker-digit-reverse' : ''}
											allowClear={true}
										/>
									</Form.Item>
								</Col>
							</Row>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Добавить посещение')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
