import { useEffect, useState } from 'react';
import { Form, Row, Col, AutoComplete, Collapse, Input, InputNumber, Popconfirm, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useData, useMethods, requiredRule, numberFormatter, notify } from 'utils';
import { Modal, Preloader, AddressMap } from 'components';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { getStreetsSearchAction, getAddressesAction, updateClientAddressesAction, getDistrictsSearchAction } from 'actions';

const Panel = Collapse.Panel;
let timeoutId = null;
let timeoutStreetId = null;

export const AddressesChange = (props) => {
	const { children, onChange } = props;
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const { client = {}, order = {}, user = {}, locale, restaurantSettings = {}, cities = [] } = useData();
	const { __, update } = useMethods();
	const [streets, setStreets] = useState([]);
	const [render, setRender] = useState(false);
	const [pending, setPending] = useState(false);
	const [hasErrors, setHasErrors] = useState([]);

	let addressIndex = (client.addresses || []).findIndex((item) => item.id === order.address_id);
	addressIndex = addressIndex > -1 ? addressIndex : 0;
	const [activeKeys, setActiveKeys] = useState([addressIndex]);

	const { satellites = [], ...rest } = cities.find((item) => item.id === restaurantSettings.city_id) || {};
	const cityList = [{ ...rest }, ...satellites];

	useEffect(() => {
		if (visible) {
			form.setFieldsValue({
				addresses: client.addresses || [
					{
						id: `_0`,
						main: true
					}
				]
			});
		}
	}, [visible, client]);

	const changeHandler = () => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(async () => {
			const values = form.getFieldsValue();
			const addresses = values.addresses || [{}];

			for (let i = 0; i < addresses.length; i++) {
				const address = addresses[i] || {};

				const titles = [];
				if (address.street) {
					titles.push(address.street);
				}
				if (address.building) {
					titles.push(address.building);
				}
				if (address.corpus) {
					titles.push(`корп.${address.corpus}`);
				}
				if (address.flat) {
					titles.push(`кв.${address.flat}`);
				}
				if (address.entrance) {
					titles.push(`под.${address.entrance}`);
				}
				if (address.floor) {
					titles.push(`эт.${address.floor}`);
				}
				if (address.city_id) {
					titles.push(cityList.find((item) => item.id === address.city_id)?.name);
				}

				addresses[i].title = titles.join(', ');

				const params = {};
				if (address.addresses_street_id) {
					params['street_id'] = address.addresses_street_id;
				}
				if (address.building) {
					params['building'] = address.building;
				}
				if (address.entrance) {
					params['entrance'] = address.entrance;
				}
				if (address.corpus) {
					params['corpus'] = address.corpus;
				}
				const result =
					address.addresses_street_id && address.building ? await getAddressesAction(locale, user.restaurant_slug, params) : null;
				if (result) {
					const [lat, lng] = result?.point?.coordinates || [];
					addresses[i].lat = lat;
					addresses[i].lng = lng;
				}
			}

			const fields = [];
			for (let i = 0; i < addresses.length; i++) {
				const address = addresses[i] || {};

				for (let key in address) {
					const errors = [];

					if (key === 'street') {
						if (addresses.filter((item) => item.title === address.title).length > 1) {
							errors.push(__('Такой адрес уже добавлен'));
						} else if (address.lat && address.lng) {
							const { id } = await getDistrictsSearchAction(locale, user.restaurant_slug, {
								lat: address.lat,
								lng: address.lng
							});
							if (!id) {
								errors.push(__('Адрес выходит за зону доставки'));
							}
						}
					}

					fields.push({
						name: ['addresses', i, key],
						value: address[key],
						errors: errors
					});
				}
			}

			form.setFields(fields);
			setRender(!render);
		}, 500);
	};

	const submitHandler = async (values = {}) => {
		setHasErrors([]);
		setPending(true);
		const addresses = values.addresses || [];
		const updatedAddresses = client.id
			? await updateClientAddressesAction(
					client.id,
					addresses.map((item) => ({ ...item, id: String(item.id).includes('_') ? null : item.id }))
			  )
			: addresses;
		update({
			client: {
				...client,
				addresses: updatedAddresses
			}
		});
		if (onChange) {
			const index = activeKeys.length && activeKeys[0] ? +activeKeys[0] : null;
			await onChange(updatedAddresses, index);
		}
		notify('success', __('Список адресов обновлен'));
		setPending(false);
		setVisible(false);
	};

	const errorHandler = ({ errorFields = [] }) => {
		const errors = [];
		if (Array.isArray(errorFields)) {
			errorFields.forEach(({ name = [] }) => {
				if (Array.isArray(name) && name.length > 2 && !errors.includes(name[1])) {
					errors.push(name[1]);
				}
			});
		}
		setHasErrors(errors);
	};

	const panelChangeHandler = (keys) => {
		setActiveKeys(Array.isArray(keys) ? keys : [keys]);
	};

	const changeStreetHandler = (value, index) => {
		if (value === undefined) {
			setStreets([]);
		} else {
			clearTimeout(timeoutStreetId);
			timeoutStreetId = setTimeout(async () => {
				if (value.length >= 3) {
					const addresses = form.getFieldValue('addresses');
					const address = addresses[index] || {};
					const result = await getStreetsSearchAction(locale, address.city_id, value);
					setStreets(
						result.map((item) => ({
							value: item.name,
							label: item.name,
							id: item.id
						}))
					);
				} else {
					setStreets([]);
				}
			}, 500);
		}
	};

	const selectStreetHandler = (index, key, option) => {
		const values = form.getFieldsValue();
		form.setFieldsValue({
			addresses: (values.addresses || [{}]).map((address = {}, i) => {
				if (index === i) {
					return {
						...address,
						addresses_street_id: option.id || `_${key}`,
						lat: option.lat || 0,
						lng: option.lng || 0
					};
				} else {
					return address;
				}
			})
		});
		changeHandler();
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="big">
				<div className="modal__container">
					<div className="modal__title">{__('Список адресов')}</div>
					<div className="addresses-change">
						<Form className="form" onFinish={submitHandler} onFinishFailed={errorHandler} form={form} layout="vertical" noValidate={true}>
							<Preloader spinning={pending}>
								<Form.List name="addresses">
									{(fields, { add, remove }) => {
										const panelAddHandler = () => {
											add({
												id: `_${fields.length}`,
												main: false
											});
											setActiveKeys([String(fields.length)]);
										};

										return (
											<>
												<Collapse accordion activeKey={activeKeys} onChange={panelChangeHandler} className="form__item">
													{fields.map(({ key, name, fieldKey, ...restField }, index) => {
														const { addresses = [] } = form.getFieldsValue();
														const {
															lat = 0,
															lng = 0,
															id,
															addresses_street_id,
															title
														} = addresses.find((item, i) => i === index) || {};
														const isNotStreetId = client.id && !addresses_street_id && !String(id).includes('_');

														return (
															<Panel
																key={index}
																header={
																	<div
																		className={`addresses-change__title${
																			hasErrors.includes(index) ? ' addresses-change__title--error' : ''
																		}`}
																	>
																		{title || `${__('Адрес')} ${index + 1}`}
																	</div>
																}
																collapsible="header"
																extra={
																	fields.length > 1 ? (
																		<Popconfirm
																			title={__('Вы действительно хотите удалить этот адрес?')}
																			okText={__('Да')}
																			cancelText={__('Отмена')}
																			placement="topRight"
																			onConfirm={() => remove(name)}
																		>
																			<button type="button" className="button button--icon-close">
																				<CloseIcon />
																			</button>
																		</Popconfirm>
																	) : null
																}
															>
																<Form.Item
																	{...restField}
																	fieldKey={[fieldKey, 'city_id']}
																	name={[name, 'city_id']}
																	className="form__item"
																	required
																	rules={[requiredRule(__)]}
																	label={__('Город')}
																>
																	<Select
																		dropdownClassName="form__dropdown"
																		onChange={() => changeHandler('city_id', index)}
																		options={cityList.map((item) => ({ label: item.name, value: item.id }))}
																	/>
																</Form.Item>
																<Form.Item
																	{...restField}
																	fieldKey={[fieldKey, 'street']}
																	name={[name, 'street']}
																	className="form__item"
																	required
																	rules={[requiredRule(__)]}
																	label={isNotStreetId ? __('Введите заново адрес') : __('Улица')}
																>
																	<AutoComplete
																		allowClear={false}
																		options={streets}
																		autoClearSearchValue={false}
																		onChange={(value) => changeStreetHandler(value, index)}
																		onSelect={(value, option) => selectStreetHandler(index, key, option)}
																	/>
																</Form.Item>
																<Row gutter={12}>
																	<Col span={24} sm={12} md={8} xl={4}>
																		<Form.Item
																			{...restField}
																			fieldKey={[fieldKey, 'building']}
																			name={[name, 'building']}
																			className="form__item"
																			required
																			rules={[requiredRule(__)]}
																			label={__('Дом')}
																		>
																			<Input onChange={changeHandler} />
																		</Form.Item>
																	</Col>
																	<Col span={24} sm={12} md={8} xl={4}>
																		<Form.Item
																			{...restField}
																			fieldKey={[fieldKey, 'corpus']}
																			name={[name, 'corpus']}
																			className="form__item"
																			label={__('Корпус')}
																		>
																			<Input onChange={changeHandler} />
																		</Form.Item>
																	</Col>
																	<Col span={24} sm={12} md={8} xl={4}>
																		<Form.Item
																			{...restField}
																			fieldKey={[fieldKey, 'entrance']}
																			name={[name, 'entrance']}
																			className="form__item"
																			label={__('Подъезд')}
																		>
																			<InputNumber
																				min={0}
																				onChange={changeHandler}
																				formatter={numberFormatter}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24} sm={12} md={8} xl={4}>
																		<Form.Item
																			{...restField}
																			fieldKey={[fieldKey, 'flat']}
																			name={[name, 'flat']}
																			className="form__item"
																			label={__('Квартира')}
																		>
																			<Input onChange={changeHandler} />
																		</Form.Item>
																	</Col>
																	<Col span={24} sm={12} md={8} xl={4}>
																		<Form.Item
																			{...restField}
																			fieldKey={[fieldKey, 'floor']}
																			name={[name, 'floor']}
																			className="form__item"
																			label={__('Этаж')}
																		>
																			<InputNumber
																				min={0}
																				onChange={changeHandler}
																				formatter={numberFormatter}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24} sm={12} md={8} xl={4}>
																		<Form.Item
																			{...restField}
																			fieldKey={[fieldKey, 'intercom']}
																			name={[name, 'intercom']}
																			className="form__item"
																			label={__('Домофон')}
																		>
																			<Input onChange={changeHandler} />
																		</Form.Item>
																	</Col>
																	<Col span={24} md={12}>
																		<Form.Item
																			{...restField}
																			fieldKey={[fieldKey, 'description']}
																			name={[name, 'description']}
																			className="form__item form__item--textarea-map"
																			label={__('Примечание к адресу')}
																		>
																			<Input.TextArea />
																		</Form.Item>
																	</Col>
																	<Col span={24} md={12}>
																		<AddressMap lat={lat} lng={lng} key={`${lat}${lng}`} />
																	</Col>
																</Row>
																<Form.Item
																	{...restField}
																	fieldKey={[fieldKey, 'title']}
																	name={[name, 'title']}
																	hidden
																>
																	<Input type="hidden" />
																</Form.Item>
																<Form.Item
																	{...restField}
																	fieldKey={[fieldKey, 'addresses_street_id']}
																	name={[name, 'addresses_street_id']}
																	hidden
																>
																	<Input type="hidden" />
																</Form.Item>
																<Form.Item {...restField} fieldKey={[fieldKey, 'lat']} name={[name, 'lat']} hidden>
																	<Input type="hidden" />
																</Form.Item>
																<Form.Item {...restField} fieldKey={[fieldKey, 'lng']} name={[name, 'lng']} hidden>
																	<Input type="hidden" />
																</Form.Item>
																<Form.Item {...restField} fieldKey={[fieldKey, 'id']} name={[name, 'id']} hidden>
																	<Input type="hidden" />
																</Form.Item>
																<Form.Item {...restField} fieldKey={[fieldKey, 'main']} name={[name, 'main']} hidden>
																	<Input type="hidden" />
																</Form.Item>
															</Panel>
														);
													})}
												</Collapse>
												<button type="button" className="button button--dark" onClick={panelAddHandler}>
													<PlusOutlined />
													<span>{__('Добавить адрес')}</span>
												</button>
											</>
										);
									}}
								</Form.List>
								<div className="form__submit">
									<button className="button button--main button--full">
										<span>{__('Сохранить')}</span>
									</button>
								</div>
							</Preloader>
						</Form>
					</div>
				</div>
			</Modal>
		</>
	);
};
