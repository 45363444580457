import { Preloader } from 'components';

export const CustomTable = (props) => {
	const { pending, data = [], columns = [], summary = [], rowClassName, type, tableProps = {}, ...rest } = props;

	return (
		<Preloader spinning={pending}>
			<div className={`custom-table${type ? ` custom-table--${type}` : ''}`} {...rest}>
				<table {...tableProps}>
					<colgroup>
						{columns.map((column, colIndex) => {
							let colProps = {};
							if (column.width) {
								colProps.style = {
									width: `${column.width}px`,
									minWidth: `${column.width}px`
								};
							}

							return <col key={colIndex} {...colProps} />;
						})}
					</colgroup>
					<thead>
						<tr>
							{columns.map((column, colIndex) => {
								let cellProps = {};

								if (column.className) {
									cellProps.className = typeof column.className === 'function' ? column.className(colIndex) : column.className;
								}

								if (column.style) {
									cellProps.style = typeof column.style === 'function' ? column.style(colIndex) : column.style;
								}

								return (
									<th key={colIndex} {...cellProps}>
										{column.title}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{data.map((row, rowIndex) => {
							let rowProps = {};
							if (rowClassName) {
								rowProps.className = typeof rowClassName === 'function' ? rowClassName(row, rowIndex) : rowClassName;
							}

							return (
								<tr key={rowIndex} {...rowProps}>
									{columns.map((column, colIndex) => {
										let child = null;
										let cellProps = {};

										if (column.className) {
											cellProps.className =
												typeof column.className === 'function' ? column.className(row, rowIndex, colIndex) : column.className;
										}
										if (column.style) {
											cellProps.style =
												typeof column.style === 'function' ? column.style(row, rowIndex, colIndex) : column.style;
										}
										if (column.render) {
											const comp = column.render(row[column.dataIndex], row, rowIndex, colIndex);
											if (comp.children) {
												child = comp.children;
											} else {
												child = comp;
											}
										} else if (column.dataIndex) {
											child = row[column.dataIndex];
										}

										return (
											<td key={`${rowIndex}${colIndex}`} {...cellProps}>
												{child}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
					{summary.length ? (
						<tfoot>
							{summary.map((row, rowIndex) => {
								let rowProps = {};
								if (rowClassName) {
									rowProps.className = typeof rowClassName === 'function' ? rowClassName(row, rowIndex) : rowClassName;
								}

								return (
									<tr key={rowIndex} {...rowProps}>
										{columns.map((column, colIndex) => {
											let child = null;
											let cellProps = {};

											if (column.className) {
												cellProps.className =
													typeof column.className === 'function'
														? column.className(row, rowIndex, colIndex)
														: column.className;
											}
											if (column.style) {
												cellProps.style =
													typeof column.style === 'function' ? column.style(row, rowIndex, colIndex) : column.style;
											}
											if (column.render) {
												const comp = column.render(row[column.dataIndex], row, rowIndex, colIndex);
												if (comp.children) {
													child = comp.children;
												} else {
													child = comp;
												}
											} else if (column.dataIndex) {
												child = row[column.dataIndex];
											}

											return (
												<td key={`${rowIndex}${colIndex}`} {...cellProps}>
													{child}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tfoot>
					) : null}
				</table>
			</div>
		</Preloader>
	);
};
