import { getLocale, getHomeUrl, getSearch, getLocalStorage, getUserMedia, isUrl } from 'utils';
import {
	getOrdersStatisticAction,
	getCouriersStatisticAction,
	getUserAction,
	getRestaurantSettingsAction,
	getDeliveryTimesAction,
	getNotificationsAction,
	getEmployeesAction,
	getOrderStatusesAction,
	getPaymentTypesAction,
	getOrderTypesAction,
	getDeliveryTypesAction,
	getCouriersAction,
	getEntrepreneursAction,
	getProductsAction,
	getNomenclaturesAction,
	getOrderSalesAction,
	getCitiesAction,
	getIngredientsAction,
	getDishCategoriesAction,
	getDepartmentsAction,
	getPositionsAction,
	getRatesAction,
	getWarehousesAction,
	getSettingsAction,
	getTransactionsTypesAction,
	getOperationsTypesAction
} from 'actions';
import browserizr, { isMobile } from '@wezom/browserizr-cjs';

export const getInitialProps = async () => {
	const user = !isUrl('/relogin', true) ? await getUserAction() : {};
	const homeUrl = getHomeUrl();
	const collapsed = getLocalStorage('collapsed', browserizr.detect(isMobile));
	const isUserMedia = await getUserMedia();
	const audioMicDeviceId = getLocalStorage('audioMicDeviceId');
	const audioCallDeviceId = getLocalStorage('audioCallDeviceId');
	const audioBellDeviceId = getLocalStorage('audioBellDeviceId');

	return {
		user,
		homeUrl,
		collapsed,
		isUserMedia,
		audioMicDeviceId,
		audioCallDeviceId,
		audioBellDeviceId,
		sip: {
			incoming: {
				status: 'done'
			},
			outgoing: {
				status: 'done'
			}
		}
	};
};

export const getDefaultAuthProps = {
	orderStatuses: [],
	paymentTypes: [],
	deliveryTypes: [],
	couriers: [],
	cities: [],
	departments: [],
	positions: [],
	rates: [],
	warehouses: [],
	settings: []
};

export const getAuthProps = async () => {
	const [orderStatuses, paymentTypes, deliveryTypes, couriers, cities, departments, positions, rates, warehouses, settings] = await Promise.all([
		getOrderStatusesAction(),
		getPaymentTypesAction(),
		getDeliveryTypesAction(),
		getCouriersAction(),
		getCitiesAction(),
		getDepartmentsAction(),
		getPositionsAction(),
		getRatesAction(),
		getWarehousesAction(),
		getSettingsAction()
	]);

	return {
		orderStatuses,
		paymentTypes,
		deliveryTypes,
		couriers,
		cities,
		departments,
		positions,
		rates,
		warehouses,
		settings
	};
};

export const getDefaultRestaurantProps = {
	restaurantSettings: {},
	notifications: [],
	orderSales: [],
	orderTypes: [],
	products: [],
	transactionsTypes: [],
	operationsTypes: [],
	ordersStatistic: {},
	couriersStatistic: {},
	deliveryTimesCurrent: [],
	employees: [],
	entrepreneurs: [],
	nomenclatures: [],
	ingredients: [],
	dishCategories: []
};

export const getRestaurantProps = async () => {
	const [
		restaurantSettings,
		notifications,
		orderSales,
		orderTypes,
		products,
		transactionsTypes,
		operationsTypes,
		ordersStatistic,
		couriersStatistic,
		deliveryTimesCurrent,
		employees,
		entrepreneurs,
		nomenclatures,
		ingredients,
		dishCategories
	] = await Promise.all([
		getRestaurantSettingsAction(),
		getNotificationsAction(),
		getOrderSalesAction(),
		getOrderTypesAction(),
		getProductsAction(),
		getTransactionsTypesAction(),
		getOperationsTypesAction(),
		getOrdersStatisticAction(),
		getCouriersStatisticAction(),
		getDeliveryTimesAction(),
		getEmployeesAction(),
		getEntrepreneursAction(),
		getNomenclaturesAction(),
		getIngredientsAction(),
		getDishCategoriesAction()
	]);

	return {
		restaurantSettings,
		notifications,
		orderSales,
		orderTypes,
		products,
		transactionsTypes,
		operationsTypes,
		ordersStatistic,
		couriersStatistic,
		deliveryTimesCurrent,
		employees,
		entrepreneurs,
		nomenclatures,
		ingredients,
		dishCategories
	};
};

export const getLocaleProps = async () => {
	const locale = getLocale();
	const translations = {};

	return {
		locale,
		translations
	};
};

export const getRouteProps = async () => {
	const search = getSearch();

	return {
		search
	};
};
