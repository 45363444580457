import { Typography } from 'antd';

export const Title = (props) => {
	const { children } = props;

	return (
		<Typography.Title level={2} style={{ marginBottom: '30px' }}>
			{children}
		</Typography.Title>
	);
};
