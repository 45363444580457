/*
 * $Id$
 *
 * Copyright 2013 Valentyn Kolesnikov
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

let currencyList = {
	CurrencyList: {
		language: { '-value': 'UKR' },
		UKR: {
			item: [
				{
					'-value': 'minus',
					'-text': 'мінус'
				},
				{
					'-value': '0',
					'-text': 'нуль'
				},
				{
					'-value': '1000_10',
					'-text': 'тисяч,мільйонів,мільярдів,трильйонів'
				},
				{
					'-value': '1000_1',
					'-text': 'тисяча,мільйон,мільярд,трильйон'
				},
				{
					'-value': '1000_234',
					'-text': 'тисячі,мільйона,мільярда,трильйона'
				},
				{
					'-value': '1000_5',
					'-text': 'тисяч,мільйонів,мільярдів,трильйонів'
				},
				{
					'-value': '10_19',
					'-text': "десять,одинадцять,дванадцять,тринадцять,чотирнадцять,п’ятнадцять,шiстнадцять,сiмнадцять,вiсiмнадцять,дев'ятнадцять"
				},
				{
					'-value': '1',
					'-text': 'одна,один,один,одна'
				},
				{
					'-value': '2',
					'-text': 'дві,два,два,дві'
				},
				{
					'-value': '3_9',
					'-text': 'три,чотири,п’ять,шість,сім,вісім,дев’ять'
				},
				{
					'-value': '100_900',
					'-text': 'сто ,двісті ,триста ,чотириста ,п’ятсот ,шістсот ,сімсот ,вісімсот ,дев’ятсот '
				},
				{
					'-value': '20_90',
					'-text': 'двадцять ,тридцять ,сорок ,п’ятдесят ,шістдесят ,сімдесят ,вісімдесят ,дев’яносто '
				},
				{
					'-value': 'pdv',
					'-text': 'в т.ч. ПДВ '
				},
				{
					'-value': 'pdv_value',
					'-text': '20'
				}
			]
		},
		RUS: {
			item: [
				{
					'-value': 'minus',
					'-text': 'минус'
				},
				{
					'-value': '0',
					'-text': 'ноль'
				},
				{
					'-value': '1000_10',
					'-text': 'тысяч,миллионов,миллиардов,триллионов'
				},
				{
					'-value': '1000_1',
					'-text': 'тысяча,миллион,миллиард,триллион'
				},
				{
					'-value': '1000_234',
					'-text': 'тысячи,миллиона,миллиарда,триллиона'
				},
				{
					'-value': '1000_5',
					'-text': 'тысяч,миллионов,миллиардов,триллионов'
				},
				{
					'-value': '10_19',
					'-text': 'десять,одиннадцать,двенадцать,тринадцать,четырнадцать,пятнадцать,шестнадцать,семнадцать,восемнадцать,девятнадцать'
				},
				{
					'-value': '1',
					'-text': 'одна,один,один,одна'
				},
				{
					'-value': '2',
					'-text': 'две,два,два,две'
				},
				{
					'-value': '3_9',
					'-text': 'три,четыре,пять,шесть,семь,восемь,девять'
				},
				{
					'-value': '100_900',
					'-text': 'сто ,двести ,триста ,четыреста ,пятьсот ,шестьсот ,семьсот ,восемьсот ,девятьсот '
				},
				{
					'-value': '20_90',
					'-text': 'двадцать ,тридцать ,сорок ,пятьдесят ,шестьдесят ,семьдесят ,восемьдесят ,девяносто '
				},
				{
					'-value': 'pdv',
					'-text': 'в т.ч. НДС '
				},
				{
					'-value': 'pdv_value',
					'-text': '18'
				}
			]
		},
		ENG: {
			item: [
				{
					'-value': 'minus',
					'-text': 'minus'
				},
				{
					'-value': '0',
					'-text': 'zero'
				},
				{
					'-value': '1000_10',
					'-text': 'thousand,million,billion,trillion'
				},
				{
					'-value': '1000_1',
					'-text': 'thousand,million,billion,trillion'
				},
				{
					'-value': '1000_234',
					'-text': 'thousand,million,billion,trillion'
				},
				{
					'-value': '1000_5',
					'-text': 'thousand,million,billion,trillion'
				},
				{
					'-value': '10_19',
					'-text': 'ten,eleven,twelve,thirteen,fourteen,fifteen,sixteen,seventeen,eighteen,nineteen'
				},
				{
					'-value': '1',
					'-text': 'one,one,one,one'
				},
				{
					'-value': '2',
					'-text': 'two,two,two,two'
				},
				{
					'-value': '3_9',
					'-text': 'three,four,five,six,seven,eight,nine'
				},
				{
					'-value': '100_900',
					'-text':
						'one hundred ,two hundred ,three hundred ,four hundred ,five hundred ,six hundred ,seven hundred ,eight hundred ,nine hundred '
				},
				{
					'-value': '20_90',
					'-text': 'twenty-,thirty-,forty-,fifty-,sixty-,seventy-,eighty-,ninety-'
				},
				{
					'-value': 'pdv',
					'-text': 'including VAT '
				},
				{
					'-value': 'pdv_value',
					'-text': '10'
				}
			]
		},
		RUR: [
			{
				'-CurrID': '810',
				'-CurrName': 'Российские рубли',
				'-language': 'RUS',
				'-RubOneUnit': 'рубль',
				'-RubTwoUnit': 'рубля',
				'-RubFiveUnit': 'рублей',
				'-RubSex': 'M',
				'-RubShortUnit': 'руб.',
				'-KopOneUnit': 'копейка',
				'-KopTwoUnit': 'копейки',
				'-KopFiveUnit': 'копеек',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '810',
				'-CurrName': 'Российские рубли',
				'-language': 'UKR',
				'-RubOneUnit': 'рубль',
				'-RubTwoUnit': 'рублі',
				'-RubFiveUnit': 'рублів',
				'-RubSex': 'M',
				'-RubShortUnit': 'руб.',
				'-KopOneUnit': 'копійка',
				'-KopTwoUnit': 'копійки',
				'-KopFiveUnit': 'копійок',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '810',
				'-CurrName': 'Российские рубли',
				'-language': 'ENG',
				'-RubOneUnit': 'ruble',
				'-RubTwoUnit': 'rubles',
				'-RubFiveUnit': 'rubles',
				'-RubSex': 'M',
				'-RubShortUnit': 'RUR.',
				'-KopOneUnit': 'kopeck',
				'-KopTwoUnit': 'kopecks',
				'-KopFiveUnit': 'kopecks',
				'-KopSex': 'M'
			}
		],
		UAH: [
			{
				'-CurrID': '980',
				'-CurrName': 'Украинскі гривні',
				'-language': 'RUS',
				'-RubOneUnit': 'гривня',
				'-RubTwoUnit': 'гривни',
				'-RubFiveUnit': 'гривень',
				'-RubSex': 'F',
				'-RubShortUnit': 'грн.',
				'-KopOneUnit': 'коп',
				'-KopTwoUnit': 'коп',
				'-KopFiveUnit': 'коп',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '980',
				'-CurrName': 'Украинскі гривні',
				'-language': 'UKR',
				'-RubOneUnit': 'гривня',
				'-RubTwoUnit': 'гривні',
				'-RubFiveUnit': 'гривень',
				'-RubSex': 'F',
				'-RubShortUnit': 'грн.',
				'-KopOneUnit': 'коп',
				'-KopTwoUnit': 'коп',
				'-KopFiveUnit': 'коп',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '980',
				'-CurrName': 'Украинскі гривні',
				'-language': 'ENG',
				'-RubOneUnit': 'hryvnia',
				'-RubTwoUnit': 'hryvnias',
				'-RubFiveUnit': 'hryvnias',
				'-RubSex': 'M',
				'-RubShortUnit': 'UAH.',
				'-KopOneUnit': 'kopeck',
				'-KopTwoUnit': 'kopecks',
				'-KopFiveUnit': 'kopecks',
				'-KopSex': 'M'
			}
		],
		USD: [
			{
				'-CurrID': '840',
				'-CurrName': 'Долари США',
				'-language': 'RUS',
				'-RubOneUnit': 'доллар',
				'-RubTwoUnit': 'доллара',
				'-RubFiveUnit': 'долларов',
				'-RubSex': 'M',
				'-RubShortUnit': 'дол.',
				'-KopOneUnit': 'цент',
				'-KopTwoUnit': 'цента',
				'-KopFiveUnit': 'центов',
				'-KopSex': 'M'
			},
			{
				'-CurrID': '840',
				'-CurrName': 'Долари США',
				'-language': 'UKR',
				'-RubOneUnit': 'долар',
				'-RubTwoUnit': 'долара',
				'-RubFiveUnit': 'доларів',
				'-RubSex': 'M',
				'-RubShortUnit': 'дол.',
				'-KopOneUnit': 'цент',
				'-KopTwoUnit': 'цента',
				'-KopFiveUnit': 'центів',
				'-KopSex': 'M'
			},
			{
				'-CurrID': '840',
				'-CurrName': 'Долари США',
				'-language': 'ENG',
				'-RubOneUnit': 'dollar',
				'-RubTwoUnit': 'dollars',
				'-RubFiveUnit': 'dollars',
				'-RubSex': 'M',
				'-RubShortUnit': 'USD.',
				'-KopOneUnit': 'cent',
				'-KopTwoUnit': 'cents',
				'-KopFiveUnit': 'cents',
				'-KopSex': 'M'
			}
		],
		EUR: [
			{
				'-CurrID': '840',
				'-CurrName': 'Евро ЕС',
				'-language': 'RUS',
				'-RubOneUnit': 'евро',
				'-RubTwoUnit': 'евро',
				'-RubFiveUnit': 'евро',
				'-RubSex': 'M',
				'-RubShortUnit': 'евр.',
				'-KopOneUnit': 'цент',
				'-KopTwoUnit': 'цента',
				'-KopFiveUnit': 'центов',
				'-KopSex': 'M'
			},
			{
				'-CurrID': '840',
				'-CurrName': 'Евро ЕС',
				'-language': 'UKR',
				'-RubOneUnit': 'євро',
				'-RubTwoUnit': 'євро',
				'-RubFiveUnit': 'євро',
				'-RubSex': 'M',
				'-RubShortUnit': 'дол.',
				'-KopOneUnit': 'цент',
				'-KopTwoUnit': 'цента',
				'-KopFiveUnit': 'центів',
				'-KopSex': 'M'
			},
			{
				'-CurrID': '840',
				'-CurrName': 'Долари США',
				'-language': 'ENG',
				'-RubOneUnit': 'euro',
				'-RubTwoUnit': 'euros',
				'-RubFiveUnit': 'euros',
				'-RubSex': 'M',
				'-RubShortUnit': 'USD.',
				'-KopOneUnit': 'cent',
				'-KopTwoUnit': 'cents',
				'-KopFiveUnit': 'cents',
				'-KopSex': 'M'
			}
		],
		PER10: [
			{
				'-CurrID': '556',
				'-CurrName': 'Вiдсотки з десятими частинами',
				'-language': 'RUS',
				'-RubOneUnit': 'целая,',
				'-RubTwoUnit': 'целых,',
				'-RubFiveUnit': 'целых,',
				'-RubSex': 'F',
				'-KopOneUnit': 'десятая процента',
				'-KopTwoUnit': 'десятых процента',
				'-KopFiveUnit': 'десятых процента',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '556',
				'-CurrName': 'Вiдсотки з десятими частинами',
				'-language': 'UKR',
				'-RubOneUnit': 'ціла,',
				'-RubTwoUnit': 'цілих,',
				'-RubFiveUnit': 'цілих,',
				'-RubSex': 'F',
				'-KopOneUnit': 'десята відсотка',
				'-KopTwoUnit': 'десятих відсотка',
				'-KopFiveUnit': 'десятих відсотка',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '560',
				'-CurrName': 'Вiдсотки з десятими частинами',
				'-language': 'ENG',
				'-RubOneUnit': ',',
				'-RubTwoUnit': 'integers,',
				'-RubFiveUnit': 'integers,',
				'-RubSex': 'F',
				'-KopOneUnit': 'tenth of one percent',
				'-KopTwoUnit': 'tenth of one percent',
				'-KopFiveUnit': 'tenth of one percent',
				'-KopSex': 'F'
			}
		],
		PER100: [
			{
				'-CurrID': '557',
				'-CurrName': 'Вiдсотки з сотими частинами',
				'-language': 'RUS',
				'-RubOneUnit': 'целая,',
				'-RubTwoUnit': 'целых,',
				'-RubFiveUnit': 'целых,',
				'-RubSex': 'F',
				'-KopOneUnit': 'сотая процента',
				'-KopTwoUnit': 'сотых процента',
				'-KopFiveUnit': 'сотых процента',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '557',
				'-CurrName': 'Вiдсотки з сотими частинами',
				'-language': 'UKR',
				'-RubOneUnit': 'ціла,',
				'-RubTwoUnit': 'цілих,',
				'-RubFiveUnit': 'цілих,',
				'-RubSex': 'F',
				'-KopOneUnit': 'сота відсотка',
				'-KopTwoUnit': 'сотих відсотка',
				'-KopFiveUnit': 'сотих відсотка',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '561',
				'-CurrName': 'Вiдсотки з сотими частинами',
				'-language': 'ENG',
				'-RubOneUnit': ',',
				'-RubTwoUnit': 'integers,',
				'-RubFiveUnit': 'integers,',
				'-RubSex': 'F',
				'-KopOneUnit': 'hundred percent',
				'-KopTwoUnit': 'hundredth of percent',
				'-KopFiveUnit': 'hundredth of percent',
				'-KopSex': 'F'
			}
		],
		PER1000: [
			{
				'-CurrID': '558',
				'-CurrName': 'Вiдсотки з тисячними частинами',
				'-language': 'RUS',
				'-RubOneUnit': 'целая,',
				'-RubTwoUnit': 'целых,',
				'-RubFiveUnit': 'целых,',
				'-RubSex': 'F',
				'-KopOneUnit': 'тысячная процента',
				'-KopTwoUnit': 'тысячных процента',
				'-KopFiveUnit': 'тысячных процента',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '558',
				'-CurrName': 'Вiдсотки з тисячними частинами',
				'-language': 'UKR',
				'-RubOneUnit': 'ціла,',
				'-RubTwoUnit': 'цілих,',
				'-RubFiveUnit': 'цілих,',
				'-RubSex': 'F',
				'-KopOneUnit': 'тисячна відсотка',
				'-KopTwoUnit': 'тисячних відсотка',
				'-KopFiveUnit': 'тисячних відсотка',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '562',
				'-CurrName': 'Вiдсотки з тисячними частинами',
				'-language': 'ENG',
				'-RubOneUnit': ',',
				'-RubTwoUnit': 'integers,',
				'-RubFiveUnit': 'integers,',
				'-RubSex': 'F',
				'-KopOneUnit': 'thousandth of percent',
				'-KopTwoUnit': 'thousandths of percent',
				'-KopFiveUnit': 'thousandths of percent',
				'-KopSex': 'F'
			}
		],
		PER10000: [
			{
				'-CurrID': '559',
				'-CurrName': 'Вiдсотки з десяти тисячними частинами',
				'-language': 'RUS',
				'-RubOneUnit': 'целая,',
				'-RubTwoUnit': 'целых,',
				'-RubFiveUnit': 'целых,',
				'-RubSex': 'F',
				'-KopOneUnit': 'десятитысячная процента',
				'-KopTwoUnit': 'десятитысячные процента',
				'-KopFiveUnit': 'десятитысячных процента',
				'-KopSex': 'F'
			},
			{
				'-CurrID': '559',
				'-CurrName': 'Вiдсотки з десяти тисячними частинами',
				'-language': 'UKR',
				'-RubOneUnit': 'ціла,',
				'-RubTwoUnit': 'цілих,',
				'-RubFiveUnit': 'цілих,',
				'-RubSex': 'F',
				'-KopOneUnit': 'десятитисячна відсотка',
				'-KopTwoUnit': 'десятитисячних відсотка',
				'-KopFiveUnit': 'десятитисячних відсотка',
				'-KopSex': 'M'
			},
			{
				'-CurrID': '563',
				'-CurrName': 'Вiдсотки з десяти тисячними частинами',
				'-language': 'ENG',
				'-RubOneUnit': ',',
				'-RubTwoUnit': 'integers,',
				'-RubFiveUnit': 'integers,',
				'-RubSex': 'F',
				'-KopOneUnit': 'ten percent',
				'-KopTwoUnit': 'ten-percent',
				'-KopFiveUnit': 'ten-percent',
				'-KopSex': 'F'
			}
		]
	}
};
/**
 * Converts numbers to symbols.
 *
 * @author Valentyn V Kolesnikov
 * @version $Revision$ $Date$
 */

/** Currency. */
let Currency = (function () {
	function Currency() {}
	Currency.UAH = 'UAH';

	Currency.RUR = 'RUR';

	Currency.USD = 'USD';

	Currency.EUR = 'EUR';

	Currency.PER10 = 'PER10';

	Currency.PER100 = 'PER100';

	Currency.PER1000 = 'PER1000';

	Currency.PER10000 = 'PER10000';
	return Currency;
})();

/** Language. */
let Language = (function () {
	function Language() {}
	Language.RUS = 'RUS';

	Language.UKR = 'UKR';

	Language.ENG = 'ENG';
	return Language;
})();

/** Pennies. */
let Pennies = (function () {
	function Pennies() {}
	Pennies.NUMBER = 'NUMBER';

	Pennies.TEXT = 'TEXT';
	return Pennies;
})();

let StringBuilder = (function () {
	function StringBuilder() {
		this._buffer = [];
	}
	StringBuilder.prototype.append = function (text) {
		this._buffer[this._buffer.length] = text;
		return this;
	};

	StringBuilder.prototype.insert = function (index, text) {
		this._buffer.splice(index, 0, text);
		return this;
	};

	StringBuilder.prototype.length = function () {
		return this.toString().length;
	};

	StringBuilder.prototype.deleteCharAt = function (index) {
		let str = this.toString();
		this._buffer = [];
		this.append(str.substring(0, index));
		return this;
	};

	StringBuilder.prototype.toString = function () {
		return this._buffer.join('');
	};
	return StringBuilder;
})();

let MoneyToStr = (function () {
	MoneyToStr.NUM0 = 0;
	MoneyToStr.NUM1 = 1;
	MoneyToStr.NUM2 = 2;
	MoneyToStr.NUM3 = 3;
	MoneyToStr.NUM4 = 4;
	MoneyToStr.NUM5 = 5;
	MoneyToStr.NUM6 = 6;
	MoneyToStr.NUM7 = 7;
	MoneyToStr.NUM8 = 8;
	MoneyToStr.NUM9 = 9;
	MoneyToStr.NUM10 = 10;
	MoneyToStr.NUM11 = 11;
	MoneyToStr.NUM14 = 14;
	MoneyToStr.NUM100 = 100;
	MoneyToStr.NUM1000 = 1000;
	MoneyToStr.NUM10000 = 10000;
	MoneyToStr.INDEX_0 = 0;
	MoneyToStr.INDEX_1 = 1;
	MoneyToStr.INDEX_2 = 2;
	MoneyToStr.INDEX_3 = 3;

	MoneyToStr.Currency = Currency;
	MoneyToStr.Language = Language;
	MoneyToStr.Pennies = Pennies;

	MoneyToStr.percentToStr = function (amount, lang) {
		if (amount === null) {
			throw new Error('amount is null');
		}
		if (lang === null) {
			throw new Error('lang is null');
		}
		let intPart = parseInt(amount);
		let fractPart = 0;
		let result;
		if (amount === parseInt(amount)) {
			result = new MoneyToStr(Currency.PER10, lang, Pennies.TEXT).convert(amount, 0);
		} else if ((amount * MoneyToStr.NUM10).toFixed(4) === parseInt(amount * MoneyToStr.NUM10)) {
			fractPart = Math.round((amount - intPart) * MoneyToStr.NUM10);
			result = new MoneyToStr(Currency.PER10, lang, Pennies.TEXT).convert(intPart, fractPart);
		} else if ((amount * MoneyToStr.NUM100).toFixed(4) === parseInt(amount * MoneyToStr.NUM100)) {
			fractPart = Math.round((amount - intPart) * MoneyToStr.NUM100);
			result = new MoneyToStr(Currency.PER100, lang, Pennies.TEXT).convert(intPart, fractPart);
		} else if ((amount * MoneyToStr.NUM1000).toFixed(4) === parseInt(amount * MoneyToStr.NUM1000)) {
			fractPart = Math.round((amount - intPart) * MoneyToStr.NUM1000);
			result = new MoneyToStr(Currency.PER1000, lang, Pennies.TEXT).convert(intPart, fractPart);
		} else {
			fractPart = Math.round((amount - intPart) * MoneyToStr.NUM10000);
			result = new MoneyToStr(Currency.PER10000, lang, Pennies.TEXT).convert(intPart, fractPart);
		}
		return result;
	};

	function MoneyToStr(currency, language, pennies) {
		this.currency = currency;
		this.language = language;
		this.pennies = pennies;
		let languageElement = language;
		let items = currencyList['CurrencyList'][languageElement]['item'];
		this.messages = {};
		for (let index in items) {
			let languageItem = items[index];
			if (languageItem['-text']) {
				this.messages[languageItem['-value']] = languageItem['-text'].split(',');
			}
		}
		let currencyItem = currencyList['CurrencyList'][currency];
		let theISOElement = null;
		for (let index in currencyItem) {
			if (currencyItem[index]['-language'] === language) {
				theISOElement = currencyItem[index];
				break;
			}
		}
		if (theISOElement === null) {
			throw new Error('Currency not found ' + currency);
		}
		this.rubOneUnit = theISOElement['-RubOneUnit'];
		this.rubTwoUnit = theISOElement['-RubTwoUnit'];
		this.rubFiveUnit = theISOElement['-RubFiveUnit'];
		this.kopOneUnit = theISOElement['-KopOneUnit'];
		this.kopTwoUnit = theISOElement['-KopTwoUnit'];
		this.kopFiveUnit = theISOElement['-KopFiveUnit'];
		this.rubSex = theISOElement['-RubSex'];
		this.kopSex = theISOElement['-KopSex'];
		this.rubShortUnit = theISOElement['-RubShortUnit'];
	}

	/**
	 * Converts double value to the text description.
	 *
	 * @param theMoney
	 *            the amount of money in format major.minor
	 * @return the string description of money value
	 */
	MoneyToStr.prototype.convertValue = function (theMoney) {
		if (typeof theMoney === 'undefined' || theMoney === null) {
			throw new Error('theMoney is null');
		}
		let intPart = parseInt(theMoney);
		let fractPart = Math.round((theMoney - intPart) * MoneyToStr.NUM100);
		if (this.currency === Currency.PER1000) {
			fractPart = Math.round((theMoney - intPart) * MoneyToStr.NUM1000);
		}
		return this.convert(intPart, fractPart);
	};

	/**
	 * Converts number to currency. Usage: let moneyToStr = new MoneyToStr(Currency.UAH, Language.UKR, Pennies.NUMBER);
	 * let result = moneyToStr.convertValue(123); Expected: result = сто двадцять три гривні 00 копійок
	 *
	 * @param theMoney
	 *            the amount of money major currency
	 * @param theKopeiki
	 *            the amount of money minor currency
	 * @return the string description of money value
	 */
	MoneyToStr.prototype.convert = function (theMoney, theKopeiki) {
		if (typeof theMoney === 'undefined' || theMoney === null) {
			throw new Error('theMoney is null');
		}
		if (typeof theKopeiki === 'undefined' || theKopeiki === null) {
			throw new Error('theKopeiki is null');
		}
		let money2str = new StringBuilder();
		let triadNum = 0;
		let theTriad = 0;
		let intPart = Math.abs(theMoney);
		if (intPart === 0) {
			money2str.append(this.messages['0'][0] + ' ');
		}
		do {
			theTriad = parseInt(intPart % MoneyToStr.NUM1000);
			money2str.insert(0, this.triad2Word(theTriad, triadNum, this.rubSex));
			if (triadNum === 0) {
				let range10 = parseInt((theTriad % MoneyToStr.NUM100) / MoneyToStr.NUM10);
				let range = parseInt(theTriad % MoneyToStr.NUM10);
				if (range10 === MoneyToStr.NUM1) {
					money2str.append(this.rubFiveUnit);
				} else {
					switch (range) {
						case MoneyToStr.NUM1:
							money2str.append(this.rubOneUnit);
							break;
						case MoneyToStr.NUM2:
						case MoneyToStr.NUM3:
						case MoneyToStr.NUM4:
							money2str.append(this.rubTwoUnit);
							break;
						default:
							money2str.append(this.rubFiveUnit);
							break;
					}
				}
			}
			intPart = parseInt(intPart / MoneyToStr.NUM1000);
			triadNum++;
		} while (intPart > 0);

		if (theMoney < 0) {
			money2str.insert(0, this.messages['minus'][0] + ' ');
		}
		if (this.pennies === Pennies.TEXT) {
			money2str
				.append(this.language === Language.ENG ? ' and ' : ' ')
				.append(theKopeiki === 0 ? this.messages['0'][0] + ' ' : this.triad2Word(Math.abs(theKopeiki), 0, this.kopSex));
		} else {
			money2str.append(' ' + (Math.abs(theKopeiki) < 10 ? '0' + Math.abs(theKopeiki) : Math.abs(theKopeiki)) + ' ');
		}
		if (theKopeiki >= MoneyToStr.NUM11 && theKopeiki <= MoneyToStr.NUM14) {
			money2str.append(this.kopFiveUnit);
		} else {
			switch (parseInt(theKopeiki % MoneyToStr.NUM10)) {
				case MoneyToStr.NUM1:
					money2str.append(this.kopOneUnit);
					break;
				case MoneyToStr.NUM2:
				case MoneyToStr.NUM3:
				case MoneyToStr.NUM4:
					money2str.append(this.kopTwoUnit);
					break;
				default:
					money2str.append(this.kopFiveUnit);
					break;
			}
		}
		return money2str.toString().trim();
	};

	MoneyToStr.prototype.triad2Word = function (triad, triadNum, sex) {
		let triadWord = new StringBuilder();

		if (triad === 0) {
			return '';
		}

		let range = this.check1(triad, triadWord);
		if (this.language === Language.ENG && triadWord.length() > 0 && triad % MoneyToStr.NUM10 === 0) {
			triadWord.deleteCharAt(triadWord.length() - 1);
			triadWord.append(' ');
		}

		let range10 = range;
		range = parseInt(triad % MoneyToStr.NUM10);
		this.check2(triadNum, sex, triadWord, triad, range10);
		switch (triadNum) {
			case MoneyToStr.NUM0:
				break;
			case MoneyToStr.NUM1:
			case MoneyToStr.NUM2:
			case MoneyToStr.NUM3:
			case MoneyToStr.NUM4:
				if (range10 === MoneyToStr.NUM1) {
					triadWord.append(this.messages['1000_10'][triadNum - 1] + ' ');
				} else {
					switch (range) {
						case MoneyToStr.NUM1:
							triadWord.append(this.messages['1000_1'][triadNum - 1] + ' ');
							break;
						case MoneyToStr.NUM2:
						case MoneyToStr.NUM3:
						case MoneyToStr.NUM4:
							triadWord.append(this.messages['1000_234'][triadNum - 1] + ' ');
							break;
						default:
							triadWord.append(this.messages['1000_5'][triadNum - 1] + ' ');
							break;
					}
				}
				break;
			default:
				triadWord.append('??? ');
				break;
		}
		return triadWord.toString();
	};

	/**
	 * @param triadNum the triad num
	 * @param sex the sex
	 * @param triadWord the triad word
	 * @param triad the triad
	 * @param range10 the range 10
	 */
	MoneyToStr.prototype.check2 = function (triadNum, sex, triadWord, triad, range10) {
		let range = parseInt(triad % MoneyToStr.NUM10);
		if (range10 === 1) {
			triadWord.append(this.messages['10_19'][range] + ' ');
		} else {
			switch (range) {
				case MoneyToStr.NUM1:
					if (triadNum === MoneyToStr.NUM1) {
						triadWord.append(this.messages['1'][MoneyToStr.INDEX_0] + ' ');
					} else if (triadNum === MoneyToStr.NUM2 || triadNum === MoneyToStr.NUM3 || triadNum === MoneyToStr.NUM4) {
						triadWord.append(this.messages['1'][MoneyToStr.INDEX_1] + ' ');
					} else if (sex === 'M') {
						triadWord.append(this.messages['1'][MoneyToStr.INDEX_2] + ' ');
					} else if (sex === 'F') {
						triadWord.append(this.messages['1'][MoneyToStr.INDEX_3] + ' ');
					}
					break;
				case MoneyToStr.NUM2:
					if (triadNum === MoneyToStr.NUM1) {
						triadWord.append(this.messages['2'][MoneyToStr.INDEX_0] + ' ');
					} else if (triadNum === MoneyToStr.NUM2 || triadNum === MoneyToStr.NUM3 || triadNum === MoneyToStr.NUM4) {
						triadWord.append(this.messages['2'][MoneyToStr.INDEX_1] + ' ');
					} else if (sex === 'M') {
						triadWord.append(this.messages['2'][MoneyToStr.INDEX_2] + ' ');
					} else if (sex === 'F') {
						triadWord.append(this.messages['2'][MoneyToStr.INDEX_3] + ' ');
					}
					break;
				case MoneyToStr.NUM3:
				case MoneyToStr.NUM4:
				case MoneyToStr.NUM5:
				case MoneyToStr.NUM6:
				case MoneyToStr.NUM7:
				case MoneyToStr.NUM8:
				case MoneyToStr.NUM9:
					triadWord.append(['', '', ''].concat(this.messages['3_9'])[range] + ' ');
					break;
				default:
					break;
			}
		}
	};

	/**
	 * @param triad the triad
	 * @param triadWord the triad word
	 * @return the range
	 */
	MoneyToStr.prototype.check1 = function (triad, triadWord) {
		let range = parseInt(triad / MoneyToStr.NUM100);
		triadWord.append([''].concat(this.messages['100_900'])[range]);

		range = parseInt((triad % MoneyToStr.NUM100) / MoneyToStr.NUM10);
		triadWord.append(['', ''].concat(this.messages['20_90'])[range]);
		return range;
	};

	MoneyToStr.prototype.getMessages = function () {
		return this.messages;
	};

	MoneyToStr.prototype.getRubShortUnit = function () {
		return this.rubShortUnit;
	};

	return MoneyToStr;
})();

export { MoneyToStr };
