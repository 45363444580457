import { useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip } from 'antd';
import { useMethods, useData, dayjs, access } from 'utils';
import { dateServerFormat } from 'const';
import { ShiftCell, EditSchedule, CustomTable } from 'components';

export const EmployeesSchedule = (props) => {
	const { pending } = props;
	const { schedule = [], scheduleStatistic = [] } = useData();
	const { __ } = useMethods();

	const { columns, data } = useMemo(() => {
		const monthDaysCount = schedule[0]?.shifts?.length || 0;
		const firstWidth = monthDaysCount === 29 ? 270 : monthDaysCount === 30 ? 220 : 170;
		const data = [];
		const currentDate = dayjs().format(dateServerFormat);

		const columns = [
			{
				title: <div className="shift-cell shift-cell--employee">{__('Сотрудник')}</div>,
				dataIndex: 'employee_name',
				width: firstWidth,
				fixed: 'left',
				className: 'is-first',
				render: (name, record) => (
					<div className="shift-cell shift-cell--employee">
						<Row gutter={10} style={{ flexWrap: 'nowrap' }}>
							{access('employees-schedule-general-update') ? (
								<Col className="is-not-print">
									<EditSchedule employee={record}>
										<Tooltip title={__('Изменить шаблон графика')} placement="topLeft">
											<button type="button" className="button button--icon-xs button--trans-main">
												<EditOutlined />
											</button>
										</Tooltip>
									</EditSchedule>
								</Col>
							) : null}
							<Col>
								<div>{name}</div>
								<div>
									{record.attendance_shifts_count} {__('смен')} / {(+record.attendance_hours_count).toFixed(0)} {__('ч')}
								</div>
							</Col>
						</Row>
					</div>
				)
			}
		];

		schedule.forEach((row, rowIndex) => {
			const { shifts = [], ...rest } = row;
			const item = {
				...rest
			};

			shifts.forEach((shift, index) => {
				const isAfterToday = dayjs(shift.date, dateServerFormat).isAfter(dayjs());
				const stat = scheduleStatistic.find((item) => item.date === shift.date);
				const attendancesCount = isAfterToday ? stat.shifts_count : stat.attendances_count;
				const weekNum = dayjs(shift.date, dateServerFormat).isoWeekday();
				const isWeekNumNew = weekNum === 7 && index !== shifts.length - 1;
				const isDisabled = currentDate === shift.date;

				if (rowIndex === 0) {
					const classNames = [];
					const style = {};
					if (isWeekNumNew) {
						classNames.push('is-finish-week');
					}
					if (weekNum === 6 || weekNum === 7) {
						classNames.push('is-week');
					}
					if (index === 0) {
						style.marginLeft = `${firstWidth}px`;
					}
					columns.push({
						title: (
							<div className={`shift-cell shift-cell--head${isDisabled ? ' is-current-day' : ''}`}>
								<div className="shift-cell__text">
									{attendancesCount} {__('чел')}
								</div>
								<div className="shift-cell__weekday">{dayjs(shift.date).format('dd')}</div>
							</div>
						),
						dataIndex: `day${index}`,
						className: classNames.join(' '),
						style: style,
						width: 50,
						render: (day, record) => <ShiftCell day={day} record={record} />
					});
				}

				item[`day${index}`] = shift;
			});

			data.push(item);
		});

		return {
			columns,
			data
		};
	}, [schedule, scheduleStatistic]);

	return (
		<CustomTable
			id="employees-schedule"
			type="schedule"
			pending={pending}
			columns={columns}
			data={data}
			tableProps={{
				class: 'table-schedule'
			}}
		/>
	);
};
