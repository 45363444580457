import { useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { dateClientFormat } from 'const';
import { ClearOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import { print, useMethods, useData, dayjs } from 'utils';
import { getShiftsMonthlyScheduleAction, getShiftsMonthlyScheduleStatisticAction } from 'actions';
import { RangePickerField } from 'components';

export const EmployeesScheduleFilter = (props) => {
	const { setPending } = props;
	const { employees = [], departments = [], positions = [], scheduleFiltersDefault = {}, scheduleFilters = {} } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(scheduleFiltersDefault);
	}, [scheduleFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...scheduleFilters,
			...values
		};
		const [schedule, scheduleStatistic] = await Promise.all([
			getShiftsMonthlyScheduleAction(filter),
			getShiftsMonthlyScheduleStatisticAction(filter)
		]);
		setPending(false);
		update({
			scheduleFilters: filter,
			schedule,
			scheduleStatistic
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(scheduleFiltersDefault);
		const [schedule, scheduleStatistic] = await Promise.all([getShiftsMonthlyScheduleAction(), getShiftsMonthlyScheduleStatisticAction()]);
		setPending(false);
		update({
			scheduleFilters: scheduleFiltersDefault,
			schedule,
			scheduleStatistic
		});
	};

	const printTable = () => {
		print('#employees-schedule', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};

	const updateHandler = async () => {
		setPending(true);
		const [schedule, scheduleStatistic] = await Promise.all([
			getShiftsMonthlyScheduleAction(scheduleFilters),
			getShiftsMonthlyScheduleStatisticAction(scheduleFilters)
		]);
		setPending(false);
		update({
			schedule,
			scheduleStatistic
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" label={__('Сотрудники')} name="employee_ids">
								<Select
									autoClearSearchValue={true}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									dropdownClassName="form__dropdown"
									options={employees.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
									placeholder={__('Не выбрано')}
									mode="multiple"
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="department_id" label={__('Отдел')}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									dropdownClassName="form__dropdown"
									options={departments.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="position_id" label={__('Должность')}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									dropdownClassName="form__dropdown"
									options={positions.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="date" label={__('Период времени')}>
								<RangePickerField
									allowClear={false}
									format={dateClientFormat}
									showTime={false}
									separator="—"
									ranges={{
										[__('Этот месяц')]: [dayjs().startOf('month'), dayjs().endOf('month')],
										[__('Неделя')]: [dayjs(), dayjs().add(7, 'd')]
									}}
								/>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Распечатать')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={printTable}>
							<PrinterOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
