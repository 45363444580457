import { useEffect, useState } from 'react';
import { useMethods, makeYears, makeMonths, dayjs, useData } from 'utils';
import { DocsSalaryFilter, DocsSalary } from 'components';
import { getRestaurantsAction, getReportsSalaryAction } from 'actions';

export const DocsSalaryTab = () => {
	const { user = {} } = useData();
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const restaurants = await getRestaurantsAction();
			const docsSalaryFiltersDefault = {
				restaurant_id: null,
				month: +dayjs().format('M'),
				year: +dayjs().format('YYYY')
			};
			const docsSalaryFilters = {
				...docsSalaryFiltersDefault,
				restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id
			};
			const docsSalary = await getReportsSalaryAction(docsSalaryFilters);
			setPending(false);
			update({
				docsSalaryFiltersDefault,
				docsSalaryFilters,
				restaurants,
				docsSalary,
				years: makeYears(),
				months: makeMonths(__)
			});
		})();

		return () => {
			update({
				docsSalaryFiltersDefault: {},
				docsSalaryFilters: {},
				docsSalary: []
			});
		};
	}, []);

	return (
		<>
			<DocsSalaryFilter setPending={setPending} />
			<DocsSalary pending={pending} />
		</>
	);
};
