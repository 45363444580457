import { useEffect, useState } from 'react';
import { EmployeesTransactions, EmployeesTransactionsFilter } from 'components';
import { useMethods, dayjs } from 'utils';
import { getTransactionsAction, getEmployeesTransactionsStatisticAction } from 'actions';

export const EmployeesTransactionsTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const transactionsFiltersDefault = {
				employee: null,
				department: null,
				position: null,
				types: [],
				date: [dayjs().startOf('month'), dayjs().endOf('month')],
				page: 1,
				per_page: 10
			};
			const transactionsFilters = { ...transactionsFiltersDefault };
			const [transactions, transactionsStatistic] = await Promise.all([
				getTransactionsAction(transactionsFilters),
				getEmployeesTransactionsStatisticAction(transactionsFilters)
			]);
			setPending(false);
			update({
				transactions,
				transactionsStatistic,
				transactionsFiltersDefault,
				transactionsFilters,
				employeesTab: 'transactions'
			});
		})();

		return () => {
			update({
				transactions: {},
				transactionsStatistic: {},
				transactionsFiltersDefault: {},
				transactionsFilters: {},
				employeesTab: 'transactions'
			});
		};
	}, []);

	return (
		<>
			<EmployeesTransactionsFilter setPending={setPending} />
			<EmployeesTransactions pending={pending} setPending={setPending} />
		</>
	);
};
