import { useState, useEffect } from 'react';
import { Form, Input, Switch } from 'antd';
import { useMethods, useData, requiredRule, phoneFormat, phoneClear } from 'utils';
import { Modal, Preloader } from 'components';
import { addSupplierAction, getSuppliersAction } from 'actions';

export const AddWaybillSupplier = (props) => {
	const { children } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { waybillFilter = {} } = useData();
	const { __, update } = useMethods();

	useEffect(() => {
		if (visible) {
			form.setFieldsValue({
				name: null,
				phone: null,
				with_nds: false
			});
		}
	}, [visible]);

	const submitHandler = async (values) => {
		setPending(true);
		const success = await addSupplierAction({
			name: values.name,
			full_name: values.name,
			phone: phoneClear(values.phone),
			with_nds: !!values.with_nds,
			warehouse_id: waybillFilter.recipient_warehouse_id || null
		});
		if (success) {
			const suppliers = await getSuppliersAction({ warehouse_id: waybillFilter.recipient_warehouse_id });
			setPending(false);
			update({
				suppliers
			});
			setVisible(false);
		} else {
			setPending(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Создать поставщика')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="name" label={__('Имя поставщика')} required rules={[requiredRule(__)]}>
								<Input />
							</Form.Item>
							<Form.Item
								className="form__item"
								name="phone"
								label={__('Номер поставщика')}
								normalize={phoneFormat}
								required
								rules={[requiredRule(__)]}
							>
								<Input type="tel" addonBefore="+38" />
							</Form.Item>
							<Form.Item className="form__item" name="with_nds" label={__('Поставщик с ПДВ')} valuePropName="checked">
								<Switch checkedChildren={__('Да')} unCheckedChildren={__('Нет')} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Создать')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
