import { useEffect, useState } from 'react';
import { useMethods, makeMonthsReverse, dayjs } from 'utils';
import { DocsRevenueFilter, DocsRevenue } from 'components';
import { getReportsRevenueAction, getRestaurantsAction } from 'actions';

export const DocsRevenueTab = () => {
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const docsRevenueFiltersDefault = {
				dates: [dayjs(), dayjs()],
				month: null
			};
			const docsRevenueFilters = { ...docsRevenueFiltersDefault };
			const [restaurants, docsRevenue] = await Promise.all([getRestaurantsAction(), getReportsRevenueAction(docsRevenueFilters.dates)]);
			setPending(false);
			update({
				docsRevenueFiltersDefault,
				docsRevenueFilters,
				restaurants,
				docsRevenue,
				months: makeMonthsReverse(__)
			});
		})();

		return () => {
			update({
				docsRevenueFiltersDefault: {},
				docsRevenueFilters: {},
				docsRevenue: {}
			});
		};
	}, []);

	return (
		<>
			<DocsRevenueFilter setPending={setPending} />
			<DocsRevenue pending={pending} />
		</>
	);
};
