import { useEffect, useState } from 'react';
import { ActiveOrdersStatistic, ActiveOrdersFilter, ActiveOrders } from 'components';
import { getOrdersAction } from 'actions';
import { useMethods } from 'utils';

export const ActiveOrdersTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const orders = await getOrdersAction();
			const ordersFiltersDefault = {
				status: 'in_work',
				day_id: null,
				phone: null,
				address_name: null,
				payment_type_id: null,
				order_type_id: null,
				is_fiscal_printed: false
			};
			const ordersFilters = { ...ordersFiltersDefault };
			setPending(false);
			update({
				ordersFiltersDefault,
				ordersFilters,
				orders
			});
		})();

		return () => {
			update({
				ordersFiltersDefault: {},
				ordersFilters: {},
				orders: []
			});
		};
	}, []);

	return (
		<>
			<ActiveOrdersStatistic />
			<ActiveOrdersFilter setPending={setPending} />
			<ActiveOrders pending={pending} />
		</>
	);
};
