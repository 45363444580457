import { HtmlHead, Sidebar, Header, Footer, CallModal } from 'components';

export const MainLayout = (props) => {
	const { children, seo } = props;

	return (
		<>
			<HtmlHead seo={seo} />
			<div className="layout layout--main">
				<div className="layout__sidebar">
					<Sidebar />
				</div>
				<div id="layout-container" className="layout__container">
					<div className="layout__wrap">
						<div className="layout__header">
							<Header />
						</div>
						<div className="layout__body">{children}</div>
					</div>
					<div className="layout__footer">
						<Footer />
					</div>
					<CallModal />
				</div>
			</div>
		</>
	);
};
