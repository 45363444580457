import { TimePicker } from 'antd';

export const TimePickerField = (props) => {
	const { value, onChange, popupClassName, ...rest } = props;

	return (
		<TimePicker
			inputReadOnly={true}
			value={value}
			onSelect={(value) => onChange(value)}
			onChange={() => onChange(null)}
			popupClassName={`ant-picker-dropdown-footer-hide${popupClassName ? ` ${popupClassName}` : ''}`}
			{...rest}
		/>
	);
};
