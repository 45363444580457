import { useEffect, useState, Fragment } from 'react';
import { Form, Input, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useData, useMethods, phoneFormat, phoneClear, phoneClientRule, notify } from 'utils';
import { Modal, Preloader } from 'components';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { updateClientPhonesAction } from 'actions';

export const PhonesChange = (props) => {
	const { children, onChange } = props;
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const { client = {} } = useData();
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				phones: (
					client.phones || [
						{
							id: `_0`,
							main: true
						}
					]
				).map((item) => ({
					...item,
					value: phoneFormat(item.value)
				}))
			});
		}
	}, [visible, client]);

	const submitHandler = async (values = {}) => {
		setPending(true);
		const phones = (values.phones || []).map((item) => ({
			...item,
			value: phoneClear(item.value)
		}));
		const updatedPhones = client.id
			? await updateClientPhonesAction(
					client.id,
					phones.map((item) => ({
						...item,
						id: String(item.id).includes('_') ? null : item.id
					}))
			  )
			: phones;
		update({
			client: {
				...client,
				phones: updatedPhones
			}
		});
		if (onChange) {
			onChange(updatedPhones);
		}
		notify('success', __('Список телефонов обновлен'));
		setPending(false);
		setVisible(false);
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Список телефонов')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.List name="phones">
								{(fields, { add, remove }) => (
									<>
										{fields.map(({ key, name, fieldKey, ...restField }, index) => (
											<Fragment key={key}>
												<Form.Item
													{...restField}
													fieldKey={[fieldKey, 'value']}
													name={[name, 'value']}
													className="form__item"
													required
													rules={[phoneClientRule(__, form, index)]}
													normalize={phoneFormat}
												>
													<Input
														placeholder={'Введите номер телефона'}
														addonBefore="+38"
														addonAfter={
															fields.length > 1 ? (
																<Popconfirm
																	title={__('Вы действительно хотите удалить этот номер?')}
																	okText={__('Да')}
																	cancelText={__('Отмена')}
																	placement="topRight"
																	onConfirm={() => remove(name)}
																>
																	<div className="form__addon">
																		<CloseIcon />
																	</div>
																</Popconfirm>
															) : null
														}
													/>
												</Form.Item>
												<Form.Item {...restField} fieldKey={[fieldKey, 'id']} name={[name, 'id']} hidden>
													<Input type="hidden" />
												</Form.Item>
												<Form.Item {...restField} fieldKey={[fieldKey, 'main']} name={[name, 'main']} hidden>
													<Input type="hidden" />
												</Form.Item>
											</Fragment>
										))}
										{fields.length < 3 ? (
											<button
												type="button"
												className="button button--dark"
												onClick={() =>
													add({
														id: `_${fields.length}`,
														main: false
													})
												}
											>
												<PlusOutlined />
												<span>{__('Добавить телефон')}</span>
											</button>
										) : null}
									</>
								)}
							</Form.List>
							<div className="form__submit">
								<button className="button button--main button--full">
									<span>{__('Сохранить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
