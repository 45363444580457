import { api, dayjs } from 'utils';
import { dateServerFormat } from 'const';

export const getShiftsAction = async (values = {}) => {
	const { page = 1, per_page = 10, date, ...rest } = values;
	const params = {
		...rest,
		page,
		per_page
	};
	if (date && date.length) {
		params.date = `${date[0].format(dateServerFormat)} - ${date[1].format(dateServerFormat)}`;
	}
	const response = await api.get(`/shifts`, { params });
	return response || {};
};

export const addAttendanceAction = async (values) => {
	const { data } = await api.post(`/shifts/attendance`, values);
	return !!data;
};

export const editAttendanceAction = async (id, values) => {
	const { data } = await api.put(`/shifts/attendance/${id}`, values);
	return !!data;
};

export const deleteAttendanceAction = async (id) => {
	const { success } = await api.delete(`/shifts/attendance/${id}`);
	return success;
};

export const getShiftsMonthlyScheduleAction = async (values = {}) => {
	const { date = [dayjs().startOf('month'), dayjs().endOf('month')], ...rest } = values;
	const params = {
		...rest,
		date: `${date[0].format(dateServerFormat)} - ${date[1].format(dateServerFormat)}`
	};
	const response = await api.get(`/shifts/monthly-schedule`, { params });
	return response.data || [];
};

export const getShiftsMonthlyScheduleStatisticAction = async (values = {}) => {
	const { date = [dayjs().startOf('month'), dayjs().endOf('month')], ...rest } = values;
	const params = {
		...rest,
		date: `${date[0].format(dateServerFormat)} - ${date[1].format(dateServerFormat)}`
	};
	const response = await api.get(`/shifts/monthly-schedule-statistic`, { params });
	return response.data || [];
};

export const editPlannedShiftAction = async (id, values) => {
	const { data } = await api.put(`/shifts/change-planned/${id}`, values);
	return !!data;
};

export const editScheduleAction = async (values) => {
	const { success } = await api.put(`/shifts/change-employee-schedule`, values);
	return !!success;
};

export const checkInAction = async (values) => {
	const { data } = await api.post(`/shifts/check-in`, values);
	return !!data;
};
