import { SIP, getLocalStorage, setLocalStorage, api, getStore, phoneClear } from 'utils';
import { getClientFromPhoneAction } from 'actions';

export const sipConnectAction = async (login, password) => {
	return await SIP(login, password);
};

export const sipDisconnectAction = async () => {
	const sipInstance = await SIP();
	await sipInstance.disconnect();
};

export const updateDevicesAction = async () => {
	const audioOutputDevices = [];
	const audioInputDevices = [];

	const devices = (await window.navigator.mediaDevices.enumerateDevices()) || [];
	devices.forEach((device) => {
		if (device.kind === 'audiooutput') {
			audioOutputDevices.push(device);
		} else if (device.kind === 'audioinput') {
			audioInputDevices.push(device);
		}
	});

	let audioMicDeviceId = getLocalStorage('audioMicDeviceId');
	if ((!audioMicDeviceId || !audioInputDevices.find((item) => item.deviceId === audioMicDeviceId)) && audioInputDevices.length) {
		audioMicDeviceId = audioInputDevices[0].deviceId;
		setLocalStorage('audioMicDeviceId', audioMicDeviceId);
	}

	let audioCallDeviceId = getLocalStorage('audioCallDeviceId');
	if ((!audioCallDeviceId || !audioOutputDevices.find((item) => item.deviceId === audioCallDeviceId)) && audioOutputDevices.length) {
		audioCallDeviceId = audioOutputDevices[0].deviceId;
		setLocalStorage('audioCallDeviceId', audioCallDeviceId);
	}

	let audioBellDeviceId = getLocalStorage('audioBellDeviceId');
	if ((!audioBellDeviceId || !audioOutputDevices.find((item) => item.deviceId === audioBellDeviceId)) && audioOutputDevices.length) {
		audioBellDeviceId = audioOutputDevices[0].deviceId;
		setLocalStorage('audioBellDeviceId', audioBellDeviceId);
	}

	const sipInstance = await SIP();
	sipInstance.changeMicDevice(audioMicDeviceId);
	sipInstance.changeCallDevice(audioCallDeviceId);
	sipInstance.changeBellDevice(audioBellDeviceId);

	return {
		audioMicDeviceId,
		audioCallDeviceId,
		audioBellDeviceId,
		audioOutputDevices,
		audioInputDevices
	};
};

export const changeMicDeviceAction = async (deviceId) => {
	const sipInstance = await SIP();
	sipInstance.changeMicDevice(deviceId);
	setLocalStorage('audioInputDeviceId', deviceId);
};

export const changeBellDeviceAction = async (deviceId) => {
	const sipInstance = await SIP();
	sipInstance.changeBellDevice(deviceId);
	setLocalStorage('audioOutputDeviceId', deviceId);
};

export const sipCallAction = async (number = '', order = {}) => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	const sipWork = getStore('sipWork');
	if (sipWork) {
		number = phoneClear(number);
		const client = !order.id ? await getClientFromPhoneAction(number) : {};
		const sipInstance = await SIP();
		update({
			sip: {
				...sip,
				outgoing: {
					status: 'call',
					number,
					client,
					order
				},
				show: true
			}
		});
		await sipInstance.call(number);
	}
};

export const sipOutgoingAction = async () => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	update({
		sip: {
			...sip,
			outgoing: {
				...(sip.outgoing || {}),
				status: 'calling'
			}
		}
	});
};

export const sipEndAction = async () => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	const sipInstance = await SIP();
	update({
		sip: {
			...sip,
			outgoing: {
				status: 'done'
			},
			show: false
		}
	});
	sipInstance.end();
};

export const sipIncomeAction = async (number = '') => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	number = phoneClear(number);
	const client = await getClientFromPhoneAction(number);
	update({
		sip: {
			...sip,
			incoming: {
				status: 'incoming',
				number,
				client
			},
			show: true
		}
	});
};

export const sipAnswerAction = async () => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	const sipInstance = await SIP();
	update({
		sip: {
			...sip,
			incoming: {
				...(sip.incoming || {}),
				status: 'answer'
			}
		}
	});
	await sipInstance.answer();
};

export const sipCancelAction = async () => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	const sipInstance = await SIP();
	update({
		sip: {
			...sip,
			incoming: {
				status: 'done'
			},
			show: false
		}
	});
	sipInstance.cancel();
};

export const sipChangeStatusAction = async (status) => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	const sipInstance = await SIP();
	setLocalStorage('sipOnlineStatus', !!status);
	update({
		sip: {
			...sip,
			online: !!status
		}
	});
	sipInstance.changeStatus(!!status);
};

export const sipDoneAction = () => {
	const update = getStore('update');
	const sip = getStore('sip', {});
	update({
		sip: {
			...sip,
			incoming: {
				status: 'done'
			},
			outgoing: {
				status: 'done'
			}
		}
	});
};

export const checkSipAction = async () => {
	const response = await api.get(`/sip/check-current-user-account`);
	return !!response.success;
};

export const callsTypesAction = async () => {
	const response = await api.get(`/calls-history/type-list`);
	return response.data || [];
};

export const getHistoryCompletedAction = async (values = {}) => {
	const params = {};
	params.time_to = values.time_to || Math.floor(Date.now() / 1000);
	params.offset = values.offset || 0;
	const response = await api.get(`/calls-history/completed`, { params });
	return response.data || {};
};

export const getHistoryMissedAction = async (values = {}) => {
	const params = {};
	params.time_to = values.time_to || Math.floor(Date.now() / 1000);
	params.offset = values.offset || 0;
	const response = await api.get(`/calls-history/missed`, { params });
	return response.data || {};
};
