import { useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { useMethods, useData, dayjs } from 'utils';
import { getReportsRevenueAction } from 'actions';
import { dateClientFormat, dateServerFormat } from 'const';
import { RevenueGraph, RangePickerField } from 'components';
import { ReactComponent as GraphRevenue } from 'assets/svg/graph-revenue.svg';

export const DocsRevenueFilter = (props) => {
	const { setPending } = props;
	const { months = [], docsRevenueFiltersDefault = {}, docsRevenueFilters = {}, docsRevenue = {} } = useData();
	const { dates = [] } = docsRevenue;
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(docsRevenueFiltersDefault);
	}, [docsRevenueFiltersDefault]);

	const filterHandler = async (changedValues) => {
		setPending(true);
		const filter = {
			...docsRevenueFilters,
			...changedValues
		};
		if (changedValues.month) {
			filter.dates = [dayjs(changedValues.month, dateServerFormat), dayjs(changedValues.month, dateServerFormat).endOf('month')];
		}
		if (changedValues.dates) {
			filter.month = null;
		}
		const docsRevenue = await getReportsRevenueAction(filter.dates);
		form.setFieldsValue(filter);
		setPending(false);
		update({
			docsRevenueFilters: filter,
			docsRevenue
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(docsRevenueFiltersDefault);
		const docsRevenue = await getReportsRevenueAction(docsRevenueFiltersDefault.dates);
		setPending(false);
		update({
			docsRevenueFilters: docsRevenueFiltersDefault,
			docsRevenue
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="dates" label={__('Период времени')}>
								<RangePickerField
									allowClear={false}
									format={dateClientFormat}
									showTime={false}
									separator="—"
									ranges={{
										[__('Сегодня')]: [dayjs(), dayjs()],
										[__('Текущий месяц')]: [dayjs().startOf('month'), dayjs().endOf('month')],
										[__('Последние 3 месяца')]: [dayjs().subtract(3, 'month'), dayjs().endOf('month')],
										[__('За текущий год')]: [dayjs().startOf('year'), dayjs().endOf('year')]
									}}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="month" label={__('Месяц')}>
								<Select dropdownClassName="form__dropdown" options={months} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				{dates.length > 2 ? (
					<div className="filter__button">
						<RevenueGraph>
							<Tooltip title={__('Просмотр графика')} placement="topRight">
								<button className="button button--main button--icon-small">
									<GraphRevenue />
								</button>
							</Tooltip>
						</RevenueGraph>
					</div>
				) : null}
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
