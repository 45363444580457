import { useEffect, useState } from 'react';
import { useMethods } from 'utils';
import { getProcurementsAction } from 'actions';
import { ProcurementTable } from 'components';

export const ProcurementTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const procurements = await getProcurementsAction();
			setPending(false);
			update({
				procurements
			});
		})();

		return () => {
			update({
				procurements: []
			});
		};
	}, []);

	return (
		<>
			<ProcurementTable pending={pending} />
		</>
	);
};
