import { api } from 'utils';

export const getEmployeesAction = async (params = {}) => {
	const response = await api.get(`/employees`, { params });
	return response.data ? response.data : [];
};

export const getEmployeesListAction = async (params = {}) => {
	const response = await api.get(`/employees/list`, { params });
	return response.data ? response.data : [];
};

export const getEmployeesSearchAction = async (search = '') => {
	const response = await api.get(`/employees/autocomplete-search`, { params: { search } });
	return response.data ? response.data : [];
};
