export const print = (selectors, replaceArray = {}, styles = '') => {
	const container = window.document.getElementById('print-container');
	let html = '';

	if (Array.isArray(selectors)) {
		selectors.forEach((selector) => {
			const target = window.document.querySelector(selector);
			if (target) {
				html += target.innerHTML;
			}
		});
	} else if (typeof selectors === 'function') {
		html = selectors();
	} else {
		const target = window.document.querySelector(selectors);
		if (target) {
			html = target.innerHTML;
		}
	}

	if (container && html) {
		window.document.body.classList.add('is-visually-hidden');
		Object.keys(replaceArray).forEach((key) => {
			html = html.replace(key, replaceArray[key]);
		});
		if (styles) {
			html += `<style>${styles}</style>`;
		}
		container.innerHTML = html;

		window.onafterprint = function () {
			window.document.body.classList.remove('is-visually-hidden');
			container.innerHTML = '';
			window.onafterprint = null;
		};

		window.print();
	}
};
