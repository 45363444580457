import { MainLayout } from 'layouts';
import { useMethods, useData } from 'utils';
import { Tabs } from 'antd';
import { FiscalCashboxTab, Title } from 'components';

export const CashboxPage = () => {
	const { __ } = useMethods();
	const { entrepreneurs = [] } = useData();

	return (
		<MainLayout seo={{ title: `${__('Касса')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				<Tabs.TabPane tab={__('Касса')} key="fiscal">
					{entrepreneurs.length ? <FiscalCashboxTab /> : <Title>{__('Пока нет ни одного предпринимателя')}</Title>}
				</Tabs.TabPane>
			</Tabs>
		</MainLayout>
	);
};
