import { useEffect, useState } from 'react';
import { FiscalCashboxFilter, FiscalCashboxTable, Preloader } from 'components';
import { getFiscalTransactionsAction, getFiscalTransactionTypesAction } from 'actions';
import { useMethods } from 'utils';

export const FiscalCashboxTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const fiscalTransactionsFiltersDefault = {
				entrepreneur_id: null,
				date: null,
				cash_shift_id: null
			};
			const fiscalTransactionsFilters = {
				...fiscalTransactionsFiltersDefault
			};
			const [fiscalTransactions, fiscalTransactionTypes] = await Promise.all([
				getFiscalTransactionsAction(),
				getFiscalTransactionTypesAction()
			]);
			setPending(false);
			update({
				fiscalTransactionsFiltersDefault,
				fiscalTransactionsFilters,
				fiscalTransactions,
				fiscalTransactionTypes
			});
		})();

		return () => {
			update({
				fiscalTransactionsFilters: {},
				fiscalTransactions: []
			});
		};
	}, []);

	return (
		<Preloader spinning={pending}>
			<FiscalCashboxFilter setPending={setPending} />
			<FiscalCashboxTable setPending={setPending} />
		</Preloader>
	);
};
