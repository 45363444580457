import { useEffect, useState } from 'react';
import { ClientsFilter, ClientsTable } from 'components';
import { getClientsAction } from 'actions';
import { useMethods } from 'utils';

export const ClientsTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const clientsFiltersDefault = {
				name: null,
				phone: null,
				card: null,
				address: null,
				last_order_date: [null, null],
				page: 1,
				per_page: 10
			};
			const clientsFilters = { ...clientsFiltersDefault };
			const clients = await getClientsAction(clientsFilters);
			setPending(false);
			update({
				clientsFiltersDefault,
				clientsFilters,
				clients
			});
		})();

		return () => {
			update({
				clientsFiltersDefault: {},
				clientsFilters: {},
				clients: {}
			});
		};
	}, []);

	return (
		<>
			<ClientsFilter setPending={setPending} />
			<ClientsTable pending={pending} setPending={setPending} />
		</>
	);
};
