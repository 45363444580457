import { useState, useEffect } from 'react';
import { Form, Select, Input, DatePicker } from 'antd';
import { useMethods, useData, dayjs, requiredRule, digitsRule, notify } from 'utils';
import { Modal, Preloader } from 'components';
import { dateTimeClientFormat, dateTimeServerFormat } from 'const';
import { getTransactionsAction, addTransactionAction, editTransactionAction } from 'actions';

export const FinancialTransaction = (props) => {
	const { children, transaction = {} } = props;
	const { id, employee_id, date, sum, type_id, description } = transaction;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { employees = [], transactionsFilters = {}, operationsTypes = [] } = useData();
	const { __, update } = useMethods();
	const operationsTypesList = operationsTypes.filter((item) => !item.private).map((item) => ({ label: item.name, value: item.id }));

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				employee_id: employee_id ? `${employee_id}` : null,
				type_id,
				sum: sum ? Math.abs(sum) : null,
				date: date ? dayjs(date, dateTimeServerFormat) : null,
				description
			});
		}
	}, [visible]);

	const submitHandler = async (values) => {
		const data = {
			...values,
			employee_id: +values.employee_id,
			date: values.date ? dayjs(values.date).format(dateTimeClientFormat) : null
		};
		setPending(true);
		const updateData = {};
		const success = id ? await editTransactionAction(id, data) : await addTransactionAction(data);
		updateData.transactions = await getTransactionsAction(transactionsFilters);
		setPending(false);
		update(updateData);
		if (success) {
			notify('success', id ? __('Операция сохранена') : __('Операция добавлена'));
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{id ? __('Редактирование операции') : __('Новая операция')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" label={__('ФИО сотрудника')} name="employee_id" required rules={[requiredRule(__)]}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									options={employees.map((item) => ({ label: item.name, value: String(item.id) }))}
									disabled={employee_id}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item className="form__item" name="type_id" label={__('Тип операции')} required rules={[requiredRule(__)]}>
								<Select dropdownClassName="form__dropdown" options={operationsTypesList} allowClear={true} />
							</Form.Item>
							<Form.Item className="form__item" name="sum" label={__('Сумма')} required rules={[requiredRule(__), digitsRule(__)]}>
								<Input allowClear={true} />
							</Form.Item>
							<Form.Item className="form__item" label={__('Дата и время операции')} name="date" required rules={[requiredRule(__)]}>
								<DatePicker
									format={dateTimeClientFormat}
									showTime={true}
									allowClear={true}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
								/>
							</Form.Item>
							<Form.Item
								className="form__item form__item--autosize"
								name="description"
								label={__('Примечание')}
								required
								rules={[requiredRule(__)]}
							>
								<Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Сохранить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
