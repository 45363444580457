import { notification } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';

notification.config({
	placement: 'bottomRight',
	duration: 4,
	closeIcon: <CloseIcon />
});

export const notify = (status, message) => {
	if (status === 'success') {
		notification.open({
			message: message,
			className: 'is-success'
		});
	} else if (status === 'error') {
		notification.open({
			message: message,
			className: 'is-error'
		});
	}
};
