import { access, formatDigits, useData, useMethods } from 'utils';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import { LogoutOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { DepositCashboxModal, WithdrawalCashboxModal } from 'components';
import { closeCashboxAction, getCurrentCashboxAction, openCashboxAction } from 'actions';

export const CashboxInfo = (props) => {
	const { setPending } = props;
	const { cashbox = {} } = useData();
	const { __, update } = useMethods();

	const closeCashboxHandler = async () => {
		setPending(true);
		const success = await closeCashboxAction();
		setPending(false);
		if (success) {
			update({
				cashbox: {}
			});
		}
	};

	const openCashboxHandler = async () => {
		setPending(true);
		const cashboxData = await openCashboxAction();
		setPending(false);
		update({
			cashbox: cashboxData
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const cashbox = await getCurrentCashboxAction();
		setPending(false);
		update({
			cashbox
		});
	};

	return (
		<div className="filter filter--cashbox">
			<div className="filter__form">
				<Row gutter={12}>
					{access('cashbox-open-close') && !cashbox.id ? (
						<Col style={{ marginBottom: '20px' }}>
							<button type="button" className="button button--main button--icon-left" onClick={openCashboxHandler}>
								<PlusCircleOutlined />
								{__('Открыть кассу')}
							</button>
						</Col>
					) : null}
					{!!cashbox.id ? (
						<>
							<Col>
								<Row gutter={12}>
									{access('cashbox-transaction-create') ? (
										<Col style={{ marginBottom: '20px' }}>
											<DepositCashboxModal>
												<button type="button" className="button button--success">
													{__('Внесение средств')}
												</button>
											</DepositCashboxModal>
										</Col>
									) : null}
									{access('cashbox-transaction-create') ? (
										<Col style={{ marginBottom: '20px' }}>
											<WithdrawalCashboxModal>
												<button type="button" className="button button--main">
													{__('Изъятие средств')}
												</button>
											</WithdrawalCashboxModal>
										</Col>
									) : null}
								</Row>
							</Col>
							<Col>
								<Row gutter={12}>
									<Col style={{ marginBottom: '20px' }}>
										<Tooltip title={__('Обновить')} placement="topRight">
											<button className="button button--main-border button--icon-small" onClick={updateHandler}>
												<ReloadOutlined />
											</button>
										</Tooltip>
									</Col>
								</Row>
							</Col>
						</>
					) : null}
				</Row>
			</div>
			{!!cashbox.id ? (
				<div className="filter__info">
					<div className="cashbox">
						<Row gutter={24}>
							<Col>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Идентификатор кассы')}:</span>
									<span className="cashbox__item-value">{cashbox.monthly_id}</span>
								</div>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Дата/время открытия')}:</span>
									<span className="cashbox__item-value">{cashbox.started_at}</span>
								</div>
								<div style={{ marginTop: '20px' }}>
									<Popconfirm
										placement="bottomLeft"
										title={__('Вы уверены, что хотите закрыть кассу?')}
										onConfirm={closeCashboxHandler}
										okText={__('Ок')}
										cancelText={__('Отменить')}
									>
										<button type="button" className="button button--main button--icon-left button--full">
											<LogoutOutlined />
											{__('Закрыть кассу')}
										</button>
									</Popconfirm>
								</div>
							</Col>
							<Col>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Остаток с прошлой смены')}:</span>
									<span className="cashbox__item-value">
										{formatDigits({ number: cashbox.pre, toFixed: 2, defaultValue: 0 })} {__('грн')}
									</span>
								</div>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Приход')}:</span>
									<span className="cashbox__item-value cashbox__item-value--inflow">
										{formatDigits({ number: cashbox.inflow, toFixed: 2, defaultValue: 0 })} {__('грн')}
									</span>
								</div>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Расход')}:</span>
									<span className="cashbox__item-value cashbox__item-value--outflow">
										{formatDigits({ number: cashbox.outflow, toFixed: 2, defaultValue: 0 })} {__('грн')}
									</span>
								</div>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Сумма в кассе')}:</span>
									<span className="cashbox__item-value">
										<b>
											{formatDigits({ number: cashbox.post, toFixed: 2, defaultValue: 0 })} {__('грн')}
										</b>
									</span>
								</div>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Терминал')}:</span>
									<span className="cashbox__item-value">
										<b>
											{formatDigits({ number: cashbox.terminal, toFixed: 2, defaultValue: 0 })} {__('грн')}
										</b>
									</span>
								</div>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Безналичный расчет')}:</span>
									<span className="cashbox__item-value">
										{formatDigits({ number: cashbox.cashless, toFixed: 2, defaultValue: 0 })} {__('грн')}
									</span>
								</div>
								<div className="cashbox__item">
									<span className="cashbox__item-label">{__('Всего')}:</span>
									<span className="cashbox__item-value">
										<b>
											{formatDigits({ number: cashbox.total, toFixed: 2, defaultValue: 0 })} {__('грн')}
										</b>
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			) : null}
		</div>
	);
};
