import { useEffect, useState } from 'react';
import { CashboxTable, CashboxInfo, Title, Preloader } from 'components';
import { getCurrentCashboxAction } from 'actions';
import { access, useMethods, useData } from 'utils';
import { LoadingOutlined } from '@ant-design/icons';

export const ActiveCashboxTab = () => {
	const { __, update } = useMethods();
	const { cashbox = {} } = useData();
	const [loading, setLoading] = useState(false);
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setLoading(true);
			const cashbox = await getCurrentCashboxAction();
			setLoading(false);
			update({
				cashbox
			});
		})();
	}, []);

	return loading ? (
		<button type="button" className="button button--main button--icon-left">
			<LoadingOutlined />
			{__('Проверка кассы')}
		</button>
	) : !access('cashbox-open-close') && !cashbox.id ? (
		<Title>{__('У вас нет прав просматривать этот раздел')}</Title>
	) : (
		<Preloader spinning={pending}>
			<CashboxInfo setPending={setPending} />
			<CashboxTable cashbox={cashbox} setPending={setPending} />
		</Preloader>
	);
};
