import { useEffect } from 'react';
import { Form, Row, Col, Select, Tooltip, Typography } from 'antd';
import { dateClientFormat, dateServerFormat } from 'const';
import { ClearOutlined, PlusOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import { print, useMethods, useData, downloadFile, dayjs, formatDigits } from 'utils';
import { getTransactionsAction, getEmployeesTransactionsStatisticAction } from 'actions';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';
import { FinancialTransaction, RangePickerField } from 'components';

export const EmployeesTransactionsFilter = (props) => {
	const { setPending } = props;
	const {
		employees = [],
		departments = [],
		positions = [],
		operationsTypes = [],
		transactionsFiltersDefault = {},
		transactionsFilters = {},
		transactionsStatistic = {}
	} = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(transactionsFiltersDefault);
	}, [transactionsFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...transactionsFilters,
			...values,
			page: 1
		};
		const [transactions, transactionsStatistic] = await Promise.all([
			getTransactionsAction(filter),
			getEmployeesTransactionsStatisticAction(filter)
		]);
		setPending(false);
		update({
			transactionsFilters: filter,
			transactions,
			transactionsStatistic
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(transactionsFiltersDefault);
		const [transactions, transactionsStatistic] = await Promise.all([getTransactionsAction(), getEmployeesTransactionsStatisticAction()]);
		setPending(false);
		update({
			transactionsFilters: transactionsFiltersDefault,
			transactions,
			transactionsStatistic
		});
	};

	const printTable = () => {
		print('#employees-transactions .ant-table-content', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};

	const updateHandler = async () => {
		setPending(true);
		const [transactions, transactionsStatistic] = await Promise.all([
			getTransactionsAction(transactionsFilters),
			getEmployeesTransactionsStatisticAction(transactionsFilters)
		]);
		setPending(false);
		update({
			transactions,
			transactionsStatistic
		});
	};

	const downloadXlsFile = (event) => {
		event.preventDefault();
		downloadFile(`/transactions/employees-transactions-export`, `transactions-employees.xls`, {
			...transactionsFilters,
			date:
				transactionsFilters.date && transactionsFilters.date.length
					? `${transactionsFilters.date[0].format(dateServerFormat)} - ${transactionsFilters.date[1].format(dateServerFormat)}`
					: null
		});
	};

	return (
		<>
			<div className="filter filter--transactions">
				<div className="filter__buttons">
					<div className="filter__button">
						<FinancialTransaction>
							<Tooltip title={__('Добавить операцию')} placement="topLeft">
								<button type="button" className="button button--main button--icon-small">
									<PlusOutlined />
								</button>
							</Tooltip>
						</FinancialTransaction>
					</div>
				</div>
				<div className="filter__form">
					<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
						<div className="filter__list">
							<div className="filter__item">
								<Form.Item className="form__item" label={__('ФИО сотрудника')} name="employee">
									<Select
										autoClearSearchValue={false}
										showArrow={false}
										notFoundContent={null}
										showSearch={true}
										optionFilterProp="label"
										dropdownClassName="form__dropdown"
										options={employees.map((item) => ({ label: item.name, value: item.id }))}
										allowClear={true}
										placeholder={__('Не выбрано')}
									/>
								</Form.Item>
							</div>
							<div className="filter__item">
								<Form.Item className="form__item" name="department" label={__('Отдел')}>
									<Select
										autoClearSearchValue={false}
										showArrow={false}
										notFoundContent={null}
										showSearch={true}
										optionFilterProp="label"
										dropdownClassName="form__dropdown"
										options={departments.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
							<div className="filter__item">
								<Form.Item className="form__item" name="position" label={__('Должность')}>
									<Select
										autoClearSearchValue={false}
										showArrow={false}
										notFoundContent={null}
										showSearch={true}
										optionFilterProp="label"
										dropdownClassName="form__dropdown"
										options={positions.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
							<div className="filter__item">
								<Form.Item className="form__item" name="types" label={__('Тип операции')}>
									<Select
										dropdownClassName="form__dropdown"
										options={operationsTypes.map((item) => ({ label: item.name, value: item.id }))}
										mode="multiple"
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
							<div className="filter__item filter__item--calendar">
								<Form.Item className="form__item" name="date" label={__('Период времени')}>
									<RangePickerField
										allowClear={true}
										format={dateClientFormat}
										showTime={false}
										separator="—"
										disabledDate={(current) => current && current.endOf('day') >= dayjs().endOf('day')}
									/>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
				<div className="filter__buttons">
					<div className="filter__button">
						<Tooltip title={__('Очистить фильтр')} placement="topRight">
							<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
								<ClearOutlined />
							</button>
						</Tooltip>
					</div>
					<div className="filter__button">
						<Tooltip title={__('Обновить')} placement="topRight">
							<button className="button button--main-border button--icon-small" onClick={updateHandler}>
								<ReloadOutlined />
							</button>
						</Tooltip>
					</div>
					<div className="filter__button filter__button--lg-show">
						<Tooltip title={__('Распечатать')} placement="topRight">
							<button className="button button--main-border button--icon-small" onClick={printTable}>
								<PrinterOutlined />
							</button>
						</Tooltip>
					</div>
					<div className="filter__button filter__button--lg-show">
						<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
							<a
								href="/public/transactions-employees.xls"
								className="button button--default button--icon-def"
								onClick={downloadXlsFile}
							>
								<XlsIcon />
							</a>
						</Tooltip>
					</div>
				</div>
			</div>
			<Row gutter={16}>
				<Col>
					<Typography.Title level={4} style={{ paddingTop: '10px' }}>
						{__('Всего начислено')}
						{': '}
						<span style={{ color: '#e31e24' }}>
							{formatDigits({ number: transactionsStatistic.accrual || 0, toFixed: 2 })} {__('грн')}
						</span>
					</Typography.Title>
				</Col>
				<Col>
					<Typography.Title level={4} style={{ paddingTop: '10px' }}>
						{__('Всего выплачено')}
						{': '}
						<span style={{ color: '#179e15' }}>
							{formatDigits({ number: transactionsStatistic.payout || 0, toFixed: 2 })} {__('грн')}
						</span>
					</Typography.Title>
				</Col>
			</Row>
		</>
	);
};
