import { validate } from 'indicative/validator';
import { phoneClear } from 'utils';
import { clientSearchAction } from 'actions';

export const isValidateForm = async (form) => {
	const values = form.getFieldsValue();
	let errors = {};
	try {
		errors = await form.validateFields(Object.keys(values));
	} catch (e) {
		errors = e || {};
	}
	const { errorFields = [] } = errors;
	return !!errorFields.length;
};

export const requiredRule = (__) => ({
	validator: (rule, value) =>
		new Promise((resolve, reject) => {
			if (!!value) {
				resolve(null);
			} else {
				reject(__('Это поле обязательно'));
			}
		})
});

export const digitsRule = (__) => ({
	validator: (rule, value) =>
		new Promise((resolve, reject) => {
			if (!value || /^[0-9.]+$/.test(value)) {
				resolve(null);
			} else {
				reject(__('Это поле содержит только числовое значение'));
			}
		})
});

export const emailRule = (__) => ({
	validator: ({ field }, value) =>
		new Promise((resolve, reject) => {
			validate(
				{
					[field]: value
				},
				{
					[field]: 'email'
				},
				{
					[`${field}.email`]: __('Email введен не корректно')
				}
			)
				.then(() => {
					resolve(null);
				})
				.catch((errors) => {
					reject(errors[0].message);
				});
		})
});

export const phoneClientRule = (__, form, index) => ({
	validator: async (rule, value) => {
		const phones = form.getFieldValue('phones');
		const val = phoneClear(value);
		if (val.length === 13) {
			if (String(phones[index]?.id).includes('_')) {
				const response = await clientSearchAction({
					phone: val
				});
				if (response.length) {
					throw new Error(__('Клиент с таким номером телефона уже есть'));
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			throw new Error(__('Телефон введен не корректно'));
		}
	}
});

export const filesizeRule = (__, num) => ({
	validator: (rule, value) => {
		let kb = 0;
		if (value && value.length) {
			for (let i = 0; i < value.length; i++) {
				kb += value[i].size;
			}
		}
		if (kb / 1024 / 1024 <= parseInt(num)) {
			return Promise.resolve();
		} else {
			return Promise.reject(__('Общий объем файлов не должен превышать {0}kB!').replace('{0}', num));
		}
	}
});

export const filesizeEachRule = (__, num) => ({
	validator: (rule, value) => {
		let flag = true;
		if (value && value.length) {
			for (let i = 0; i < value.length; i++) {
				if (value[i].size / 1024 / 1024 > parseInt(num)) {
					flag = false;
					break;
				}
			}
		}
		if (flag) {
			return Promise.resolve();
		} else {
			return Promise.reject(__('Объем одного файла не должен превышать {0}kB!').replace('{0}', num));
		}
	}
});

export const maxUploadRule = (__, num) => ({
	validator: (rule, value) => {
		if (value && value.length <= parseInt(num)) {
			return Promise.resolve();
		} else {
			return Promise.reject(__('Количество файлов не должно превышать {0}!').replace('{0}', num));
		}
	}
});

export const minUploadRule = (__, num) => ({
	validator: (rule, value) => {
		if (value && value.length >= parseInt(num)) {
			return Promise.resolve();
		} else {
			return Promise.reject(__('Минимальное количество файлов {0}!').replace('{0}', num));
		}
	}
});

export const filetypeRule = (__, types) => ({
	validator: (rule, value) => {
		let flag = true;
		if (value && value.length) {
			const exts = types.replace(/\s/g, '').replace(/,/g, '|');
			for (let i = 0; i < value.length; i++) {
				if (!new RegExp('.(' + exts + ')$', 'i').test(value[i].name)) {
					flag = false;
				}
			}
		}
		if (flag) {
			return Promise.resolve();
		} else {
			return Promise.reject(__('Допустимые расширения файлов: {0}').replace('{0}', types));
		}
	}
});

export const checkValue = async (field, value, rules) => {
	let error = '';
	for (const rule of rules) {
		if (typeof rule.validator === 'function') {
			try {
				await rule.validator({ field }, value);
			} catch (message) {
				error = message;
				break;
			}
		}
	}
	return error;
};

export const lessThenFieldRule = (form, name, text) => ({
	validator: (rule, value) => {
		if (value < form.getFieldValue(name)) {
			form.setFields([
				{
					[name]: {
						value: value
					}
				}
			]);
			return Promise.resolve();
		} else {
			return Promise.reject(text);
		}
	}
});

export const moreThenFiledRule = (form, name, text) => ({
	validator: (rule, value) => {
		if (value > form.getFieldValue(name)) {
			form.setFields([
				{
					[name]: {
						value: value
					}
				}
			]);
			return Promise.resolve();
		} else {
			return Promise.reject(text);
		}
	}
});

export const minLengthRule = (__, num) => ({
	validator: (rule, value) => {
		if (String(value).length >= num) {
			return Promise.resolve();
		} else {
			return Promise.reject(__('Пожалуйста, введите не менее {0} символов!').replace('{0}', num));
		}
	}
});

export const maxLengthRule = (__, num) => ({
	validator: (rule, value) => {
		if (String(value).length <= num) {
			return Promise.resolve();
		} else {
			return Promise.reject(__(`Пожалуйста, введите не более {0} символов!`).replace('{0}', num));
		}
	}
});
