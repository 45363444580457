import { useEffect } from 'react';
import { useMethods, useData, dayjs } from 'utils';
import { DatePicker, Form, Select, Tooltip } from 'antd';
import { getCouriersAction } from 'actions';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { dateClientFormat, dateServerFormat } from 'const';

export const CouriersFilter = (props) => {
	const { setPending } = props;
	const { couriersFiltersDefault = {}, couriersFilters = {}, couriers = [], courierOrderStatuses = [], tripStatuses = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(couriersFiltersDefault);
	}, [couriersFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...couriersFilters,
			...values
		};
		const couriersDate = await getCouriersAction({
			courier: filter.courier ? +filter.courier : null,
			order_status: filter.order_status ? +filter.order_status : null,
			trip_status: filter.trip_status ? +filter.trip_status : null,
			date: filter.date ? dayjs(filter.date).format(dateServerFormat) : null
		});
		setPending(false);
		update({
			couriersFilters: filter,
			couriersDate
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		const couriersDate = await getCouriersAction({
			courier: couriersFiltersDefault.courier ? +couriersFiltersDefault.courier : null,
			order_status: couriersFiltersDefault.order_status ? +couriersFiltersDefault.order_status : null,
			trip_status: couriersFiltersDefault.trip_status ? +couriersFiltersDefault.trip_status : null,
			date: couriersFiltersDefault.date ? dayjs(couriersFiltersDefault.date).format(dateServerFormat) : null
		});
		setPending(false);
		form.setFieldsValue(couriersFiltersDefault);
		update({
			couriersFilters: couriersFiltersDefault,
			couriersDate
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const couriersDate = await getCouriersAction({
			courier: couriersFilters.courier ? +couriersFilters.courier : null,
			order_status: couriersFilters.order_status ? +couriersFilters.order_status : null,
			trip_status: couriersFilters.trip_status ? +couriersFilters.trip_status : null,
			date: couriersFilters.date ? dayjs(couriersFilters.date).format(dateServerFormat) : null
		});
		setPending(false);
		update({
			couriersDate
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="courier" label={__('ФИО')}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									options={couriers.map((item) => ({ label: item.name, value: String(item.id) }))}
									allowClear={true}
									placeholder={__('Не выбрано')}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="order_status" label={__('Статус заказа')}>
								<Select
									dropdownClassName="form__dropdown"
									options={courierOrderStatuses.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="trip_status" label={__('Статус рейса')}>
								<Select
									dropdownClassName="form__dropdown"
									options={tripStatuses.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="date" label={__('Дата')}>
								<DatePicker
									format={dateClientFormat}
									allowClear={false}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
								/>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
