import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Preloader = (props) => {
	const { type, children, indicator = <LoadingOutlined />, ...spinProps } = props;
	return (
		<Spin className={`preloader${type ? ` preloader--${type}` : ''}`} size="large" indicator={indicator} {...spinProps}>
			{children}
		</Spin>
	);
};
