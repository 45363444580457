import { Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useData, useMethods } from 'utils';
import { Link } from 'components';
import { statusIcon } from 'const';

export const ActiveOrdersStatistic = () => {
	const { ordersFilters = {}, ordersStatistic = {}, orders = [], orderStatuses = [] } = useData();
	const { statuses = [], custom = [] } = ordersStatistic;
	const { __, update } = useMethods();

	const first = [];
	custom.forEach(({ alias, title, value }) => {
		first.push({
			alias,
			title,
			value,
			icon: statusIcon[0]
		});
	});

	const result = [];
	const allOrders = statuses.reduce(
		(accum, next) => {
			if (next.id === 12 || next.id === 8) {
				return accum;
			}
			accum.count += next.count;
			accum.sum += next.sum;
			return accum;
		},
		{ count: 0, sum: 0 }
	);
	result.push({
		alias: 'in_work',
		title: __('В работе'),
		value: `${allOrders.count} / ${allOrders.sum} ${__('грн')}`,
		icon: statusIcon['in_work']
	});

	const { preOrder, fromSite, fromApp, fromSiteClarify, selfDelivery } = orders.reduce(
		(a, e) => {
			if (e.is_pre_order) {
				a.preOrder.sum += e.price;
				a.preOrder.count++;
			}
			if (e.delivery_type_id === 2) {
				a.selfDelivery.sum += e.price;
				a.selfDelivery.count++;
			}
			if (e.is_from_site) {
				if (e.status_id === 1) {
					a.fromSiteClarify.sum += e.price;
					a.fromSiteClarify.count++;
				} else {
					a.fromSite.sum += e.price;
					a.fromSite.count++;
				}
			}
			if (e.is_from_mobile) {
				if (e.status_id === 1) {
					a.fromAppClarify.sum += e.price;
					a.fromAppClarify.count++;
				} else {
					a.fromApp.sum += e.price;
					a.fromApp.count++;
				}
			}
			return a;
		},
		{
			preOrder: { sum: 0, count: 0 },
			fromSite: { sum: 0, count: 0 },
			fromSiteClarify: { sum: 0, count: 0 },
			fromApp: { sum: 0, count: 0 },
			fromAppClarify: { sum: 0, count: 0 },
			selfDelivery: { sum: 0, count: 0 }
		}
	);
	result.push({
		alias: 'self_delivery',
		title: __('Самовывоз'),
		value: `${selfDelivery.count} / ${selfDelivery.sum} ${__('грн')}`,
		icon: statusIcon['delivery-self']
	});
	result.push({
		alias: 'is_pre_order',
		title: __('Предзаказ'),
		value: `${preOrder.count} / ${preOrder.sum} ${__('грн')}`,
		icon: statusIcon[11]
	});
	result.push({
		alias: 'is_from_site',
		title: __('С сайта'),
		value: `${fromSite.count} / ${fromSite.sum} ${__('грн')}`,
		icon: statusIcon[0]
	});
	result.push({
		alias: 'is_from_mobile',
		title: __('С приложения'),
		value: `${fromApp.count} / ${fromApp.sum} ${__('грн')}`,
		icon: statusIcon['is_from_mobile']
	});
	result.push({
		alias: 'is_from_site_clarify',
		title: __('С сайта - уточнить'),
		value: `${fromSiteClarify.count} / ${fromSiteClarify.sum} ${__('грн')}`,
		icon: statusIcon['is_from_site']
	});

	statuses.forEach((item) => {
		if (item.id !== 11) {
			const statusItem = orderStatuses.find((status) => status.id === item.id);
			if (statusItem) {
				result.push({
					alias: item.id,
					title: statusItem.name,
					value: `${item.count} / ${item.sum} ${__('грн')}`,
					icon: statusIcon[+item.id] || null
				});
			}
		}
	});

	const changeHandler = (status) => {
		update({
			ordersFilters: {
				...ordersFilters,
				status
			}
		});
	};

	return (
		<div className="orders-statistic">
			<div className="orders-statistic__button">
				<Tooltip title={__('Создать заказ')} placement="bottomLeft">
					<Link href="/orders/create" className="button button--main button--icon-small">
						<PlusOutlined />
					</Link>
				</Tooltip>
			</div>
			<div className="orders-statistic__list">
				{first.map(({ alias, title, value, icon }, index) => (
					<div className="orders-statistic__item" key={index}>
						<Tag className="orders-statistic__tag is-not-active">
							{icon}
							<span>{title}:</span>
							<strong>{value}</strong>
						</Tag>
					</div>
				))}
				{result.map(({ alias, title, value, icon }, index) => (
					<div className="orders-statistic__item" key={index}>
						<Tag
							className={`orders-statistic__tag${ordersFilters.status === alias ? ' is-active' : ''}`}
							onClick={() => changeHandler(alias)}
						>
							{icon}
							<span>{title}:</span>
							<strong>{value}</strong>
						</Tag>
					</div>
				))}
			</div>
		</div>
	);
};
