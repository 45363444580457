import { useEffect } from 'react';
import { Form, Row, Col, Input, Select, Tooltip, DatePicker, InputNumber, Checkbox } from 'antd';
import { getAddresses, getPhones, requiredRule, useMethods, useData, floatFormatter, access, dayjs, nameFormatter, notify } from 'utils';
import { ArrowLeftOutlined, EditOutlined, PhoneOutlined } from '@ant-design/icons';
import { AddressesChange, Link, PhonesChange, Preloader } from 'components';
import { dateClientFormat, dateServerFormat } from 'const';
import { createClientAction, sipCallAction, updateClientAction } from 'actions';
import { useHistory } from 'react-router-dom';

export const ClientForm = (props) => {
	const { pending, setPending } = props;
	const { clientId, oldClient = {}, client = {}, sipWork } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const history = useHistory();

	const phoneList = getPhones(client.phones);
	const addressList = getAddresses(client.addresses);

	useEffect(() => {
		if (clientId) {
			const mainPhone = (oldClient.phones || []).find((item) => item.main)?.id;
			const mainAddress = (oldClient.addresses || []).find((item) => item.main)?.id;
			const firstPhone = oldClient.phones && oldClient.phones.length ? oldClient.phones[0].id : null;
			const firstAddress = oldClient.addresses && oldClient.addresses.length ? oldClient.addresses[0].id : null;

			form.setFieldsValue({
				...oldClient,
				phone_id: mainPhone || firstPhone,
				address_id: mainAddress || firstAddress,
				key_date: oldClient.key_date ? dayjs(oldClient.key_date, dateServerFormat) : null,
				birthday: oldClient.birthday ? dayjs(oldClient.birthday, dateServerFormat) : null
			});
		}
	}, [oldClient]);

	const submitHandler = async (values) => {
		setPending(true);
		const data = {
			name: values.name,
			email: values.email,
			key_date: values.key_date ? dayjs(values.key_date).format(dateServerFormat) : null,
			birthday: values.birthday ? dayjs(values.birthday).format(dateServerFormat) : null,
			discount_percent: Math.abs(+values.discount_percent),
			referral_link: values.referral_link,
			channel_of_information: values.channel_of_information,
			notes: values.notes,
			subscribed: !!values.subscribed,
			registered: !!values.registered,
			made_an_order: !!values.made_an_order,
			blacklisted: !!values.blacklisted,
			phones: (client.phones || []).map((item) => ({
				...item,
				main: !!(values.phone_id && item.id === values.phone_id),
				id: String(item.id).includes('_') ? null : item.id
			})),
			addresses: (client.addresses || []).map((item) => ({
				...item,
				main: !!(values.address_id && item.id === values.address_id),
				id: String(item.id).includes('_') ? null : item.id
			}))
		};
		const clientData = clientId ? await updateClientAction(clientId, data) : await createClientAction(data);
		setPending(false);
		if (clientData.id) {
			if (!clientId) {
				notify('success', __('Клиент добавлен'));
				history.push('/clients');
			} else {
				notify('success', __('Клиент обновлен'));
				update({
					client: clientData,
					oldClient: clientData
				});
			}
		}
	};

	const changePhonesHandler = (phones = []) => {
		const data = form.getFieldsValue();
		const phoneIdExist = phones.find((item) => item === data.phone_id);
		if (!phoneIdExist) {
			form.setFieldsValue({
				...data,
				phone_id: phones.length ? phones[0].id : null
			});
		}
	};

	const changeAddressesHandler = (addresses = [], index = null) => {
		const data = form.getFieldsValue();
		let addressId = null;
		if (index !== null && addresses.length && addresses[index]) {
			addressId = addresses[index].id;
		} else {
			const addressIdExist = addresses.find((item) => item.id === data.address_id);
			if (!addressIdExist && addresses.length) {
				addressId = addresses[0].id;
			}
		}
		if (addressId) {
			form.setFieldsValue({
				...data,
				address_id: addressId
			});
		}
	};

	const phoneCallHandler = async () => {
		const { phone_id } = form.getFieldsValue();
		const phone = phoneList.find((item) => item.value === phone_id)?.label;
		if (phone) {
			await sipCallAction(phone);
		}
	};

	return (
		<>
			<Row style={{ marginBottom: '20px' }}>
				<Col>
					<Link href="/clients" className="button button--full button--main">
						<ArrowLeftOutlined />
						<span>{__('Вернуться назад')}</span>
					</Link>
				</Col>
			</Row>
			<Preloader spinning={pending}>
				<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
					<Row gutter={12}>
						<Col span={24} md={12} xxl={6}>
							<Form.Item
								className="form__item"
								name="name"
								label={__('ФИО')}
								normalize={nameFormatter}
								required
								rules={[requiredRule(__)]}
							>
								<Input allowClear={true} />
							</Form.Item>
						</Col>
						<Col span={24} md={12} xxl={6}>
							<Row gutter={12} style={{ flexWrap: 'nowrap' }}>
								<Col style={{ width: 'calc(100% - 52px * 2)' }}>
									<Form.Item className="form__item" name="phone_id" label={__('Телефон')} required rules={[requiredRule(__)]}>
										<Select dropdownClassName="form__dropdown" options={phoneList} />
									</Form.Item>
								</Col>
								<Col style={{ flexShrink: 0, marginTop: '25px' }}>
									{!sipWork || !phoneList.length ? (
										<button type="button" className="button button--icon-small button--main" disabled={true}>
											<PhoneOutlined />
										</button>
									) : (
										<Tooltip placement="topRight" title={__('Позвонить')}>
											<button type="button" className="button button--icon-small button--main" onClick={phoneCallHandler}>
												<PhoneOutlined />
											</button>
										</Tooltip>
									)}
								</Col>
								<Col style={{ flexShrink: 0, marginTop: '25px' }}>
									<PhonesChange onChange={changePhonesHandler}>
										<Tooltip placement="topRight" title={__('Редактировать список телефонов')}>
											<button type="button" className="button button--icon-small button--main">
												<EditOutlined />
											</button>
										</Tooltip>
									</PhonesChange>
								</Col>
							</Row>
						</Col>
						<Col span={24} md={12} xxl={6}>
							<Form.Item className="form__item" name="key_date" label={__('Значимая дата')}>
								<DatePicker format={dateClientFormat} allowClear={true} />
							</Form.Item>
						</Col>
						<Col span={24} md={12} xxl={6}>
							<Form.Item className="form__item" name="birthday" label={__('День Рождения')}>
								<DatePicker format={dateClientFormat} allowClear={true} />
							</Form.Item>
						</Col>
						<Col span={24} md={12} xxl={6}>
							<Form.Item className="form__item" name="email" label="Email">
								<Input type="email" allowClear={true} />
							</Form.Item>
						</Col>
						<Col span={24} md={12} xxl={6}>
							<Form.Item className="form__item" name="discount_percent" label={__('Скидка')}>
								<InputNumber min={0} formatter={floatFormatter} />
							</Form.Item>
						</Col>
						<Col span={24} md={12} xxl={6}>
							<Form.Item className="form__item" name="referral_link" label={__('Реферальная ссылка')}>
								<Input allowClear={true} />
							</Form.Item>
						</Col>
						<Col span={24} md={12} xxl={6}>
							<Form.Item className="form__item" name="channel_of_information" label={__('Источник информации')}>
								<Input allowClear={true} />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Row gutter={12} style={{ flexWrap: 'nowrap' }}>
								<Col style={{ width: 'calc(100% - 52px)' }}>
									<Form.Item className="form__item" name="address_id" label={__('Адрес')}>
										<Select dropdownClassName="form__dropdown" options={addressList} />
									</Form.Item>
								</Col>
								<Col style={{ flexShrink: 0, marginTop: '25px' }}>
									<AddressesChange onChange={changeAddressesHandler}>
										<Tooltip placement="topRight" title={__('Редактировать список адресов')}>
											<button type="button" className="button button--icon-small button--main">
												<EditOutlined />
											</button>
										</Tooltip>
									</AddressesChange>
								</Col>
							</Row>
						</Col>
						<Col span={24} xl={12}>
							<Form.Item className="form__item form__item--textarea-client" name="notes" label={__('Примечание')}>
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={24} xl={12}>
							<Form.Item className="form__item form__item--checkbox" name="subscribed" valuePropName="checked">
								<Checkbox>{__('Согласен с рассылкой сообщений')}</Checkbox>
							</Form.Item>
							<Form.Item className="form__item form__item--checkbox" name="registered" valuePropName="checked">
								<Checkbox>{__('Зарегистрирован на сайте')}</Checkbox>
							</Form.Item>
							<Form.Item className="form__item form__item--checkbox" name="made_an_order" valuePropName="checked">
								<Checkbox>{__('Делал заказ на сайте')}</Checkbox>
							</Form.Item>
							<Form.Item className="form__item form__item--checkbox" name="blacklisted" valuePropName="checked">
								<Checkbox>{__('В черном списке')}</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={12} justify="end" style={{ marginTop: '50px' }}>
						<Col>
							<Link href="/clients" className="button button--main-border">
								<span>{__('Отменить')}</span>
							</Link>
						</Col>
						{(clientId && access('clients-update')) || (!clientId && access('clients-create')) ? (
							<Col>
								<button className="button button--main">{clientId ? __('Сохранить') : __('Создать')}</button>
							</Col>
						) : null}
					</Row>
				</Form>
			</Preloader>
		</>
	);
};
