import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { refreshTokenAction } from 'actions';
import { getCookie, getLocale } from 'utils';

window.Pusher = Pusher;
const SOCKET_DEBUG = process.env.NODE_ENV === 'development';
const PREFIX_PRIVATE = 'private-';
const PREFIX_ENCRYPTED = 'encrypted-';
const IS_ENCRYPTED = process.env.REACT_APP_WS_ENCRYPTED && process.env.REACT_APP_WS_ENCRYPTED === 'true';

const SocketInstance = {
	echo: null,
	channels: {}
};

let refreshProcess = null;
const refresh = () => {
	if (!refreshProcess) {
		refreshProcess = refreshTokenAction();
	}
	return refreshProcess.then((result) => {
		refreshProcess = null;
		return result;
	});
};

SocketInstance.subscribe = (channelName) => {
	const channelNameFull = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	return new Promise((resolve) => {
		if (SocketInstance.echo && SocketInstance.echo.connector.pusher.connection.state === 'connected') {
			if (!SocketInstance.channels[channelNameFull]) {
				const pusherChannel = SocketInstance.echo.connector.pusher.subscribe(channelNameFull);
				pusherChannel.bind('pusher:subscription_succeeded', function () {
					if (SOCKET_DEBUG) console.log(`socket subscribed '${channelNameFull}'`);
					SocketInstance.channels[channelNameFull] = pusherChannel;
					resolve(SocketInstance.channels[channelNameFull]);
				});
				pusherChannel.bind('pusher:subscription_error', function (error) {
					if (SOCKET_DEBUG) console.error(`socket not subscribed '${channelNameFull}'`);
					if (error.status === 401) {
						refresh().then(({ success }) => {
							if (success && SocketInstance.echo?.options?.auth?.headers) {
								SocketInstance.echo.options.auth.headers.Authorization = getCookie('accessToken', '');
								resolve(SocketInstance.subscribe(channelName));
							} else {
								SocketInstance.channels[channelNameFull] = null;
								resolve(null);
							}
						});
					} else {
						SocketInstance.channels[channelNameFull] = null;
						resolve(null);
					}
				});
			} else {
				resolve(SocketInstance.channels[channelNameFull]);
			}
		} else {
			resolve(null);
		}
	});
};

SocketInstance.unsubscribe = async (channelName) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (SocketInstance.echo && SocketInstance.echo.connector.pusher.connection.state === 'connected') {
		if (SocketInstance.channels[channelName]) {
			await SocketInstance.echo.connector.pusher.unsubscribe(channelName);
			SocketInstance.channels[channelName] = null;
			if (SOCKET_DEBUG) console.log(`socket unsubscribed '${channelName}'`);
		}
	}
};

SocketInstance.emit = (channelName, eventName, data) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (SocketInstance.channels[channelName]) {
		if (SOCKET_DEBUG) console.log(`socket emit '${eventName}'`, data);
		SocketInstance.channels[channelName].trigger(eventName, data);
	}
};

SocketInstance.on = (channelName, eventName, callback) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (SocketInstance.channels[channelName]) {
		SocketInstance.channels[channelName].bind(eventName, (response) => {
			if (SOCKET_DEBUG) console.log(`socket on '${eventName}'`, response);
			callback(response);
		});
	}
};

SocketInstance.off = async (channelName, eventName) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (SocketInstance.channels[channelName]) {
		await SocketInstance.channels[channelName].unbind(eventName);
		if (SOCKET_DEBUG) console.log(`socket off '${eventName}'`);
	}
};

SocketInstance.disconnect = async () => {
	if (SocketInstance.echo && SocketInstance.echo.connector.pusher.connection.state === 'connected') {
		await SocketInstance.echo.connector.pusher.disconnect();
		SocketInstance.channels = {};
	}
};

export const WS = () => {
	return new Promise((resolve) => {
		if (!SocketInstance.echo) {
			const options = {
				broadcaster: 'pusher',
				key: process.env.REACT_APP_WS_PUSHER_KEY,
				wsHost: process.env.REACT_APP_WS_API_URL,
				wsPort: process.env.REACT_APP_WS_API_PORT,
				authEndpoint: process.env.REACT_APP_WS_API_AUTH,
				disableStats: true,
				enabledTransports: ['ws', 'wss'],
				auth: {
					headers: {
						Accept: 'application/json',
						'Accept-Language': getLocale(),
						Authorization: getCookie('accessToken', '')
					}
				}
			};

			if (IS_ENCRYPTED) {
				options.encrypted = true;
			}

			SocketInstance.echo = new Echo(options);
			SocketInstance.echo.connector.pusher.connection.bind('connected', () => {
				if (SOCKET_DEBUG) console.log('socket connected');
				resolve(SocketInstance);
			});
			SocketInstance.echo.connector.pusher.connection.bind('error', (error) => {
				if (SOCKET_DEBUG) console.log('socket error', error.error?.data?.message);
				SocketInstance.echo = null;
				SocketInstance.channels = {};
				resolve(SocketInstance);
			});
			SocketInstance.echo.connector.pusher.connection.bind('disconnected', () => {
				if (SocketInstance.echo) {
					if (SOCKET_DEBUG) console.log('socket disconnected');
					SocketInstance.echo = null;
					SocketInstance.channels = {};
				}
			});
		} else {
			resolve(SocketInstance);
		}
	});
};
