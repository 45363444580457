import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { useMethods, useData, formatDigits, dayjs } from 'utils';

export const DocsSales = (props) => {
	const { pending } = props;
	const { docsSales = {} } = useData();
	const { dates = [], categories = [] } = docsSales;
	const { __ } = useMethods();

	let width = 200;
	const columns = [
		{
			title: __('Блюдо'),
			width: 200,
			dataIndex: 'title',
			sorter: (a, b) => a.title.localeCompare(b.title)
		}
	];
	if (dates.length > 1) {
		columns.push({
			title: `${__('Всего от')} ${dayjs(dates[0]).format('DD.MM')} ${__('по')} ${dayjs(dates[dates.length - 1]).format('DD.MM')}`,
			children: [
				{
					title: __('Сумма'),
					dataIndex: 'all_amount',
					width: 100,
					sorter: (a, b) => a.all_amount - b.all_amount,
					key: 'all_amount',
					render: (sum) => {
						return formatDigits({ number: sum, toFixed: 2, defaultValue: 0 });
					}
				},
				{
					title: __('Кол-во товар'),
					dataIndex: 'all_count',
					width: 100,
					key: 'all_count',
					sorter: (a, b) => a.all_count - b.all_count
				},
				{
					title: __('Кол-во заказ'),
					dataIndex: 'all_order_count',
					width: 100,
					key: 'all_order_count',
					sorter: (a, b) => a.all_order_count - b.all_order_count
				}
			]
		});
		width += 300;
	}

	dates.forEach((item) => {
		columns.push({
			title: dayjs(item).format('DD.MM'),
			children: [
				{
					title: __('Сумма'),
					dataIndex: item + '_amount',
					width: 100,
					sorter: (a, b) => a[item + '_amount'] - b[item + '_amount'],
					key: item + '_amount',
					render: (sum) => {
						return formatDigits({ number: sum, toFixed: 2, defaultValue: 0 });
					}
				},
				{
					title: __('Кол-во товар'),
					dataIndex: item + '_count',
					width: 100,
					key: item + '_count',
					sorter: (a, b) => a[item + '_count'] - b[item + '_count']
				},
				{
					title: __('Кол-во заказ'),
					dataIndex: item + '_order_count',
					width: 100,
					key: item + '_order_count',
					sorter: (a, b) => a[item + '_order_count'] - b[item + '_order_count']
				}
			]
		});
		width += 300;
	});

	const list = [];
	categories.forEach((category) => {
		let itemCategory = {
			isFoot: true,
			id: category.title,
			title: category.title,
			children: [],
			all_amount: 0,
			all_count: 0,
			all_order_count: 0
		};
		if (category.items.length) {
			dates.forEach((date) => {
				const { amount = 0, count = 0, order_count = 0 } = category.dates.find((key) => key.date === date) || {};

				itemCategory[date + '_amount'] = amount;
				itemCategory[date + '_count'] = count;
				itemCategory[date + '_order_count'] = order_count;

				itemCategory.all_amount += parseInt(amount);
				itemCategory.all_count += parseInt(count);
				itemCategory.all_order_count += parseInt(order_count);
			});
		}
		category.items.forEach((product) => {
			let item = {
				title: product.title,
				id: product.title,
				all_amount: 0,
				all_count: 0,
				all_order_count: 0
			};
			dates.forEach((date) => {
				const { amount = 0, count = 0, order_count = 0 } = product.dates.find((key) => key.date === date) || {};

				item[date + '_amount'] = amount;
				item[date + '_count'] = count;
				item[date + '_order_count'] = order_count;

				item.all_amount += parseInt(amount);
				item.all_count += parseInt(count);
				item.all_order_count += parseInt(order_count);
			});
			itemCategory.children.push(item);
		});
		list.push(itemCategory);
	});

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: width
			}}
			rowClassName={(record) => (record.isFoot ? 'is-foot' : '')}
			columns={columns}
			dataSource={list}
			pagination={false}
		/>
	);
};
