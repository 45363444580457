import { useMethods, useData, dayjs } from 'utils';
import { dateClientFormat, timeClientFormat } from 'const';

export const CashWarrant = (props) => {
	const { isAdd } = props;
	const { user = {}, restaurantSettings = {} } = useData();
	const { __ } = useMethods();

	return (
		<div className="document document--cash-warrant">
			<div className="document__head">
				<div className="document__select">{restaurantSettings.invoice_name}</div>
				<div className="document__help">({__('Найменування підприємства')})</div>
			</div>
			<br />
			<div>
				{__('Квитанція до')} {isAdd ? __('прибуткового') : __('видаткового')} {__('касового ордеру')} №{' '}
				<span className="document__select">[NUMBER]</span>
				<br />
				{__('від')} <span className="document__select">{dayjs().format(dateClientFormat)}</span> року
			</div>
			<br />
			<div>
				{isAdd ? __('Прийнято від') : __('Видано')}: <span className="document__select">[NAME]</span>
			</div>
			<br />
			<div>
				{__('Підстава')}: <span className="document__select">[DESCRIPTION]&nbsp;</span>
			</div>
			<br />
			<div>
				{__('Сума')}: <span className="document__select">[SUM]</span>
			</div>
			<br />
			<div style={{ marginLeft: '1cm' }}>{__('М. П.')}</div>
			<br />
			<div>
				{__('Касир')}:{' '}
				<span className="document__underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{' '}
				<span className="document__select">{user.short_name || user.name || ''}</span>
			</div>
			<br />
			<div>
				{__('Час транзакції')}: {dayjs().format(timeClientFormat)}
			</div>
		</div>
	);
};
