import { useEffect, useState } from 'react';
import { useMethods, makeYears, makeMonths, dayjs, useData } from 'utils';
import { DocsDishesFilter, DocsDishes } from 'components';
import { getRestaurantsAction, getReportsDishesAction } from 'actions';

export const DocsDishesTab = () => {
	const { user = {} } = useData();
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const restaurants = await getRestaurantsAction();
			const docsDishesFiltersDefault = {
				restaurant_id: null,
				category_ids: [],
				product_name: null,
				group: null,
				month: +dayjs().format('M'),
				year: +dayjs().format('YYYY')
			};
			const docsDishesFilters = {
				...docsDishesFiltersDefault,
				restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id
			};
			const docsDishes = await getReportsDishesAction(docsDishesFilters);
			setPending(false);
			update({
				docsDishesFiltersDefault,
				docsDishesFilters,
				restaurants,
				docsDishes,
				years: makeYears(),
				months: makeMonths(__)
			});
		})();

		return () => {
			update({
				docsDishesFiltersDefault: {},
				docsDishesFilters: {},
				docsDishes: []
			});
		};
	}, []);

	return (
		<>
			<DocsDishesFilter setPending={setPending} />
			<DocsDishes pending={pending} />
		</>
	);
};
