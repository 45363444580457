import { dayjs, phoneFormat } from 'utils';
import { dateServerFormat, timeServerFormat } from 'const';

export const checkOrderForm = (order = {}, client = {}) => {
	let result = true;

	if (!((order.products && order.products.length) || (order.nomenclatures && order.nomenclatures.length))) {
		result = false;
	}

	const requires = [
		'status_id',
		'order_type_id',
		'delivery_type_id',
		'payment_type_id',
		'discount_id',
		'phone_id',
		'delivery_date',
		'delivery_time'
	];
	requires.forEach((key) => {
		if (order[key] === undefined || order[key] === null) {
			result = false;
		}
	});

	if (!client.name || !client.phones || !client.addresses) {
		result = false;
	}

	if (order.status_id === 10 && !order.rejection_reason) {
		result = false;
	}

	if (order.delivery_type_id === 1 && (order.address_id === undefined || order.address_id === null)) {
		result = false;
	}

	return result;
};

export const getOrderStatuses = (orderStatuses = [], orderTypes = [], orderStatusId, orderTypeId, deliveryTypeId) => {
	const currentStatus = orderStatuses.find((item) => +orderStatusId === item.id);
	const allowedOrderStatuses = orderStatuses.find((item) => +orderStatusId === item.id)?.statuses_allowed;
	const allowedOrderTypes = orderTypes.find((item) => +orderTypeId === item.id)?.statuses_allowed;
	let statuses = orderStatuses
		.filter((item) => {
			return (
				allowedOrderStatuses &&
				allowedOrderStatuses.includes(+item.id) &&
				(allowedOrderTypes ? allowedOrderTypes.includes(+item.id) : true) &&
				(deliveryTypeId ? item.delivery_types.includes(+deliveryTypeId) : true)
			);
		})
		.map((item) => ({
			value: item.id,
			label: item.name
		}));
	statuses = statuses.filter((item) => item.value !== orderStatusId);
	const currentList = currentStatus ? [{ value: currentStatus.id, label: currentStatus.name }] : [];
	return currentList.concat(statuses);
};

export const getOrderTypes = (orderTypes = [], __) => {
	return orderTypes.map((option) => {
		let isDisabled = false;
		let suffix = '';
		if (option.time_dependent) {
			const timeNow = dayjs();
			const timeStart = dayjs(option.time_dependent_start, timeServerFormat);
			const timeStartNext = dayjs(option.time_dependent_start, timeServerFormat).add(1, 'days');
			const timeFinish = dayjs(option.time_dependent_finish, timeServerFormat);
			if (timeNow.isBefore(timeStart)) {
				isDisabled = true;
				suffix = ` (${__('до активации')} ${dayjs.duration(timeStart.diff(timeNow, 'second'), 'seconds').humanize()})`;
			}
			if (timeNow.isAfter(timeFinish)) {
				isDisabled = true;
				suffix = ` (${__('до активации')} ${dayjs.duration(timeStartNext.diff(timeFinish, 'second'), 'seconds').humanize()})`;
			}
		}
		if (option.weekdays_dependent && option.weekdays_allowed) {
			const weekNow = dayjs().day();
			if (!option.weekdays_allowed.includes(weekNow)) {
				isDisabled = true;
				let weekDay = null;
				option.weekdays_allowed.forEach((item) => {
					if (weekDay === null && item > weekNow) {
						weekDay = item;
					}
				});
				if (weekDay === null) {
					weekDay = option.weekdays_allowed[0];
				}
				suffix = ` (${__('будет активным в')} ${dayjs().day(weekDay).format('dddd')})`;
			}
		}
		return {
			value: option.id,
			label: `${option.name}${suffix}`,
			disabled: isDisabled
		};
	});
};

export const getPaymentTypes = (paymentTypes = []) => {
	return paymentTypes.map((item) => ({
		value: item.id,
		label: item.name
	}));
};

export const getOrderSales = (orderSales = [], orderTypes = [], orderTypeId, __) => {
	const orderTypeSale = orderSales && orderTypes?.order_sale_id && orderSales?.[orderTypes.order_sale_id]?.value;
	const sales = orderSales
		.filter((sale) => {
			let result = true;
			if (sale.type === 1) {
				result = false;
			}
			const orderType = orderTypes.find((item) => item.id === orderTypeId);
			if (orderType) {
				const orderTypeSale = orderSales.find((item) => item.id === orderType.order_sale_id);
				if (
					orderTypeSale &&
					(!orderTypeSale.is_others_allowed || (orderTypeSale.allowed_list && !orderTypeSale.allowed_list.includes(sale.id)))
				) {
					result = false;
				}
			}
			return result;
		})
		.map((sale) => ({
			value: sale.id,
			label: orderTypeSale ? `${sale.value + orderTypeSale} %` : sale.name
		}));
	return [
		{
			value: 0,
			label: orderTypeSale ? `${__('Скидка')} ${orderTypeSale}%` : __('Без скидки')
		},
		...sales
	];
};

export const getDeliveryTypes = (deliveryTypes = [], orderTypes = [], orderTypeId) => {
	return deliveryTypes
		.filter((item) => {
			const allowedOrderTypes = orderTypes.find((item) => +orderTypeId === item.id)?.delivery_types;
			return allowedOrderTypes ? allowedOrderTypes.includes(+item.id) : true;
		})
		.map((item) => ({
			value: item.id,
			label: item.name
		}));
};

export const getPhones = (phones = []) => {
	return phones.map((item) => ({
		value: item.id,
		label: phoneFormat(item.value)
	}));
};

export const getAddresses = (addresses = []) => {
	return addresses.map((item) => ({
		value: item.id,
		label: item.title
	}));
};

export const getDeliveryTimes = (deliveryTimes = [], deliveryDate, __) => {
	return deliveryTimes.map((item, index) => {
		return {
			value: item,
			label: index === 0 && deliveryDate === dayjs().format(dateServerFormat) ? `${__('Ближайшее')} (${item})` : item
		};
	});
};

export const getCouriers = (couriers = []) => {
	return couriers.map((item) => ({
		value: item.id,
		label: item.name
	}));
};
