import { WebSocketInterface, UA } from 'jssip';
import callingAudio from 'assets/audio/calling.mp3';
import { phoneClearFull, getLocalStorage } from 'utils';
import { sipDoneAction, sipIncomeAction, sipOutgoingAction } from 'actions';

const SIP_DEBUG = process.env.NODE_ENV === 'development';

let SipInstance = {
	work: false,
	socket: null,
	ua: null,
	localStream: null,
	session: null,
	isDisconnect: false,
	online: getLocalStorage('sipOnlineStatus', true),
	bellDeviceId: undefined,
	callDeviceId: undefined,
	config: {
		pcConfig: {
			iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }]
		},
		mediaConstraints: {
			audio: {
				deviceId: undefined
			},
			video: false
		}
	}
};

SipInstance.call = async (number) => {
	if (SIP_DEBUG) console.log(`SIP call`);

	const audio = window.document.getElementById('sip-audio');
	if (SipInstance.work && number && audio) {
		if (SipInstance.callDeviceId) {
			await audio.setSinkId(SipInstance.callDeviceId);
		}
		audio.src = callingAudio;

		SipInstance.ua.call(phoneClearFull(number), SipInstance.config);
	}
};

SipInstance.end = () => {
	if (SipInstance.work && SipInstance.session) {
		if (SIP_DEBUG) console.log(`SIP end`);
		SipInstance.session.terminate();
	}
};

SipInstance.answer = async () => {
	if (SipInstance.work && SipInstance.session) {
		if (SIP_DEBUG) console.log(`SIP answer`);

		const audio = window.document.getElementById('sip-audio');
		if (SipInstance.callDeviceId) {
			await audio.setSinkId(SipInstance.callDeviceId);
		}

		SipInstance.session.answer(SipInstance.config);
	}
};

SipInstance.cancel = () => {
	if (SipInstance.work && SipInstance.session) {
		if (SIP_DEBUG) console.log(`SIP cancel`);
		SipInstance.session.terminate();
	}
};

SipInstance.disconnect = () => {
	if (SipInstance.work && SipInstance.socket) {
		if (SIP_DEBUG) console.log(`SIP disconnect`);
		SipInstance.ua.unregister({
			all: true
		});
		SipInstance.socket.disconnect();
	}
};

SipInstance.register = () => {
	if (SipInstance.work && SipInstance.socket) {
		SipInstance.ua.register();
	}
};

SipInstance.changeStatus = (onlineStatus) => {
	if (SipInstance.work && SipInstance.socket) {
		SipInstance.online = onlineStatus;
	}
};

SipInstance.changeMicDevice = (deviceId) => {
	if (SipInstance.work && SipInstance.socket) {
		if (SipInstance.config.mediaConstraints.audio.deviceId) {
			SipInstance.config.mediaConstraints.audio.deviceId.exact = deviceId;
		} else {
			SipInstance.config.mediaConstraints.audio.deviceId = {
				exact: deviceId
			};
		}
	}
};

SipInstance.changeCallDevice = (deviceId) => {
	if (SipInstance.work && SipInstance.socket) {
		SipInstance.callDeviceId = deviceId;
	}
};

SipInstance.changeBellDevice = (deviceId) => {
	if (SipInstance.work && SipInstance.socket) {
		SipInstance.bellDeviceId = deviceId;
	}
};

export const SIP = (login, password) => {
	return new Promise((resolve) => {
		if (!SipInstance.work && login && password) {
			if (!SipInstance.socket) {
				SipInstance.socket = new WebSocketInterface(`wss://${process.env.REACT_APP_SIP_SERVER}:${process.env.REACT_APP_SIP_PORT}/ws`);
				SipInstance.ua = new UA({
					sockets: [SipInstance.socket],
					uri: `sip:${login}@${process.env.REACT_APP_SIP_SERVER}`,
					contact_uri: `sip:${login}@${process.env.REACT_APP_SIP_SERVER}`,
					password: password,
					authorization_user: login,
					session_timers: false,
					registrar_server: process.env.REACT_APP_SIP_SERVER,
					realm: process.env.REACT_APP_SIP_SERVER,
					use_preloaded_route: false
				});

				SipInstance.ua.on('registered', (event) => {
					if (SIP_DEBUG) console.log(`SIP registration`, event);
					SipInstance.work = true;
					resolve(SipInstance);
				});

				SipInstance.ua.on('registrationFailed', (event) => {
					if (SIP_DEBUG) console.log(`SIP registration failed ${event.cause}`);
					SipInstance.work = false;
					resolve(SipInstance);
				});

				SipInstance.ua.on('unregistered', (event) => {
					if (SIP_DEBUG) console.log(`SIP unregistered`, event);
					SipInstance.work = false;
				});

				SipInstance.ua.on('newRTCSession', async (event) => {
					if (SIP_DEBUG) console.log(`SIP session`, event);

					const audio = window.document.getElementById('sip-audio');

					if (SipInstance.work) {
						SipInstance.session = event.session;

						if (SipInstance.session.direction === 'incoming' && SipInstance.online) {
							await sipIncomeAction(event.request.getHeader('X-Phonet-CallerID'));

							if (audio) {
								if (SipInstance.bellDeviceId) {
									await audio.setSinkId(SipInstance.bellDeviceId);
								}
								audio.src = callingAudio;
							}

							SipInstance.session.on('peerconnection', (event) => {
								if (SIP_DEBUG) console.log(`SIP ${SipInstance.session.direction} session connected`, event);

								SipInstance.session.connection.addEventListener('addstream', (data) => {
									if (audio) {
										audio.srcObject = data.stream;
									}
								});
							});
						}

						if (SipInstance.session.direction === 'outgoing') {
							SipInstance.session.on('connecting', async (event) => {
								if (SIP_DEBUG) console.log(`SIP ${SipInstance.session.direction} session connected`, event);

								await sipOutgoingAction();

								SipInstance.session.connection.addEventListener('addstream', (data) => {
									if (audio) {
										audio.srcObject = data.stream;
									}
								});
							});
						}

						SipInstance.session.on('failed', (event) => {
							if (SIP_DEBUG) console.log(`SIP ${SipInstance.session.direction} session failed`, event);
							sipDoneAction();
							SipInstance.session = null;
							if (audio) {
								audio.src = '';
								audio.srcObject = null;
							}
						});
						SipInstance.session.on('ended', (event) => {
							if (SIP_DEBUG) console.log(`SIP ${SipInstance.session.direction} session ended`, event);
							sipDoneAction();
							SipInstance.session = null;
							if (audio) {
								audio.src = '';
								audio.srcObject = null;
							}
						});
					}
				});
			}
			SipInstance.ua.start();
		} else {
			resolve(SipInstance);
		}
	});
};
