import { Link } from 'react-router-dom';

const CustomLink = (props) => {
	const { children, href = '', ...rest } = props;
	return (
		<Link to={href} {...rest}>
			{children}
		</Link>
	);
};

export { CustomLink as Link };
