import { useState } from 'react';
import { Store, translationsInit, preloaderHide, setStore } from 'utils';

export const StoreProvider = (props) => {
	const { children, value = {} } = props;
	const { translations = {} } = value;
	const [data, setData] = useState(value);

	const __ = translationsInit(translations);
	const update = (newData = {}) => {
		setData((prevData = {}) => {
			const nextData = {
				...prevData,
				...newData
			};
			setStore(nextData);
			return nextData;
		});
		preloaderHide();
	};

	const methods = {
		__,
		update
	};

	setStore(data);
	setStore(methods);

	return <Store.Provider value={{ data, methods }}>{children}</Store.Provider>;
};
