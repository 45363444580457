import Helmet from 'react-helmet';

export const HtmlHead = (props) => {
	const { seo = {} } = props;
	const { title = 'Ikura CRM' } = seo;

	return (
		<Helmet>
			<title>{title}</title>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
			<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap" rel="stylesheet" />
			<link rel="stylesheet" href="/css/antd.css" />
		</Helmet>
	);
};
