import { formatDigits, useData, useMethods, calcNomTotalSum, nomenclaturesChange } from 'utils';
import { Input, InputNumber, Table, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { pdvPercent } from 'const';

export const WaybillTable = () => {
	const { waybillType, waybillId, waybillFilter = {}, suppliers = [], waybillsTypes = [] } = useData();
	const { nomenclatures = [] } = waybillFilter;
	const { __, update } = useMethods();

	const typeItem = waybillsTypes.find((item) => item.alias === waybillFilter.type) || {};
	const { properties = [] } = typeItem;
	const isWaybill = waybillType && waybillId;

	const isSupplierWithPdv =
		properties.includes('supplier_id') &&
		waybillFilter.supplier_id &&
		!!suppliers.find((item) => item.id === waybillFilter.supplier_id)?.with_nds;
	const isPurchaseOrSaleInvoiceOrWriteOffAct = ['purchase-invoice', 'sale-invoice', 'write-of-act'].includes(waybillFilter.type);

	const total = calcNomTotalSum(nomenclatures);

	const changeNomenclature = (id, field, val) => {
		update({
			waybillFilter: {
				...waybillFilter,
				nomenclatures: nomenclaturesChange(
					nomenclatures.map((item) => {
						if (item.id === id) {
							return {
								...item,
								[field]: val
							};
						} else {
							return item;
						}
					}),
					field === 'count' || field === 'fact_count'
				)
			}
		});
	};

	const deleteNomenclature = (id) => {
		update({
			waybillFilter: {
				...waybillFilter,
				nomenclatures: nomenclatures.filter((item) => item.id !== id)
			}
		});
	};

	let width = 150;
	let colspan = 1;
	const columns = [
		{
			title: __('Наименование'),
			dataIndex: 'name',
			width: 150
		}
	];
	if (waybillFilter.type === 'inventory-act') {
		columns.push({
			title: __('Категория'),
			dataIndex: 'category_name',
			width: 150,
			sorter: (a, b) => a.category_name.localeCompare(b.category_name)
		});
		width += 150;
		colspan++;
	}
	if (isPurchaseOrSaleInvoiceOrWriteOffAct) {
		const columnTitle =
			waybillFilter.type === 'purchase-invoice' ? __('прихода') : waybillFilter.type === 'write-of-act' ? __('списания') : __('расхода');
		columns.push({
			title: `${__('Данные')} ${columnTitle} - ${__('калькулятор')}`,
			children: [
				{
					title: __('Количество упаковок'),
					dataIndex: 'calc_count',
					width: 150,
					render: (calc_count, record) => {
						const calcCount = (calc_count || 0).toFixed(1);
						return isWaybill ? (
							calcCount
						) : (
							<div>
								<InputNumber
									value={calcCount}
									onChange={(val) => changeNomenclature(record.id, 'calc_count', val)}
									step={0.1}
									min={0}
									parser={(val) => val?.replace(',', '.')}
								/>
							</div>
						);
					}
				},
				{
					title: __('Кг/шт в упаковке'),
					dataIndex: 'calc_weight',
					width: 150,
					render: (calc_weight, record) => {
						const calcWeight = (calc_weight || 0).toFixed(3);
						return (
							<div>
								{waybillFilter.type !== 'purchase-invoice' || isWaybill ? (
									calcWeight
								) : (
									<InputNumber
										value={calcWeight}
										onChange={(val) => changeNomenclature(record.id, 'calc_weight', val)}
										step={0.001}
										min={0}
										precision={3}
										parser={(val) => val?.replace(',', '.')}
									/>
								)}{' '}
								{record.unit_title}
							</div>
						);
					}
				},
				{
					title: __('Стоимость упаковки'),
					dataIndex: 'calc_cost',
					width: 150,
					render: (calc_cost, record) => {
						const calcCost = (calc_cost || 0).toFixed(2);
						return (
							<div>
								{waybillFilter.type !== 'purchase-invoice' || isWaybill ? (
									formatDigits({ number: calcCost, toFixed: 2 })
								) : (
									<InputNumber
										value={calcCost}
										onChange={(val) => changeNomenclature(record.id, 'calc_cost', val)}
										step={1}
										min={0}
										disabled={waybillFilter.type === 'purchase-invoice' ? isWaybill : true}
										parser={(val) => val?.replace(',', '.')}
									/>
								)}{' '}
								{__('грн')}
							</div>
						);
					}
				}
			]
		});
		width += 450;
		colspan += 3;
	}
	if (waybillFilter.type === 'write-of-act') {
		columns.push({
			title: __('Комментарий'),
			width: 400,
			render: (record) =>
				isWaybill ? (
					record.comment || '-'
				) : (
					<div>
						<Input
							value={record.comment || ''}
							onChange={(e) => changeNomenclature(record.id, 'comment', e.target.value)}
							disabled={isWaybill}
						/>
					</div>
				)
		});
		width += 400;
		colspan++;
	} else {
		columns.push({
			title: waybillFilter.type === 'inventory-act' ? __('Количество') : __('Общее количество'),
			dataIndex: 'count',
			width: 150,
			render: (count, record) => {
				const countData = (count || 0).toFixed(3);
				return isPurchaseOrSaleInvoiceOrWriteOffAct ? (
					`${countData} ${record.unit_title}`
				) : (
					<div>
						{isWaybill || waybillFilter.type === 'inventory-act' ? (
							countData
						) : (
							<InputNumber
								value={countData}
								onChange={(val) => changeNomenclature(record.id, 'count', val)}
								step={1}
								min={0}
								parser={(val) => val?.replace(',', '.')}
							/>
						)}{' '}
						{record.unit_title}
					</div>
				);
			}
		});
		width += 150;
		colspan++;
	}
	if (waybillFilter.type === 'inventory-act') {
		columns.push({
			title: __('Сумма'),
			width: 150,
			dataIndex: 'sum',
			render: (sum) => `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`
		});
		width += 150;
		colspan++;
		columns.push({
			title: __('Расчетные данные'),
			children: [
				{
					title: __('Фактическое кол-во'),
					width: 200,
					dataIndex: 'fact_count',
					render: (fact_count, record) => {
						const factCount = (fact_count || 0).toFixed(1);
						return isWaybill ? (
							factCount
						) : (
							<div>
								<InputNumber
									value={factCount}
									onChange={(val) => changeNomenclature(record.id, 'fact_count', val)}
									step={0.1}
									min={0}
									parser={(val) => val?.replace(',', '.')}
								/>
							</div>
						);
					}
				},
				{
					title: __('Цена ед.'),
					width: 150,
					dataIndex: 'fact_cost',
					render: (cost) => `${formatDigits({ number: cost, toFixed: 2 })} ${__('грн')}`
				},
				{
					title: __('Сумма'),
					width: 150,
					dataIndex: 'fact_sum',
					render: (fact_sum) => `${formatDigits({ number: fact_sum, toFixed: 2 })} ${__('грн')}`
				}
			]
		});
		width += 500;
		colspan += 3;
		columns.push({
			title: __('Разница'),
			children: [
				{
					title: __('КГ'),
					width: 100,
					dataIndex: 'difference_kg',
					render: (difference_kg) => `${(difference_kg || 0).toFixed(2)}`
				},
				{
					title: __('ГРН'),
					width: 100,
					dataIndex: 'difference_sum',
					render: (difference_sum) => `${formatDigits({ number: difference_sum, toFixed: 2 })} ${__('грн')}`
				}
			]
		});
		width += 200;
		colspan += 2;
	}
	if (isPurchaseOrSaleInvoiceOrWriteOffAct || waybillFilter.type === 'order-act') {
		columns.push({
			title: __('Цена за кг/шт'),
			dataIndex: waybillFilter.type === 'order-act' ? 'cost' : 'fact_cost',
			width: 150,
			render: (cost) => `${formatDigits({ number: cost, toFixed: 2 })} ${__('грн')}`
		});
		width += 150;
		colspan++;
		if (waybillFilter.type !== 'write-of-act' && waybillFilter.type !== 'order-act') {
			columns.push({
				title: __('Стоимость за КГ'),
				width: 150,
				dataIndex: 'cost_kg',
				render: (cost_kg) => `${formatDigits({ number: cost_kg, toFixed: 2 })} ${__('грн')}`
			});
			width += 150;
			colspan++;
		}
		columns.push({
			title: __('Сумма'),
			width: 150,
			dataIndex: waybillFilter.type === 'order-act' ? 'total' : 'sum',
			render: (sum) => `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`
		});
		width += 150;
	}
	if (isSupplierWithPdv) {
		columns.push({
			title: `${__('ПДВ')} ${pdvPercent}%`,
			width: 150,
			dataIndex: 'pdv',
			render: (pdv) => `${formatDigits({ number: pdv, toFixed: 2 })} ${__('грн')}`
		});
		width += 150;
		columns.push({
			title: __('Сумма с ПДВ'),
			width: 150,
			dataIndex: 'sum_pdv',
			render: (sum_pdv) => `${formatDigits({ number: sum_pdv, toFixed: 2 })} ${__('грн')}`
		});
		width += 150;
	}
	if (!isWaybill) {
		columns.push({
			title: __('Действия'),
			dataIndex: 'id',
			width: 100,
			align: 'right',
			render: (id) => {
				return (
					<Tooltip placement="topRight" title={__('Удалить позицию из списка')}>
						<button type="button" className="button button--trans-error button--icon-xs" onClick={() => deleteNomenclature(id)}>
							<DeleteOutlined />
						</button>
					</Tooltip>
				);
			}
		});
		width += 100;
	}

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			scroll={{
				x: width
			}}
			pagination={false}
			columns={columns}
			dataSource={nomenclatures}
			summary={() =>
				isPurchaseOrSaleInvoiceOrWriteOffAct || waybillFilter.type === 'order-act' ? (
					<Table.Summary fixed>
						<Table.Summary.Row>
							<Table.Summary.Cell colSpan={colspan} align="right">
								{__('Итого')}
							</Table.Summary.Cell>
							<Table.Summary.Cell width={150}>
								{formatDigits({ number: waybillFilter.type === 'order-act' ? total.total : total.sum, toFixed: 2 })} {__('грн')}
							</Table.Summary.Cell>
							{isSupplierWithPdv ? (
								<>
									<Table.Summary.Cell width={150}>
										{formatDigits({ number: total.pdv, toFixed: 2 })} {__('грн')}
									</Table.Summary.Cell>
									<Table.Summary.Cell width={150}>
										{formatDigits({ number: total.sum_pdv, toFixed: 2 })} {__('грн')}
									</Table.Summary.Cell>
								</>
							) : null}
							{!isWaybill ? <Table.Summary.Cell width={100} /> : null}
						</Table.Summary.Row>
					</Table.Summary>
				) : waybillFilter.type === 'inventory-act' ? (
					<Table.Summary fixed>
						<Table.Summary.Row>
							<Table.Summary.Cell>{__('Итого')}</Table.Summary.Cell>
							<Table.Summary.Cell width={100} />
							<Table.Summary.Cell width={100} />
							<Table.Summary.Cell width={150}>
								{formatDigits({ number: total.sum, toFixed: 2 })} {__('грн')}
							</Table.Summary.Cell>
							<Table.Summary.Cell width={100} />
							<Table.Summary.Cell width={100} />
							<Table.Summary.Cell width={150}>
								{formatDigits({ number: total.fact_sum, toFixed: 2 })} {__('грн')}
							</Table.Summary.Cell>
							<Table.Summary.Cell width={100} />
							<Table.Summary.Cell width={100} />
							{!isWaybill ? <Table.Summary.Cell width={100} /> : null}
						</Table.Summary.Row>
					</Table.Summary>
				) : null
			}
		/>
	);
};
