export const isFractionalNomenclature = (unit, __) => {
	let result = false;
	switch (unit) {
		case 'кг':
			result = true;
			break;
		case 'л':
			result = true;
			break;
		default:
			break;
	}
	return result;
};

export const createNomenclatureName = (name, unit, weight, __) => {
	if (weight || unit) {
		let additionalText = '';
		if (weight && unit) {
			additionalText = `(${weight} ${__('г')})${unit === 'шт' ? ` ${__('в шт')}` : ''}`;
		} else if (weight) {
			additionalText = `(${weight} ${__('г')})`;
		} else {
			additionalText = `(${__('в')} ${unit})`;
		}
		return `${name} ${additionalText}`;
	} else {
		return name;
	}
};
