import { useState } from 'react';
import { Input, AutoComplete, Tooltip } from 'antd';
import { useMethods, phoneClear } from 'utils';
import { clientSearchPhoneAction, createClientAction } from 'actions';
import { AddNewClient } from 'components';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';

let timeoutId = null;

export const ClientSearch = (props) => {
	const { disabled, onChange } = props;
	const { __, update } = useMethods();
	const [clients, setClients] = useState([]);
	const [val, setVal] = useState('+38');
	const [search, setSearch] = useState(false);

	const renderList = () => {
		return clients.map((item) => {
			const name = item.name ? `${item.name} ••• ` : '';
			const card = item.card ? `${__('Номер карты')}: ${item.card} ••• ` : '';
			const phones = item.phones ? `${__('Телефоны')}: ${item.phones.map((phone) => phone.value).join(' | ')} ` : '';
			const blacklistNote = item.blacklisted ? ` (${__('в черном списке')})` : '';
			return {
				value: `${item.id}`,
				label: `${name}${card}${phones}${blacklistNote}`,
				disabled: !!item.blacklisted
			};
		});
	};

	const changeHandler = (value = '') => {
		const changeValue = String(value).replace(/[^+0-9]*/g, '');
		if (changeValue !== val && changeValue.length <= 13) {
			setVal(changeValue);
			clearTimeout(timeoutId);
			timeoutId = setTimeout(async () => {
				if (changeValue && changeValue.length >= 3) {
					const result = await clientSearchPhoneAction(phoneClear(changeValue));
					setClients(result);
					setSearch(true);
				} else {
					setClients([]);
					setSearch(false);
				}
			}, 500);
		}
	};

	const selectHandler = async (value, option) => {
		setVal(option.label);
		const client = clients.find((item) => item.id === +value);
		if (client) {
			update({
				client
			});
			if (onChange) {
				await onChange(client);
			}
		}
	};

	const addHandler = async (values) => {
		let clientData = {
			phones: [
				{
					id: null,
					main: true,
					value: phoneClear(values.phone)
				}
			],
			addresses: [],
			name: values.name || __('Новый клиент')
		};
		clientData = await createClientAction(clientData);
		update({
			client: clientData
		});
		if (onChange) {
			await onChange(clientData);
		}
		setVal('+38');
		setSearch(false);
	};

	return (
		<div className="search-block">
			<div className="search-block__title">{__('Поиск клиента по номеру тел.')}</div>
			<div className="search-block__content">
				<AutoComplete
					value={val}
					allowClear={true}
					disabled={disabled}
					options={renderList()}
					autoClearSearchValue={false}
					onSearch={changeHandler}
					onSelect={selectHandler}
				>
					<Input prefix={<SearchOutlined />} />
				</AutoComplete>
				{search && !clients.length && phoneClear(val).length === 13 ? (
					<div className="search-block__button">
						<AddNewClient phone={val} onSubmit={addHandler}>
							<Tooltip placement="topRight" title={__('Добавить нового клиента')}>
								<button type="button" className="button button--icon-small button--main button--no-border">
									<PlusOutlined />
								</button>
							</Tooltip>
						</AddNewClient>
					</div>
				) : null}
			</div>
		</div>
	);
};
