import { useEffect, useState } from 'react';
import { EmployeesShifts, EmployeesShiftsFilter } from 'components';
import { useMethods } from 'utils';
import { getShiftsAction } from 'actions';

export const EmployeesShiftsTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const shifts = await getShiftsAction();
			const shiftsFiltersDefault = {
				employee: null,
				department: null,
				position: null,
				date: [],
				page: 1,
				per_page: 10
			};
			const shiftsFilters = { ...shiftsFiltersDefault };
			setPending(false);
			update({
				shifts,
				shiftsFiltersDefault,
				shiftsFilters,
				employeesTab: 'shifts'
			});
		})();

		return () => {
			update({
				shifts: {},
				shiftsFiltersDefault: {},
				shiftsFilters: {},
				employeesTab: 'shifts'
			});
		};
	}, []);

	return (
		<>
			<EmployeesShiftsFilter setPending={setPending} />
			<EmployeesShifts pending={pending} setPending={setPending} />
		</>
	);
};
