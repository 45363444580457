import { Table, Row, Col, Tooltip, Popconfirm } from 'antd';
import { useData, useMethods, dayjs, formatDigits, access, notify } from 'utils';
import { paginationDefault, dateClientFormat } from 'const';
import { ReadOutlined, LoadingOutlined } from '@ant-design/icons';
import { getWaybillsAction, deleteWaybillAction } from 'actions';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { Link } from 'components';

export const WaybillsTable = (props) => {
	const { pending, setPending } = props;
	const { waybillsFilters = {}, waybills = {}, waybillsTypes = [] } = useData();
	const { data = [], meta = {} } = waybills;
	const { current_page = 1, total = 0, per_page = 10 } = meta;
	const { __, update } = useMethods();

	const tableChangeHandler = async (pagination) => {
		setPending(true);
		const filter = {
			...waybillsFilters,
			page: pagination.current,
			per_page: pagination.pageSize
		};
		const waybills = await getWaybillsAction(filter);
		setPending(false);
		update({
			waybillsFilters: filter,
			waybills
		});
	};

	const deleteWaybillHandler = async (type, id) => {
		setPending(true);
		const success = await deleteWaybillAction(type, id);
		if (success) {
			const waybills = await getWaybillsAction(waybillsFilters);
			notify('success', __('Накладная удалена'));
			setPending(false);
			update({
				waybills
			});
		} else {
			setPending(false);
		}
	};

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1550
			}}
			columns={[
				{
					title: __('Номер'),
					dataIndex: 'id',
					width: 50
				},
				{
					title: __('Тип накладной'),
					dataIndex: 'type',
					width: 150,
					render: (type) => waybillsTypes.find((item) => item.alias === type)?.title || '-'
				},
				{
					title: __('Дата'),
					dataIndex: 'date',
					width: 150,
					render: (date) => (date ? dayjs(date).format(dateClientFormat) : '-')
				},
				{
					title: __('Описание'),
					dataIndex: 'description',
					width: 150,
					render: (val) => val || '-'
				},
				{
					title: __('Склад получателя'),
					dataIndex: 'recipient_warehouse',
					width: 150,
					render: (val) => (val ? val.name : '-')
				},
				{
					title: __('Получил'),
					dataIndex: 'recipient_employee',
					width: 150,
					render: (val) => (val ? val.name : '-')
				},
				{
					title: __('Склад поставщика'),
					dataIndex: 'supplier_warehouse',
					width: 150,
					render: (val) => (val ? val.name : '-')
				},
				{
					title: __('Выдал'),
					dataIndex: 'supplier_employee',
					width: 150,
					render: (val) => (val ? val.name : '-')
				},
				{
					title: __('Поставщик'),
					dataIndex: 'supplier',
					width: 150,
					render: (val) => (val ? val.full_name : '-')
				},
				{
					title: __('Сумма'),
					dataIndex: 'total',
					width: 150,
					render: (total) => (total ? `${formatDigits({ number: total, toFixed: 2 })} ${__('грн')}` : '-')
				},
				{
					title: __('Действия'),
					dataIndex: 'id',
					align: 'right',
					width: 150,
					render: (id, record) => {
						const { permissions = [] } = waybillsTypes.find((item) => item.alias === record.type) || {};
						const [showWaybill, editWaybill] = permissions;
						return (
							<Row gutter={10} justify="end">
								{showWaybill && access(showWaybill) ? (
									<Col>
										<Tooltip title={__('Просмотреть накладную')} placement="topRight">
											<Link href={`/warehouse/${record.type}/${id}`} className="button button--icon-xs button--trans-main">
												<ReadOutlined />
											</Link>
										</Tooltip>
									</Col>
								) : null}
								{editWaybill && access(editWaybill) ? (
									<Col>
										<Popconfirm
											title={__('Вы точно хотите удалить накладную?')}
											okText={__('Да')}
											cancelText={__('Отмена')}
											placement="topRight"
											onConfirm={() => deleteWaybillHandler(record.type, id)}
										>
											<Tooltip title={__('Удалить накладную')} placement="bottomRight">
												<button type="button" className="button button--icon-xs button--trans-error">
													<IconClose />
												</button>
											</Tooltip>
										</Popconfirm>
									</Col>
								) : null}
							</Row>
						);
					}
				}
			]}
			dataSource={data}
			pagination={{
				...paginationDefault,
				current: current_page,
				pageSize: +per_page,
				total
			}}
			onChange={tableChangeHandler}
		/>
	);
};
