import { useState } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import { Map } from 'components';
import { markerIcon, dayjs, useMethods } from 'utils';
import { timeClientFormat } from 'const';

export const CourierMap = (props) => {
	const { data = {} } = props;
	const [open, setOpen] = useState(null);
	const { __ } = useMethods();

	const addressLocation = { lat: +data.address_lat, lng: +data.address_lng };
	const startLocation = { lat: +data.start_delivery_lat, lng: +data.start_delivery_lng };
	const isStartLocation = !!data.start_delivery_lat && !!data.start_delivery_lng;
	const courierLocation = { lat: +data.delivery_lat, lng: +data.delivery_lng };
	const isCourierLocation = !!data.delivery_lat && !!data.delivery_lng;
	const bounds = [addressLocation];
	if (isStartLocation && (startLocation.lat !== addressLocation.lat || startLocation.lng !== addressLocation.lng)) {
		bounds.push(startLocation);
	}
	if (isCourierLocation && (courierLocation.lat !== addressLocation.lat || courierLocation.lng !== addressLocation.lng)) {
		bounds.push(courierLocation);
	}

	return (
		<>
			<p>
				{__('Время отправки')}: <b>{data.started_delivery_at ? dayjs(data.started_delivery_at).format(timeClientFormat) : ''}</b>
			</p>
			<p>
				{__('Время доставки')}: <b>{data.delivered_at ? dayjs(data.delivered_at).format(timeClientFormat) : ''}</b>
			</p>
			<p>
				{__('Пройденное расстояние')}: <b>{data.distance || 0} км</b>
			</p>
			<Map
				mapProps={{
					defaultCenter: addressLocation,
					bounds: bounds.length > 1 ? bounds : null
				}}
				containerProps={{
					style: {
						width: '100%',
						height: 180
					}
				}}
			>
				<Marker position={addressLocation} icon={markerIcon('#000000')} onClick={() => setOpen('address')}>
					{open === 'address' ? (
						<InfoWindow onCloseClick={() => setOpen(null)}>
							<>
								<div>{__('Место доставки')}</div>
								<div>{data.address}</div>
							</>
						</InfoWindow>
					) : null}
				</Marker>
				{isStartLocation ? (
					<Marker position={startLocation} icon={markerIcon('#1890ff')} onClick={() => setOpen('start')}>
						{open === 'start' ? (
							<InfoWindow onCloseClick={() => setOpen(null)}>
								<>
									<div>{__('Место отправки курьера')}</div>
								</>
							</InfoWindow>
						) : null}
					</Marker>
				) : null}
				{isCourierLocation ? (
					<Marker position={courierLocation} icon={markerIcon('#f5222d')} onClick={() => setOpen('courier')}>
						{open === 'courier' ? (
							<InfoWindow onCloseClick={() => setOpen(null)}>
								<>
									<div>{__('Место отметки курьера')}</div>
								</>
							</InfoWindow>
						) : null}
					</Marker>
				) : null}
			</Map>
		</>
	);
};
