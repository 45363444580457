import { useEffect } from 'react';
import { Form, Input, Tooltip, Select, Switch } from 'antd';
import { ClearOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import { useData, useMethods, print } from 'utils';
import { getOrdersAction } from 'actions';

export const ActiveOrdersFilter = (props) => {
	const { setPending } = props;
	const { ordersFiltersDefault = {}, ordersFilters = {}, paymentTypes = [], orderTypes = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(ordersFiltersDefault);
	}, [ordersFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		update({
			ordersFilters: {
				...ordersFilters,
				...values
			}
		});
	};

	const clearFilterHandler = async () => {
		const filters = {
			...ordersFiltersDefault,
			status: ordersFilters.status
		};
		update({
			ordersFilters: filters
		});
		form.setFieldsValue(filters);
	};

	const updateHandler = async () => {
		setPending(true);
		const orders = await getOrdersAction();
		setPending(false);
		update({
			orders
		});
	};

	const printTable = () => {
		print('#active-orders .ant-table-content', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};

	return (
		<div className="filter filter--5">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="phone" label={__('Телефон')}>
								<Input type="tel" placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="address_name" label={__('Адрес')}>
								<Input placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="payment_type_id" label={__('Тип оплаты')}>
								<Select
									dropdownClassName="form__dropdown"
									options={paymentTypes.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="order_type_id" label={__('Тип заказа')}>
								<Select
									dropdownClassName="form__dropdown"
									options={orderTypes.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<div className="filter__row">
								<div className="filter__col">
									<Form.Item className="form__item" name="day_id" label={__('Номер заказа')}>
										<Input placeholder={__('Не выбрано')} allowClear={true} />
									</Form.Item>
								</div>
								<div className="filter__col">
									<Form.Item className="form__item" name="is_fiscal_printed" valuePropName="checked" label={__('С чеками')}>
										<Switch />
									</Form.Item>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Распечатать')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={printTable}>
							<PrinterOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
