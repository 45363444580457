import { api } from 'utils';

export const getRestaurantSettingsAction = async () => {
	const response = await api.get(`/current-restaurant-settings`);
	return response.data ? response.data : {};
};

export const getNotificationsAction = async () => {
	const response = await api.get(`/notifications`);
	return response.data ? response.data : [];
};

export const deleteNotificationAction = async (id) => {
	const { success } = await api.put(`/notifications/${id}/deactivate`);
	return !!success;
};

export const getOrderStatusesAction = async () => {
	const response = await api.get(`/dictionary-order-statuses`);
	return response.data ? response.data : [];
};

export const getPaymentTypesAction = async () => {
	const response = await api.get(`/dictionary-payment-types`);
	return response.data ? response.data : [];
};

export const getOrderTypesAction = async () => {
	const response = await api.get(`/dictionary-order-types`);
	return response.data ? response.data : [];
};

export const getDeliveryTypesAction = async () => {
	const response = await api.get(`/dictionary-delivery-types`);
	return response.data ? response.data : [];
};

export const getEntrepreneursAction = async () => {
	const response = await api.get(`/entrepreneurs/list`);
	return response.data ? response.data : [];
};

export const getProductsAction = async () => {
	const response = await api.get(`/dictionary-products`);
	return response.data || [];
};

export const getNomenclaturesAction = async () => {
	const response = await api.get(`/nomenclature/list`);
	let data = response.data ? response.data : [];
	data = data.map((item) => ({
		...item,
		price: item.internal_price
	}));
	return data;
};

export const getOrderSalesAction = async () => {
	const response = await api.get(`/dictionary-order-sales`);
	return response.data ? response.data : [];
};

export const getTransactionTypesAction = async () => {
	const response = await api.get(`/dictionary-transactions-types`);
	return response.data ? response.data : [];
};

export const getCitiesAction = async () => {
	const response = await api.get(`/dictionary-cities`);
	return response.data ? response.data : [];
};

export const getIngredientsAction = async () => {
	const response = await api.get(`/ingredients/list`);
	return response.data ? response.data : [];
};

export const getDishCategoriesAction = async () => {
	const response = await api.get(`/categories/list`);
	return response.data ? response.data : [];
};

export const getRestaurantsAction = async () => {
	const response = await api.get(`/dictionary-restaurants`);
	return response.data ? response.data : [];
};

export const getDepartmentsAction = async () => {
	const response = await api.get(`/dictionary-staff-departments`);
	return response.data ? response.data : [];
};

export const getPositionsAction = async () => {
	const response = await api.get(`/dictionary-staff-positions`);
	return response.data ? response.data : [];
};

export const getRatesAction = async () => {
	const response = await api.get(`/dictionary-staff-rates`);
	return response.data ? response.data : [];
};

export const getTripStatusesAction = async () => {
	const response = await api.get(`/dictionary-trip-statuses`);
	return response.data ? response.data : [];
};

export const getCourierOrderStatusesAction = async () => {
	const response = await api.get(`/dictionary-courier-order-statuses`);
	return response.data ? response.data : [];
};

export const getWarehousesAction = async () => {
	const response = await api.get(`/dictionary-warehouses`);
	return response.data ? response.data : [];
};

export const getSettingsAction = async () => {
	const response = await api.get(`/dictionary-settings`);
	return response.data ? response.data : [];
};

export const getTransactionsTypesAction = async () => {
	const response = await api.get(`/dictionary-transactions-types`);
	return response.data ? response.data : [];
};

export const getOperationsTypesAction = async () => {
	const response = await api.get(`/dictionary-employees-transactions-types`);
	return response.data ? response.data : [];
};

export const getScheduleTemplatesAction = async () => {
	const response = await api.get(`/dictionary-staff-schedule-templates`);
	return response.data ? response.data : [];
};
