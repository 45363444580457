import { WS, addToArray, updateArray, deleteFromArray, getStore, access, dayjs, wait } from 'utils';
import { dictionaries, employeeDictionaries, restaurantDictionaries, restaurantSettings, dateServerFormat } from 'const';
import {
	checkSipAction,
	getOrdersAction,
	getProductsAction,
	getReportsRevenueAction,
	getUserAction,
	sipConnectAction,
	sipDisconnectAction
} from 'actions';
import newOrderAudio from 'assets/audio/new-order.mp3';

let isWs = false;

const setAction = (prop, action, resource, update) => {
	switch (action) {
		case 'created':
			update({ [prop]: addToArray(getStore(prop, []), resource || {}) });
			break;
		case 'updated':
			update({ [prop]: updateArray(getStore(prop, []), resource || {}) });
			break;
		case 'deleted':
			update({ [prop]: deleteFromArray(getStore(prop, []), resource || {}) });
			break;
		case 'changed':
			update({ [prop]: resource || [] });
			break;
		default:
			break;
	}
};

export const socketConnectAction = async () => {
	const { restaurant_slug, id } = getStore('user', {});
	const update = getStore('update', () => {});

	if (!isWs) {
		isWs = true;
		const ws = await WS();

		await ws.subscribe('general');
		for (let key in dictionaries) {
			const item = dictionaries[key];
			for (let action of item.actions) {
				await ws.on('general', `${key}-${action}`, (response) => {
					setAction(item.prop, action, response.resource, update);
				});
			}
		}
		await ws.on('general', 'sip-reload', async () => {
			const sipProps = {};
			await sipDisconnectAction();
			const user = await getUserAction();
			const sipSuccess = await checkSipAction();
			const sipEnabled = user?.sip?.enabled;
			const sipLogin = user?.sip?.login;
			const sipPassword = user?.sip?.pass;
			if (sipSuccess && sipEnabled && sipLogin && sipPassword) {
				const sipInstance = await sipConnectAction(sipLogin, sipPassword);
				sipProps.sipWork = sipInstance.work && sipInstance.socket;
			}
			update({
				user,
				...sipProps
			});
		});

		await ws.subscribe(`general-${restaurant_slug}`);
		for (let key in restaurantDictionaries) {
			const item = restaurantDictionaries[key];
			for (let action of item.actions) {
				await ws.on(`general-${restaurant_slug}`, `${key}-${action}`, (response) => {
					setAction(item.prop, action, response.resource, update);
				});
			}
		}

		await ws.subscribe(`settings-${restaurant_slug}`);
		for (let key in restaurantSettings) {
			const item = restaurantSettings[key];
			for (let action of item.actions) {
				await ws.on(`settings-${restaurant_slug}`, `${key}-${action}`, (response) => {
					setAction(item.prop, action, response.resource, update);
				});
			}
		}

		await ws.subscribe(`employee-${id}`);
		for (let key in employeeDictionaries) {
			const item = employeeDictionaries[key];
			for (let action of item.actions) {
				await ws.on(`employee-${id}`, `${key}-${action}`, (response) => {
					setAction(item.prop, action, response.resource, update);
				});
			}
		}

		// await ws.subscribe(`printer-${restaurant_slug}`);
		// await ws.on(`printer-${restaurant_slug}`, 'print', (response) => {
		// 	// todo
		// });

		await ws.subscribe(`products-${restaurant_slug}`);
		await ws.on(`products-${restaurant_slug}`, 'reload', async () => {
			update({ products: await getProductsAction() });
		});
		await ws.on(`products-${restaurant_slug}`, 'created', async (response) => {
			update({ products: addToArray(getStore('products', []), response.resource) });
		});
		await ws.on(`products-${restaurant_slug}`, 'updated', async (response) => {
			update({ products: updateArray(getStore('products', []), response.resource) });
		});
		await ws.on(`products-${restaurant_slug}`, 'deleted', async (response) => {
			update({ products: deleteFromArray(getStore('products', []), response.resource) });
		});

		if (access('orders-page')) {
			await ws.subscribe(`orders-${restaurant_slug}`);
			await ws.on(`orders-${restaurant_slug}`, 'order-created', async (response) => {
				if (response.resource) {
					if (response.resource.is_from_site || response.resource.is_from_mobile) {
						const audio = window.document.getElementById('order-audio');
						audio.src = newOrderAudio;
						await wait(3700);
						audio.src = '';
					}
					if (response.resource.is_from_site) {
						update({ orders: addToArray(getStore('orders', []), response.resource) });
					} else {
						const orders = await getOrdersAction();
						update({ orders });
					}
				}
			});
			await ws.on(`orders-${restaurant_slug}`, 'order-updated', async (response) => {
				if (response.resource) {
					const orders = await getOrdersAction();
					update({ orders });
				}
			});
			await ws.on(`orders-${restaurant_slug}`, 'order-finished', (response) => {
				if (response.resource) {
					update({ orders: deleteFromArray(getStore('orders', []), response.resource) });
				}
			});
			await ws.on(`orders-${restaurant_slug}`, 'order-statistic-changed', (response) => {
				if (response.resource) {
					update({ ordersStatistic: response.resource });
				}
			});
			await ws.on(`orders-${restaurant_slug}`, 'today-courier-delivery-schedule-changed', (response) => {
				const order = getStore('order', {});
				if (response.resource) {
					const deliveryTimes = getStore('deliveryTimes', []);
					update({
						deliveryTimesCurrent: response.resource,
						deliveryTimes: order.delivery_type_id === 1 ? response.resource : deliveryTimes
					});
				}
			});
			await ws.on(`orders-${restaurant_slug}`, 'today-self-delivery-schedule-changed', (response) => {
				const order = getStore('order', {});
				if (response.resource) {
					const deliveryTimes = getStore('deliveryTimes', []);
					update({
						deliveryTimes: order.delivery_type_id === 2 ? response.resource : deliveryTimes
					});
				}
			});
			await ws.on(`orders-${restaurant_slug}`, 'revenue-report-updated', async () => {
				const docsRevenueFilters = getStore('docsRevenueFilters', {});
				if (docsRevenueFilters.dates) {
					const docsRevenue = await getReportsRevenueAction(docsRevenueFilters.dates);
					update({
						docsRevenue
					});
				}
			});
		}

		if (access('cashbox-page')) {
			await ws.subscribe(`cashbox-${restaurant_slug}`);
			await ws.on(`cashbox-${restaurant_slug}`, 'cash-shift-transaction-created', (response) => {
				const { transactions = [], ...rest } = getStore('cashbox', {});
				if (rest.id) {
					update({
						cashbox: {
							...rest,
							transactions: addToArray(transactions, response.resource || {})
						}
					});
				}
			});
			await ws.on(`cashbox-${restaurant_slug}`, 'cash-shift-transaction-deleted', (response) => {
				const { transactions = [], ...rest } = getStore('cashbox', {});
				if (rest.id) {
					update({
						cashbox: {
							...rest,
							transactions: deleteFromArray(transactions, response.resource || {})
						}
					});
				}
			});
			await ws.on(`cashbox-${restaurant_slug}`, 'cash-shift-transaction-updated', (response) => {
				const { transactions = [], ...rest } = getStore('cashbox', {});
				if (rest.id) {
					update({
						cashbox: {
							...rest,
							transactions: updateArray(transactions, response.resource || {})
						}
					});
				}
			});
			await ws.on(`cashbox-${restaurant_slug}`, 'cash-shift-opened', (response) => {
				update({
					cashbox: response.resource || {}
				});
			});
			await ws.on(`cashbox-${restaurant_slug}`, 'cash-shift-closed', () => {
				update({
					cashbox: {}
				});
			});
			await ws.on(`cashbox-${restaurant_slug}`, 'cash-shift-cashless-changed', (response) => {
				const cashbox = getStore('cashbox', {});
				update({
					cashbox: {
						...cashbox,
						...(response.resource || {})
					}
				});
			});

			await ws.subscribe(`fiscal-transactions-${restaurant_slug}`);
			await ws.on(`fiscal-transactions-${restaurant_slug}`, 'created', (response) => {
				const fiscalTransactions = getStore('fiscalTransactions', []);
				if (response.resource) {
					update({
						fiscalTransactions: addToArray(fiscalTransactions, response.resource || [])
					});
				}
			});
			await ws.on(`fiscal-transactions-${restaurant_slug}`, 'updated', (response) => {
				const fiscalTransactions = getStore('fiscalTransactions', []);
				if (response.resource) {
					update({
						fiscalTransactions: updateArray(fiscalTransactions, response.resource || [])
					});
				}
			});
		}

		await ws.subscribe(`delivery-${restaurant_slug}`);
		await ws.on(`delivery-${restaurant_slug}`, 'couriers-current-statistics-changed', (response) => {
			if (response.resource) {
				update({ couriersStatistic: response.resource });
			}
		});
		await ws.on(`delivery-${restaurant_slug}`, 'couriers-list-updated', (response) => {
			if (response.resource) {
				const couriersFilters = getStore('couriersFilters', {});
				if (couriersFilters.date && dayjs().format(dateServerFormat) === dayjs(couriersFilters.date).format(dateServerFormat)) {
					update({ couriersDate: updateArray(getStore('couriersDate', []), response.resource) });
				}
			}
		});
		await ws.on(`delivery-${restaurant_slug}`, 'couriers-list-created', (response) => {
			if (response.resource) {
				const couriersFilters = getStore('couriersFilters', {});
				if (couriersFilters.date && dayjs().format(dateServerFormat) === dayjs(couriersFilters.date).format(dateServerFormat)) {
					update({ couriersDate: addToArray(getStore('couriersDate', []), response.resource) });
				}
			}
		});
		await ws.on(`delivery-${restaurant_slug}`, 'couriers-list-deleted', (response) => {
			if (response.resource) {
				const couriersFilters = getStore('couriersFilters', {});
				if (couriersFilters.date && dayjs().format(dateServerFormat) === dayjs(couriersFilters.date).format(dateServerFormat)) {
					update({ couriersDate: deleteFromArray(getStore('couriersDate', []), response.resource) });
				}
			}
		});
		await ws.on(`delivery-${restaurant_slug}`, 'couriers-accounts-updated', (response) => {
			if (response.resource) {
				const couriersAccountFilters = getStore('couriersAccountFilters', {});
				if (couriersAccountFilters.date && dayjs().format(dateServerFormat) === dayjs(couriersAccountFilters.date).format(dateServerFormat)) {
					update({ couriersAccount: updateArray(getStore('couriersAccount', []), response.resource) });
				}
			}
		});
		await ws.on(`delivery-${restaurant_slug}`, 'couriers-accounts-created', (response) => {
			if (response.resource) {
				const couriersAccountFilters = getStore('couriersAccountFilters', {});
				if (couriersAccountFilters.date && dayjs().format(dateServerFormat) === dayjs(couriersAccountFilters.date).format(dateServerFormat)) {
					update({ couriersAccount: addToArray(getStore('couriersAccount', []), response.resource) });
				}
			}
		});
		await ws.on(`delivery-${restaurant_slug}`, 'couriers-accounts-deleted', (response) => {
			if (response.resource) {
				const couriersAccountFilters = getStore('couriersAccountFilters', {});
				if (couriersAccountFilters.date && dayjs().format(dateServerFormat) === dayjs(couriersAccountFilters.date).format(dateServerFormat)) {
					update({ couriersAccount: deleteFromArray(getStore('couriersAccount', []), response.resource) });
				}
			}
		});
	}
};

export const socketDisconnectAction = async () => {
	const ws = await WS();
	await ws.disconnect();
	isWs = false;
};
