import { DatePicker, Form, Select } from 'antd';
import { dateClientFormat, dateServerFormat } from 'const';
import { dayjs, formatDigits, useMethods, useData } from 'utils';
import { getCashboxAction, getCashboxShiftsAction } from 'actions';

export const FinishedCashboxInfo = (props) => {
	const { setPending } = props;
	const [form] = Form.useForm();
	const { __, update } = useMethods();
	const { cashboxArchive = {}, shiftsList = [] } = useData();

	const filterHandler = async (changedValues) => {
		if (changedValues.date) {
			setPending(true);
			const shifts = await getCashboxShiftsAction({
				date: dayjs(changedValues.date).format(dateServerFormat)
			});
			setPending(false);
			form.setFieldsValue({
				shiftsList: null
			});
			update({
				shiftsList: shifts.map((item) => ({ label: item.monthly_id, value: item.id })),
				cashboxArchive: {}
			});
		} else if (changedValues.shift) {
			setPending(true);
			const data = await getCashboxAction(changedValues.shift);
			setPending(false);
			update({
				cashboxArchive: data
			});
		} else {
			update({
				shiftsList: [],
				cashboxArchive: {}
			});
		}
	};

	return (
		<div className="filter filter--cashbox">
			<div className="filter__form">
				<Form className="form" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" label={__('Дата')} name="date">
								<DatePicker
									format={dateClientFormat}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
									allowClear={false}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" label={__('Смена')} name="shift">
								<Select dropdownClassName="form__dropdown" options={shiftsList} placeholder={__('Не выбрано')} />
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			{cashboxArchive.id ? (
				<div className="filter__info">
					<div className="cashbox">
						<div className="cashbox__item">
							<span className="cashbox__item-label">{__('Остаток с прошлой смены')}:</span>
							<span className="cashbox__item-value">
								{formatDigits({ number: cashboxArchive.pre, toFixed: 2, defaultValue: 0 })} {__('грн')}
							</span>
						</div>
						<div className="cashbox__item">
							<span className="cashbox__item-label">{__('Приход')}:</span>
							<span className="cashbox__item-value cashbox__item-value--inflow">
								{formatDigits({ number: cashboxArchive.inflow, toFixed: 2, defaultValue: 0 })} {__('грн')}
							</span>
						</div>
						<div className="cashbox__item">
							<span className="cashbox__item-label">{__('Расход')}:</span>
							<span className="cashbox__item-value cashbox__item-value--outflow">
								{formatDigits({ number: cashboxArchive.outflow, toFixed: 2, defaultValue: 0 })} {__('грн')}
							</span>
						</div>
						<div className="cashbox__item">
							<span className="cashbox__item-label">{__('Сумма в кассе')}:</span>
							<span className="cashbox__item-value">
								<b>
									{formatDigits({ number: cashboxArchive.post, toFixed: 2, defaultValue: 0 })} {__('грн')}
								</b>
							</span>
						</div>
						<div className="cashbox__item">
							<span className="cashbox__item-label">{__('Терминал')}:</span>
							<span className="cashbox__item-value">
								<b>
									{formatDigits({ number: cashboxArchive.terminal, toFixed: 2, defaultValue: 0 })} {__('грн')}
								</b>
							</span>
						</div>
						<div className="cashbox__item">
							<span className="cashbox__item-label">{__('Безналичный расчет')}:</span>
							<span className="cashbox__item-value">
								{formatDigits({ number: cashboxArchive.cashless, toFixed: 2, defaultValue: 0 })} {__('грн')}
							</span>
						</div>
						<div className="cashbox__item">
							<span className="cashbox__item-label">{__('Всего')}:</span>
							<span className="cashbox__item-value">
								<b>
									{formatDigits({ number: cashboxArchive.total, toFixed: 2, defaultValue: 0 })} {__('грн')}
								</b>
							</span>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
