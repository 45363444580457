import { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useMethods, useData, setLocalStorage, SIP, wait } from 'utils';
import { LoadingOutlined, NotificationOutlined } from '@ant-design/icons';
import checkAudio from 'assets/audio/check-audio.mp3';

export const SettingsTab = () => {
	const { __ } = useMethods();
	const { audioOutputDevices = [], audioInputDevices = [], audioMicDeviceId, audioCallDeviceId, audioBellDeviceId } = useData();
	const [form] = Form.useForm();
	const [bellPending, setBellPending] = useState(false);
	const [callPending, setCallPending] = useState(false);

	useEffect(() => {
		form.setFieldsValue({
			bell: audioBellDeviceId,
			call: audioCallDeviceId,
			mic: audioMicDeviceId
		});
	}, [audioBellDeviceId, audioCallDeviceId, audioMicDeviceId]);

	const changeHandler = async (changedValues) => {
		const sipInstance = await SIP();
		if (changedValues.bell) {
			setLocalStorage('audioBellDeviceId', changedValues.bell);
			sipInstance.changeBellDevice(changedValues.bell);
		}
		if (changedValues.call) {
			setLocalStorage('audioCallDeviceId', changedValues.call);
			sipInstance.changeCallDevice(changedValues.call);
		}
		if (changedValues.mic) {
			setLocalStorage('audioMicDeviceId', changedValues.mic);
			sipInstance.changeMicDevice(changedValues.mic);
		}
	};

	const checkBellHandler = async () => {
		setBellPending(true);
		const { bell } = form.getFieldsValue();
		const audio = window.document.getElementById('sip-audio');
		if (bell) {
			await audio.setSinkId(bell);
		}
		audio.src = checkAudio;
		await wait(3700);
		audio.src = '';
		setBellPending(false);
	};

	const checkCallHandler = async () => {
		setCallPending(true);
		const { bell } = form.getFieldsValue();
		const audio = window.document.getElementById('sip-audio');
		if (bell) {
			await audio.setSinkId(bell);
		}
		audio.src = checkAudio;
		await wait(3700);
		audio.src = '';
		setCallPending(false);
	};

	return (
		<div className="call-modal__content">
			<Form form={form} layout="vertical" noValidate={true} onValuesChange={changeHandler}>
				<Form.Item className="form__item" name="bell" label={__('Аудиовыход для входящего звонка')}>
					<Select
						dropdownClassName="form__dropdown"
						options={audioOutputDevices.map((item) => ({ label: item.label, value: item.deviceId }))}
					/>
				</Form.Item>
				<div className="form__item">
					<button className="button button--main button--full" onClick={checkBellHandler}>
						{bellPending ? <LoadingOutlined /> : <NotificationOutlined />}
						<span>{__('Проверить звук')}</span>
					</button>
				</div>
				<Form.Item className="form__item" name="call" label={__('Аудиовыход для разговора')}>
					<Select
						dropdownClassName="form__dropdown"
						options={audioOutputDevices.map((item) => ({ label: item.label, value: item.deviceId }))}
					/>
				</Form.Item>
				<div className="form__item">
					<button className="button button--main button--full" onClick={checkCallHandler}>
						{callPending ? <LoadingOutlined /> : <NotificationOutlined />}
						<span>{__('Проверить звук')}</span>
					</button>
				</div>
				<Form.Item className="form__item" name="mic" label={__('Микрофон')}>
					<Select
						dropdownClassName="form__dropdown"
						options={audioInputDevices.map((item) => ({ label: item.label, value: item.deviceId }))}
					/>
				</Form.Item>
			</Form>
		</div>
	);
};
