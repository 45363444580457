import { useState } from 'react';
import { MainLayout } from 'layouts';
import { useMethods, access } from 'utils';
import { Tabs, Row, Col } from 'antd';
import { DocsTransactionsTab, DocsDishesTab, DocsCouriersTab, DocsSalesTab, DocsCheckTab, DocsRevenueTab, DocsSalaryTab } from 'components';

export const DocumentsPage = () => {
	const { __ } = useMethods();
	const [tab, setTab] = useState('transactions');

	return (
		<MainLayout seo={{ title: `${__('Документы')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				{access('docs-reports-page') ? (
					<Tabs.TabPane tab={__('Отчеты')} key="reports">
						<Row gutter={12} style={{ marginBottom: '20px' }}>
							{access('docs-reports-transactions-tab') ? (
								<Col style={{ marginBottom: '10px' }}>
									<button
										className={`button ${tab === 'transactions' ? 'button--main' : 'button--default'}`}
										onClick={() => setTab('transactions')}
									>
										<span>{__('О движении средств')}</span>
									</button>
								</Col>
							) : null}
							{access('docs-reports-dishes-tab') ? (
								<Col style={{ marginBottom: '10px' }}>
									<button
										className={`button ${tab === 'dishes' ? 'button--main' : 'button--default'}`}
										onClick={() => setTab('dishes')}
									>
										<span>{__('По рейтингу блюд')}</span>
									</button>
								</Col>
							) : null}
							{access('docs-reports-couriers-loading-tab') ? (
								<Col style={{ marginBottom: '10px' }}>
									<button
										className={`button ${tab === 'couriers' ? 'button--main' : 'button--default'}`}
										onClick={() => setTab('couriers')}
									>
										<span>{__('По загрузке курьеров')}</span>
									</button>
								</Col>
							) : null}
							{access('docs-reports-product-sales-tab') ? (
								<Col style={{ marginBottom: '10px' }}>
									<button
										className={`button ${tab === 'sales' ? 'button--main' : 'button--default'}`}
										onClick={() => setTab('sales')}
									>
										<span>{__('По продажам')}</span>
									</button>
								</Col>
							) : null}
							{access('docs-reports-average-check-tab') ? (
								<Col style={{ marginBottom: '10px' }}>
									<button
										className={`button ${tab === 'check' ? 'button--main' : 'button--default'}`}
										onClick={() => setTab('check')}
									>
										<span>{__('По среднему чеку')}</span>
									</button>
								</Col>
							) : null}
							{access('docs-reports-revenue') ? (
								<Col style={{ marginBottom: '10px' }}>
									<button
										className={`button ${tab === 'revenue' ? 'button--main' : 'button--default'}`}
										onClick={() => setTab('revenue')}
									>
										<span>{__('По выручке')}</span>
									</button>
								</Col>
							) : null}
							{access('docs-salary-expenses-percentage') ? (
								<Col style={{ marginBottom: '10px' }}>
									<button
										className={`button ${tab === 'salary' ? 'button--main' : 'button--default'}`}
										onClick={() => setTab('salary')}
									>
										<span>{__('По зарплате')}</span>
									</button>
								</Col>
							) : null}
						</Row>
						<Tabs className="tabs tabs--blocks" activeKey={tab}>
							{access('docs-reports-transactions-tab') ? (
								<Tabs.TabPane key="transactions">
									<DocsTransactionsTab />
								</Tabs.TabPane>
							) : null}
							{access('docs-reports-dishes-tab') ? (
								<Tabs.TabPane key="dishes">
									<DocsDishesTab />
								</Tabs.TabPane>
							) : null}
							{access('docs-reports-couriers-loading-tab') ? (
								<Tabs.TabPane key="couriers">
									<DocsCouriersTab />
								</Tabs.TabPane>
							) : null}
							{access('docs-reports-product-sales-tab') ? (
								<Tabs.TabPane key="sales">
									<DocsSalesTab />
								</Tabs.TabPane>
							) : null}
							{access('docs-reports-average-check-tab') ? (
								<Tabs.TabPane key="check">
									<DocsCheckTab />
								</Tabs.TabPane>
							) : null}
							{access('docs-reports-revenue') ? (
								<Tabs.TabPane key="revenue">
									<DocsRevenueTab />
								</Tabs.TabPane>
							) : null}
							{access('docs-salary-expenses-percentage') ? (
								<Tabs.TabPane key="salary">
									<DocsSalaryTab />
								</Tabs.TabPane>
							) : null}
						</Tabs>
					</Tabs.TabPane>
				) : null}
			</Tabs>
		</MainLayout>
	);
};
