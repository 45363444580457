import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseLayout } from 'layouts';
import { getSearch, preloaderShow, useData, useMethods } from 'utils';
import { getUserAction, loginAction } from 'actions';

export const ReloginPage = () => {
	const { seo = {}, homeUrl } = useData();
	const { update } = useMethods();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			preloaderShow();
			const values = getSearch();
			if (values.login && values.password) {
				const response = await loginAction(values);
				const { success } = response;
				if (success) {
					const user = await getUserAction();
					update({
						user
					});
				}
			}
			history.push(homeUrl);
		})();
	}, []);

	return <BaseLayout seo={seo} />;
};
