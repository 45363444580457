import { LoadingOutlined } from '@ant-design/icons';
import { dateClientFormat, paginationDefault } from 'const';
import { Table } from 'antd';
import { useMethods, useData, dayjs, getNegativeOrPositiveCurrencyFormat, formatDigits } from 'utils';

export const EmployeesPayrolls = (props) => {
	const { pending } = props;
	const { payrolls = {}, positions = [], payrollsFilters = {}, payrollsSelect = {} } = useData();
	const { __, update } = useMethods();

	return (
		<Table
			id="employees-payrolls"
			rowKey="employee_id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1200
			}}
			rowSelection={{
				selectedRowKeys: payrollsSelect.selectedRowKeys,
				onChange: (selectedRowKeys = [], selectedRows = []) =>
					update({
						payrollsSelect: {
							selectedRowKeys,
							selectedRows
						}
					})
			}}
			columns={[
				{
					title: __('Сотрудник'),
					dataIndex: 'employee',
					width: 180
				},
				{
					title: __('Должность'),
					dataIndex: 'position_id',
					width: 150,
					render: (positionId) => positions.find((item) => item.id === positionId)?.name || '-'
				},
				{
					title: __('Часов отработано'),
					dataIndex: 'total_hours',
					width: 130
				},
				{
					title: __('Смен отработано'),
					dataIndex: 'total_shift_count',
					width: 130
				},
				{
					title: __('За отработанные часы'),
					dataIndex: 'total_hour_rate',
					width: 150,
					render: (sum) => getNegativeOrPositiveCurrencyFormat({ number: sum, currency: __('грн') })
				},
				{
					title: __('За заказы'),
					dataIndex: 'total_order',
					width: 150,
					render: (sum) => getNegativeOrPositiveCurrencyFormat({ number: sum, currency: __('грн') })
				},
				{
					title: __('За доставку'),
					dataIndex: 'total_delivery',
					width: 150,
					render: (sum) => getNegativeOrPositiveCurrencyFormat({ number: sum, currency: __('грн') })
				},
				{
					title: __('Премия'),
					dataIndex: 'total_premium',
					width: 150,
					render: (sum) => getNegativeOrPositiveCurrencyFormat({ number: sum, currency: __('грн') })
				},
				{
					title: __('Штраф'),
					dataIndex: 'total_penalty',
					width: 150,
					render: (sum) => getNegativeOrPositiveCurrencyFormat({ number: sum, currency: __('грн') })
				},
				{
					title: () => `${__('Сумма к выплате на')} ${dayjs(payrollsFilters.date).format(dateClientFormat)}`,
					dataIndex: 'post',
					width: 150,
					render: (amount) => `${formatDigits({ number: amount })} ${__('грн')}`
				}
			]}
			dataSource={payrolls.employees || []}
			pagination={paginationDefault}
		/>
	);
};
