import { useState } from 'react';
import { Table, Tag, Popover, Tooltip, Row, Col, Popconfirm } from 'antd';
import {
	LoadingOutlined,
	PoweroffOutlined,
	PhoneOutlined,
	PrinterOutlined,
	SwitcherOutlined,
	FormOutlined,
	CheckOutlined,
	ProfileOutlined
} from '@ant-design/icons';
import { useData, useMethods, dayjs, phoneFormat, access, formatDigits, sortArray } from 'utils';
import { Link, ChangeOrderStatus, ShowOrderLogs, FinishOrderTerminal } from 'components';
import { dateTimeServerFormat, dateTimeClientFormat, paginationDefault, statusIcon, workshopIcon } from 'const';
import { ReactComponent as DeliveryCourierIcon } from 'assets/svg/delivery-courier.svg';
import { ReactComponent as DeliverySelfIcon } from 'assets/svg/delivery-self.svg';
import {
	changeStatusAction,
	printFiscalInvoiceAction,
	printInvoiceAction,
	printInvoiceClientAction,
	printReturnInvoiceAction,
	sipCallAction
} from 'actions';

const intervalTimers = [];

export const ActiveOrders = (props) => {
	const { pending } = props;
	const {
		orders = [],
		orderTypes = [],
		paymentTypes = [],
		deliveryTypes = [],
		couriers = [],
		orderStatuses = [],
		entrepreneurs = [],
		ordersFilters = {},
		ordersStatistic = {}
	} = useData();
	const { custom = [] } = ordersStatistic;
	const { __ } = useMethods();
	const [invoiceId, setInvoiceId] = useState(0);
	const [fiscalInvoiceId, setFiscalInvoiceId] = useState(0);
	const [returnInvoiceId, setReturnInvoiceId] = useState(0);

	const filterCouriers = couriers
		.filter((item) => orders.filter((order) => +order.courier_id === item.id).length)
		.map((item) => ({ text: item.name, value: item.id }));

	const data = sortArray(orders, ['delivery_date', true, 'd'], ['status_id', false, 'n'])
		.filter((item) => {
			let result = true;

			Object.keys(ordersFilters).forEach((key) => {
				const value = ordersFilters[key];
				let res = false;

				if (key === 'status') {
					if (value === 'in_work') {
						res = ![9, 10, 12].includes(item.status_id); // Все заказы на сегодня кроме выполнен, отказано, отложен
					} else if (value === 'self_delivery') {
						res = item.delivery_type_id === 2; // Самовывоз
					} else if (value === 'is_pre_order') {
						res = item.is_pre_order; // Предзаказ
					} else if (value === 'is_from_site') {
						res = item.is_from_site && item.status_id !== 1; // С сайта обработанные
					} else if (value === 'is_from_mobile') {
						res = item.is_from_mobile && item.status_id !== 1; // С приложения обработанные
					} else if (value === 'is_from_site_clarify') {
						res = item.is_from_site && item.status_id === 1; // С сайта не обработанные
					} else if (+value === +item.status_id || value === custom[0]?.alias) {
						res = true;
					}
				} else if (['phone', 'address_name'].includes(key) && value) {
					res = item[key]?.toString().toLowerCase().includes(value.toString().toLowerCase().trim());
				} else if (value) {
					res = item[key] === value;
				} else {
					res = true;
				}

				result = result && res;
			});

			return result;
		})
		.map((item) => {
			const now = dayjs();
			const deliveryDate = dayjs(`${item.delivery_date} ${item.delivery_time}`, dateTimeServerFormat);
			const timeLeft = deliveryDate.diff(now, 'minute');
			return {
				...item,
				timeLeft
			};
		});

	const printInvoiceHandler = async (id) => {
		setInvoiceId(id);
		await printInvoiceAction(id);
		setInvoiceId(0);
	};

	const printInvoiceClientHandler = async (id) => {
		setInvoiceId(id);
		await printInvoiceClientAction(id);
		setInvoiceId(0);
	};

	const printReturnInvoiceHandler = async (id) => {
		setReturnInvoiceId(id);
		await printReturnInvoiceAction(id);
		setReturnInvoiceId(0);
	};

	const printFiscalInvoiceHandler = async (id) => {
		setFiscalInvoiceId(id);
		await printFiscalInvoiceAction(id);
		setFiscalInvoiceId(0);
	};

	return (
		<Table
			id="active-orders"
			rowKey="id"
			className="table table--order"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1600
			}}
			rowClassName={(record) => {
				const additionalClass = [];
				if (record.is_from_site) {
					additionalClass.push('is-from-site');
				}
				if (record.timeLeft < 0) {
					additionalClass.push('is-expired');
				} else if (record.status_id === 1) {
					additionalClass.push('is-created');
				} else if (record.is_late) {
					additionalClass.push('is-late');
				} else if (record.status_id === 12 && ordersFilters.status !== 12) {
					additionalClass.push('is-delayed');
				} else if (record.status_id === 8 && ordersFilters.status !== 8) {
					additionalClass.push('is-delivered');
				} else if (record.status_id === 5 && ordersFilters.status !== 5) {
					additionalClass.push('is-picked-up');
				} else if (record.status_id === 7 && ordersFilters.status !== 7) {
					additionalClass.push('is-delivering');
				} else if (record.status_id === 6 && ordersFilters.status !== 6) {
					additionalClass.push('is-courier');
				}
				return additionalClass.join(' ');
			}}
			columns={[
				{
					title: __('ФИО клиента'),
					dataIndex: 'client_name',
					width: 130,
					render: (value, record) => <Link href={`/orders/${record.id}`}>{value}</Link>
				},
				{
					title: __('Предзаказ'),
					dataIndex: 'is_pre_order',
					width: 80,
					render: (value) => {
						return value ? <Tag>{__('ПРЕДЗАКАЗ')}</Tag> : '-';
					}
				},
				{
					title: __('Номер'),
					dataIndex: 'day_id',
					width: 50,
					render: (value) => value || '-'
				},
				{
					title: __('Цех'),
					dataIndex: 'workshops',
					width: 90,
					render: (workshops) =>
						workshops &&
						workshops.map((value, index) => (
							<button className="button button--trans button--icon-def" key={index}>
								{workshopIcon[value]}
							</button>
						))
				},
				{
					title: __('Тип Заказа'),
					dataIndex: 'order_type_id',
					width: 80,
					render: (value) => orderTypes.find(({ id }) => id === value)?.name || '-'
				},
				{
					title: __('Дата/Время создания'),
					dataIndex: 'created_at',
					width: 90,
					render: (value) => (value ? dayjs(value, dateTimeServerFormat).format(dateTimeClientFormat) : '-')
				},
				{
					title: __('Дата/Время доставки'),
					dataIndex: 'delivery_date',
					width: 90,
					render: (value, record) => {
						return (
							<div>
								{record.on_ready_delivery ? (
									<>
										<Tag>{__('ближайшее')}</Tag>
										<br />
									</>
								) : null}
								{value && record.delivery_time
									? dayjs(`${value} ${record.delivery_time}`, dateTimeServerFormat).format(dateTimeClientFormat)
									: '-'}
							</div>
						);
					}
				},
				{
					title: __('Тип оплаты'),
					dataIndex: 'payment_type_id',
					width: 80,
					render: (value) => paymentTypes.find(({ id }) => id === value)?.name || '-'
				},
				{
					title: __('Сумма, грн'),
					dataIndex: 'price',
					width: 70,
					render: (value) => formatDigits({ number: value, toFixed: 2 })
				},
				{
					title: __('Таймер'),
					dataIndex: 'timer',
					width: 100,
					render: (value, record) => {
						if (!intervalTimers[`${record.id}`]) {
							intervalTimers[`${record.id}`] = null;
						}
						// Доставлено, Выполнен, Отказано, Предзаказ, Отложено
						if ([8, 9, 10, 11, 12].includes(record.status_id)) return '-';

						const el = window.document.getElementById(`order-countdown-${record.id}`);

						const setTime = () => {
							const hours = Math.floor(Math.abs(record.timeLeft) / 60);
							const minutes = Math.floor(Math.abs(record.timeLeft) - hours * 60);
							if (el) {
								el.innerHTML = `${record.timeLeft < 0 ? '-' : ''}${hours > 9 ? hours : `0${hours}`}:${
									minutes > 9 ? minutes : `0${minutes}`
								}`;
							}
						};

						clearInterval(intervalTimers[`${record.id}`]);
						intervalTimers[`${record.id}`] = setInterval(() => {
							record.timeLeft--;
							setTime();
						}, 1000 * 60);
						setTime();

						return <div className="countdown" id={`order-countdown-${record.id}`} />;
					}
				},
				{
					title: __('Адрес'),
					dataIndex: 'address_name',
					width: 200,
					render: (value, record) => (record.delivery_type_id !== 2 ? value : '-')
				},
				{
					title: __('Доставка'),
					dataIndex: 'courier_id',
					width: 100,
					filters: filterCouriers.length ? filterCouriers : false,
					onFilter: (value, record) => record.courier_id === value,
					render: (value, record) => {
						const name = deliveryTypes.find(({ id }) => id === record.delivery_type_id)?.name;
						if (record.delivery_type_id === 1 || !record.delivery_type_id) {
							const courier = couriers.find((item) => item.id === value);
							const orders = courier?.orders.reduce(
								(a, order) => {
									if (+order.status_id === 9) {
										// 9 - Выполнен
										return a;
									}
									if (!order.is_trip_finished) {
										a.length++;
									}
									if (order.payment_type_id === 1) {
										a.total += order.total;
									}
									return a;
								},
								{ length: 0, total: 0 }
							);

							return courier ? (
								<Popover
									content={
										<div>
											<p>
												<b>{courier.name}</b>
											</p>
											<p>
												{__('Количество заказов')}:{' '}
												<b>
													{orders.length} {__('шт')}
												</b>
											</p>
											<p>
												{__('Сумма к сдаче')}:{' '}
												<b>
													{orders.total.toFixed(2)} {__('грн')}
												</b>
											</p>
											<p>
												{__('Телефон')}:{' '}
												{courier.phone ? (
													<span className="link" onClick={() => sipCallAction(courier.phone)}>
														{phoneFormat(courier.phone)}
													</span>
												) : (
													'-'
												)}
											</p>
										</div>
									}
									placement="topRight"
								>
									<h4 className="courier-link">{courier.name}</h4>
								</Popover>
							) : (
								<Tooltip title={name} placement="right">
									<button className="button button--trans button--icon-def">
										<DeliveryCourierIcon />
									</button>
								</Tooltip>
							);
						} else {
							if (record.delivery_type_id === 2) {
								return (
									<Tooltip title={name} placement="right">
										<button className="button button--trans button--icon-def">
											<DeliverySelfIcon />
										</button>
									</Tooltip>
								);
							} else {
								return name ? <Tag>{name}</Tag> : '-';
							}
						}
					}
				},
				{
					title: __('Примечание'),
					dataIndex: 'description',
					width: 200
				},
				{
					title: __('Статус заказа'),
					dataIndex: 'status_id',
					width: 110,
					render: (value, record) => {
						const name = orderStatuses.find((item) => item.id === value)?.name || __('Неизвестный статус');
						return (
							<Row align="middle" gutter={5}>
								<Col>
									<ChangeOrderStatus order={record}>
										<Tooltip title={name}>
											<button className="button button--trans button--icon-def">{statusIcon[value] || name}</button>
										</Tooltip>
									</ChangeOrderStatus>
								</Col>
								{value === 1 || value === 12 ? (
									<Col>
										<Tooltip title={'Готовится'}>
											<button
												className="button button--trans button--icon-xs"
												onClick={() => changeStatusAction(record.id, { status_id: 2 })}
											>
												<PoweroffOutlined />
											</button>
										</Tooltip>
									</Col>
								) : null}
							</Row>
						);
					}
				},
				{
					title: __('Действие'),
					width: 120,
					align: 'right',
					render: (value, record) => {
						const phone = record.phone || '';
						return (
							<Row gutter={5} justify="end">
								<Col>
									<Popover
										content={
											phone ? (
												<div className="link" onClick={() => sipCallAction(phone)}>
													{phoneFormat(phone)}
												</div>
											) : (
												'-'
											)
										}
										placement="topRight"
									>
										<button className="button button--trans button--icon-xs">
											<PhoneOutlined />
										</button>
									</Popover>
								</Col>
								{record.status_id === 1 ? null : (
									<Col>
										<Popconfirm
											placement="topRight"
											title={__('Распечатать чек')}
											onConfirm={() => printInvoiceHandler(record.id)}
											onCancel={() => printInvoiceClientHandler(record.id)}
											okText={__('Полная печать')}
											cancelText={__('Печать предчека')}
										>
											<Tooltip title={__('Печатать чек')} placement={'rightBottom'}>
												<button className="button button--trans button--icon-xs">
													{invoiceId === record.id ? <LoadingOutlined /> : <PrinterOutlined />}
												</button>
											</Tooltip>
										</Popconfirm>
									</Col>
								)}
								{access('orders-log') ? (
									<Col>
										<ShowOrderLogs orderId={record.id}>
											<Tooltip title={__('Показать логи заказа')} placement="topRight">
												<button className="button button--trans button--icon-xs">
													<FormOutlined />
												</button>
											</Tooltip>
										</ShowOrderLogs>
									</Col>
								) : null}
								{record.has_done_button && access('orders-create') ? (
									<Col>
										<Tooltip title={__('Завершить заказ')}>
											<FinishOrderTerminal order={record}>
												<button className="button button--trans button--icon-xs">
													<CheckOutlined />
												</button>
											</FinishOrderTerminal>
										</Tooltip>
									</Col>
								) : null}
								{access('order-fiscal-invoice-printing') && entrepreneurs.length && record.status_id >= 5 ? (
									record.is_fiscal_printed ? (
										<Col>
											<Tooltip title={__('Печатать чек отмены')} placement={'rightBottom'}>
												<button
													className="button button--trans button--icon-xs"
													onClick={() => printReturnInvoiceHandler(record.id)}
												>
													{returnInvoiceId === record.id ? <LoadingOutlined /> : <SwitcherOutlined />}
												</button>
											</Tooltip>
										</Col>
									) : (
										<Col>
											<Tooltip title={__('Печатать фискальный чек')} placement={'rightBottom'}>
												<button
													className="button button--trans button--icon-xs"
													onClick={() => printFiscalInvoiceHandler(record.id)}
												>
													{fiscalInvoiceId === record.id ? <LoadingOutlined /> : <ProfileOutlined />}
												</button>
											</Tooltip>
										</Col>
									)
								) : null}
							</Row>
						);
					}
				}
			]}
			dataSource={data}
			pagination={{
				...paginationDefault,
				defaultPageSize: 50
			}}
		/>
	);
};
