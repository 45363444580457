import { Table, Tag, Tooltip, Row, Col, Popconfirm } from 'antd';
import { LoadingOutlined, PrinterOutlined, FormOutlined, ProfileOutlined, SwitcherOutlined, CheckOutlined } from '@ant-design/icons';
import { useData, useMethods, dayjs, access, phoneFormat, formatDigits } from 'utils';
import { Link, ChangeOrderStatus, ShowOrderLogs } from 'components';
import { dateTimeServerFormat, dateTimeClientFormat, paginationDefault, statusIcon } from 'const';
import {
	getFinishedOrdersAction,
	getFinishedOrdersStatisticAction,
	printFiscalInvoiceAction,
	printInvoiceAction,
	printInvoiceClientAction,
	printReturnInvoiceAction,
	sipCallAction
} from 'actions';
import { ReactComponent as DeliveryCourierIcon } from 'assets/svg/delivery-courier.svg';
import { ReactComponent as DeliverySelfIcon } from 'assets/svg/delivery-self.svg';
import { useState } from 'react';

export const FinishedOrders = (props) => {
	const { pending, setPending } = props;
	const {
		finishedOrders = {},
		paymentTypes = [],
		couriers = [],
		orderStatuses = [],
		entrepreneurs = [],
		finishedOrdersFilters = {},
		deliveryTypes = []
	} = useData();
	const { data = [], meta = {} } = finishedOrders;
	const { current_page = 1, total = 0, per_page = 10 } = meta;
	const { __, update } = useMethods();
	const [invoiceId, setInvoiceId] = useState(0);
	const [fiscalInvoiceId, setFiscalInvoiceId] = useState(0);
	const [returnInvoiceId, setReturnInvoiceId] = useState(0);

	const filterCouriers = couriers.map((item) => ({ text: item.name, value: item.id }));

	const printInvoiceHandler = async (id) => {
		setInvoiceId(id);
		await printInvoiceAction(id);
		setInvoiceId(0);
	};

	const printInvoiceClientHandler = async (id) => {
		setInvoiceId(id);
		await printInvoiceClientAction(id);
		setInvoiceId(0);
	};

	const printFiscalInvoiceHandler = async (id) => {
		setFiscalInvoiceId(id);
		await printFiscalInvoiceAction(id);
		setFiscalInvoiceId(0);
	};

	const printReturnInvoiceHandler = async (id) => {
		setReturnInvoiceId(id);
		await printReturnInvoiceAction(id);
		setReturnInvoiceId(0);
	};

	const tableChangeHandler = async (pagination, filters) => {
		setPending(true);
		const tableFilters = {
			couriers: []
		};
		Object.keys(filters).forEach((filter) => {
			if (filters[filter] && filters[filter].length) {
				if (filter === 'courier_id') {
					tableFilters['couriers'] = filters[filter];
				}
			}
		});
		const filter = {
			...finishedOrdersFilters,
			page: pagination.current,
			per_page: pagination.pageSize,
			...tableFilters
		};
		const [finishedOrdersStatistic, finishedOrders] = await Promise.all([
			getFinishedOrdersStatisticAction(filter),
			getFinishedOrdersAction(filter)
		]);
		setPending(false);
		update({
			finishedOrdersFilters: filter,
			finishedOrdersStatistic,
			finishedOrders
		});
	};

	return (
		<Table
			id="finished-orders"
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1400
			}}
			columns={[
				{
					title: __('ФИО клиента'),
					dataIndex: 'client_name',
					width: 130,
					render: (value, record) => <Link href={`/orders/${record.id}`}>{value}</Link>
				},
				{
					title: __('Номер заказа'),
					dataIndex: 'day_id',
					width: 80,
					render: (value) => value || '-'
				},
				{
					title: __('Дата/Время создания'),
					dataIndex: 'created_at',
					width: 110,
					render: (value) => (value ? dayjs(value, dateTimeServerFormat).format(dateTimeClientFormat) : '-')
				},
				{
					title: __('Дата/Время доставки'),
					dataIndex: 'delivery_date',
					width: 110,
					render: (value, record) => {
						return (
							<div>
								{record.on_ready_delivery ? (
									<>
										<Tag>{__('ближайшее')}</Tag>
										<br />
									</>
								) : null}
								{value && record.delivery_time
									? dayjs(`${value} ${record.delivery_time}`, dateTimeServerFormat).format(dateTimeClientFormat)
									: '-'}
							</div>
						);
					}
				},
				{
					title: __('Тип оплаты'),
					dataIndex: 'payment_type_id',
					width: 120,
					render: (value) => paymentTypes.find(({ id }) => id === value)?.name || '-'
				},
				{
					title: __('Сумма, грн'),
					dataIndex: 'price',
					width: 70,
					render: (value) => formatDigits({ number: value, toFixed: 2 })
				},
				{
					title: __('Телефон'),
					dataIndex: 'phone',
					width: 120,
					render: (value) =>
						value ? (
							<div className="link" onClick={() => sipCallAction(value)}>
								{phoneFormat(value)}
							</div>
						) : (
							'-'
						)
				},
				{
					title: __('Адрес'),
					dataIndex: 'address_name',
					width: 200,
					render: (value, record) => (record.delivery_type_id !== 2 ? value : '-')
				},
				{
					title: __('Доставка'),
					dataIndex: 'courier_id',
					width: 100,
					filters: filterCouriers.length ? filterCouriers : false,
					filteredValue: finishedOrdersFilters.couriers || null,
					render: (value, record) => {
						const name = deliveryTypes.find(({ id }) => id === record.delivery_type_id)?.name;
						const courier = couriers.find((item) => item.id === value)?.name;
						if (courier) {
							return courier;
						}
						if (record.delivery_type_id === 1) {
							return (
								<Tooltip title={name} placement="right">
									<button className="button button--trans button--icon-def">
										<DeliveryCourierIcon />
									</button>
								</Tooltip>
							);
						} else if (record.delivery_type_id === 2) {
							return (
								<Tooltip title={name} placement="right">
									<button className="button button--trans button--icon-def">
										<DeliverySelfIcon />
									</button>
								</Tooltip>
							);
						} else {
							return name ? <Tag>{name}</Tag> : '-';
						}
					}
				},
				{
					title: __('Причина отказа'),
					dataIndex: 'rejection_reason',
					width: 150,
					render: (rejection_reason) => rejection_reason || '-'
				},
				{
					title: __('Примечание'),
					dataIndex: 'notes',
					width: 150,
					render: (notes) => notes || '-'
				},
				{
					title: __('Фискальный чек'),
					dataIndex: 'is_fiscal_printed',
					width: 110,
					render: (value) =>
						value ? (
							<button className="button button--trans button--icon-xs">
								<CheckOutlined />
							</button>
						) : null
				},
				{
					title: __('Статус заказа'),
					dataIndex: 'status_id',
					width: 110,
					render: (value, record) => {
						const name = orderStatuses.find((item) => item.id === value)?.name || __('Неизвестный статус');
						return (
							<Tooltip title={name}>
								<ChangeOrderStatus order={record}>
									<button className="button button--trans button--icon-xs">{statusIcon[value] || name}</button>
								</ChangeOrderStatus>
							</Tooltip>
						);
					}
				},
				{
					title: __('Действие'),
					width: 150,
					align: 'right',
					render: (value, record) => {
						return (
							<Row gutter={5} justify="end">
								<Col>
									<Popconfirm
										placement="topRight"
										title={__('Распечатать чек')}
										onConfirm={() => printInvoiceHandler(record.id)}
										onCancel={() => printInvoiceClientHandler(record.id)}
										okText={__('Полная печать')}
										cancelText={__('Печать предчека')}
									>
										<Tooltip title={__('Печатать чек')} placement={'rightBottom'}>
											<button className="button button--trans button--icon-xs">
												{invoiceId === record.id ? <LoadingOutlined /> : <PrinterOutlined />}
											</button>
										</Tooltip>
									</Popconfirm>
								</Col>
								{access('orders-log') ? (
									<Col>
										<Tooltip title={__('Показать логи заказа')} placement="topRight">
											<ShowOrderLogs orderId={record.id}>
												<button className="button button--trans button--icon-xs">
													<FormOutlined />
												</button>
											</ShowOrderLogs>
										</Tooltip>
									</Col>
								) : null}
								{access('order-fiscal-invoice-printing') && entrepreneurs.length ? (
									record.is_fiscal_printed ? (
										<Col>
											<Tooltip title={__('Печатать чек отмены')} placement={'rightBottom'}>
												<button
													className="button button--trans button--icon-xs"
													onClick={() => printReturnInvoiceHandler(record.id)}
												>
													{returnInvoiceId === record.id ? <LoadingOutlined /> : <SwitcherOutlined />}
												</button>
											</Tooltip>
										</Col>
									) : (
										<Col>
											<Tooltip title={__('Печатать фискальный чек')} placement={'rightBottom'}>
												<button
													className="button button--trans button--icon-xs"
													onClick={() => printFiscalInvoiceHandler(record.id)}
												>
													{fiscalInvoiceId === record.id ? <LoadingOutlined /> : <ProfileOutlined />}
												</button>
											</Tooltip>
										</Col>
									)
								) : null}
							</Row>
						);
					}
				}
			]}
			dataSource={data}
			pagination={{
				...paginationDefault,
				current: current_page,
				pageSize: +per_page,
				total
			}}
			onChange={tableChangeHandler}
		/>
	);
};
