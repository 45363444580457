import { Table, Row, Col, Tooltip, Tag } from 'antd';
import { useData, useMethods, formatDigits, dayjs, makeMonths, isNumeric, ucFirst } from 'utils';
import { paginationDefault, dateClientFormat } from 'const';
import { ReloadOutlined } from '@ant-design/icons';
import { getOrderAction } from 'actions';
import { useHistory } from 'react-router-dom';

export const ClientOrders = () => {
	const { clientOrders = [], oldClient = {}, couriers = [], orderStatuses = [] } = useData();
	const { phones = [], addresses = [] } = oldClient;
	const { __, update } = useMethods();
	const history = useHistory();

	const repeatOrderHandler = async (id) => {
		const order = await getOrderAction(id);
		update({
			orderRepeat: {
				...order,
				status_id: 1
			}
		});
		history.push(`/orders/create`);
	};

	return (
		<Table
			className="table"
			scroll={scroll}
			rowKey={(record) => record.id}
			dataSource={clientOrders}
			pagination={paginationDefault}
			title={() => {
				const orders = {
					count: clientOrders.length,
					total: formatDigits({ number: clientOrders.reduce((sum, order) => sum + order.price, 0) })
				};
				return (
					<Row gutter={12} justify="end">
						<Col>
							<span>
								{__('Всего заказов')}: <strong>{orders.count}</strong>
							</span>
						</Col>
						<Col>
							<span>
								{__('Сумма заказов')}:{' '}
								<strong>
									{orders.total} {__('грн')}
								</strong>
							</span>
						</Col>
					</Row>
				);
			}}
			columns={[
				{
					title: __('Номер заказа'),
					dataIndex: 'day_id',
					render: (dayId) => dayId || '-'
				},
				{
					title: __('Дата и время'),
					dataIndex: 'created_at',
					filters: makeMonths().map((item) => ({ text: item.label, value: item.value })),
					onFilter: (value, record) => dayjs(record.delivery_date).format('M') === value,
					render: (date) => dayjs(date).format(dateClientFormat)
				},
				{
					title: __('Сумма заказа'),
					dataIndex: 'price',
					render: (sum) => `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`
				},
				{
					title: __('Номер телефона'),
					dataIndex: 'phone_id',
					render: (id) => {
						if (isNumeric(id)) {
							return phones.find((item) => item.id === id)?.value || '-';
						} else {
							return phones.find((item) => item.main)?.value || '-';
						}
					}
				},
				{
					title: __('Адрес'),
					dataIndex: 'address_id',
					render: (id) => {
						if (isNumeric(id)) {
							return addresses.find((item) => item.id === id)?.title || '-';
						} else {
							return addresses.find((item) => item.main)?.title || '-';
						}
					}
				},
				{
					title: __('Курьер'),
					dataIndex: 'courier_id',
					render: (id) => couriers.find((item) => item.id === id)?.name || '-'
				},
				{
					title: __('Статус'),
					dataIndex: 'status_id',
					render: (id) => {
						const status = orderStatuses.find((item) => item.id === id);
						const name = status?.name || __('Неизвестный');
						const color = status?.color || null;
						return <Tag color={color}>{name}</Tag>;
					}
				},
				{
					title: __('Действия'),
					dataIndex: 'id',
					align: 'right',
					render: (orderId, record) => {
						return record.day_id?.toLowerCase()?.includes('довоз') ? null : (
							<Tooltip placement="topRight" title={__('Повторить заказ')}>
								<button className="button button--main-border button--icon-small" onClick={() => repeatOrderHandler(orderId)}>
									<ReloadOutlined />
								</button>
							</Tooltip>
						);
					}
				}
			]}
			expandedRowRender={(record) => (
				<Table
					rowKey={(record) => record.id}
					className="table"
					rowClassName={(record) => {
						return record.ingredientable && record.ingredients && record.ingredients.length ? null : 'no-expand';
					}}
					dataSource={record.products}
					pagination={false}
					columns={[
						{
							title: __('Наименование'),
							dataIndex: 'name',
							render: (name) => <strong>{ucFirst(name)}</strong>
						},
						{
							title: __('Цена'),
							dataIndex: 'price',
							render: (price) => `${formatDigits({ number: price, toFixed: 2 })} ${__('грн')}`
						},
						{
							title: __('Количество'),
							dataIndex: 'quantity',
							render: (quantity) => quantity || 0
						},
						{
							title: __('Сумма'),
							dataIndex: 'total',
							render: (text, record) => {
								const sum = record.price * record.quantity;
								return `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`;
							}
						},
						{
							title: __('Примечание'),
							dataIndex: 'comment',
							render: (comment) => comment || '-'
						}
					]}
					expandedRowRender={(record) =>
						record.ingredients && record.ingredients.length ? (
							<Table
								rowKey={(record) => record.id}
								className="table"
								dataSource={record.ingredients}
								pagination={false}
								columns={[
									{
										title: __('Наименование'),
										dataIndex: 'name',
										render: (name) => <strong>{name}</strong>
									},
									{
										title: __('Цена'),
										dataIndex: 'price',
										render: (price) => `${formatDigits({ number: price, toFixed: 2 })} ${__('грн')}`
									},
									{
										title: __('Вес'),
										dataIndex: 'weight',
										render: (weight) => `${weight} ${__('г')}`
									},
									{
										title: __('Количество'),
										dataIndex: 'quantity',
										render: (quantity) => quantity || 0
									},
									{
										title: __('Сумма'),
										dataIndex: 'total',
										render: (total, ingredient) => {
											const sum = ingredient.price * ingredient.quantity;
											return `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`;
										}
									}
								]}
							/>
						) : null
					}
				/>
			)}
		/>
	);
};
