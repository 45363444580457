import { useEffect, useState } from 'react';
import { useMethods, makeYears, makeMonths, dayjs, useData } from 'utils';
import { DocsSalesFilter, DocsSales } from 'components';
import { getRestaurantsAction, getReportsSalesAction } from 'actions';

export const DocsSalesTab = () => {
	const { user = {} } = useData();
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const restaurants = await getRestaurantsAction();
			const docsSalesFiltersDefault = {
				restaurant_id: null,
				category_ids: [],
				product_name: null,
				date_from: dayjs().subtract(1, 'month'),
				date_to: dayjs()
			};
			const docsSalesFilters = {
				...docsSalesFiltersDefault,
				restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id
			};
			const docsSales = await getReportsSalesAction(docsSalesFilters);
			setPending(false);
			update({
				docsSalesFiltersDefault,
				docsSalesFilters,
				restaurants,
				docsSales,
				years: makeYears(),
				months: makeMonths(__)
			});
		})();

		return () => {
			update({
				docsSalesFiltersDefault: {},
				docsSalesFilters: {},
				docsSales: {}
			});
		};
	}, []);

	return (
		<>
			<DocsSalesFilter setPending={setPending} />
			<DocsSales pending={pending} />
		</>
	);
};
