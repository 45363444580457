import { useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { dateClientFormat } from 'const';
import { ClearOutlined, PlusOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import { print, useMethods, useData, dayjs } from 'utils';
import { getShiftsAction } from 'actions';
import { AddAttendance, RangePickerField } from 'components';

export const EmployeesShiftsFilter = (props) => {
	const { setPending } = props;
	const { employees = [], departments = [], positions = [], shiftsFiltersDefault = {}, shiftsFilters = {} } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(shiftsFiltersDefault);
	}, [shiftsFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...shiftsFilters,
			...values,
			page: 1
		};
		const shifts = await getShiftsAction(filter);
		setPending(false);
		update({
			shiftsFilters: filter,
			shifts
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(shiftsFiltersDefault);
		const shifts = await getShiftsAction();
		setPending(false);
		update({
			shiftsFilters: shiftsFiltersDefault,
			shifts
		});
	};

	const printTable = () => {
		print('#employees-shifts .ant-table-content', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};

	const updateHandler = async () => {
		setPending(true);
		const shifts = await getShiftsAction(shiftsFilters);
		setPending(false);
		update({
			shifts
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__buttons">
				<div className="filter__button">
					<AddAttendance>
						<Tooltip title={__('Добавить посещение')} placement="topLeft">
							<button type="button" className="button button--main button--icon-small">
								<PlusOutlined />
							</button>
						</Tooltip>
					</AddAttendance>
				</div>
			</div>
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" label={__('ФИО сотрудника')} name="employee">
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									dropdownClassName="form__dropdown"
									options={employees.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
									placeholder={__('Не выбрано')}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="department" label={__('Отдел')}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									dropdownClassName="form__dropdown"
									options={departments.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="position" label={__('Должность')}>
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									dropdownClassName="form__dropdown"
									options={positions.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="date" label={__('Период времени')}>
								<RangePickerField
									allowClear={true}
									format={dateClientFormat}
									showTime={false}
									separator="—"
									disabledDate={(current) => current && current.endOf('day') >= dayjs().endOf('day')}
								/>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Распечатать')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={printTable}>
							<PrinterOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
