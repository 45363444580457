import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { useMethods, useData, formatDigits } from 'utils';

export const DocsDishes = (props) => {
	const { pending } = props;
	const { docsDishes = [] } = useData();
	const { __ } = useMethods();
	const regex = /,/g;
	const japaneseMenu = [84, 85, 132, 139, 95, 96, 97];

	const columns = [
		{
			title: __('Блюдо'),
			dataIndex: 'title',
			sorter: (a, b) => a.title.localeCompare(b.title)
		},
		{
			title: __('Группа'),
			dataIndex: 'category',
			sorter: (a, b) => a.category.localeCompare(b.category),
			render: (data, record) => {
				return {
					props: {
						className: 'table__td-no-padding'
					},
					children: (
						<div className="table__td-padding" style={{ backgroundColor: record.color }}>
							{data}
						</div>
					)
				};
			}
		},
		{
			title: __('% от выручки'),
			dataIndex: 'revenue_percent',
			sorter: (a, b) => a.revenue_percent - b.revenue_percent,
			render: (data) => {
				return formatDigits({ number: data || 0, toFixed: 2, defaultValue: 0 });
			}
		},
		{
			title: __('Валовая за ед.'),
			dataIndex: 'gross_price',
			sorter: (a, b) => a.gross_price - b.gross_price,
			render: (data) => {
				return formatDigits({ number: data || 0, toFixed: 2, defaultValue: 0 });
			}
		},
		{
			title: __('Кол-во'),
			dataIndex: 'count',
			sorter: (a, b) => a.count - b.count
		},
		{
			title: __('Выручка'),
			dataIndex: 'revenue',
			sorter: (a, b) => a.revenue - b.revenue,
			render: (data) => {
				return formatDigits({ number: data || 0, toFixed: 2, defaultValue: 0 });
			}
		},
		{
			title: __('Себес.'),
			dataIndex: 'total_cost',
			sorter: (a, b) => a.total_cost - b.total_cost,
			render: (data) => {
				return formatDigits({ number: data || 0, toFixed: 2, defaultValue: 0 });
			}
		},
		{
			title: __('Себес., %'),
			dataIndex: 'total_cost_percent',
			sorter: (a, b) => a.total_cost_percent - b.total_cost_percent,
			render: (data) => {
				return formatDigits({ number: data || 0, toFixed: 2, defaultValue: 0 });
			}
		},
		{
			title: __('Вал. прибыль'),
			dataIndex: 'gross_profit',
			sorter: (a, b) => a.gross_profit - b.gross_profit,
			render: (data) => {
				return formatDigits({ number: data || 0, toFixed: 2, defaultValue: 0 });
			}
		}
	];

	const list = [];
	const japaneseDish = {
		index: 0,
		isFoot: true,
		title: __('Японское меню'),
		children: []
	};
	docsDishes.forEach((dishe, index) => {
		if (japaneseMenu.includes(dishe.id) && dishe.items.length) {
			japaneseDish.children.push(...(dishe.items || []));
			Object.entries(dishe.summary || {}).forEach(([k, v]) => {
				if (japaneseDish[k]) {
					japaneseDish[k] += v ? (typeof v === 'number' ? v : +v.replace(regex, '') || 0) : 0;
				} else {
					japaneseDish[k] = v ? (typeof v === 'number' ? v : +v.replace(regex, '') || 0) : 0;
				}
			});
		} else if (dishe.items.length) {
			list.push({
				index: index + 1,
				isFoot: true,
				...dishe.summary,
				title: dishe.title,
				children: dishe.items || []
			});
		}
	});
	if (japaneseDish.children.length) {
		list.unshift(japaneseDish);
	}

	return (
		<Table
			rowKey="index"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			rowClassName={(record) => (record.isFoot ? 'is-foot' : '')}
			columns={columns}
			dataSource={list}
			pagination={false}
		/>
	);
};
