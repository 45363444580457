import { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { phoneFormat, requiredRule, useMethods } from 'utils';
import { Modal } from 'components';

export const AddNewClient = (props) => {
	const { children, phone, onSubmit } = props;
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const { __ } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				phone: phoneFormat(phone),
				name: ''
			});
		}
	}, [visible]);

	const submitHandler = (values) => {
		if (onSubmit) {
			onSubmit(values);
		}
		setVisible(false);
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Добавление нового клиента')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Form.Item className="form__item" name="phone" label={__('Телефон')}>
							<Input addonBefore="+38" disabled />
						</Form.Item>
						<Form.Item className="form__item" name="name" required label={__('ФИО')} rules={[requiredRule(__)]}>
							<Input />
						</Form.Item>
						<div className="form__button">
							<button className="button button--main button--full">
								<span>{__('Добавить')}</span>
							</button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	);
};
