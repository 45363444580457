import { useEffect, useState } from 'react';
import { Form, Row, Col, Radio, Checkbox, Input } from 'antd';
import { useMethods, useData, nomenclaturesChange } from 'utils';
import { Modal, Preloader } from 'components';
import { getNomenclatureListAction } from 'actions';

export const AddNomenclatures = (props) => {
	const { children } = props;
	const [form] = Form.useForm();
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const { nomenclatureCategories = [], waybillFilter = {} } = useData();
	const { __, update } = useMethods();
	const [nomenclatures, setNomenclatures] = useState([]);
	const [nomenclaturesFilters, setNomenclaturesFilters] = useState({});
	const [nomenclaturesList, setNomenclaturesList] = useState([]);
	const [ids, setIds] = useState([]);

	useEffect(() => {
		if (visible) {
			(async () => {
				let list = nomenclatures;
				if (!nomenclatures.length) {
					setPending(true);
					const response = await getNomenclatureListAction({
						simplest: waybillFilter.type !== 'inventory-act'
					});
					list = response.sort((a, b) => a.name.localeCompare(b.name));
					setPending(false);
				}
				setNomenclaturesFilters({});
				setNomenclatures(list);
				setNomenclaturesList(list);
				setIds([]);
				form.setFieldsValue({
					group: 0,
					search: '',
					ids: []
				});
			})();
		}
	}, [visible]);

	const changeHandler = (changedValues) => {
		if (changedValues.group !== undefined) {
			setNomenclaturesFilters({
				categoryId: changedValues.group,
				search: ''
			});
			setNomenclaturesList(
				nomenclatures.filter((item) => {
					let isCheck = true;
					if (changedValues.group && changedValues.group !== item.category_id) {
						isCheck = false;
					}
					return isCheck;
				})
			);
			const values = form.getFieldsValue();
			form.setFieldsValue({
				...values,
				ids
			});
		} else if (changedValues.search !== undefined) {
			setNomenclaturesFilters({
				...nomenclaturesFilters,
				search: changedValues.search
			});
			setNomenclaturesList(
				nomenclatures.filter((item) => {
					let isCheck = true;
					if (nomenclaturesFilters.categoryId && nomenclaturesFilters.categoryId !== item.category_id) {
						isCheck = false;
					}
					if (changedValues.search && !new RegExp(`^${changedValues.search.toLowerCase()}`).test(item.name.toLowerCase())) {
						isCheck = false;
					}
					return isCheck;
				})
			);
		} else if (changedValues.ids !== undefined) {
			const noms = ids.filter((id) => {
				const isNomenclatureList = nomenclaturesList.find((item) => item.id === id);
				const isIds = changedValues.ids.includes(id);
				if (isNomenclatureList) {
					return isIds;
				} else {
					return true;
				}
			});
			changedValues.ids.forEach((id) => {
				if (!ids.includes(id)) {
					noms.push(id);
				}
			});
			setIds(noms);
		}
	};

	const submitHandler = async () => {
		const noms = waybillFilter.nomenclatures || [];
		update({
			waybillFilter: {
				...waybillFilter,
				nomenclatures: nomenclaturesChange(
					nomenclatures
						.filter((item) => {
							const isNew = ids.includes(item.id);
							const isOld = noms.find((nom) => nom.id === item.id);
							return isOld || isNew;
						})
						.map((item) => {
							const old = noms.find((nom) => nom.id === item.id);
							if (old) {
								return old;
							} else {
								return item;
							}
						})
				)
			}
		});
		setVisible(false);
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="middle">
				<div className="modal__container">
					<Form className="form" onFinish={submitHandler} onValuesChange={changeHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<div className="nomenclature-block">
								<Row gutter={20}>
									<Col span={12}>
										<div className="nomenclature-block__title">{__('Группы номенклатуры')}</div>
										<Form.Item name="group">
											<Radio.Group
												className="nomenclature-block__categories"
												options={[{ label: __('Все'), value: 0 }].concat(
													nomenclatureCategories
														.sort((a, b) => a.name.localeCompare(b.name))
														.map((item) => ({ label: item.name, value: item.id }))
												)}
												optionType="button"
												buttonStyle="solid"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<div className="nomenclature-block__search">
											<Form.Item name="search">
												<Input placeholder={__('Поиск по номенклатуре')} value={nomenclaturesFilters.search} />
											</Form.Item>
										</div>
										<div className="nomenclature-block__list">
											<Form.Item name="ids">
												<Checkbox.Group>
													{nomenclaturesList.map((item) => {
														return (
															<div className="nomenclature-block__item" key={item.id}>
																<Checkbox value={item.id}>
																	{item.name} ({item.unit_title})
																</Checkbox>
															</div>
														);
													})}
												</Checkbox.Group>
											</Form.Item>
										</div>
									</Col>
								</Row>
							</div>
							<div className="form__button">
								<Row justify="end" gutter={16}>
									<Col>
										<button type="button" className="button button--main-border" onClick={() => setVisible(false)}>
											<span>{__('Отмена')}</span>
										</button>
									</Col>
									<Col>
										<button className="button button--main">
											<span>{__('Добавить')}</span>
										</button>
									</Col>
								</Row>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
