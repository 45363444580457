import { Layout, Tooltip } from 'antd';
import { Link } from 'components';
import { useMethods, useData, isUrl, setLocalStorage, changeCollapsedInHtml } from 'utils';
import { sidebarMenu } from 'const';
import logo from 'assets/images/logo.svg';
import logoSmall from 'assets/images/logo-small.svg';
import { ReactComponent as CallIcon } from 'assets/svg/call.svg';

export const Sidebar = () => {
	const { homeUrl, sip = {}, sipWork, collapsed, entrepreneurs = [] } = useData();
	const { __, update } = useMethods();

	const setCollapsedHandler = (value) => {
		setLocalStorage('collapsed', value);
		changeCollapsedInHtml(value);
		update({
			collapsed: value
		});
	};

	const callShowHandler = () => {
		update({
			sip: {
				...sip,
				show: !sip.show
			}
		});
	};

	return (
		<div className={`sidebar ${collapsed ? 'sidebar--collapsed' : ''}`}>
			<Layout.Sider
				className="sidebar__container"
				collapsible
				collapsed={collapsed}
				onCollapse={setCollapsedHandler}
				width={250}
				collapsedWidth={70}
			>
				<div className="sidebar__head">
					{isUrl(homeUrl) ? (
						<div className="sidebar__logo">
							<img src={logo} alt="Ikura CRM" className="sidebar__full-image" />
							<img src={logoSmall} alt="Ikura CRM" className="sidebar__small-image" />
						</div>
					) : (
						<Link href={homeUrl} className="sidebar__logo">
							<img src={logo} alt="Ikura CRM" className="sidebar__full-image" />
							<img src={logoSmall} alt="Ikura CRM" className="sidebar__small-image" />
						</Link>
					)}
				</div>
				<div className="sidebar__menu">
					{sidebarMenu.map(({ href, name, Icon }, index) =>
						href === '/cashbox' && !entrepreneurs.length ? null : (
							<div className="sidebar__item" key={index}>
								<Tooltip title={collapsed ? __(name) : null} placement="right">
									<Link href={href} className={`sidebar__link${isUrl(href, true) ? ' sidebar__link--active' : ''}`}>
										<Icon />
										<span>{__(name)}</span>
									</Link>
								</Tooltip>
							</div>
						)
					)}
					{sipWork ? (
						<div className="sidebar__item">
							<Tooltip title={collapsed ? __('Звонки') : null} placement="right">
								<div className={`sidebar__link${sip.show ? ' sidebar__link--active' : ''}`} onClick={callShowHandler}>
									<CallIcon />
									<span>{__('Звонки')}</span>
								</div>
							</Tooltip>
						</div>
					) : null}
				</div>
			</Layout.Sider>
		</div>
	);
};
