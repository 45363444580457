import { useEffect, useState } from 'react';
import { CouriersFilter, CouriersStatistic, CouriersTable } from 'components';
import { useMethods, dayjs } from 'utils';
import { dateServerFormat } from 'const';
import { getCourierOrderStatusesAction, getCouriersAction, getTripStatusesAction, getPackedOrdersAction } from 'actions';

export const CouriersTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const couriersFiltersDefault = {
				courier: null,
				order_status: null,
				trip_status: null,
				date: dayjs(),
				status: 'all'
			};
			const couriersFilters = { ...couriersFiltersDefault };
			const [couriersDate, courierOrderStatuses, tripStatuses, packedOrders] = await Promise.all([
				getCouriersAction({
					courier: couriersFilters.courier ? +couriersFilters.courier : null,
					order_status: couriersFilters.order_status ? +couriersFilters.order_status : null,
					trip_status: couriersFilters.trip_status ? +couriersFilters.trip_status : null,
					date: couriersFilters.date ? dayjs(couriersFilters.date).format(dateServerFormat) : null
				}),
				getCourierOrderStatusesAction(),
				getTripStatusesAction(),
				getPackedOrdersAction()
			]);
			setPending(false);
			update({
				couriersFiltersDefault,
				couriersFilters,
				courierOrderStatuses,
				tripStatuses,
				couriersDate,
				packedOrders
			});
		})();

		return () => {
			update({
				couriersFiltersDefault: {},
				couriersFilters: {},
				couriersDate: []
			});
		};
	}, []);

	return (
		<>
			<CouriersFilter setPending={setPending} />
			<CouriersStatistic />
			<CouriersTable pending={pending} />
		</>
	);
};
