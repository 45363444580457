import React from 'react';
import { ReactComponent as VsegoZagazovIcon } from 'assets/svg/vsego-zagazov.svg';
import { ReactComponent as SozdanIcon } from 'assets/svg/sozdan.svg';
import { ReactComponent as PrinyatVRabotyIcon } from 'assets/svg/prinyat-v-raboty.svg';
import { ReactComponent as GotovitsyaIcon } from 'assets/svg/gotovitsya.svg';
import { ReactComponent as SobiraetsyaIcon } from 'assets/svg/sobiraetsya.svg';
import { ReactComponent as SobranIcon } from 'assets/svg/sobran.svg';
import { ReactComponent as PeredanCourieryIcon } from 'assets/svg/peredan-couriery.svg';
import { ReactComponent as DostavlyautIcon } from 'assets/svg/dostavlyaut.svg';
import { ReactComponent as DostavlenIcon } from 'assets/svg/dostavlen.svg';
import { ReactComponent as PredzakazIcon } from 'assets/svg/predzakaz.svg';
import { ReactComponent as OtlojenIcon } from 'assets/svg/otlojen.svg';
import { ReactComponent as VipolnenIcon } from 'assets/svg/vipolnen.svg';
import { ReactComponent as OtkazanIcon } from 'assets/svg/otkazan.svg';
import { ReactComponent as ActivnihZakazovIcon } from 'assets/svg/activnih-zakazov.svg';
import { ReactComponent as SvobodnihCourierovIcon } from 'assets/svg/svobodnih-courierov.svg';
import { ReactComponent as ZagryzkaKyhniIcon } from 'assets/svg/zagryzka-kyhni.svg';
import { ReactComponent as CourierIcon } from 'assets/svg/courier.svg';
import { ReactComponent as EmployeesIcon } from 'assets/svg/employees.svg';
import { ReactComponent as ClientsIcon } from 'assets/svg/clients.svg';
import { ReactComponent as OrdersIcon } from 'assets/svg/orders.svg';
import { ReactComponent as CashboxIcon } from 'assets/svg/cashbox.svg';
import { ReactComponent as OperationIcon } from 'assets/svg/operation.svg';
import { ReactComponent as CallIcon } from 'assets/svg/call.svg';
import { ReactComponent as CouriersIcon } from 'assets/svg/couriers.svg';
import { ReactComponent as SettlementIcon } from 'assets/svg/settlement.svg';
import { ReactComponent as OutflowIcon } from 'assets/svg/outflow.svg';
import { ReactComponent as InflowIcon } from 'assets/svg/inflow.svg';
import { ReactComponent as DocsIcon } from 'assets/svg/docs.svg';
import { ReactComponent as CallIncomingIcon } from 'assets/svg/call-incoming.svg';
import { ReactComponent as CallOutgoingIcon } from 'assets/svg/call-outgoing.svg';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';
import { ReactComponent as WarehouseIcon } from 'assets/svg/warehouse.svg';
import { ReactComponent as NewClients } from 'assets/svg/vacansiess.svg';
import { ReactComponent as FromSite } from 'assets/svg/from-site.svg';
import { ReactComponent as FromApp } from 'assets/svg/from-app.svg';
import { ReactComponent as DeliveryCourier } from 'assets/svg/delivery-courier.svg';
import { ReactComponent as DeliverySelf } from 'assets/svg/delivery-self.svg';
import { ReactComponent as GraphRevenue } from 'assets/svg/graph-revenue.svg';
import { ReactComponent as SushiWorkshop } from 'assets/svg/sushi-workshop.svg';
import { ReactComponent as MarketWorkshop } from 'assets/svg/market-workshop.svg';
import { ReactComponent as HotWorkshop } from 'assets/svg/hot-workshop.svg';
import { ReactComponent as PizzaWorkshop } from 'assets/svg/pizza-workshop.svg';
import { ReactComponent as AssemblyWorkshop } from 'assets/svg/assembly-workshop.svg';

export const fileIcons = {
	xls: <XlsIcon />
};

export const callIcon = {
	incoming: <CallIncomingIcon />,
	outgoing: <CallOutgoingIcon />
};

export const headerIcon = {
	0: <ActivnihZakazovIcon />,
	1: <SvobodnihCourierovIcon />,
	2: <DostavlyautIcon />,
	3: <ZagryzkaKyhniIcon />
};

export const statusIcon = {
	0: <VsegoZagazovIcon />,
	1: <SozdanIcon />,
	2: <GotovitsyaIcon />,
	3: <PrinyatVRabotyIcon />,
	4: <SobiraetsyaIcon />,
	5: <SobranIcon />,
	6: <PeredanCourieryIcon />,
	7: <DostavlyautIcon />,
	8: <DostavlenIcon />,
	9: <VipolnenIcon />,
	10: <OtkazanIcon />,
	11: <PredzakazIcon />,
	12: <OtlojenIcon />,

	in_work: <ActivnihZakazovIcon />,
	'new-clients': <NewClients />,
	is_from_site: <FromSite />,
	is_from_mobile: <FromApp />,
	'delivery-courier': <DeliveryCourier />,
	'delivery-self': <DeliverySelf />
};

export const courierIcon = <CourierIcon />;
export const graphRevenueIcon = <GraphRevenue />;

export const menuIcon = {
	employees: <EmployeesIcon />,
	clients: <ClientsIcon />,
	couriers: <CouriersIcon />,
	orders: <OrdersIcon />,
	cashbox: <CashboxIcon />,
	operation: <OperationIcon />,
	docs: <DocsIcon />,
	call: <CallIcon />,
	warehouse: <WarehouseIcon />
};

export const couriersStatusIcon = {
	0: <VsegoZagazovIcon />,
	1: <DostavlyautIcon />,
	2: <PeredanCourieryIcon />,
	3: <OtlojenIcon />
};

export const courierButtonsIcon = {
	settlement: <SettlementIcon />,
	outflow: <OutflowIcon />,
	inflow: <InflowIcon />
};

export const workshopIcon = {
	'sushi-workshop': <SushiWorkshop />,
	'market-workshop': <MarketWorkshop />,
	'hot-workshop': <HotWorkshop />,
	'pizza-workshop': <PizzaWorkshop />,
	'assembly-workshop': <AssemblyWorkshop />
};
