import { Table, Row, Col, Button, Tooltip, Tag } from 'antd';
import { useData, useMethods, formatDigits, dayjs, ucFirst, phoneFormat, makeMonths } from 'utils';
import { paginationDefault, dateTimeClientFormat } from 'const';
import { useHistory } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import { getOrderAction, sipCallAction } from 'actions';

const Group = Button.Group;

export const OrderHistory = () => {
	const { clientOrders = [], oldClient = {}, couriers = [], orderStatuses = [] } = useData();
	const { __, update } = useMethods();
	const history = useHistory();

	const repeatOrderHandler = async (id) => {
		const order = await getOrderAction(id);
		update({
			orderRepeat: {
				...order,
				status_id: 1
			}
		});
		history.push(`/orders/create`);
	};

	const orderCount = clientOrders.length;
	const orderTotal = formatDigits({ number: clientOrders.reduce((sum, order) => sum + order.price, 0) });

	return (
		<>
			<Row gutter={12} align="middle" justify="end" style={{ marginBottom: '20px' }}>
				<Col>
					<Row gutter={12} align="middle">
						<Col>
							<span>
								{__('Всего заказов')}: <strong>{orderCount}</strong>
							</span>
						</Col>
						<Col>
							<span>
								{__('Сумма заказов')}:{' '}
								<strong>
									{orderTotal} {__('грн')}
								</strong>
							</span>
						</Col>
					</Row>
				</Col>
			</Row>
			<Table
				className="table"
				scroll={scroll}
				rowKey={(record) => record.id}
				dataSource={clientOrders}
				bordered
				pagination={{
					...paginationDefault,
					defaultPageSize: 50
				}}
				columns={[
					{
						title: __('Номер заказа'),
						dataIndex: 'day_id',
						render: (dayId) => dayId || '-'
					},
					{
						title: __('Дата и время'),
						dataIndex: 'created_at',
						filters: makeMonths().map((item) => ({ text: item.label, value: item.value })),
						onFilter: (value, record) => {
							const month = record.delivery_date.split(' ')[0].split('.')[1];
							return month === value;
						},
						render: (date) => (date ? dayjs(date).format(dateTimeClientFormat) : '-')
					},
					{
						title: __('Сумма заказа'),
						dataIndex: 'price',
						render: (sum) => `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`
					},
					{
						title: __('Номер телефона'),
						dataIndex: 'phone_id',
						render: (id) => {
							const phone = oldClient.phones
								? id
									? oldClient.phones.find((phone) => phone.id === id)?.value
									: oldClient.phones.find((phone) => phone.main)?.value
								: null;
							return phone ? (
								<div className="link" onClick={() => sipCallAction(phone)}>
									{phoneFormat(phone)}
								</div>
							) : (
								'-'
							);
						}
					},
					{
						title: __('Адрес'),
						dataIndex: 'address_id',
						render: (id) => (oldClient.addresses && oldClient.addresses.find((address) => address.id === id)?.title) || '-'
					},
					{
						title: __('Курьер'),
						dataIndex: 'courier_id',
						render: (id) => couriers.find((item) => item.id === id)?.name || '-'
					},
					{
						title: __('Статус'),
						dataIndex: 'status_id',
						render: (id) => {
							const name = orderStatuses.find((item) => item.id === id)?.name;
							const color = orderStatuses.find((item) => item.id === id)?.color;
							return name ? <Tag color={color}>{name}</Tag> : '-';
						}
					},
					{
						title: __('Действия'),
						dataIndex: 'id',
						align: 'right',
						render: (orderId, record) => {
							return record.day_id?.toLowerCase()?.includes(__('довоз')) ? null : (
								<Group>
									<Tooltip placement="topRight" title={__('Повторить заказ')}>
										<button
											type="button"
											className="button button--trans-main button--icon-xs"
											onClick={() => repeatOrderHandler(orderId)}
										>
											<ReloadOutlined />
										</button>
									</Tooltip>
								</Group>
							);
						}
					}
				]}
				expandedRowRender={(record) => (
					<Table
						rowKey={(record) => record.id}
						rowClassName={(record) => {
							return record.canAddIngredients && record.ingredients && record.ingredients.length ? null : 'no-expand';
						}}
						dataSource={record.products}
						pagination={false}
						columns={[
							{
								title: __('Наименование'),
								dataIndex: 'name',
								render: (text) => <strong>{ucFirst(text)}</strong>
							},
							{
								title: __('Цена'),
								dataIndex: 'price',
								render: (text, record) => `${formatDigits({ number: record.price, toFixed: 2 })} ${__('грн')}`
							},
							{
								title: __('Количество'),
								dataIndex: 'quantity'
							},
							{
								title: __('Сумма'),
								dataIndex: 'total',
								render: (text, record) => {
									const sum = record.price * record.quantity;
									return `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`;
								}
							},
							{
								title: __('Примечание'),
								dataIndex: 'comment'
							}
						]}
						expandedRowRender={(record) => (
							<Table
								rowKey={(record) => record.id}
								dataSource={record.ingredients}
								pagination={false}
								size={'small'}
								columns={[
									{
										title: __('Наименование'),
										dataIndex: 'name',
										render: (text) => <strong>{text}</strong>
									},
									{
										title: __('Цена'),
										dataIndex: 'price',
										render: (sum) => `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`
									},
									{
										title: __('Вес'),
										dataIndex: 'weight',
										render: (text) => `${text} ${__('г')}.`
									},
									{
										title: __('Количество'),
										dataIndex: 'quantity'
									},
									{
										title: __('Сумма'),
										dataIndex: 'sum',
										render: (_, ingredient) => {
											const sum = ingredient.price * ingredient.quantity;
											return `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`;
										}
									}
								]}
							/>
						)}
					/>
				)}
			/>
		</>
	);
};
