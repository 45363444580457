import { useEffect, useState } from 'react';
import { useMethods } from 'utils';
import { getWaybillsTypesAction, getWaybillsAction } from 'actions';
import { WaybillsFilter, WaybillsTable } from 'components';

export const WaybillsTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const waybillsFiltersDefault = {
				type: null,
				date: [null, null],
				supplier_warehouse_id: null,
				recipient_warehouse_id: null,
				page: 1,
				per_page: 10
			};
			const waybillsFilters = { ...waybillsFiltersDefault };
			const [waybillsTypes, waybills] = await Promise.all([getWaybillsTypesAction(), getWaybillsAction(waybillsFilters)]);
			setPending(false);
			update({
				waybillsFiltersDefault,
				waybillsFilters,
				waybillsTypes,
				waybills
			});
		})();

		return () => {
			update({
				waybillsFiltersDefault: {},
				waybillsFilters: {},
				waybills: {}
			});
		};
	}, []);

	return (
		<>
			<WaybillsFilter setPending={setPending} />
			<WaybillsTable pending={pending} setPending={setPending} />
		</>
	);
};
