import { Tooltip, Badge, Popover, Popconfirm } from 'antd';
import { SettingOutlined, BellOutlined } from '@ant-design/icons';
import { useMethods, useData, dayjs, access, preloaderShow } from 'utils';
import { AddAttendance } from 'components';
import { increaseCookLoadAction, getNotificationsAction, deleteNotificationAction, logoutAction } from 'actions';
import { dateServerFormat, dateTimeServerFormat } from 'const';
import { ReactComponent as ActivnihZakazovIcon } from 'assets/svg/activnih-zakazov.svg';
import { ReactComponent as SvobodnihCourierovIcon } from 'assets/svg/svobodnih-courierov.svg';
import { ReactComponent as DostavlyautIcon } from 'assets/svg/dostavlyaut.svg';
import { ReactComponent as ZagryzkaKyhniIcon } from 'assets/svg/zagryzka-kyhni.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as ExitIcon } from 'assets/svg/exit.svg';

export const Header = () => {
	const {
		ordersStatistic = {},
		couriersStatistic = {},
		restaurantSettings = {},
		deliveryTimesCurrent = [],
		user = {},
		notifications = []
	} = useData();
	const { __, update } = useMethods();
	const { statuses = [] } = ordersStatistic;

	const now = dayjs();
	const nowDate = now.format(dateServerFormat);
	const deliveryTime = deliveryTimesCurrent.filter((time) => dayjs(`${nowDate} ${time}:00`, dateTimeServerFormat).isAfter(now));
	const statistics = [
		{
			icon: <ActivnihZakazovIcon />,
			label: __('Активных заказов'),
			text: statuses[0] ? `${statuses[0].count} ${__('шт')}` : ''
		},
		{
			icon: <SvobodnihCourierovIcon />,
			bgClass: couriersStatistic.count_free > 0 ? 'is-bg' : '',
			label: __('Свободных'),
			text: couriersStatistic.count_free !== undefined ? `${couriersStatistic.count_free} ${__('чел')}` : ''
		},
		{
			icon: <DostavlyautIcon />,
			label: __('Доставляют'),
			text: couriersStatistic.count_busy !== undefined ? `${couriersStatistic.count_busy} ${__('чел')}` : ''
		},
		{
			icon: <ZagryzkaKyhniIcon />,
			label: __('Загрузка кухни'),
			text:
				restaurantSettings.cook_load_current !== undefined
					? `${restaurantSettings.cook_load_current} ${__('ур')}${deliveryTime.length ? ` (${deliveryTime[0]})` : ''}`
					: '',
			confirmText: __('Увеличить время загрузки кухни?'),
			onClick: () => increaseCookLoadAction()
		}
	];

	const logoutHandler = async () => {
		preloaderShow();
		await logoutAction();
		update({
			user: {}
		});
	};

	const closeNotifications = async (id) => {
		await deleteNotificationAction(id);
		const notifications = await getNotificationsAction();
		update({
			notifications
		});
	};

	return (
		<div className="header">
			<div className="header__content">
				<div className="header__list">
					<div className="header__item header__item--stat">
						<div className="header__list">
							{statistics.map((record, index) => (
								<div className="header__item" key={index}>
									{record.onClick && record.confirmText ? (
										<Popconfirm
											placement="bottomLeft"
											title={__(record.confirmText)}
											onConfirm={record.onClick}
											okText={__('Ок')}
											cancelText={__('Отменить')}
										>
											<div className="inform inform--button">
												<Tooltip title={record.label} placement="bottomLeft">
													<Badge count={record.text} overflowCount={999} className={record.bgClass ? record.bgClass : ''}>
														<div className="inform__icon">{record.icon}</div>
													</Badge>
												</Tooltip>
											</div>
										</Popconfirm>
									) : (
										<div className={`inform${record.onClick ? ' inform--button' : ''}`} onClick={record.onClick}>
											<Tooltip title={record.label} placement="bottomLeft">
												<Badge count={record.text} overflowCount={999} className={record.bgClass ? record.bgClass : ''}>
													<div className="inform__icon">{record.icon}</div>
												</Badge>
											</Tooltip>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
					<div className="header__item">
						<div className="header__list">
							<div className="header__item">
								<a
									href="https://api.ikura.lg.ua/admin"
									className="button button--icon button--trans"
									target="_blank"
									rel="noreferrer"
								>
									<SettingOutlined />
								</a>
							</div>
							<div className="header__item">
								<div className="notifications">
									<Popover
										placement="bottomRight"
										trigger="click"
										overlayStyle={{ zIndex: 999 }}
										content={
											notifications.length ? (
												<div className="notifications__list">
													{notifications.map((item, index) => (
														<div className="notifications__item" key={index}>
															<div className="notifications__date">
																{item.date_time ? dayjs(item.date_time, dateTimeServerFormat).fromNow() : ''}
															</div>
															<div className="notifications__text">{item.name}</div>
															<Popconfirm
																placement="leftTop"
																title={__('Удалить уведомление?')}
																onConfirm={() => closeNotifications(item.id)}
																okText={__('Да')}
																cancelText={__('Нет')}
															>
																<div className="notifications__close">
																	<CloseIcon />
																</div>
															</Popconfirm>
															{item.action === 'recreate-deleted-attendance' &&
															item.permission &&
															access(item.permission) ? (
																<AddAttendance
																	employeeId={item.payload.employee_id}
																	date={item.payload.employee_date}
																	startedTime={item.payload.employee_time_start}
																	notificationId={item.id}
																>
																	<div className="notifications__recreate">
																		<span>{__('Восстановить')}</span>
																	</div>
																</AddAttendance>
															) : null}
														</div>
													))}
												</div>
											) : (
												<div>{__('Нет новых уведомлений')}</div>
											)
										}
									>
										<Badge count={notifications.length} className="notifications__dot">
											<button className="button button--icon button--trans">
												<BellOutlined />
											</button>
										</Badge>
									</Popover>
								</div>
							</div>
							<div className="header__item">
								<div className="header__user">
									<div className="header__user-title">{user.name}</div>
									<div className="header__user-text">{restaurantSettings.name ? `(${restaurantSettings.name})` : null}</div>
								</div>
							</div>
							<div className="header__item">
								<Popconfirm
									placement="bottomRight"
									title={__('Выйти из CRM?')}
									onConfirm={logoutHandler}
									okText={__('Ок')}
									cancelText={__('Отменить')}
								>
									<Tooltip title={__('Выйти')} placement="topRight">
										<button className="button button--icon button--trans-error">
											<ExitIcon />
										</button>
									</Tooltip>
								</Popconfirm>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
