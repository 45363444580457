import { useEffect, useState } from 'react';
import { useMethods } from 'utils';
import { getWarehouseRestsAction, getNomenclatureCategoriesAction } from 'actions';
import { WarehouseRestsFilter, WarehouseRestsTable } from 'components';

export const WarehouseRestsTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const warehouseRestsFiltersDefault = {
				date: null,
				name: null,
				warehouse_id: null,
				category_id: null,
				page: 1,
				per_page: 100
			};
			const warehouseRestsFilters = { ...warehouseRestsFiltersDefault };
			const [nomenclatureCategories, warehouseRests] = await Promise.all([
				getNomenclatureCategoriesAction(),
				getWarehouseRestsAction(warehouseRestsFilters)
			]);
			setPending(false);
			update({
				warehouseRestsFiltersDefault,
				warehouseRestsFilters,
				warehouseRests,
				nomenclatureCategories
			});
		})();

		return () => {
			update({
				warehouseRestsFiltersDefault: {},
				warehouseRestsFilters: {},
				warehouseRests: {}
			});
		};
	}, []);

	return (
		<>
			<WarehouseRestsFilter setPending={setPending} />
			<WarehouseRestsTable pending={pending} setPending={setPending} />
		</>
	);
};
