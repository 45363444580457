import { api, dayjs } from 'utils';
import { dateServerFormat } from 'const';

export const getReportsTransactionsAction = async (params = {}) => {
	const response = await api.get(`/reports/transactions`, { params });
	return response.data || {};
};

export const getReportsDishesAction = async (params = {}) => {
	const response = await api.get(`/reports/dishes`, { params });
	return response.data || [];
};

export const getReportsCouriersAction = async (values = {}) => {
	const { date = dayjs(), ...rest } = values;
	const params = {
		...rest,
		date: date ? dayjs(date).format(dateServerFormat) : null
	};
	const response = await api.get(`/reports/couriers-loading`, { params });
	return response.data || [];
};

export const getReportsSalesAction = async (values = {}) => {
	const { date_from = dayjs(), date_to = dayjs(), ...rest } = values;
	const params = {
		...rest,
		date_from: date_from ? dayjs(date_from).format(dateServerFormat) : null,
		date_to: date_to ? dayjs(date_to).format(dateServerFormat) : null
	};
	const response = await api.get(`/reports/product-sales`, { params });
	return response.data || {};
};

export const getReportsDayCheckAction = async (values = {}) => {
	const { date_from = dayjs(), date_to = dayjs(), ...rest } = values;
	const params = {
		...rest,
		date_from: date_from ? dayjs(date_from).format(dateServerFormat) : null,
		date_to: date_to ? dayjs(date_to).format(dateServerFormat) : null
	};
	const response = await api.get(`/reports/average-day-check`, { params });
	return response.data || [];
};

export const getReportsHourCheckAction = async (values = {}) => {
	const { date = dayjs(), ...rest } = values;
	const params = {
		...rest,
		date: date ? dayjs(date).format(dateServerFormat) : null
	};
	const response = await api.get(`/reports/average-hour-check`, { params });
	return response.data || [];
};

export const getReportsRawAction = async (params = {}) => {
	const response = await api.get(`/reports/raw-matrix`, { params });
	return response.data || {};
};

export const getReportsRevenueAction = async (dates = []) => {
	const params = {
		date_from: dayjs(dates[0]).format(dateServerFormat),
		date_to: dayjs(dates[1]).format(dateServerFormat)
	};
	const response = await api.get(`/reports/revenue`, { params });
	return response.data || {};
};

export const getReportsPurchasesAction = async (values = {}) => {
	const { date = [], ...rest } = values;
	const params = {
		...rest,
		date: date[0] && date[1] ? `${dayjs(date[0]).format(dateServerFormat)} - ${dayjs(date[1]).format(dateServerFormat)}` : null
	};
	const response = await api.get(`/reports/purchases`, { params });
	return response.data || {};
};

export const getNomenclatureExpensesAction = async (values = {}) => {
	const { date = [], ...rest } = values;
	const params = {
		...rest,
		date: date[0] && date[1] ? `${dayjs(date[0]).format(dateServerFormat)} - ${dayjs(date[1]).format(dateServerFormat)}` : null
	};
	const response = await api.get(`/reports/nomenclature-expenses`, { params });
	return response.data || [];
};

export const getReportsSalaryAction = async (params = {}) => {
	const response = await api.get(`/reports/salary-expenses-report`, { params });
	return response.data;
};
