import { Routes } from 'components';
import { setBrowserizr } from 'utils';
import { StoreProvider } from 'providers';

export const App = (props) => {
	setBrowserizr();

	return (
		<StoreProvider value={props}>
			<Routes />
		</StoreProvider>
	);
};
