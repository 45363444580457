import { BaseLayout } from 'layouts';
import { Login } from 'components';
import { useData } from 'utils';

export const LoginPage = () => {
	const { seo = {} } = useData();

	return (
		<BaseLayout seo={seo}>
			<Login />
		</BaseLayout>
	);
};
