import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/ru';
import { getLocale } from 'utils';

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.locale(getLocale());

const makeYears = (from = 2020, to = new Date().getFullYear()) => {
	const result = [];
	for (let i = from; i <= to; i++) {
		result.push({
			label: i,
			value: i
		});
	}
	return result;
};

const makeMonths = (__) => {
	const result = [];
	for (let i = 1; i <= 12; i++) {
		result.push({
			label: dayjs(`1.${i}.2021`, 'D.M.YYYY').format('MMMM'),
			value: i
		});
	}
	return result;
};

const makeMonthsReverse = (__) => {
	const result = [];
	const currentMonth = dayjs().format('M');
	const currentYear = dayjs().format('YYYY');
	for (let i = currentMonth; i >= 1; i--) {
		result.push({
			label: dayjs(`1.${i}.2021`, 'D.M.YYYY').format('MMMM'),
			value: `${currentYear}-${i <= 9 ? `0${i}` : i}-01`
		});
	}
	for (let i = 12; i > currentMonth; i--) {
		result.push({
			label: `${dayjs(`1.${i}.2021`, 'D.M.YYYY').format('MMMM')} (${currentYear - 1})`,
			value: `${currentYear - 1}-${i <= 9 ? `0${i}` : i}-01`
		});
	}
	return result;
};

export { dayjs, makeYears, makeMonths, makeMonthsReverse };
