import { api } from 'utils';

export const getCouriersStatisticAction = async () => {
	const response = await api.get(`/couriers-current-statistics`);
	return response.data ? response.data : {};
};

export const getCouriersAction = async (params = {}) => {
	const response = await api.get(`/delivery/couriers-list`, { params });
	return response.data ? response.data : [];
};

export const getPackedOrdersAction = async () => {
	const response = await api.get(`/delivery/packed-orders`);
	return response.data ? response.data : [];
};

export const changeActiveTripAction = async (id, values = {}) => {
	const response = await api.put(`/delivery/change-active-trip-courier/${id}`, values);
	return response.success;
};

export const getCouriersAccountAction = async (params = {}) => {
	const response = await api.get(`/delivery/couriers-accounts-list`, { params });
	return response.data ? response.data : [];
};

export const courierCashDebtAction = async (id) => {
	const response = await api.get(`/delivery/courier-cash-debt/${id}`);
	return response.data || {};
};

export const ordersCashHandOverAction = async (id, values = {}) => {
	const response = await api.put(`/delivery/courier-orders-cash-hand-over/${id}`, values);
	return response.data || [];
};

export const calculateDailyPayoutAction = async (id, params = {}) => {
	const response = await api.get(`/delivery/courier-income/calculate-daily-payout/${id}`, { params });
	return response.data || [];
};

export const dailyPayoutAction = async (id, values = {}) => {
	const response = await api.put(`/delivery/courier-income/daily-payout/${id}`, values);
	return response.data || [];
};
