import { MainLayout } from 'layouts';
import { useMethods } from 'utils';
import { Tabs } from 'antd';
import { ClientsTab } from 'components';

export const ClientsPage = () => {
	const { __ } = useMethods();

	return (
		<MainLayout seo={{ title: `${__('Клиенты')} | Ikura CRM` }}>
			<Tabs className="tabs" animated={false}>
				<Tabs.TabPane tab={__('Клиенты')} key="clients">
					<ClientsTab />
				</Tabs.TabPane>
			</Tabs>
		</MainLayout>
	);
};
