import { useEffect, useState } from 'react';
import { Form, Switch } from 'antd';
import { useMethods, print, money2str } from 'utils';
import { Modal, Preloader, CashWarrant } from 'components';
import { finishSelfDeliveryAction } from 'actions';

export const FinishOrderTerminal = (props) => {
	const { children, order = {} } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { __ } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				is_terminal: false
			});
		}
	}, [visible]);

	const submitHandler = async (values) => {
		setPending(true);
		const response = await finishSelfDeliveryAction(order.id, {
			is_terminal: !!values.is_terminal
		});
		setPending(false);
		if (response) {
			print(
				`#finish-order-${order.id}`,
				{
					'[NUMBER]': response.cash_uid || 0,
					'[NAME]': response.counterparty_name || '',
					'[DESCRIPTION]': response.description || '',
					'[SUM]': money2str(Math.abs(response.sum) || 0)
				},
				`@page {margin: 1.1cm 1cm 1.1cm 1cm; size: portrait;}`
			);
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{`${__('Завершить заказ')} ${order.id}`}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="is_terminal" label={__('Проводить через терминал')} valuePropName="checked">
								<Switch checkedChildren={__('Да')} unCheckedChildren={__('Нет')} />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Сохранить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
					<div className="is-print">
						<div id={`finish-order-${order.id}`}>
							<CashWarrant isAdd={true} />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};
