import { defaultLocale, locales } from 'const';

export const getLocale = () => {
	let locale = defaultLocale;
	const urlLang = window.location.pathname.replace(/^\/([^/]*)(\/.*)?$/, '$1');
	locales.forEach((item) => {
		if (item === urlLang) {
			locale = urlLang;
		}
	});
	return locale;
};

export const translationsInit = (translations) => (key, values) => {
	if (translations[key]) {
		let translation = translations[key];
		if (values) {
			for (const prop in values) {
				translation = translation.replace(new RegExp(`:${prop}`, 'g'), values[prop]);
			}
		}
		return translation;
	} else {
		return key;
	}
};
