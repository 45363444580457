import 'assets/less/index.less';
import 'assets/sass/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'components';
import { getAuthProps, getInitialProps, preloaderHide, getLocalStorage, setLocalStorage, getRestaurantProps, isUrl } from 'utils';

(async () => {
	const initialProps = await getInitialProps();
	let authProps = {};
	let needAuthProps = true;
	let restaurantProps = {};
	let needRestaurantProps = true;

	if (initialProps?.user.id && !isUrl('/relogin', true)) {
		authProps = getLocalStorage('props');
		if (!authProps) {
			authProps = await getAuthProps(initialProps?.user);
			setLocalStorage('props', authProps);
			needAuthProps = false;
		}

		const restaurants = getLocalStorage('restaurants', {});
		const restaurantSlug = initialProps?.user?.restaurant_slug;
		restaurantProps = restaurants[restaurantSlug];
		if (!restaurantProps) {
			restaurantProps = await getRestaurantProps(restaurantSlug);
			restaurants[restaurantSlug] = restaurantProps;
			setLocalStorage('restaurants', restaurants);
			needRestaurantProps = false;
		}
	}

	const props = {
		...initialProps,
		...authProps,
		...restaurantProps,
		needAuthProps,
		needRestaurantProps
	};

	preloaderHide();

	ReactDOM.render(<App {...props} />, document.getElementById('root'));
})();
