import { api, dayjs } from 'utils';
import { dateServerFormat } from 'const';

export const getTransactionsAction = async (values = {}) => {
	const { page = 1, per_page = 10, date, ...rest } = values;
	const params = {
		...rest,
		page,
		per_page
	};
	if (date && date.length) {
		params.date = `${date[0].format(dateServerFormat)} - ${date[1].format(dateServerFormat)}`;
	}
	const response = await api.get(`/transactions/employees-transactions`, { params });
	return response || {};
};

export const addTransactionAction = async (values) => {
	const { data } = await api.post(`/transactions/employees-transactions`, values);
	return !!data;
};

export const editTransactionAction = async (id, values) => {
	const { data } = await api.put(`/transactions/employees-transactions/${id}`, values);
	return !!data;
};

export const getEmployeesTotalsAction = async (values = {}) => {
	const { date, ...rest } = values;
	const params = {
		...rest,
		date: date ? date.format(dateServerFormat) : dayjs().subtract(1, 'days').format(dateServerFormat)
	};
	const response = await api.get(`/transactions/employees-totals-for-period`, { params });
	return response.data || {};
};

export const getEmployeesTransactionsStatisticAction = async (values = {}) => {
	const { page = 1, per_page = 10, date, ...rest } = values;
	const params = {
		...rest,
		page,
		per_page
	};
	if (date && date.length) {
		params.date = `${date[0].format(dateServerFormat)} - ${date[1].format(dateServerFormat)}`;
	}
	const response = await api.get(`/transactions/employees-transactions-statistic`, { params });
	return response.data || {};
};

export const paySalaryAction = async (values) => {
	const { success } = await api.post(`/transactions/bulk-salary-payment`, values);
	return success;
};

export const checksPrintingAction = async (values) => {
	const { success } = await api.post(`/transactions/bulk-salary-checks-printing`, values);
	return success;
};
