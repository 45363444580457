import { useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import { MainLayout } from 'layouts';
import { checkOrderForm, useData, useMethods, dayjs, access, notify } from 'utils';
import { SearchProducts, OrderComposition, Preloader, OrderForm, OrderHistory, Link } from 'components';
import {
	getOrderAction,
	getClientAction,
	getClientOrdersAction,
	getDeliveryTimesAction,
	getOrderStatusHistoryAction,
	updateClientAction,
	createClientAction,
	createOrderAction,
	updateOrderAction,
	sendOrderData,
	getPromoCodesAction,
	getCurrentCashboxAction
} from 'actions';
import { dateServerFormat, timeServerFormat, timeClientFormat } from 'const';
import { useHistory } from 'react-router-dom';

export const OrderPage = (props) => {
	const { match = {} } = props;
	const { params = {} } = match;
	const { id } = params;
	const {
		order = {},
		orderRepeat = {},
		client = {},
		clientOrders = [],
		deliveryTimesCurrent = [],
		orderTypes = [],
		paymentTypes = [],
		deliveryTypes = []
	} = useData();
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);
	const [hasErrors, setHasErrors] = useState(true);
	const history = useHistory();

	const orderDefault = {
		status_id: 1,
		order_type_id: orderTypes.find((item) => item.default)?.id,
		delivery_type_id: deliveryTypes.find((item) => item.default)?.id,
		payment_type_id: paymentTypes.find((item) => item.default)?.id,
		discount_id: 0,
		delivery_date: dayjs()
	};

	useEffect(() => {
		(async () => {
			setPending(true);
			const cashbox = await getCurrentCashboxAction();
			const promocodes = await getPromoCodesAction();
			const orderData = id ? await getOrderAction(id) : orderRepeat.id ? orderRepeat : orderDefault;
			const clientData = orderData.client_id ? await getClientAction(orderData.client_id) : client;
			const clientOrdersData = orderData.client_id ? await getClientOrdersAction(orderData.client_id) : [];

			orderData.delivery_date = orderData.delivery_date ? dayjs(orderData.delivery_date, dateServerFormat) : dayjs();
			const deliveryTimes =
				dayjs(orderData.delivery_date).format(dateServerFormat) !== dayjs(orderDefault.delivery_date).format(dateServerFormat) ||
				orderData.delivery_type_id !== orderDefault.delivery_type_id
					? await getDeliveryTimesAction(orderData.delivery_date, orderData.delivery_type_id)
					: [...deliveryTimesCurrent];
			orderData.delivery_time = orderData.delivery_time
				? dayjs(orderData.delivery_time, timeServerFormat).format(timeClientFormat)
				: deliveryTimes[0];

			const mainPhone = (clientData.phones || []).find((item) => item.main)?.id;
			const mainAddress = (clientData.addresses || []).find((item) => item.main)?.id;
			const firstPhone = clientData.phones && clientData.phones.length ? clientData.phones[0].id : null;
			const firstAddress = clientData.addresses && clientData.addresses.length ? clientData.addresses[0].id : null;
			orderData.phone_id = orderData.phone_id || mainPhone || firstPhone;
			orderData.address_id = orderData.address_id || mainAddress || firstAddress;

			const statusHistory = id ? await getOrderStatusHistoryAction(id) : [];

			setPending(false);
			update({
				cashbox,
				promocodes,
				orderId: id,
				oldOrder: orderData,
				order: orderData,
				orderRepeat: {},
				oldClient: clientData,
				client: clientData,
				clientOrders: clientOrdersData,
				deliveryTimes,
				statusHistory
			});
		})();

		return () => {
			update({
				orderId: null,
				oldOrder: {},
				order: {},
				orderRepeat: {},
				client: {},
				oldClient: {},
				clientOrders: [],
				statusHistory: []
			});
		};
	}, []);

	useEffect(() => {
		const newErrors = !checkOrderForm(order, client);
		if (newErrors !== hasErrors) {
			setHasErrors(newErrors);
		}
	}, [order, client]);

	const orderSubmitHandler = async () => {
		setPending(true);
		const clientData = {
			name: client.name,
			notes: client.notes,
			phones: (client.phones || []).map((item) => ({
				...item,
				main: !!(order.phone_id && item.id === order.phone_id),
				id: String(item.id).includes('_') ? null : item.id
			})),
			addresses: (client.addresses || []).map((item) => ({
				...item,
				main: !!(order.address_id && item.id === order.address_id),
				id: String(item.id).includes('_') ? null : item.id
			}))
		};
		const clientResponse = client.id ? await updateClientAction(client.id, clientData) : await createClientAction(clientData);
		if (clientResponse.id) {
			const orderRequest = sendOrderData(order);
			if (!orderRequest.id) {
				orderRequest.client_id = clientResponse.id;
			}
			if (!orderRequest.phone_id) {
				orderRequest.phone_id = clientResponse.phones.find((item) => item.main)?.id || null;
			}
			if (orderRequest.delivery_type_id === 1 && !orderRequest.address_id) {
				orderRequest.address_id = clientResponse.addresses.find((item) => item.main)?.id || null;
			}
			const orderResponse = order.id ? await updateOrderAction(order.id, orderRequest) : await createOrderAction(orderRequest);
			setPending(false);
			if (orderResponse.id) {
				notify('success', order.id ? __('Заказ обновлен') : __('Заказ добавлен'));
				history.push('/orders');
			}
		} else {
			setPending(false);
		}
	};

	return (
		<MainLayout seo={{ title: `${id ? __(`Редактирование заказа №${id}`) : __('Создание заказа')} | Ikura CRM` }}>
			<Preloader spinning={pending}>
				<div className="block">
					<div className="block__container">
						<Tabs id="product" className="tabs" defaultActiveKey="search" animated={false}>
							<Tabs.TabPane tab={`${__('Содержимое заказа')}`} key="search">
								<SearchProducts setPending={setPending} />
								<OrderComposition setPending={setPending} />
							</Tabs.TabPane>
							<Tabs.TabPane tab={__('Сведения о клиенте')} key="data">
								<OrderForm setPending={setPending} />
							</Tabs.TabPane>
							{clientOrders.length ? (
								<Tabs.TabPane tab={`${__('История заказов')} (${clientOrders.length})`} key="history">
									<OrderHistory />
								</Tabs.TabPane>
							) : null}
						</Tabs>
					</div>
					<div className="block__button">
						<Row id="order-submit" gutter={12} justify="end">
							<Col>
								<Link href="/orders" className="button button--main-border">
									<span>{__('Отменить')}</span>
								</Link>
							</Col>
							{(id && access('orders-update')) || (!id && access('orders-create')) ? (
								<Col>
									<button type="button" className="button button--main" disabled={hasErrors} onClick={orderSubmitHandler}>
										{id ? (order.status_id === 12 ? __('Отправить в отложенные') : __('Обновить заказ')) : __('Оформить заказ')}
									</button>
								</Col>
							) : null}
						</Row>
					</div>
				</div>
			</Preloader>
		</MainLayout>
	);
};
