import { Row, Col, Table, Tag } from 'antd';
import { useState } from 'react';
import { Modal } from 'components';
import { useMethods, useData, dayjs, formatDigits } from 'utils';
import { getOrderLogAction } from 'actions';
import { LoadingOutlined } from '@ant-design/icons';
import { dateTimeClientFormat } from 'const';

export const ShowOrderLogs = (props) => {
	const { children, orderId } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [orderLog, setOrderLog] = useState([]);
	const { products = [], locale } = useData();
	const { __ } = useMethods();

	const keyNamesAttributes = {
		price_full: __('Сумма заказа'),
		price: __('Итого')
	};

	const showHandler = async () => {
		setVisible(true);
		setPending(true);
		const data = await getOrderLogAction(orderId);
		setOrderLog(data);
		setPending(false);
	};

	const logColumns = [
		{
			title: __('Пользователь'),
			dataIndex: 'user_name'
		},
		{
			title: __('Время/Дата'),
			dataIndex: 'created_at',
			render: (date) => {
				return (
					<Tag>
						<strong>{dayjs(date).format(dateTimeClientFormat)}</strong>
					</Tag>
				);
			}
		}
	];

	const changeOrderColumns = [
		{
			title: __('Название'),
			dataIndex: 'key',
			render: (key, record) => keyNamesAttributes[key] || record.name
		},
		{
			title: __('Старое значение'),
			dataIndex: 'old',
			render: (oldProducts) => (
				<Tag>
					<strong>{oldProducts}</strong>
				</Tag>
			)
		},
		{
			title: __('Новое значение'),
			dataIndex: 'new',
			render: (newProducts) => (
				<Tag>
					<strong>{newProducts}</strong>
				</Tag>
			)
		}
	];

	const productColumns = [
		{
			key: 'Название',
			title: __('Название'),
			render: (record) => {
				const name = record['name'] || (record['product_id'] && products.find((prod) => prod.id === record.product_id)?.name[locale]);
				return name && record.is_pizza ? `${name} (${record.pizza_size} см)` : name;
			}
		},
		{
			key: 'Цена за ед.',
			title: __('Цена за ед.'),
			render: (record) => {
				return `${formatDigits({ number: record.price || 0, toFixed: 2 })} ${__('грн')}`;
			}
		},
		{
			key: 'Кол-во',
			title: __('Кол-во'),
			render: (record) => {
				return record.quantity;
			}
		},
		{
			key: 'Сумма',
			title: __('Сумма'),
			render: (record) => {
				const sum = (record.price || 0) * (record.quantity || 0);
				return `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`;
			}
		},
		{
			key: 'Сумма со скидкой',
			title: __('Сумма со скидкой'),
			render: (record) => {
				return `${formatDigits({ number: record.sale_price || 0, toFixed: 2 })} ${__('грн')}`;
			}
		}
	];

	const ingredientsColumns = [
		{
			key: 'Ингредиент',
			title: __('Ингредиент'),
			render: (record) => {
				return record['name'];
			}
		},
		{
			key: 'Кол-во',
			title: __('Кол-во'),
			render: (record) => {
				return record.quantity;
			}
		},
		{
			key: 'Цена за ед.',
			title: __('Цена за ед.'),
			render: (record) => {
				return `${formatDigits({ number: record.price || 0, toFixed: 2 })} ${__('грн')}`;
			}
		},
		{
			key: 'Сумма',
			title: __('Сумма'),
			render: (record) => {
				const sum = (record.price || 0) * (record.quantity || 0);
				return `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`;
			}
		}
	];

	return (
		<>
			<div onClick={showHandler}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="big">
				<div className="modal__container">
					<div className="modal__title">{__('Лог заказа')}</div>
					<Table
						rowKey="key"
						className="table"
						bordered
						loading={{
							spinning: pending,
							size: 'large',
							indicator: <LoadingOutlined />
						}}
						dataSource={orderLog.map((item, key) => ({
							...item,
							key,
							attributes: item.attributes ? item.attributes.map((attr, index) => ({ ...attr, index })) : []
						}))}
						pagination={false}
						columns={logColumns}
						expandedRowRender={(record) => {
							const { products, nomenclatures, attributes = [] } = record;
							const { old: oldProducts, new: newProducts } = products || {};
							const { old: oldNomenclatures, new: newNomenclatures } = nomenclatures || {};
							return (
								<>
									<div style={{ marginBottom: '12px' }}>
										<h2 align="center" style={{ margin: '10px 0' }}>
											{__('Изменение данных о заказе')}
										</h2>
										<Table
											rowKey="index"
											className="table"
											dataSource={attributes}
											pagination={false}
											columns={changeOrderColumns}
										/>
									</div>
									{!oldProducts && !newProducts ? null : (
										<div className="expanded-block">
											<h2 align="center" style={{ margin: '10px 0' }}>
												{__('Содержимое заказа')}
											</h2>
											<Row gutter={12}>
												<Col span={24} xl={12} style={{ marginBottom: '12px' }}>
													<h3 align="center">{__('Новые продукты')}</h3>
													{newProducts ? (
														<Table
															rowKey="id"
															className="table is-full"
															dataSource={newProducts}
															pagination={false}
															bordered
															columns={productColumns}
															rowClassName={(rec) => (rec.ingredients.length ? null : 'no-expand')}
															expandedRowRender={(rec) =>
																rec.ingredients.length ? (
																	<Table
																		rowKey={(_rec, i) => +i}
																		className="table"
																		dataSource={rec.ingredients || []}
																		pagination={false}
																		bordered
																		columns={ingredientsColumns}
																		locale={{ emptyText: __('Нет ингредиентов') }}
																	/>
																) : (
																	false
																)
															}
														/>
													) : null}
												</Col>
												<Col span={24} xl={12} style={{ marginBottom: '12px' }}>
													<h3 align="center">{__('Старые продукты')}</h3>
													{oldProducts ? (
														<Table
															rowKey="id"
															className="table is-full"
															dataSource={oldProducts}
															pagination={false}
															bordered
															columns={productColumns}
															rowClassName={(rec) => (rec.ingredients.length ? null : 'no-expand')}
															expandedRowRender={(rec) =>
																rec.ingredients.length ? (
																	<Table
																		rowKey="id"
																		className="table"
																		dataSource={rec.ingredients || []}
																		pagination={false}
																		bordered
																		columns={ingredientsColumns}
																		locale={{ emptyText: __('Нет ингредиентов') }}
																	/>
																) : (
																	false
																)
															}
														/>
													) : null}
												</Col>
											</Row>
											{!oldNomenclatures && !newNomenclatures ? null : (
												<Row gutter={12}>
													<Col span={24} xl={12} style={{ marginBottom: '12px' }}>
														<h3 align="center">{__('Новая номенклатура')}</h3>
														{newNomenclatures ? (
															<Table
																rowKey="id"
																className="table is-full"
																dataSource={newNomenclatures}
																pagination={false}
																bordered
																columns={productColumns}
															/>
														) : null}
													</Col>
													<Col span={24} xl={12} style={{ marginBottom: '12px' }}>
														<h3 align="center">{__('Старая номенклатура')}</h3>
														{oldNomenclatures ? (
															<Table
																rowKey="id"
																className="table is-full"
																dataSource={oldNomenclatures}
																pagination={false}
																bordered
																columns={productColumns}
															/>
														) : null}
													</Col>
												</Row>
											)}
										</div>
									)}
								</>
							);
						}}
					/>
				</div>
			</Modal>
		</>
	);
};
