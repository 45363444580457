import { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { routesList, locales, defaultLocale } from 'const';
import { RouteProvider } from 'providers';
import {
	useMethods,
	useData,
	getAuthProps,
	setLocalStorage,
	getRestaurantProps,
	getLocalStorage,
	getDefaultAuthProps,
	getDefaultRestaurantProps,
	isUrl
} from 'utils';
import { checkSipAction, sipConnectAction, sipDisconnectAction, socketConnectAction, socketDisconnectAction, updateDevicesAction } from 'actions';

export const Routes = () => {
	const { user = {}, needAuthProps, needRestaurantProps, isUserMedia } = useData();
	const { update } = useMethods();

	useEffect(() => {
		(async () => {
			if (!isUrl('/relogin', true)) {
				let updateProps = {};

				if (!!user.id) {
					if (needAuthProps) {
						const authProps = await getAuthProps();
						setLocalStorage('props', authProps);
						updateProps = {
							...updateProps,
							...authProps,
							needAuthProps: false
						};
					}

					if (needRestaurantProps) {
						const restaurantSlug = user?.restaurant_slug;
						const restaurantProps = await getRestaurantProps(restaurantSlug);
						const restaurants = getLocalStorage('restaurants', {});
						restaurants[restaurantSlug] = restaurantProps;
						setLocalStorage('restaurants', restaurants);
						updateProps = {
							...updateProps,
							...restaurantProps,
							needRestaurantProps: false
						};
					}

					await socketConnectAction();

					if (isUserMedia) {
						const sipSuccess = await checkSipAction();
						const sipEnabled = user?.sip?.enabled;
						const sipLogin = user?.sip?.login;
						const sipPassword = user?.sip?.pass;
						if (sipSuccess && sipEnabled && sipLogin && sipPassword) {
							const sipInstance = await sipConnectAction(sipLogin, sipPassword);
							const { audioMicDeviceId, audioCallDeviceId, audioBellDeviceId, audioOutputDevices, audioInputDevices } =
								await updateDevicesAction();
							updateProps = {
								...updateProps,
								sipWork: sipInstance.work && sipInstance.socket,
								audioMicDeviceId,
								audioCallDeviceId,
								audioBellDeviceId,
								audioOutputDevices,
								audioInputDevices
							};
						}
					}
				} else {
					await socketDisconnectAction();
					await sipDisconnectAction();

					updateProps = {
						...updateProps,
						sipWork: false,
						needAuthProps: true,
						needRestaurantProps: true,
						sip: {},
						historyCompleted: [],
						historyMissed: [],
						...getDefaultAuthProps,
						...getDefaultRestaurantProps
					};
				}

				update(updateProps);
			}
		})();
	}, [user.id]);

	return (
		<BrowserRouter>
			<Switch>
				{routesList.map(({ path, exact, component: Component, needAuth }, index) =>
					locales.map((locale) => {
						if (locale !== defaultLocale && path === '*') {
							return null;
						}
						return (
							<Route
								key={locale + index}
								path={`${locale !== defaultLocale ? `/${locale}` : ''}${path}`}
								exact={exact}
								render={(props) => (
									<RouteProvider locale={locale} location={props.location}>
										<Component {...props} />
									</RouteProvider>
								)}
							/>
						);
					})
				)}
			</Switch>
		</BrowserRouter>
	);
};
