import { useState, useEffect } from 'react';
import { Modal, Preloader } from 'components';
import { Form, Select, Input, InputNumber, Upload } from 'antd';
import { useMethods, requiredRule, useData, normFile, filetypeRule, filesizeEachRule, floatFormatter, minLengthRule } from 'utils';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { createCashboxInflowAction, updateCashboxTransactionAction } from 'actions';

export const DepositCashboxModal = (props) => {
	const { children, values = {} } = props;
	const { __ } = useMethods();
	const [form] = Form.useForm();
	const [pending, setPending] = useState(false);
	const [visible, setVisible] = useState(false);
	const { transactionsTypes = [], paymentTypes = [] } = useData();
	const transactionsTypesData = transactionsTypes.filter((item) => !item.private && item.is_cash && item.impact === 1);
	const transactionId = values.id;
	const paymentTypesList = paymentTypes
		.filter((item) => item.allowed_for_cash_shift_transactions)
		.map((item) => ({ label: item.name, value: item.id }));

	useEffect(() => {
		if (!visible && transactionId) {
			form.setFieldsValue({
				name: values.creator_name,
				sum: values.sum ? Math.abs(values.sum) : null,
				transactions_type_id: values.transactions_type_id || null,
				description: values.description,
				payment_type_id: values.payment_type_id,
				files: []
			});
		}
	}, [visible, values]);

	const submitHandler = async (values) => {
		setPending(true);
		const data = {
			name: values.name,
			transactions_type_id: values.transactions_type_id || null,
			sum: parseFloat(values.sum),
			description: values.description,
			payment_type_id: values.payment_type_id || null,
			files: (values.files || []).map((file) => file.originFileObj)
		};

		const transaction = transactionId ? await updateCashboxTransactionAction(transactionId, data) : await createCashboxInflowAction(data);
		setPending(false);
		if (transaction) {
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Внесение средств')}</div>
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending} indicator={null}>
							<Form.Item className="form__item" name="name" label={__('От кого')} required rules={[requiredRule(__)]}>
								<Input allowClear={true} />
							</Form.Item>
							<Form.Item className="form__item" name="sum" label={__('Сумма внесения, грн')} required rules={[requiredRule(__)]}>
								<InputNumber min={0} step={0.01} formatter={floatFormatter} />
							</Form.Item>
							<Form.Item
								className="form__item "
								name="transactions_type_id"
								label={__('Назначение средств')}
								required
								rules={[requiredRule(__)]}
							>
								<Select
									dropdownClassName="form__dropdown"
									placeholder={__('Не выбрано')}
									options={transactionsTypesData.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item className="form__item" name="payment_type_id" label={__('Тип оплаты')} required rules={[requiredRule(__)]}>
								<Select
									dropdownClassName="form__dropdown"
									placeholder={__('Не выбрано')}
									options={paymentTypesList}
									allowClear={true}
								/>
							</Form.Item>
							<Form.Item
								className="form__item  form__item--autosize"
								name="description"
								label={__('Примечание')}
								required
								rules={[requiredRule(__), minLengthRule(__, 10)]}
							>
								<Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
							</Form.Item>
							{!transactionId ? (
								<>
									<div className="form__label">{__('Файлы')}</div>
									<div className="form__upload-text">
										{__('Допустимые расширения файлов')}: png, jpg, jpeg, pdf, txt, doc, docx, xls, xlsx.{' '}
										{__('Объем файла не должен превышать')} 8MB
									</div>
									<Form.Item
										className="form__item"
										name="files"
										valuePropName="fileList"
										getValueFromEvent={normFile}
										rules={[filetypeRule(__, 'png, jpg, jpeg, pdf, txt, doc, docx, xls, xlsx'), filesizeEachRule(__, 8)]}
									>
										<Upload multiple={true} beforeUpload={() => false}>
											<button type="button" className="button button--white">
												<UploadOutlined />
												<span>{__('Прикрепить файл')}</span>
											</button>
										</Upload>
									</Form.Item>
								</>
							) : null}
							<div className="form__button">
								<button className="button button--main button--full">
									{pending ? <LoadingOutlined /> : null}
									<span>{!transactionId ? __('Подтвердить внесение') : __('Сохранить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
