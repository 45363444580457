import { useEffect, useState } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { Link, RangePickerField } from 'components';
import { ClearOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useData, useMethods } from 'utils';
import { getWaybillsAction } from 'actions';
import { dateClientFormat } from 'const';
import { dayjs } from '../../utils/datetime';

export const WaybillsFilter = (props) => {
	const { setPending } = props;
	const { waybillsFiltersDefault = {}, waybillsFilters = {}, waybillsTypes = [], warehouses = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const [filterType, setFilterType] = useState(null);
	const typeItem = waybillsTypes.find((item) => item.alias === filterType) || {};
	const { properties = [] } = typeItem;

	useEffect(() => {
		form.setFieldsValue(waybillsFiltersDefault);
	}, [waybillsFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		if (changedValues.type !== undefined) {
			setFilterType(changedValues.type);
		}
		const filter = {
			...waybillsFilters,
			...values,
			page: 1
		};
		const waybills = await getWaybillsAction(filter);
		setPending(false);
		update({
			waybillsFilters: filter,
			waybills
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		setFilterType(null);
		form.setFieldsValue(waybillsFiltersDefault);
		const waybills = await getWaybillsAction(waybillsFiltersDefault);
		setPending(false);
		update({
			waybillsFilters: waybillsFiltersDefault,
			waybills
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const waybills = await getWaybillsAction(waybillsFilters);
		setPending(false);
		update({
			waybills
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Создать накладную')} placement="topLeft">
						<Link href="/warehouse/create" className="button button--main button--icon-small">
							<PlusOutlined />
						</Link>
					</Tooltip>
				</div>
			</div>
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="type" label={__('Тип накладной')}>
								<Select
									dropdownClassName="form__dropdown"
									options={waybillsTypes.map((item) => ({ label: item.title, value: item.alias }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						{properties.includes('date') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="date" label={__('Период времени')}>
									<RangePickerField
										format={dateClientFormat}
										showTime={false}
										allowClear={true}
										separator="—"
										disabledDate={(current) => current && current.endOf('day') > dayjs().endOf('day')}
									/>
								</Form.Item>
							</div>
						) : null}
						{properties.includes('supplier_warehouse_id') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="supplier_warehouse_id" label={__('Со склада')}>
									<Select
										dropdownClassName="form__dropdown"
										options={warehouses.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
						{properties.includes('recipient_warehouse_id') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="recipient_warehouse_id" label={__('На склад')}>
									<Select
										dropdownClassName="form__dropdown"
										options={warehouses.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
