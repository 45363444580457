import { List } from 'antd';
import { formatDigits, useMethods, useData, dayjs } from 'utils';
import { dateTimeServerFormat, dateTimeClientFormat } from 'const';

const ListItem = List.Item;

export const OrderStatistic = () => {
	const { clientOrders = [] } = useData();
	const { __ } = useMethods();

	const clientOrdersLength = clientOrders.length;
	const clientOrdersTotalSum = clientOrders.reduce((acc, nextOrder) => acc + nextOrder.price, 0);
	const clientOrdersAverageSum = clientOrdersTotalSum / clientOrdersLength;
	const lastClientOrder = clientOrders[clientOrdersLength - 1];
	const lastOrderDateTime = lastClientOrder ? dayjs(lastClientOrder.created_at, dateTimeServerFormat).format(dateTimeClientFormat) : '-';

	return clientOrders.length ? (
		<div className="order-form__stat">
			<div className="order-form__title">{__('Статистика заказов клиента')}</div>
			<List>
				<ListItem className="order-form__stat-row">
					<span>{__('Всего заказов')}:</span>
					<strong>{clientOrdersLength}</strong>
				</ListItem>
				<ListItem className="order-form__stat-row">
					<span>{__('Всего заказов на сумму')}:</span>
					<strong>
						{formatDigits({ number: clientOrdersTotalSum, toFixed: 2 })} {__('грн')}
					</strong>
				</ListItem>
				<ListItem className="order-form__stat-row">
					<span>{__('Средняя сумма заказа')}:</span>
					<strong>
						{formatDigits({ number: clientOrdersAverageSum, toFixed: 2 })} {__('грн')}
					</strong>
				</ListItem>
				<ListItem className="order-form__stat-row">
					<span>{__('Дата и время последнего заказа')}:</span>
					<strong>{lastOrderDateTime}</strong>
				</ListItem>
			</List>
		</div>
	) : null;
};
