import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

export const CountInput = (props) => {
	const { onChange, value, min = 0, max = 10000000, disabled = false, ...rest } = props;
	const [inputValue, setInputValue] = useState(value || 0);

	const onMinus = () => {
		let resultValue = inputValue - 1;
		if (resultValue < min) {
			resultValue = min;
		}
		if (resultValue > max) {
			resultValue = max;
		}
		setInputValue(resultValue);
		if (onChange) {
			onChange(resultValue);
		}
	};

	const onPlus = () => {
		let resultValue = inputValue + 1;
		if (resultValue < min) {
			resultValue = min;
		}
		if (resultValue > max) {
			resultValue = max;
		}
		setInputValue(resultValue);
		if (onChange) {
			onChange(resultValue);
		}
	};

	const onInput = (event) => {
		const value = event.currentTarget.value;
		let resultValue = Number(String(value).replace(/[^0-9]/gi, '')) || 0;
		if (resultValue < min) {
			resultValue = min;
		}
		if (resultValue > max) {
			resultValue = max;
		}
		setInputValue(resultValue);
		if (onChange) {
			onChange(resultValue);
		}
	};

	return (
		<span className="count-input" {...rest}>
			<Input
				addonBefore={
					<Button icon={<MinusOutlined />} className="count-input__minus" onClick={onMinus} disabled={inputValue < 1 || disabled} />
				}
				addonAfter={<Button icon={<PlusOutlined />} className="count-input__plus" onClick={onPlus} disabled={disabled} />}
				value={inputValue}
				disabled={disabled}
				onChange={onInput}
			/>
		</span>
	);
};
