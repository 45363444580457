import { useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { useData, useMethods, formatDigits, dayjs } from 'utils';
import { getReportsPurchasesAction } from 'actions';
import { dateClientFormat } from 'const';
import { RangePickerField } from 'components';

export const ReportPurchasesFilter = (props) => {
	const { setPending } = props;
	const { reportPurchasesFiltersDefault = {}, reportPurchasesFilters = {}, reportPurchases = [], warehouses = [], suppliers = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	const { cost, weight } = reportPurchases.reduce(
		(a, e) => {
			return {
				weight: e.weight + a.weight,
				cost: e.cost + a.cost
			};
		},
		{ cost: 0, weight: 0 }
	);

	useEffect(() => {
		form.setFieldsValue(reportPurchasesFiltersDefault);
	}, [reportPurchasesFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...reportPurchasesFilters,
			...values
		};
		const reportPurchases = await getReportsPurchasesAction(filter);
		setPending(false);
		update({
			reportPurchasesFilters: filter,
			reportPurchases
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(reportPurchasesFiltersDefault);
		const reportPurchases = await getReportsPurchasesAction(reportPurchasesFiltersDefault);
		setPending(false);
		update({
			reportPurchasesFilters: reportPurchasesFiltersDefault,
			reportPurchases
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const reportPurchases = await getReportsPurchasesAction(reportPurchasesFilters);
		setPending(false);
		update({
			reportPurchases
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" name="date" label={__('Период времени')}>
								<RangePickerField
									format={dateClientFormat}
									showTime={false}
									allowClear={false}
									separator="—"
									disabledDate={(current) => current && current > dayjs().endOf('day')}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="warehouse_id" label={__('Склад')}>
								<Select
									dropdownClassName="form__dropdown"
									options={warehouses.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="supplier_id" label={__('Поставщик')}>
								<Select
									dropdownClassName="form__dropdown"
									options={suppliers.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item filter__item--center">
							<div className="filter__text-block">
								<div>
									{__('Кол-во')}:{' '}
									<span style={{ color: '#2e9a5e' }}>
										{formatDigits({ number: weight, toFixed: 2, defaultValue: 0 })} {__('кг')}
									</span>
								</div>
								<div>
									{__('Сумма')}:{' '}
									<span style={{ color: '#ff0101' }}>
										{formatDigits({ number: cost, toFixed: 2, defaultValue: 0 })} {__('грн')}
									</span>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="bottomRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
