import { useEffect } from 'react';
import { Form, Select, Tooltip, Input } from 'antd';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMethods, useData, downloadFile, access } from 'utils';
import { getReportsDishesAction } from 'actions';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';

let timeoutId = null;

export const DocsDishesFilter = (props) => {
	const { setPending } = props;
	const {
		restaurants = [],
		years = [],
		months = [],
		dishCategories = [],
		docsDishesFiltersDefault = {},
		docsDishesFilters = {},
		user = {}
	} = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const fileName = `dishes-report-${docsDishesFilters.restaurant_id}${
		docsDishesFilters.category_ids && docsDishesFilters.category_ids.length ? `-${docsDishesFilters.category_ids.join('-')}` : ''
	}-${docsDishesFilters.year}-${docsDishesFilters.month}-${docsDishesFilters.group}${
		docsDishesFilters.product_name ? `-${docsDishesFilters.product_name}` : ''
	}.xlsx`;

	useEffect(() => {
		form.setFieldsValue({
			...docsDishesFiltersDefault,
			restaurant_id: restaurants.find((item) => item.slug === user.restaurant_slug)?.id
		});
	}, [docsDishesFiltersDefault]);

	const filterHandler = (changedValues, values) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(async () => {
			setPending(true);
			const filter = {
				...docsDishesFilters,
				...values
			};
			const docsDishes = await getReportsDishesAction(filter);
			setPending(false);
			update({
				docsDishesFilters: filter,
				docsDishes
			});
		}, 500);
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(docsDishesFiltersDefault);
		const docsDishes = await getReportsDishesAction(docsDishesFiltersDefault);
		setPending(false);
		update({
			docsDishesFilters: docsDishesFiltersDefault,
			docsDishes
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const docsDishes = await getReportsDishesAction(docsDishesFilters);
		setPending(false);
		update({
			docsDishes
		});
	};

	const groups = [
		{ label: __('Звезда'), value: 'star' },
		{ label: __('Лошадь'), value: 'horse' },
		{ label: __('Секрет'), value: 'secret' },
		{ label: __('Собака'), value: 'dog' }
	];

	const downloadXlsFile = (event) => {
		event.preventDefault();
		downloadFile(`reports/generate-dishes-file`, fileName, {
			restaurant_id: docsDishesFilters.restaurant_id,
			category_ids: docsDishesFilters.category_ids,
			month: docsDishesFilters.month,
			year: docsDishesFilters.year,
			group: docsDishesFilters.group,
			product_name: docsDishesFilters.product_name
		});
	};

	return (
		<div className="filter filter--6">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						{access('docs-reports-dishes-restaurant-filter') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="restaurant_id" label={__('Ресторан')}>
									<Select
										dropdownClassName="form__dropdown"
										options={restaurants.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
						<div className="filter__item">
							<Form.Item className="form__item" name="category_ids" label={__('Категория')}>
								<Select
									dropdownClassName="form__dropdown"
									options={dishCategories.map((item) => ({ label: item.name, value: item.id }))}
									placeholder={__('Не выбрано')}
									mode="multiple"
									allowClear={true}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="product_name" label={__('Название блюда')}>
								<Input placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="group" label={__('Группа')}>
								<Select dropdownClassName="form__dropdown" options={groups} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="month" label={__('Месяц')}>
								<Select dropdownClassName="form__dropdown" options={months} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="year" label={__('Год')}>
								<Select dropdownClassName="form__dropdown" options={years} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
				{access('docs-reports-dishes-file') ? (
					<div className="filter__button">
						<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
							<a href={`/public/${fileName}`} className="button button--default button--icon-def" onClick={downloadXlsFile}>
								<XlsIcon />
							</a>
						</Tooltip>
					</div>
				) : null}
			</div>
		</div>
	);
};
