import { DatePicker, Form, Select, Tooltip } from 'antd';
import { ClearOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useMethods, useData, dayjs } from 'utils';
import { getFiscalTransactionsAction, printFiscalXReportAction, printFiscalZReportAction } from 'actions';
import { FiscalCashboxModal } from 'components';
import { dateClientFormat } from 'const';

export const FiscalCashboxFilter = (props) => {
	const { setPending } = props;
	const { fiscalTransactionsFiltersDefault = {}, fiscalTransactionsFilters = {}, entrepreneurs = [] } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...fiscalTransactionsFilters,
			...values
		};
		const fiscalTransactions = await getFiscalTransactionsAction(filter);
		setPending(false);
		update({
			fiscalTransactionsFilters: filter,
			fiscalTransactions
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(fiscalTransactionsFiltersDefault);
		const fiscalTransactions = await getFiscalTransactionsAction(fiscalTransactionsFiltersDefault);
		setPending(false);
		update({
			fiscalTransactionsFilters: fiscalTransactionsFiltersDefault,
			fiscalTransactions
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const fiscalTransactions = await getFiscalTransactionsAction(fiscalTransactionsFilters);
		setPending(false);
		update({
			fiscalTransactions
		});
	};

	return (
		<div className="filter filter--4">
			<div className="filter__buttons">
				<div className="filter__button">
					<FiscalCashboxModal>
						<button type="button" className="button button--main button--full">
							<PlusOutlined />
							<span>{__('Добавить')}</span>
						</button>
					</FiscalCashboxModal>
				</div>
			</div>
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						<div className="filter__item">
							<Form.Item className="form__item" label={__('Юр. лицо')} name="entrepreneur_id">
								<Select
									autoClearSearchValue={false}
									showArrow={false}
									notFoundContent={null}
									showSearch={true}
									optionFilterProp="label"
									dropdownClassName="form__dropdown"
									options={entrepreneurs.map((item) => ({ label: item.name, value: item.id }))}
									allowClear={true}
									placeholder={__('Не выбрано')}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" label={__('Дата')} name="date">
								<DatePicker
									format={dateClientFormat}
									disabledDate={(current) => current && current > dayjs().endOf('day')}
									allowClear={false}
								/>
							</Form.Item>
						</div>
						<div className="filter__item">
							<div className="filter__cells">
								<div className="filter__cell">
									<Tooltip title={__('Очистить фильтр')} placement="topRight">
										<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
											<ClearOutlined />
										</button>
									</Tooltip>
								</div>
								<div className="filter__cell">
									<Tooltip title={__('Обновить')} placement="topRight">
										<button className="button button--main-border button--icon-small" onClick={updateHandler}>
											<ReloadOutlined />
										</button>
									</Tooltip>
								</div>
								<div className="filter__cell">
									{fiscalTransactionsFilters.entrepreneur_id ? (
										<Tooltip title={__('Печать X отчета')} placement="topRight">
											<button
												className="button button--main-border button--icon-small"
												onClick={() => printFiscalXReportAction(fiscalTransactionsFilters.entrepreneur_id)}
											>
												X
											</button>
										</Tooltip>
									) : (
										<button className="button button--main-border button--icon-small" disabled={true}>
											X
										</button>
									)}
								</div>
								<div className="filter__cell">
									{fiscalTransactionsFilters.entrepreneur_id ? (
										<Tooltip title={__('Печать Z отчета')} placement="topRight">
											<button
												className="button button--main-border button--icon-small"
												onClick={() => printFiscalZReportAction(fiscalTransactionsFilters.entrepreneur_id)}
											>
												Z
											</button>
										</Tooltip>
									) : (
										<button className="button button--main-border button--icon-small" disabled={true}>
											Z
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
};
