import { useState } from 'react';
import { DatePicker } from 'antd';

export const RangePickerField = (props) => {
	const { value, onChange, ...rest } = props;
	const [openPopup, setOpenPopup] = useState(false);
	const [val, setVal] = useState([]);

	return (
		<DatePicker.RangePicker
			value={openPopup ? val : value}
			onChange={(date) => {
				setVal(date);
				if (!date) {
					onChange([]);
				} else if (date.length === 2 && ((date[0] && date[1]) || (date[0] === null && date[1] === null))) {
					onChange(date);
				}
			}}
			open={openPopup}
			onOpenChange={(open) => {
				if (!open) {
					setVal([]);
				}
				setOpenPopup(open);
			}}
			{...rest}
		/>
	);
};
