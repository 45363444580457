import { useMethods, useData, dayjs, formatDigits, phoneClear, phoneFormat, access } from 'utils';
import { Table, Row, Col, Tooltip, Popover } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { dateServerFormat, timeClientFormat, statusIcon } from 'const';
import { ReactComponent as CourierIcon } from 'assets/svg/courier.svg';
import { Link, ChangeCourier, AddCourierAttendance, CourierMap } from 'components';

export const CouriersTable = (props) => {
	const { pending } = props;
	const { couriersDate = [], couriersFilters = {}, orderStatuses = [], paymentTypes = [] } = useData();
	const { __ } = useMethods();

	const sortOrders = {
		busy: 0,
		free: 1,
		off: 2
	};
	const filterCouriers = couriersDate
		.filter((courier) => {
			let result = true;
			const isNowDate = dayjs(couriersFilters.date).format(dateServerFormat) === dayjs().format(dateServerFormat);
			const isActive = !!courier.orders.length;
			const isDelivering = isActive && !!courier.orders.find((order) => !order.is_trip_finished);

			if (couriersFilters.status === 'busy' && !isDelivering) {
				result = false;
			} else if (!isNowDate && !isActive) {
				result = false;
			}

			return result;
		})
		.sort(function (a, b) {
			if (sortOrders[a.status] > sortOrders[b.status]) {
				return 1;
			} else if (sortOrders[a.status] < sortOrders[b.status]) {
				return -1;
			}
			return 0;
		});

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1550
			}}
			columns={[
				{
					title: __('Курьер'),
					dataIndex: 'name',
					render: (name, record) => {
						return (
							<span>
								<span className={`courier-status courier-status--${record.status}`} />
								{name}
							</span>
						);
					}
				},
				{
					title: __('Время выезда'),
					dataIndex: 'departure',
					render: (time) => {
						return time ? dayjs(time).format(timeClientFormat) : '-';
					}
				},
				{
					title: __('Кол-во рейсов'),
					dataIndex: 'trips_count'
				},
				{
					title: __('Сумма заказов, грн'),
					dataIndex: 'orders_all_total',
					render: (sum) => {
						return formatDigits({ number: sum, toFixed: 2, defaultValue: 0 });
					}
				},
				{
					title: __('Сумма заказов наличными, грн'),
					dataIndex: 'orders_cash_total',
					render: (sum) => {
						return formatDigits({ number: sum, toFixed: 2, defaultValue: 0 });
					}
				},
				{
					title: __('Период работы'),
					dataIndex: 'shift_start',
					render: (shiftStart, record) => {
						return shiftStart && record.shift_finish
							? `${__('c')} ${dayjs(shiftStart).format(timeClientFormat)} ${__('по')} ${dayjs(record.shift_finish).format(
									timeClientFormat
							  )}`
							: '-';
					}
				},
				{
					title: __('Телефон'),
					dataIndex: 'phone',
					render: (phone) => {
						return phone ? <a href={`tel:${phoneClear(phone)}`}>{phoneFormat(phone)}</a> : '-';
					}
				},
				{
					title: __('Действие'),
					align: 'right',
					render: (phone, record) => {
						const order = record.orders.find((item) => !item.is_trip_finished) || null;
						const isNowDate =
							couriersFilters.date && dayjs(couriersFilters.date).format(dateServerFormat) === dayjs().format(dateServerFormat);
						return (
							<Row gutter={5} justify="end">
								{access('couriers-attendance-create') && record.status === 'off' && isNowDate ? (
									<Col>
										<AddCourierAttendance courier={record}>
											<Tooltip placement="topRight" title={__('Добавить посещение')}>
												<button className="button button--trans-main button--icon-xs">
													<PlusOutlined />
												</button>
											</Tooltip>
										</AddCourierAttendance>
									</Col>
								) : null}
								{access('couriers-change-trip-courier') && record.status === 'busy' && order && isNowDate ? (
									<Col>
										<ChangeCourier courier={record}>
											<Tooltip placement="topRight" title={__('Сменить курьера')}>
												<button className="button button--trans button--icon-xs">
													<CourierIcon />
												</button>
											</Tooltip>
										</ChangeCourier>
									</Col>
								) : null}
							</Row>
						);
					}
				}
			]}
			expandedRowRender={(record) => (
				<Table
					rowKey={(row) => row.id}
					dataSource={record.orders}
					pagination={false}
					className="table"
					rowClassName={(record) => (!record.is_trip_finished ? 'in-work' : '')}
					columns={[
						{
							title: __('Номер рейса'),
							dataIndex: 'trip_day_id',
							render: (val) => val || '-'
						},
						{
							title: __('Номер заказа'),
							dataIndex: 'order_day_id',
							render: (val) => val || '-'
						},
						{
							title: __('Статус заказа'),
							dataIndex: 'status_id',
							render: (id) => {
								const name = orderStatuses.find((item) => item.id === id)?.name || __('Неизвестный статус');
								return (
									<Tooltip title={name}>
										<button className="button button--trans button--icon-xs">{statusIcon[id] || name}</button>
									</Tooltip>
								);
							}
						},
						{
							title: __('Клиент'),
							dataIndex: 'client_id',
							render: (id, record) => {
								return (
									<Link href={`/clients/${id}`} target={'_blank'}>
										{record.client_name}
									</Link>
								);
							}
						},
						{
							title: __('Тип оплаты'),
							dataIndex: 'payment_type_id',
							render: (id) => {
								return paymentTypes.find((item) => item.id === id)?.name || '-';
							}
						},
						{
							title: __('Сумма заказов'),
							dataIndex: 'total',
							render: (total) => {
								return formatDigits({ number: total, toFixed: 2, defaultValue: 0 });
							}
						},
						{
							title: __('Адрес'),
							dataIndex: 'address',
							render: (val) => val || '-'
						},
						{
							title: __('Время доставки'),
							dataIndex: 'delivery_time',
							render: (time) => {
								return time ? dayjs(time).format(timeClientFormat) : '-';
							}
						},
						{
							title: __('Детали заказа'),
							align: 'right',
							render: (_, record) => {
								return (
									<Popover content={<CourierMap data={record} />} placement="topLeft">
										<button type="button" className="button button--xs button--main">
											<span>{__('Подробнее')}</span>
										</button>
									</Popover>
								);
							}
						}
					]}
				/>
			)}
			dataSource={filterCouriers}
			pagination={false}
		/>
	);
};
