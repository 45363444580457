import { useState } from 'react';
import { Form, Input } from 'antd';
import { Preloader } from 'components';
import { requiredRule, useMethods, preloaderShow } from 'utils';
import { getUserAction, loginAction } from 'actions';
import { LoadingOutlined } from '@ant-design/icons';

export const Login = () => {
	const [pending, setPending] = useState(false);
	const [form] = Form.useForm();
	const { __, update } = useMethods();

	const submitHandler = async (values) => {
		setPending(true);
		const response = await loginAction(values);
		const { success } = response;
		setPending(false);
		if (success) {
			preloaderShow();
			form.resetFields();
			const user = await getUserAction();
			update({
				user
			});
		}
	};

	return (
		<div className="auth">
			<div className="auth__container">
				<div className="auth__title">{__('Добро пожаловать!')}</div>
				<div className="auth__form">
					<Form className="form" onFinish={submitHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending} indicator={null}>
							<Form.Item className="form__item" label={__('Ваш логин')} name="login" required rules={[requiredRule(__)]}>
								<Input type="email" />
							</Form.Item>
							<Form.Item className="form__item" label={__('Ваш пароль')} name="password" required rules={[requiredRule(__)]}>
								<Input type="password" />
							</Form.Item>
							<div className="form__button">
								<button className="button button--main button--full">
									{pending ? <LoadingOutlined /> : null}
									<span>{__('Войти')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</div>
		</div>
	);
};
