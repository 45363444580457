import { useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { ClearOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMethods, useData, downloadFile, access, changeCollapsedInHtml } from 'utils';
import { getReportsTransactionsAction } from 'actions';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';

export const DocsTransactionsFilter = (props) => {
	const { setPending } = props;
	const { restaurants = [], years = [], months = [], docsTransFiltersDefault = {}, docsTransFilters = {}, collapsed } = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const fileName = `transactions-report-${docsTransFilters.restaurant_id}-${docsTransFilters.year}-${docsTransFilters.month}.xlsx`;

	changeCollapsedInHtml(collapsed);

	useEffect(() => {
		form.setFieldsValue(docsTransFiltersDefault);
	}, [docsTransFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		setPending(true);
		const filter = {
			...docsTransFilters,
			...values
		};
		const docsTrans = await getReportsTransactionsAction(filter);
		setPending(false);
		update({
			docsTransFilters: filter,
			docsTrans
		});
	};

	const clearFilterHandler = async () => {
		setPending(true);
		form.setFieldsValue(docsTransFiltersDefault);
		const docsTrans = await getReportsTransactionsAction(docsTransFiltersDefault);
		setPending(false);
		update({
			docsTransFilters: docsTransFiltersDefault,
			docsTrans
		});
	};

	const updateHandler = async () => {
		setPending(true);
		const docsTrans = await getReportsTransactionsAction(docsTransFilters);
		setPending(false);
		update({
			docsTrans
		});
	};

	const downloadXlsFile = (event) => {
		event.preventDefault();
		downloadFile(`/reports/generate-transactions-file`, fileName, {
			restaurant_id: docsTransFilters.restaurant_id,
			month: docsTransFilters.month,
			year: docsTransFilters.year
		});
	};

	return (
		<div className="filter filter--docs-transactions">
			<div className="filter__form">
				<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
					<div className="filter__list">
						{access('docs-reports-transactions-restaurant-filter') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="restaurant_id" label={__('Ресторан')}>
									<Select
										dropdownClassName="form__dropdown"
										options={restaurants.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
						<div className="filter__item">
							<Form.Item className="form__item" name="month" label={__('Месяц')}>
								<Select dropdownClassName="form__dropdown" options={months} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
						<div className="filter__item">
							<Form.Item className="form__item" name="year" label={__('Год')}>
								<Select dropdownClassName="form__dropdown" options={years} placeholder={__('Не выбрано')} allowClear={true} />
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="filter__buttons">
				<div className="filter__button">
					<Tooltip title={__('Очистить фильтр')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={clearFilterHandler}>
							<ClearOutlined />
						</button>
					</Tooltip>
				</div>
				<div className="filter__button">
					<Tooltip title={__('Обновить')} placement="topRight">
						<button className="button button--main-border button--icon-small" onClick={updateHandler}>
							<ReloadOutlined />
						</button>
					</Tooltip>
				</div>
				{access('docs-reports-transactions-file') ? (
					<div className="filter__button">
						<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
							<a href={`/public/${fileName}`} className="button button--default button--icon-def" onClick={downloadXlsFile}>
								<XlsIcon />
							</a>
						</Tooltip>
					</div>
				) : null}
			</div>
		</div>
	);
};
