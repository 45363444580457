import { Table } from 'antd';
import { formatDigits, useData, useMethods } from 'utils';
import { paginationLarge } from 'const';
import { LoadingOutlined } from '@ant-design/icons';
import { getWarehouseRestsAction } from 'actions';

export const WarehouseRestsTable = (props) => {
	const { pending, setPending } = props;
	const { warehouseRestsFilters = {}, warehouseRests = {} } = useData();
	const { data = [], meta = {} } = warehouseRests;
	const { current_page = 1, total = 0, per_page = 10 } = meta;
	const { __, update } = useMethods();

	const tableChangeHandler = async (pagination) => {
		setPending(true);
		const filter = {
			...warehouseRestsFilters,
			page: pagination.current,
			per_page: pagination.pageSize
		};
		const warehouseRests = await getWarehouseRestsAction(filter);
		setPending(false);
		update({
			warehouseRestsFilters: filter,
			warehouseRests
		});
	};

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1050
			}}
			columns={[
				{
					title: __('Номенклатура'),
					dataIndex: 'nomenclature',
					width: 150,
					render: ({ name }) => name || '-'
				},
				{
					title: __('Категория'),
					dataIndex: 'nomenclature',
					width: 150,
					render: ({ category_name }) => category_name || '-'
				},
				{
					title: __('Количество единиц'),
					dataIndex: 'value',
					width: 150,
					render: (value, record) => (value ? `${value} ${record.nomenclature.unit_title}` : '-')
				},
				{
					title: __('Минимум'),
					dataIndex: 'min',
					width: 150,
					render: (value, record) => (value ? `${value} ${record.nomenclature.unit_title}` : '-')
				},
				{
					title: __('Закупочная цена'),
					dataIndex: 'purchase_price',
					width: 150,
					render: (value) => formatDigits({ number: value, toFixed: 2 })
				},
				{
					title: __('Расчетная цена'),
					dataIndex: 'calculation_price',
					width: 150,
					render: (value) => formatDigits({ number: value, toFixed: 2 })
				},
				{
					title: __('Склад'),
					dataIndex: 'warehouse',
					width: 150,
					render: (warehouse) => (warehouse && warehouse.name ? warehouse.name : '-')
				}
			]}
			dataSource={data}
			pagination={{
				...paginationLarge,
				current: current_page,
				pageSize: +per_page,
				total
			}}
			rowClassName={(record) => {
				if (!record.min) {
					return 'no-min';
				}
				if (record.value < record.min) {
					return 'min-value';
				}
			}}
			onChange={tableChangeHandler}
		/>
	);
};
