import { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useMethods, useData } from 'utils';
import { PhoneTab, SettingsTab, CompletedTab, MissedTab } from 'components';
import { callsTypesAction, getHistoryCompletedAction, getHistoryMissedAction } from 'actions';

export const CallModal = () => {
	const { __, update } = useMethods();
	const { sip = {}, sipWork, collapsed } = useData();
	const { outgoing = {}, incoming = {} } = sip;
	const [tab, setTab] = useState('phone');
	const [pendingCompleted, setPendingCompleted] = useState(false);
	const [pendingMissed, setPendingMissed] = useState(false);

	useEffect(() => {
		(async () => {
			if (sipWork) {
				setPendingCompleted(true);
				setPendingMissed(true);
				const [callsTypes, historyCompleted, historyMissed] = await Promise.all([
					callsTypesAction(),
					getHistoryCompletedAction(),
					getHistoryMissedAction()
				]);
				setPendingCompleted(false);
				setPendingMissed(false);
				update({
					callsTypes,
					historyCompleted,
					historyMissed
				});
			}
		})();
	}, [sipWork]);

	useEffect(() => {
		if (outgoing.status === 'call' || incoming.status === 'incoming') {
			setTab('phone');
		}
	}, [outgoing, incoming]);

	return sipWork ? (
		<div className={`call-modal${collapsed ? ' is-collapsed' : ''}${sip.show ? ' is-show' : ''}`}>
			<Tabs activeKey={tab || 1} animated={false} onChange={(key) => setTab(key)}>
				<Tabs.TabPane key="phone" tab={__('Телефон')}>
					<PhoneTab />
				</Tabs.TabPane>
				<Tabs.TabPane key="completed" tab={__('Совершенные')}>
					<CompletedTab pending={pendingCompleted} setPending={setPendingCompleted} />
				</Tabs.TabPane>
				<Tabs.TabPane key="missed" tab={__('Пропущенные')}>
					<MissedTab pending={pendingMissed} setPending={setPendingMissed} />
				</Tabs.TabPane>
				<Tabs.TabPane key="settings" tab={__('Настройки')}>
					<SettingsTab />
				</Tabs.TabPane>
			</Tabs>
		</div>
	) : null;
};
