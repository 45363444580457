import { pdvPercent } from 'const';

export const nomenclaturesChange = (list, isChangeCount) => {
	return list.map((item) => {
		const calcCount = +(item.calc_count || item.last_purchase_waybill_data?.calc_count || 0);
		const calcWeight = +(item.calc_weight || item.last_purchase_waybill_data?.calc_weight || 0);
		const calcCost = +(item.calc_cost || item.last_purchase_waybill_data?.calc_cost || 0);

		const count = isChangeCount ? (item.count ? +item.count : item.value) : calcCount * calcWeight;
		const factCost = calcWeight > 0 ? calcCost / calcWeight : 0;
		const sum = factCost * count;
		const factCount = +(item.fact_count || 0);
		const factSum = factCost * factCount;
		const differenceKg = factCount - count;
		const differenceSum = factSum - sum;
		const costKg = (1000 / (item.unit_weight || 0)) * factCost;
		const pdv = (sum * pdvPercent) / 100;
		const sumPdv = sum + pdv;

		return {
			...item,
			calc_cost: calcCost,
			calc_count: calcCount,
			calc_weight: calcWeight,
			count: count,
			fact_cost: factCost,
			sum: sum,
			fact_count: factCount,
			fact_sum: factSum,
			difference_kg: differenceKg,
			difference_sum: differenceSum,
			cost_kg: costKg,
			pdv: pdv,
			sum_pdv: sumPdv
		};
	});
};

export const calcNomTotalSum = (items = []) => {
	return items.reduce(
		(a, e) => {
			a.sum += e.sum || 0;
			a.total += e.total || 0;
			a.pdv += e.pdv || 0;
			a.sum_pdv += e.sum_pdv || 0;
			a.fact_sum += e.fact_sum || 0;
			return a;
		},
		{ sum: 0, total: 0, pdv: 0, sum_pdv: 0, fact_sum: 0 }
	);
};
