import { Table } from 'antd';
import { useData, useMethods, formatDigits } from 'utils';
import { LoadingOutlined } from '@ant-design/icons';

export const ReportPurchasesTable = (props) => {
	const { pending } = props;
	const { reportPurchases = [], nomenclatures = [], suppliers = [], warehouses = [] } = useData();
	const { __ } = useMethods();

	return (
		<Table
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 900
			}}
			columns={[
				{
					title: __('Номенклатура'),
					width: 150,
					dataIndex: 'nomenclature_id',
					render: (nomenclature_id) => {
						const nom = nomenclatures.find((item) => item.id === nomenclature_id);
						return nom ? nom.name : '-';
					}
				},
				{
					title: __('Категория'),
					width: 150,
					render: (_, record) => {
						const nom = nomenclatures.find((item) => item.id === record.nomenclature_id);
						return nom ? nom.category_name : '-';
					}
				},
				{
					title: __('Количество'),
					width: 150,
					dataIndex: 'weight',
					render: (weight) => (weight ? `${weight} ${__('кг')}` : '-')
				},
				{
					title: __('Сумма'),
					width: 150,
					dataIndex: 'cost',
					render: (cost) => `${formatDigits({ number: cost, toFixed: 2 })} ${__('грн')}`
				},
				{
					title: __('Поставщик'),
					width: 150,
					dataIndex: 'supplier_id',
					render: (supplier_id) => {
						const supplier = suppliers.find((item) => item.id === supplier_id);
						return supplier ? supplier.name : '-';
					}
				},
				{
					title: __('Склад'),
					width: 150,
					dataIndex: 'warehouse_id',
					render: (warehouse_id) => {
						const warehouse = warehouses.find((item) => item.id === warehouse_id);
						return warehouse ? warehouse.name : '-';
					}
				}
			]}
			dataSource={reportPurchases}
			pagination={false}
		/>
	);
};
