import { useEffect, useState } from 'react';
import { useMethods, dayjs } from 'utils';
import { getReportsPurchasesAction, getSuppliersAction } from 'actions';
import { ReportPurchasesFilter, ReportPurchasesTable } from 'components';

export const ReportPurchasesTab = () => {
	const { update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			setPending(true);
			const reportPurchasesFiltersDefault = {
				date: [dayjs(), dayjs()],
				supplier_id: null,
				warehouse_id: null
			};
			const reportPurchasesFilters = { ...reportPurchasesFiltersDefault };
			const [suppliers, reportPurchases] = await Promise.all([getSuppliersAction(), getReportsPurchasesAction(reportPurchasesFilters)]);
			setPending(false);
			update({
				reportPurchasesFiltersDefault,
				reportPurchasesFilters,
				suppliers,
				reportPurchases
			});
		})();

		return () => {
			update({
				reportPurchasesFiltersDefault: {},
				reportPurchasesFilters: {},
				reportPurchases: {}
			});
		};
	}, []);

	return (
		<>
			<ReportPurchasesFilter setPending={setPending} />
			<ReportPurchasesTable pending={pending} />
		</>
	);
};
