import { Link } from 'components';
import { useMethods } from 'utils';

export const Error = (props) => {
	const { statusCode } = props;
	const { __ } = useMethods();

	return (
		<div className="error">
			<div className="error__container">
				<div className="error__code">{statusCode}</div>
				<div className="error__title">{__('Упс, произошла ошибка')}</div>
				<div className="error__text">{__('Ничего страшного, просто перейдите на главную')}</div>
				<Link href="/" className="button button--main">
					<span>{__('Перейти на главную')}</span>
				</Link>
			</div>
		</div>
	);
};
