import { useEffect } from 'react';
import { Form, Select, Tooltip, Row, Col, DatePicker } from 'antd';
import { useMethods, useData, downloadFile, access, dayjs, num2str } from 'utils';
import { getReportsCouriersAction } from 'actions';
import { ReactComponent as XlsIcon } from 'assets/svg/xls.svg';
import { dateClientFormat, dateServerFormat } from 'const';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Preloader } from 'components';

export const DocsCouriers = (props) => {
	const { pending1, pending2, setPending1, setPending2 } = props;
	const {
		restaurants = [],
		docsCouriersFiltersDefault = {},
		docsCouriersFilters = {},
		docsCouriers1 = [],
		docsCouriers2 = [],
		docsCouriers3 = [],
		docsCouriers4 = []
	} = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const fileName = `couriers-report-${docsCouriersFilters.restaurant_id}-${docsCouriersFilters.date1}-${docsCouriersFilters.date2}-${docsCouriersFilters.date3}-${docsCouriersFilters.date4}.xlsx`;

	useEffect(() => {
		form.setFieldsValue(docsCouriersFiltersDefault);
	}, [docsCouriersFiltersDefault]);

	const filterHandler = async (changedValues, values) => {
		if (changedValues.restaurant_id || changedValues.date1 || changedValues.date2) {
			setPending1(true);
		}
		if (changedValues.restaurant_id || changedValues.date3 || changedValues.date4) {
			setPending2(true);
		}
		const filter = {
			...docsCouriersFilters,
			...values
		};
		const data = {
			docsCouriersFilters: filter
		};
		if (changedValues.restaurant_id || changedValues.date1 || changedValues.date2) {
			const [docsCouriers1, docsCouriers2] = await Promise.all([
				getReportsCouriersAction({
					restaurant_id: filter.restaurant_id,
					date: filter.date1
				}),
				getReportsCouriersAction({
					restaurant_id: filter.restaurant_id,
					date: filter.date2
				})
			]);
			data.docsCouriers1 = docsCouriers1;
			data.docsCouriers2 = docsCouriers2;
		}
		if (changedValues.restaurant_id || changedValues.date3 || changedValues.date4) {
			const [docsCouriers3, docsCouriers4] = await Promise.all([
				getReportsCouriersAction({
					restaurant_id: filter.restaurant_id,
					date: filter.date3
				}),
				getReportsCouriersAction({
					restaurant_id: filter.restaurant_id,
					date: filter.date4
				})
			]);
			data.docsCouriers3 = docsCouriers3;
			data.docsCouriers4 = docsCouriers4;
		}
		if (changedValues.restaurant_id || changedValues.date1 || changedValues.date2) {
			setPending1(false);
		}
		if (changedValues.restaurant_id || changedValues.date3 || changedValues.date4) {
			setPending2(false);
		}
		update(data);
	};

	const downloadXlsFile = (event) => {
		event.preventDefault();
		downloadFile(`/reports/generate-couriers-loading-file`, fileName, {
			restaurant_id: docsCouriersFilters.restaurant_id,
			date_1: docsCouriersFilters.date1 ? dayjs(docsCouriersFilters.date1).format(dateServerFormat) : null,
			date_2: docsCouriersFilters.date2 ? dayjs(docsCouriersFilters.date2).format(dateServerFormat) : null,
			date_3: docsCouriersFilters.date3 ? dayjs(docsCouriersFilters.date3).format(dateServerFormat) : null,
			date_4: docsCouriersFilters.date4 ? dayjs(docsCouriersFilters.date4).format(dateServerFormat) : null
		});
	};

	const getOptions = (date1, couriers1 = [], date2, couriers2 = []) => {
		const labels = [];

		const data1 = [];
		couriers1.forEach((item) => {
			labels.push(parseInt(item.hour));
			data1.push({
				x: parseInt(item.hour),
				y: parseInt(item.count)
			});
		});

		const data2 = [];
		couriers2.forEach((item) => {
			if (!labels.includes(parseInt(item.hour))) {
				labels.push(parseInt(item.hour));
			}
			data2.push({
				x: parseInt(item.hour),
				y: parseInt(item.count)
			});
		});

		labels.sort();

		return {
			data: {
				labels: labels,
				datasets: [
					{
						key: '1',
						label: date1 ? dayjs(date1).format(dateClientFormat) : '',
						lineTension: 0,
						fill: false,
						borderColor: '#142cd1',
						borderWidth: 3,
						pointRadius: 5,
						pointHoverRadius: 7,
						pointBackgroundColor: '#142cd1',
						pointHoverBackgroundColor: '#142cd1',
						data: data1
					},
					{
						key: '2',
						label: date2 ? dayjs(date2).format(dateClientFormat) : '',
						lineTension: 0,
						fill: false,
						borderColor: '#ff200d',
						borderWidth: 3,
						pointRadius: 5,
						pointHoverRadius: 7,
						pointBackgroundColor: '#ff200d',
						pointHoverBackgroundColor: '#ff200d',
						data: data2
					}
				]
			},
			options: {
				title: {
					display: false
				},
				tooltips: {
					backgroundColor: '#e6f7ff',
					bodyFontColor: '#000000',
					xPadding: 10,
					yPadding: 10,
					callbacks: {
						label: function (tooltipItem, data) {
							const dataSet = data.datasets[tooltipItem.datasetIndex];
							return ` ${dataSet.label} ${tooltipItem.label}:00 - ${tooltipItem.value} ${num2str(tooltipItem.value, [
								__('заказ'),
								__('заказа'),
								__('заказов')
							])} `;
						},
						title: function () {
							return false;
						}
					}
				},
				scales: {
					xAxes: [
						{
							display: true,
							ticks: {
								stepSize: 1
							},
							scaleLabel: {
								display: true,
								fontFamily: 'Arial',
								fontColor: '#000000',
								fontSize: 14,
								labelString: __('Часы')
							}
						}
					],
					yAxes: [
						{
							display: true,
							ticks: {
								stepSize: 1,
								callback: function (value) {
									return parseInt(value);
								}
							},
							scaleLabel: {
								display: true,
								fontFamily: 'Arial',
								fontColor: '#000000',
								fontSize: 14,
								labelString: __('Количество заказов')
							}
						}
					]
				},
				plugins: {
					datalabels: {
						display: false
					}
				}
			},
			datasetKeyProvider: (data) => data.key,
			plugins: [ChartDataLabels]
		};
	};

	return (
		<Form className="form form--small" form={form} layout="vertical" noValidate={true} onValuesChange={filterHandler}>
			<div className="filter filter--4">
				<div className="filter__form">
					<div className="filter__list">
						{access('docs-reports-couriers-loading-restaurant-filter') ? (
							<div className="filter__item">
								<Form.Item className="form__item" name="restaurant_id" label={__('Ресторан')}>
									<Select
										dropdownClassName="form__dropdown"
										options={restaurants.map((item) => ({ label: item.name, value: item.id }))}
										placeholder={__('Не выбрано')}
										allowClear={true}
									/>
								</Form.Item>
							</div>
						) : null}
					</div>
				</div>
				<div className="filter__buttons">
					{access('docs-reports-couriers-loading-file') ? (
						<div className="filter__button">
							<Tooltip title={__('Скачать в формате XLS')} placement="topRight">
								<a href={`/public/${fileName}`} className="button button--default button--icon-def" onClick={downloadXlsFile}>
									<XlsIcon />
								</a>
							</Tooltip>
						</div>
					) : null}
				</div>
			</div>
			<Row gutter={30} align="bottom">
				<Col span={24} lg={12}>
					<Row gutter={12} align="bottom">
						<Col span={24} sm={12}>
							<Form.Item className="form__item" label={__('Дата сегодня')} name="date1">
								<DatePicker format={dateClientFormat} allowClear={true} disabled={true} />
							</Form.Item>
						</Col>
						<Col span={24} sm={12}>
							<Form.Item className="form__item" label={__('Дата вчера')} name="date2">
								<DatePicker format={dateClientFormat} allowClear={true} disabled={true} />
							</Form.Item>
						</Col>
						<Col span={24} style={{ marginTop: '20px' }}>
							<Preloader spinning={pending1}>
								<Line {...getOptions(docsCouriersFilters.date1, docsCouriers1, docsCouriersFilters.date2, docsCouriers2)} />
							</Preloader>
						</Col>
					</Row>
				</Col>
				<Col span={24} lg={12}>
					<Row gutter={12} align="bottom">
						<Col span={24} sm={12}>
							<Form.Item className="form__item" label={__('Дата 1')} name="date3">
								<DatePicker format={dateClientFormat} disabledDate={(current) => current && current > dayjs().endOf('day')} />
							</Form.Item>
						</Col>
						<Col span={24} sm={12}>
							<Form.Item className="form__item" label={__('Дата 2')} name="date4">
								<DatePicker format={dateClientFormat} disabledDate={(current) => current && current > dayjs().endOf('day')} />
							</Form.Item>
						</Col>
						<Col span={24} style={{ marginTop: '20px' }}>
							<Preloader spinning={pending2}>
								<Line {...getOptions(docsCouriersFilters.date3, docsCouriers3, docsCouriersFilters.date4, docsCouriers4)} />
							</Preloader>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};
