import browserizr, { isMobile } from '@wezom/browserizr-cjs';
import { letters, dateClientFormat, dateServerFormat } from 'const';
import { MoneyToStr } from 'lib';
import { api, dayjs } from 'utils';

export const nl2br = (str) => {
	if (typeof str === 'undefined' || str === null) {
		return '';
	}
	return String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
};

export const br2nl = (str) => {
	if (typeof str === 'undefined' || str === null) {
		return '';
	}
	return str.replace(/<\s*\/?br\s*\/?>/gi, '');
};

export const addScript = (src) => {
	const script = window.document.createElement('script');
	script.src = src;
	script.defer = true;
	document.getElementsByTagName('body')[0].appendChild(script);
};

export const num2str = (number, texts = []) => {
	number = Math.abs(number) % 100;
	const num = number % 10;
	if (number > 10 && number < 20) {
		return texts[2];
	}
	if (num > 1 && num < 5) {
		return texts[1];
	}
	if (num === 1) {
		return texts[0];
	}
	return texts[2];
};

export const getCode = (string, shift) => {
	return string
		.split('')
		.map((c) => String.fromCharCode(c.charCodeAt(0) + +shift))
		.join('');
};

export const decode = (string) => {
	return JSON.parse(getCode(string, 1));
};

export const encode = (string) => {
	return getCode(JSON.stringify(string), -1);
};

export const hashCode = (str = '') => {
	str = JSON.stringify(str);
	let hash = 0;
	if (str.length === 0) return hash;
	for (let i = 0; i < str.length; i++) {
		hash = (hash << 5) - hash + str.charCodeAt(i);
		hash |= 0;
	}
	return Math.abs(hash);
};

export const phoneClearFull = (phone = '') => {
	return String(phone)
		.replace(/^38/g, '')
		.replace(/^\+38/g, '')
		.replace(/[^+0-9]*/g, '');
};

export const phoneFormatFull = (phone = '') => {
	phone = String(phone);
	if (phone.length <= 3) {
		return phone;
	}
	if (phone.length <= 7) {
		return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
	}
	return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
};

export const phoneClear = (phone = '') => {
	return `+38${phoneClearFull(phone)}`;
};

export const phoneFormat = (phone = '') => {
	return phoneFormatFull(phoneClearFull(phone));
};

export const setBrowserizr = () => {
	browserizr.setUA(window.navigator.userAgent);
	window.document.documentElement.classList = browserizr.classNames([
		{
			is: 'mobile',
			not: 'no-mobile',
			fn: isMobile
		}
	]);
};

export const preloaderShow = () => {
	window.document.getElementById('preloader').classList.remove('is-hide');
};

export const preloaderHide = () => {
	window.document.getElementById('preloader').classList.add('is-hide');
};

export const randomKey = () => {
	return String(Math.random()).substr(2);
};

export const translit = (str) => {
	return [...str].map((char) => (typeof letters[char] === 'undefined' ? char : letters[char])).join('');
};

export const nameFormatter = (value) => {
	return String(value).replace(/^\s+(.*)$/gi, '$1');
};

export const numberFormatter = (value) => {
	const result = String(value).replace(/[^0-9]/gi, '');
	return value === 0 || result ? +result : result;
};

export const floatFormatter = (value) => {
	const result = String(value).replace(/[^0-9.]/gi, '');
	return value === 0 || result ? +result : result;
};

export const isNumeric = (number) => {
	return !isNaN(parseFloat(number)) && isFinite(number);
};

export const formatDigits = ({ number = null, toFixed = 0, delimiter = ' ', defaultValue = null }) => {
	if (number === null || !/^string$|^number$/.test(typeof number)) return defaultValue;
	const _number = (+(number + '').replace(/\s/gi, '')).toFixed(toFixed);
	if (!isNumeric(_number)) return defaultValue;
	return (_number + '').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + delimiter);
};

export const normFile = (event) => {
	if (Array.isArray(event)) {
		return event;
	}
	return event && event.fileList;
};

export const getNegativeOrPositiveCurrencyFormat = ({ number = null, toFixed = 2, defaultValue = null, currency = '' }) => {
	if (!isNumeric(number)) return defaultValue;
	let temp = number;
	let sign = '';
	let style = {
		color: '#000',
		whiteSpace: 'nowrap'
	};
	if (number > 0) {
		temp = temp * 1;
		style.color = '#2e9a5e';
		sign = '+';
	} else if (number < 0) {
		temp = temp * -1;
		style.color = '#ff0101';
		sign = '-';
	}
	return (
		<span style={style}>
			{sign} {formatDigits({ number: temp, toFixed, defaultValue })}&nbsp;{currency}
		</span>
	);
};

export const ucFirst = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addToArray = (list = [], data = {}) => {
	return [...list, data];
};

export const updateArray = (list = [], data = {}, id = 'id') => {
	return list.map((item) => (item[id] === data[id] ? data : item));
};

export const deleteFromArray = (list = [], data = {}, id = 'id') => {
	return list.filter((item) => item[id] !== data[id]);
};

export const money2str = (value) => {
	const moneyToStr = new MoneyToStr(MoneyToStr.Currency.UAH, MoneyToStr.Language.UKR, MoneyToStr.Pennies.NUMBER);
	const [int, kop] = ('' + value).split('.');
	return moneyToStr.convert(parseInt(int), parseInt(kop || 0));
};

export const money2strRu = (value) => {
	const moneyToStr = new MoneyToStr(MoneyToStr.Currency.UAH, MoneyToStr.Language.RUS, MoneyToStr.Pennies.NUMBER);
	const [int, kop] = ('' + value).split('.');
	return moneyToStr.convert(parseInt(int), parseInt(kop || 0));
};

export const downloadFile = (url, fileName, params = {}) => {
	api.get(url, {
		responseType: 'blob',
		params
	}).then((response) => {
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(response.data, fileName);
		} else {
			const link = document.createElement('a');
			link.style.display = 'none';
			document.body.appendChild(link);
			link.href = window.URL.createObjectURL(response.data);
			link.setAttribute('download', fileName);
			link.click();
			window.URL.revokeObjectURL(link.href);
			document.body.removeChild(link);
		}
	});
};

export const getSummaryCellData = (column, row) => {
	let cellChild = null;
	let cellProps = {};
	if (column.className) {
		cellProps.className = column.className;
	}
	if (column.colSpan) {
		cellProps.colSpan = column.colSpan;
	}
	if (column.rowSpan) {
		cellProps.rowSpan = column.rowSpan;
	}
	if (column.render) {
		const comp = column.render(row[column.dataIndex], row);
		if (comp.props) {
			cellProps = {
				...cellProps,
				...comp.props
			};
		}
		if (comp.children) {
			cellChild = comp.children;
		} else {
			cellChild = comp;
		}
	} else if (column.dataIndex) {
		cellChild = row[column.dataIndex];
	}
	return {
		cellProps,
		cellChild
	};
};

export const dateParse = (date) => {
	if (!date) return null;
	const formattedDate = dayjs(date, dateServerFormat);
	return formattedDate.year() === dayjs().year() ? formattedDate.format('DD MMMM') : formattedDate.format(dateClientFormat);
};

export const randomColor = () => {
	const randomHex = () => {
		const hexNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'v', 'd', 'e', 'f'];
		return hexNumbers[Math.floor(Math.random() * hexNumbers.length)];
	};
	const hexValue = ['#'];
	for (let i = 0; i < 6; i++) {
		hexValue.push(randomHex());
	}
	return hexValue.join('');
};

export const json2formData = (query = {}) => {
	const body = new FormData();

	Object.keys(query).forEach((name) => {
		if (Array.isArray(query[name])) {
			for (let i = 0; i < query[name].length; i++) {
				body.append(`${name}[]`, query[name][i]);
			}
		} else if (typeof query[name] !== 'object' && query[name] !== null && query[name] !== undefined) {
			body.append(name, query[name]);
		}
	});

	return body;
};

export const json2data = (values = {}) => {
	const params = {};

	Object.keys(values).forEach((key) => {
		if (Array.isArray(values[key])) {
			params[key] = values[key];
		} else if (values[key] !== null && values[key] !== undefined) {
			params[key] = values[key];
		}
	});

	return params;
};

export const getUserMedia = () => {
	return new Promise((resolve) => {
		if (window.navigator.mediaDevices) {
			window.navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then(() => {
					resolve(true);
				})
				.catch(() => {
					resolve(false);
				});
		} else {
			resolve(false);
		}
	});
};

export const wait = (time) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, time);
	});
};

export const changeCollapsedInHtml = (value) => {
	if (value) {
		window.document.documentElement.classList.add('is-collapsed');
	} else {
		window.document.documentElement.classList.remove('is-collapsed');
	}
};

export const getStringLengthInPx = (value) => {
	const div = window.document.createElement('div');
	div.className = 'hidden-element';
	window.document.body.appendChild(div);
	div.innerHTML = value;
	const width = div.offsetWidth;
	window.document.body.removeChild(div);
	return width;
};

export function sortArray() {
	const args = arguments;

	if (!Array.isArray(args[0])) {
		return 0;
	}

	const array = [...args[0]];
	let caseSensitive = false;
	let keysLength = 0;

	if (typeof arguments[arguments.length - 1] === 'boolean') {
		caseSensitive = arguments[arguments.length - 1];
		keysLength = arguments.length - 1;
	} else {
		caseSensitive = false;
		keysLength = arguments.length;
	}

	return array.sort(function (obj1, obj2) {
		for (let i = 1; i < keysLength; i++) {
			let format;
			let desc;
			let a;
			let b;

			if (typeof args[i] !== 'string') {
				format = args[i][2];
				desc = args[i][1];
				a = obj1[args[i][0]];
				b = obj2[args[i][0]];
			} else {
				desc = false;
				a = obj1[args[i]];
				b = obj2[args[i]];
			}

			if (caseSensitive === false && (format === 's' || typeof a === 'string')) {
				a = a.toLowerCase();
				b = b.toLowerCase();
			}

			if (!desc) {
				if (format === 's' || typeof a === 'string') {
					return a.localeCompare(b);
				} else if (format === 'd') {
					return new Date(b) - new Date(a);
				} else {
					if (a < b) return -1;
					if (a > b) return 1;
				}
			} else {
				if (format === 's' || typeof a === 'string') {
					return b.localeCompare(a);
				} else if (format === 'd') {
					return new Date(a) - new Date(b);
				} else {
					if (b < a) return -1;
					if (b > a) return 1;
				}
			}
		}

		return 0;
	});
}
