import { useState, useEffect } from 'react';
import { Form, Select, InputNumber } from 'antd';
import {
	useMethods,
	useData,
	requiredRule,
	floatFormatter,
	moreThenFiledRule,
	formatDigits,
	getNegativeOrPositiveCurrencyFormat,
	print,
	money2str
} from 'utils';
import { Modal, Preloader, CashWarrant } from 'components';
import { calculateDailyPayoutAction, dailyPayoutAction } from 'actions';

let timeoutId = null;

export const CourierInflow = (props) => {
	const { children, courier = {} } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [cashData, setCashData] = useState(null);
	const [counters, setCounters] = useState({ counter_start: 0, counter_finish: 0 });
	const [form] = Form.useForm();
	const { couriers = [] } = useData();
	const { __ } = useMethods();

	useEffect(() => {
		if (!visible) {
			form.setFieldsValue({
				employee_id: courier.id || null,
				counter_start: 0,
				counter_finish: 0
			});
		}
	}, [visible, courier]);

	const couriersList = couriers.map((item) => ({ value: item.id, label: item.name }));
	const difference = counters.counter_finish - counters.counter_start;
	const distance = difference > 0 ? difference?.toFixed(2) || difference : 0;
	const payout = cashData ? cashData.cash_debt - cashData.total_payout : 0;

	const changeHandler = (changedValues, values) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(async () => {
			const { counter_start, counter_finish } = values;
			if (counter_finish > counter_start) {
				const data = await calculateDailyPayoutAction(courier.id, {
					counter_start: counter_start || 0,
					counter_finish: counter_finish || 0
				});
				setCashData(data);
			}
			setCounters({
				counter_start,
				counter_finish
			});
		}, 500);
	};

	const submitHandler = async (values) => {
		setPending(true);
		const { counter_start, counter_finish } = values;
		const response = await dailyPayoutAction(values.employee_id, {
			counter_start,
			counter_finish
		});
		setPending(false);
		if (response) {
			response.forEach((item) => {
				if (item.sum > 0) {
					print(
						`#cash-warrant-inflow-add-${courier.id}`,
						{
							'[NUMBER]': item.cash_uid || 0,
							'[NAME]': item.counterparty_name || '',
							'[DESCRIPTION]': item.description || '',
							'[SUM]': money2str(Math.abs(item.sum) || 0)
						},
						`@page {margin: 1.1cm 1cm 1.1cm 1cm; size: portrait;}`
					);
				} else if (item.sum < 0) {
					print(
						`#cash-warrant-inflow-${courier.id}`,
						{
							'[NUMBER]': item.cash_uid || 0,
							'[NAME]': item.counterparty_name || '',
							'[DESCRIPTION]': item.description || '',
							'[SUM]': money2str(Math.abs(item.sum) || 0)
						},
						`@page {margin: 1.1cm 1cm 1.1cm 1cm; size: portrait;}`
					);
				}
			});
			setVisible(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{__('Расчет за смену с курьером')}</div>
					<Form className="form" onFinish={submitHandler} onValuesChange={changeHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="employee_id" label={__('Кому')} required rules={[requiredRule(__)]}>
								<Select dropdownClassName="form__dropdown" options={couriersList} disabled={true} />
							</Form.Item>
							<Form.Item
								className="form__item"
								label={__('Показания на начало смены, км')}
								name="counter_start"
								required
								rules={[requiredRule(__)]}
							>
								<InputNumber min={0} step={0.01} formatter={floatFormatter} />
							</Form.Item>
							<Form.Item
								className="form__item"
								label={__('Показания на конец смены, км')}
								name="counter_finish"
								required
								rules={[moreThenFiledRule(form, 'counter_start', __('Конечные показания должны быть больше начальных'))]}
							>
								<InputNumber min={0} step={0.01} formatter={floatFormatter} />
							</Form.Item>
							{cashData ? (
								<div style={{ marginBottom: '20px' }}>
									<div>
										<b>
											{__('Долг курьера')}: {formatDigits({ number: cashData.cash_debt, toFixed: 2, defaultValue: 0 })}{' '}
											{__('грн')}
										</b>
									</div>
									<div>
										{__('Стоимость доставки 1 заказа')}:{' '}
										<b>
											{formatDigits({ number: cashData.per_order_cost, toFixed: 2, defaultValue: 0 })} {__('грн')}
										</b>
									</div>
									<div>
										{__('Количество заказов')}:{' '}
										<b>
											{cashData.orders_quantity || 0} {__('шт')}
										</b>
									</div>
									<div>
										{__('Выплата за заказы')}:{' '}
										<b>
											{formatDigits({ number: cashData.per_orders_payout, toFixed: 2, defaultValue: 0 })} {__('грн')}
										</b>
									</div>
									<div>
										{__('Стоимость доставки за 1 км')}:{' '}
										<b>
											{formatDigits({ number: cashData.per_kilometer_fuel_cost, toFixed: 2, defaultValue: 0 })} {__('грн')}
										</b>
									</div>
									<div>
										{__('Пройденное расстояние')}:{' '}
										<b>
											{distance} {__('км')}
										</b>
									</div>
									<div>
										{__('Планируемая дистанции')}:{' '}
										<b>
											{formatDigits({ number: cashData.planned_distance, toFixed: 2, defaultValue: 0 })} {__('км')}
										</b>
									</div>
									<div>
										{__('Выплата за км')}:{' '}
										<b>
											{formatDigits({ number: cashData.per_kilometer_payout, toFixed: 2, defaultValue: 0 })} {__('грн')}
										</b>
									</div>
									<div>
										<b>
											{__('Общая выплата')}: {formatDigits({ number: cashData.total_payout, toFixed: 2, defaultValue: 0 })}{' '}
											{__('грн')}
										</b>
									</div>
									{payout > 0 ? (
										<div style={{ color: '#2e9a5e' }}>
											<b>
												{__('Итого от курьера')}:{' '}
												{getNegativeOrPositiveCurrencyFormat({
													number: payout,
													toFixed: 2,
													defaultValue: 0,
													currency: __('грн')
												})}
											</b>
										</div>
									) : payout < 0 ? (
										<div style={{ color: '#ff0101' }}>
											<b>
												{__('Итого курьеру')}:{' '}
												{getNegativeOrPositiveCurrencyFormat({
													number: payout,
													toFixed: 2,
													defaultValue: 0,
													currency: __('грн')
												})}
											</b>
										</div>
									) : (
										<div>
											<b>
												{__('Итого по выплатам')}: {formatDigits({ number: payout, toFixed: 2, defaultValue: 0 })} {__('грн')}
											</b>
										</div>
									)}
								</div>
							) : null}
							<div className="form__button">
								<button className="button button--main button--full">
									<span>{__('Подтвердить выплату')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
					<div className="is-print">
						<div id={`cash-warrant-inflow-add-${courier.id}`}>
							<CashWarrant isAdd={true} />
						</div>
						<div id={`cash-warrant-inflow-${courier.id}`}>
							<CashWarrant />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};
