import { useState, useEffect } from 'react';
import { Form, Select, Input, Col, Row, DatePicker } from 'antd';
import { useMethods, useData, requiredRule, getOrderStatuses, dayjs, getDeliveryTimes } from 'utils';
import { Modal, Preloader } from 'components';
import { changeStatusAction, getDeliveryTimesAction } from 'actions';
import { dateClientFormat, dateServerFormat, timeClientFormat, timeServerFormat } from 'const';

export const ChangeOrderStatus = (props) => {
	const { children, order = {} } = props;
	const [visible, setVisible] = useState(false);
	const [pending, setPending] = useState(false);
	const [status, setStatus] = useState(order.status_id);
	const [form] = Form.useForm();
	const { orderStatuses = [], orderTypes = [] } = useData();
	const { __ } = useMethods();
	const [pendingDeliveryTime, setPendingDeliveryTime] = useState(false);
	const [deliveryTimesList, setDeliveryTimesList] = useState([]);

	const orderStatusesList = getOrderStatuses(orderStatuses, orderTypes, order.status_id, order.order_type_id, order.delivery_type_id);

	useEffect(() => {
		if (visible) {
			(async () => {
				setPending(true);
				const deliveryTimes = await getDeliveryTimesAction(order.delivery_date, order.delivery_type_id);
				setDeliveryTimesList(getDeliveryTimes(deliveryTimes, order.delivery_date, __));
				form.setFieldsValue({
					status_id: order.status_id,
					rejection_reason: order.rejection_reason,
					delivery_date: order.delivery_date ? dayjs(order.delivery_date, dateServerFormat) : null,
					delivery_time: order.delivery_time ? dayjs(order.delivery_time, timeServerFormat).format(timeClientFormat) : null
				});
				setPending(false);
			})();
		}
	}, [visible]);

	const submitHandler = async (values) => {
		setPending(true);
		const success = await changeStatusAction(order.id, {
			status_id: values.status_id,
			rejection_reason: values.rejection_reason || null,
			delivery_date: values.delivery_date ? dayjs(values.delivery_date).format(dateServerFormat) : null,
			delivery_time: values.delivery_time ? `${values.delivery_time}:00` : null
		});
		setPending(false);
		if (success) {
			setVisible(false);
		}
	};

	const changeHandler = async (changedValues) => {
		if (changedValues.status_id) {
			setStatus(changedValues.status_id);
		}

		if (changedValues.delivery_date !== undefined) {
			setPendingDeliveryTime(true);
			const deliveryTimes = await getDeliveryTimesAction(changedValues.delivery_date, order.delivery_type_id);
			setDeliveryTimesList(getDeliveryTimes(deliveryTimes, changedValues.delivery_date, __));
			form.setFieldsValue({
				delivery_time: deliveryTimes[0]
			});
			setPendingDeliveryTime(false);
		}
	};

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="small">
				<div className="modal__container">
					<div className="modal__title">{`${__('Изменение статуса заказа')} ${order.day_id || order.id}`}</div>
					<Form className="form" onFinish={submitHandler} onValuesChange={changeHandler} form={form} layout="vertical" noValidate={true}>
						<Preloader spinning={pending}>
							<Form.Item className="form__item" name="status_id" label={__('Статус заказа')} required rules={[requiredRule(__)]}>
								<Select dropdownClassName="form__dropdown" options={orderStatusesList} allowClear={true} />
							</Form.Item>
							{status === 10 ? (
								<Form.Item
									className="form__item form__item--autosize"
									name="rejection_reason"
									label={__('Причина отказа')}
									required
									rules={[requiredRule(__)]}
								>
									<Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} allowClear={true} />
								</Form.Item>
							) : null}
							{status === 12 ? (
								<Row gutter={20}>
									<Col span={24} sm={12}>
										<Form.Item
											className="form__item"
											label={__('Дата доставки')}
											name="delivery_date"
											required
											rules={[requiredRule(__)]}
										>
											<DatePicker
												format={dateClientFormat}
												allowClear={false}
												disabledDate={(current) => current && current < dayjs().startOf('day')}
											/>
										</Form.Item>
									</Col>
									<Col span={24} sm={12}>
										<Preloader spinning={pendingDeliveryTime}>
											<Form.Item
												className="form__item"
												name="delivery_time"
												label={__('Время доставки')}
												required
												rules={[requiredRule(__)]}
											>
												<Select dropdownClassName="form__dropdown" options={deliveryTimesList} />
											</Form.Item>
										</Preloader>
									</Col>
								</Row>
							) : null}
							<div className="form__button">
								<button className="button button--main button--full" disabled={status === order.status_id}>
									<span>{__('Сохранить')}</span>
								</button>
							</div>
						</Preloader>
					</Form>
				</div>
			</Modal>
		</>
	);
};
