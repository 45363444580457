import { useData, useMethods } from 'utils';
import { Tag } from 'antd';
import { statusIcon } from 'const';
import { getFinishedOrdersAction, getFinishedOrdersStatisticAction } from 'actions';

export const FinishedOrdersStatistic = (props) => {
	const { setPending } = props;
	const { finishedOrdersStatistic = {}, finishedOrdersFilters = {}, orderStatuses = [] } = useData();
	const { statuses = [], custom = [] } = finishedOrdersStatistic;
	const { __, update } = useMethods();

	const result = [];
	statuses.forEach((item) => {
		const statusItem = orderStatuses.find((status) => status.id === item.id);
		if (statusItem) {
			result.push({
				alias: item.id,
				title: statusItem.name,
				value: `${item.count} / ${item.sum} ${__('грн')}`,
				icon: statusIcon[item.id] || null
			});
		}
	});
	custom.forEach((item) => {
		result.push({
			alias: item.alias,
			title: item.title,
			value: item.value,
			icon: statusIcon[item.alias] || null
		});
	});

	const changeHandler = async (value) => {
		setPending(true);
		const filter = {
			...finishedOrdersFilters
		};
		const isCustom = custom.find((item) => item.alias === value);
		if (isCustom) {
			filter.custom = value;
			filter.status = null;
		} else {
			filter.status = value;
			filter.custom = null;
		}
		const [finishedOrdersStatistic, finishedOrders] = await Promise.all([
			getFinishedOrdersStatisticAction(filter),
			getFinishedOrdersAction(filter)
		]);
		setPending(false);
		update({
			finishedOrdersFilters: {
				...finishedOrdersFilters,
				...filter
			},
			finishedOrdersStatistic,
			finishedOrders
		});
	};

	return (
		<div className="orders-statistic">
			<div className="orders-statistic__list">
				{result.map(({ alias, title, value, icon }, index) => (
					<div className="orders-statistic__item" key={index}>
						<Tag
							className={`orders-statistic__tag${
								finishedOrdersFilters.status === alias || finishedOrdersFilters.custom === alias ? ' is-active' : ''
							}`}
							onClick={() => changeHandler(alias)}
						>
							{icon}
							<span>{title}:</span>
							<strong>{value}</strong>
						</Tag>
					</div>
				))}
			</div>
		</div>
	);
};
