import { useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from 'recharts';
import { useMethods, dayjs } from 'utils';
import { Modal } from 'components';
import { dateServerFormat, dateClientFormat } from 'const';

export const RevenueGraphDay = (props) => {
	const { children, date, data = [] } = props;
	const [visible, setVisible] = useState(false);
	const { __ } = useMethods();

	const list = [];
	data.forEach((item) => {
		if (item[`${date}`]?.amount) {
			list.push({
				name: item.name,
				amount: item[`${date}`].amount
			});
		}
	});

	return (
		<>
			<div onClick={() => setVisible(true)}>{children}</div>
			<Modal visible={visible} setVisible={setVisible} type="big">
				<div className="modal__container">
					<div className="modal__title">{`${__('Статистика за')} ${dayjs(date, dateServerFormat).format(dateClientFormat)}`}</div>
					<ResponsiveContainer width="100%" height={600}>
						<BarChart data={list}>
							<XAxis dataKey="name" />
							<YAxis />
							<Bar dataKey="amount" fill="#000000" />
						</BarChart>
					</ResponsiveContainer>
				</div>
			</Modal>
		</>
	);
};
